import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createFare } from "../../../actions/fareActions";
import AdminSettingPaymentAddCompanyModal from "./AdminSettingPaymentAddCompanyModal";
import { getExternalCompanies } from "../../../actions/companyAction";

const companies = [
  { id: 1, name: "Company A" },
  { id: 2, name: "Company B" },
  { id: 3, name: "Company C" },
];

function AdminSettingPaymentsFare() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const externalCompanies = useSelector((state) => state.externalCompanies);
  const location = useSelector((state) => state.location);
  const myState = useSelector((state) => state.changeTheTab);
  const service = useSelector((state) => state.service);

  let [showModal, setShowModal] = useState();
  let [selectedCompanyId, setSelectedCompanyId] = useState();
  let [serviceValue, setServiceValue] = useState();
  
  const [values, setValues] = useState({
    companyId: auth.user.companyId.id,
    serviceId: "",
    name: "",

    flatBaseFare: "",

    farePerMile: "",
    deadHeadThreshold: "",
    
    baseFarePerMile: "",
    totalMileageAllows: "",

    longDistancePerMile: "",

    offHourFee: "",
    noShowFee: "",
    cancelationWindow: "",
    cancelationFee: "",
    
    isCompany: false,
    
    // flatBaseFare: "",
    // isFlatBaseFare: false,
  });

  const handleChange = (fieldName) => (event) => {
    // if (fieldName === "isFlatBaseFare") {
    //   setValues({
    //     ...values,
    //     [fieldName]: JSON.parse(event.target.value),
    //   });
    // } else 
    if (fieldName === "isCompany") {
      setValues({
        ...values,
        [fieldName]: JSON.parse(event.target.value.toLowerCase()),
      });
    } else {
      setValues({
        ...values,
        [fieldName]: event.target.value,
      });
    }
  };
  // const fareData = {
  //   companyId: "63cb14f53f55723694dbd185",
  //   serviceId: serviceValue ? serviceValue.id : "",
  //   name: values.name,
  //   isFlatBaseFare: values.isFlatBaseFare,
  //   flatBaseFare: values.flatBaseFare,
  //   baseFarePerMile: values.baseFarePerMile,
  //   farePerMile: values.farePerMile,
  //   totalMileageAllows: values.totalMileageAllows,
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // if (values.isFlatBaseFare === true) {
    //   delete values.baseFarePerMile;
    // } else if (values.isFlatBaseFare === false) {
    //   delete values.flatBaseFare;
    // }
    let fareData = values
    for (let key of Object.keys(fareData)) {
      if (fareData[key] === "") {
        delete fareData[key];
      }
    }
    dispatch(createFare({ ...fareData, externalCompanyId: selectedCompanyId }));
    setValues({
      companyId: "",
      serviceId: "",
      name: "",
      flatBaseFare: "",
      farePerMile: "",
      deadHeadThreshold: "",
      baseFarePerMile: "",
      totalMileageAllows: "",
      longDistancePerMile: "",
      offHourFee: "",
      noShowFee: "",
      cancelationWindow: "",
      cancelationFee: "",
      isCompany: false,
    })
  };

  useEffect(() => {
    setServiceValue(
      service.service.find((c) => c.name.includes(myState.viewPayment))
    );
  }, [myState]);

  useEffect(() => {
    dispatch(getExternalCompanies({ companyId: auth.user.companyId.id }));
  }, [auth]);

  const alphabets = [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "+",
    "-",
    "/",
    "*",
    "!",
    "@",
    "#",
    "$",
    "%",
    "^",
    "&",
    "_",
    "(",
    ")",
  ];
  // console.log(serviceValue, "serviceValue", values);
  const [ additionalChargesView, setAdditionalChargesView] = useState(false)
  return (
    <>
      {location.baseLocation && location.baseLocation ? (
        <>
          {/* <div className="card tab-box">
                    <div className="row user-tabs">
                        <div className="col-lg-12 col-md-12 col-sm-12 line-tabs">
                            <ul className="nav nav-tabs nav-tabs-bottom">
                                <li className="nav-item ">
                                    <button style={{cursor: "pointer"}}  className="nav-link navlink-custom-border navlink-custom-border-active">New</button>
                                </li>
                                <li className="nav-item">
                                    <button style={{cursor: "pointer"}}  className="nav-link navlink-custom-border">List</button >
                                </li>
                            </ul>
                        </div>
                    </div>
                </div> */}
          <div className="s-b-n-content">
            <div className="tab-content" id="nav-tabContent">
              <div
                className="tab-pane fade show active"
                id="nav-email"
                role="tabpanel"
                aria-labelledby="nav-email-tab"
              >
                <div className="d-flex flex-wrap justify-content-between">
                  <div className="col-lg-12 col-md-12 ntfcn-tab-content-left w-100 p-4 ">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group my-3">
                          <label
                            className="f-14 text-dark mb-12"
                            data-label=""
                            htmlFor="Name"
                          >
                            {" "}
                            Name
                          </label>
                          <sup className="text-danger">*</sup>
                          <input
                            type="text"
                            className="form-control height-35 f-14"
                            placeholder="Enter Name"
                            id="Name"
                            value={values.name}
                            onChange={handleChange("name")}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group my-3 ">
                          <div className="d-flex justify-content-between">
                            <div className="d-flex">
                              <label
                                className="f-14 text-dark mb-12"
                                data-label=""
                                htmlFor="Name"
                              >
                                {" "}
                                Type
                              </label>
                              <sup className="text-danger">*</sup>
                            </div>
                          </div>
                          {/* {serviceValue && serviceValue.name && ( */}
                          <select
                            //   type="text"
                            className="form-control height-35 f-14"
                            onChange={handleChange("isCompany")}

                            //   value={serviceValue.name}
                            //   disabled
                          >
                            <option value="">Select Type</option>
                            <option value={false}>Individual</option>
                            <option value={true}>Company</option>
                          </select>
                          {/* )} */}
                        </div>
                      </div>

                      {values.isCompany && (
                        <div className="col-md-3">
                          <div className="form-group my-3 ">
                            <div className="d-flex justify-content-between">
                              <div className="d-flex">
                                <label
                                  className="f-14 text-dark mb-12"
                                  data-label=""
                                  htmlFor="Name"
                                >
                                  {" "}
                                  Select Company
                                </label>
                                <sup className="text-danger">*</sup>
                              </div>
                              <div>
                                <button
                                  className="bg-button"
                                  type="button"
                                  data-toggle="modal"
                                  data-target="#exampleModalCenter"
                                  onClick={() => setShowModal(true)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    className="bi bi-plus-circle-fill fa-w-20 f-20 text-dark"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                  </svg>
                                </button>
                              </div>
                            </div>
                            {/* {serviceValue && serviceValue.name && ( */}
                            <select
                              //   type="text"
                              className="form-control height-35 f-14"
                              onChange={(e) =>
                                setSelectedCompanyId(e.target.value)
                              }
                              value={selectedCompanyId}
                              //   value={serviceValue.name}
                              //   disabled
                            >
                              <option value="">Select Company</option>
                              {externalCompanies.list.map((x,i) => (
                                <option value={x.id} key={i}>{x.name}</option>
                              ))}
                            </select>
                            {/* )} */}
                          </div>
                        </div>
                      )}
                      <div className="col-md-3">
                        <div className="form-group my-3 ">
                          <div className="d-flex justify-content-between">
                            <div className="d-flex">
                              <label
                                className="f-14 text-dark mb-12"
                                data-label=""
                                htmlFor="Name"
                              >
                                {" "}
                                Service Type
                              </label>
                              <sup className="text-danger">*</sup>
                            </div>
                          </div>
                          {/* {serviceValue && serviceValue.name && ( */}
                          <select
                            //   type="text"
                            className="form-control height-35 f-14"
                            id="Name"
                            //   value={serviceValue.name}
                            onChange={handleChange("serviceId")}
                            //   disabled
                          >
                            <option value="">Select Service</option>
                            {service.service.map((x, i) => (
                              <option value={x.id} key={i}>{x.name}</option>
                            ))}
                          </select>
                          {/* )} */}
                        </div>
                      </div>
                      {/* <div className="col-md-3">
                        <div className="form-group my-3">
                          <label
                            className="f-14 text-dark mb-12"
                            data-label=""
                            htmlFor="Name"
                          >
                            {" "}
                            Flat Base Fare
                          </label>
                          <sup className="text-danger">*</sup>
                          <div className="select-picker">
                            <select
                              className="form-control select-picker"
                              style={{ height: "39px", fontSize: "14px" }}
                              value={values.isFlatBaseFare}
                              onChange={handleChange("isFlatBaseFare")}
                            >
                              <option value="false" key={"st"}>
                                {" "}
                                No
                              </option>
                              <option value="true" key={"st"}>
                                {" "}
                                Yes
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      {values.isFlatBaseFare === true && (
                        <div className="col-md-3">
                          <div className="form-group my-3">
                            <label
                              className="f-14 text-dark mb-12"
                              data-label=""
                              htmlFor="Name"
                            >
                              {" "}
                              Flat Fare Price
                            </label>
                            <sup className="text-danger">*</sup>
                            <input
                              type="text"
                              className="form-control height-35 f-14"
                              placeholder="Enter Flat Fare Price"
                              id="Name"
                              onKeyDown={(e) => {
                                if (alphabets.includes(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              value={values.flatBaseFare}
                              onChange={handleChange("flatBaseFare")}
                            />
                          </div>
                        </div>
                      )} */}


                      {/* {values.isFlatBaseFare === false && (
                        <div className="col-md-3">
                          <div className="form-group my-3">
                            <label
                              className="f-14 text-dark mb-12"
                              data-label=""
                              htmlFor="Name"
                            >
                              {" "}
                              Base Fare Per Mile
                            </label>
                            <sup className="text-danger">*</sup>
                            <input
                              type="text"
                              className="form-control height-35 f-14"
                              placeholder="Enter Base Fare Per Mile"
                              id="Name"
                              onKeyDown={(e) => {
                                if (alphabets.includes(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              value={values.baseFarePerMile}
                              onChange={handleChange("baseFarePerMile")}
                            />
                          </div>
                        </div>
                      )}
                      <div className="col-md-3">
                        <div className="form-group my-3">
                          <label
                            className="f-14 text-dark mb-12"
                            data-label=""
                            htmlFor="Name"
                          >
                            {" "}
                            Fare Per Mile
                          </label>
                          <sup className="text-danger">*</sup>
                          <input
                            type="text"
                            className="form-control height-35 f-14"
                            placeholder="Enter Fare Per Mile"
                            id="Name"
                            onKeyDown={(e) => {
                              if (alphabets.includes(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            value={values.farePerMile}
                            onChange={handleChange("farePerMile")}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group my-3">
                          <label
                            className="f-14 text-dark mb-12"
                            data-label=""
                            htmlFor="Name"
                          >
                            {" "}
                            Total Mileage Allows
                          </label>
                          <sup className="text-danger">*</sup>
                          <input
                            type="text"
                            className="form-control height-35 f-14"
                            placeholder="Enter Total Mileage Allows"
                            id="Name"
                            onKeyDown={(e) => {
                              if (alphabets.includes(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            value={values.totalMileageAllows}
                            onChange={handleChange("totalMileageAllows")}
                          />
                        </div>
                      </div> */}

                      <div className="col-md-3">
                        <div className="form-group my-3">
                          <label
                            className="f-14 text-dark mb-12"
                            data-label=""
                            htmlFor="Name"
                          >
                            {" "}
                            Base Rate
                          </label>
                          <sup className="text-danger">*</sup>
                          <input
                            type="text"
                            className="form-control height-35 f-14"
                            placeholder="Enter Load Fee"
                            id="Name"
                            onKeyDown={(e) => {
                              if (alphabets.includes(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            value={values.flatBaseFare}
                            onChange={handleChange("flatBaseFare")}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group my-3">
                          <label
                            className="f-14 text-dark mb-12"
                            data-label=""
                            htmlFor="Name"
                          >
                            {" "}
                            Standard Mileage Rate
                          </label>
                          <sup className="text-danger">*</sup>
                          <input
                            type="text"
                            className="form-control height-35 f-14"
                            placeholder="Enter Fare Per Mile"
                            id="Name"
                            onKeyDown={(e) => {
                              if (alphabets.includes(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            value={values.farePerMile}
                            onChange={handleChange("farePerMile")}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group my-3">
                          <div className="d-flex">
                            <div>
                              <label className="f-14 text-dark mb-12" data-label="" htmlFor="clnumber">Add Additional Charges</label>
                            </div>
                          </div>
                          <div className="btn-choice">
                            <label className="switch">
                              <input type="checkbox" className="switch-input" id="toggle" checked={additionalChargesView} onClick={() => setAdditionalChargesView((additionalChargesView) => !additionalChargesView)}/>
                              <span className="switch-label" data-on="" data-off=""></span>
                              <span className="switch-handle"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    {
                      additionalChargesView === true &&
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group my-3">
                            <label
                              className="f-14 text-dark mb-12"
                              data-label=""
                              htmlFor="Name"
                            >
                              {" "}
                              Dead Head Threshold
                            </label>
                            {/* <sup className="text-danger">*</sup> */}
                            <input
                              type="text"
                              className="form-control height-35 f-14"
                              placeholder="Enter Dead Head Threshold"
                              id="Name"
                              onKeyDown={(e) => {
                                if (alphabets.includes(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              value={values.deadHeadThreshold}
                              onChange={handleChange("deadHeadThreshold")}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group my-3">
                            <label
                              className="f-14 text-dark mb-12"
                              data-label=""
                              htmlFor="Name"
                            >
                              {" "}
                              Deadhead Mileage Rate
                            </label>
                            {/* <sup className="text-danger">*</sup> */}
                            <input
                              type="text"
                              className="form-control height-35 f-14"
                              placeholder="Enter Base Fare Per Mile"
                              id="Name"
                              onKeyDown={(e) => {
                                if (alphabets.includes(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              value={values.baseFarePerMile}
                              onChange={handleChange("baseFarePerMile")}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group my-3">
                            <label
                              className="f-14 text-dark mb-12"
                              data-label=""
                              htmlFor="Name"
                            >
                              {" "}
                              Long Distance Threshold
                            </label>
                            {/* <sup className="text-danger">*</sup> */}
                            <input
                              type="text"
                              className="form-control height-35 f-14"
                              placeholder="Enter Long Distance Threshold"
                              id="Name"
                              onKeyDown={(e) => {
                                if (alphabets.includes(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              value={values.totalMileageAllows}
                              onChange={handleChange("totalMileageAllows")}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group my-3">
                            <label
                              className="f-14 text-dark mb-12"
                              data-label=""
                              htmlFor="Name"
                            >
                              {" "}
                              Long Distance Per Mile
                            </label>
                            {/* <sup className="text-danger">*</sup> */}
                            <input
                              type="text"
                              className="form-control height-35 f-14"
                              placeholder="Enter Long Distance Per Mile"
                              id="Name"
                              onKeyDown={(e) => {
                                if (alphabets.includes(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              value={values.longDistancePerMile}
                              onChange={handleChange("longDistancePerMile")}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group my-3">
                            <label
                              className="f-14 text-dark mb-12"
                              data-label=""
                              htmlFor="Name"
                            >
                              {" "}
                              Off-hours Fee
                            </label>
                            {/* <sup className="text-danger">*</sup> */}
                            <input
                              type="text"
                              className="form-control height-35 f-14"
                              placeholder="Enter Off-Hours Fee"
                              id="Name"
                              onKeyDown={(e) => {
                                if (alphabets.includes(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              value={values.offHourFee}
                              onChange={handleChange("offHourFee")}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group my-3">
                            <label
                              className="f-14 text-dark mb-12"
                              data-label=""
                              htmlFor="Name"
                            >
                              {" "}
                              No-show Fee
                            </label>
                            {/* <sup className="text-danger">*</sup> */}
                            <input
                              type="text"
                              className="form-control height-35 f-14"
                              placeholder="Enter No-show Fee"
                              id="Name"
                              onKeyDown={(e) => {
                                if (alphabets.includes(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              value={values.noShowFee}
                              onChange={handleChange("noShowFee")}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group my-3">
                            <label
                              className="f-14 text-dark mb-12"
                              data-label=""
                              htmlFor="Name"
                            >
                              {" "}
                              Cancelation Window
                            </label>
                            {/* <sup className="text-danger">*</sup> */}
                            <input
                              type="text"
                              className="form-control height-35 f-14"
                              placeholder="Enter Cancelation Window"
                              id="Name"
                              onKeyDown={(e) => {
                                if (alphabets.includes(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              value={values.cancelationWindow}
                              onChange={handleChange("cancelationWindow")}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group my-3">
                            <label
                              className="f-14 text-dark mb-12"
                              data-label=""
                              htmlFor="Name"
                            >
                              {" "}
                              Cancelation Fee
                            </label>
                            {/* <sup className="text-danger">*</sup> */}
                            <input
                              type="text"
                              className="form-control height-35 f-14"
                              placeholder="Enter Cancelation Fee"
                              id="Name"
                              onKeyDown={(e) => {
                                if (alphabets.includes(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              value={values.cancelationFee}
                              onChange={handleChange("cancelationFee")}
                            />
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn-primary rounded f-14 p-2 mr-3"
                    onClick={handleSubmit}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="s-b-n-content">
          <div className="tab-content" id="nav-tabContent">
            <div
              className="tab-pane fade show active"
              id="nav-email"
              role="tabpanel"
              aria-labelledby="nav-email-tab"
            >
              <div className="d-flex flex-wrap justify-content-between">
                <div className="col-lg-12 col-md-12 ntfcn-tab-content-left w-100 p-4 ">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group my-3">
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="Name"
                        >
                          {" "}
                          First Fill the Base Location then Come Back
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <AdminSettingPaymentAddCompanyModal
        {...{ showModal, setShowModal, setSelectedCompanyId }}
      />
    </>
  );
}

export default AdminSettingPaymentsFare;
