import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { getPastReservationsByUserId } from '../../../actions/reservationAction';
import moment from 'moment';
import { Link, NavLink } from 'react-router-dom';
import shift1 from "../../../assets/images/load.png"

const DriverMyHistoryTableComponent = () => {
  const auth = useSelector((state) => state.auth);
  const reservation = useSelector((state) => state.reservation);
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false)
  const openMoreVertDropDown = () => {
      setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown)
  }
  const dispatch = useDispatch()
  const historyData = async (e) => {
      dispatch(getPastReservationsByUserId({
        driverId: auth.user.id,
        limit: limit,
        page: currentPageNumber 
    }))
  }
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [limit, setLimit] = useState(10);
  const handleLimit = async (event) => {
      setLimit(parseInt(event.target.value));
      dispatch(
        getPastReservationsByUserId({ 
          driverId: auth.user.id,
          limit: event.target.value,
          page: 1,
          })
      );
      setCurrentPageNumber(1);
  };
  const onClickNext = async () => {
    let i = currentPageNumber;
      i++;
      if (i <= reservation.pastHistory.totalPages) {
      setCurrentPageNumber(currentPageNumber + 1);
      dispatch(
        getPastReservationsByUserId({
          driverId: auth.user.id,
          limit: limit,
          page: currentPageNumber + 1,
        })
      );
    }
  };
  const onClickPrev = async () => {
    let i = currentPageNumber;
      i--;
      if (i >= 1) {
      setCurrentPageNumber(currentPageNumber - 1);
      dispatch(
        getPastReservationsByUserId({
          driverId: auth.user.id,
          limit: limit,
          page: currentPageNumber - 1,
        })
      );
    }
  };
  const handleChangeData = async (pageNo) => {
    setCurrentPageNumber(pageNo);
    dispatch(
      getPastReservationsByUserId({
        driverId: auth.user.id,
        limit: limit,
        page: pageNo,
      })
    );
  };

  useEffect(() => {
      historyData()
  },[])
  // console.log(reservation, "djdjdjjdjjdjdjdjdjdjdjdjdjdjdjdjdjdjd")
  return (
    <>
      <div className="d-flex flex-column w-tables rounded mt-3 bg-white">
        <div className="d-flex border">
          <div className="flex-grow-1">
            <div className="dataTables_length d-flex" id="leads-table_length">
              <div className="p-2">
                <label>Show entries</label>
              </div>
              <label>
                <select
                  name="leads-table_length"
                  aria-controls="leads-table"
                  className="custom-select custom-select-sm form-control form-control-sm"
                  // className="select"
                  autoComplete="false"
                  onChange={handleLimit}
                  >
                  {[10, 25, 50, 100].map((current, i) => {
                    return (
                    <option value={current} key={i}>
                      {current}
                    </option>
                    );
                })}
              </select>
            </label>
          </div>
        </div>
      </div>
      <div id="leads-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
        <div className="row">
          <div className="col-sm-12">
            <table className="table border w-100 dataTable no-footer" id="leads-table" role="grid" aria-describedby="leads-table_info" style={{ overflowX: "auto" }}>
              <thead className="card-header">
                <tr role="row">
                  <th title="order" className="sorting border-right" tabIndex="0" aria-controls="leads-table" rowSpan="1" colSpan="1" aria-label="Mobile: activate to sort column ascending">
                    Order ID
                  </th>
                  <th title="Passenger Name" className="sorting_disabled border-right" rowSpan="1" colSpan="1" aria-label="Next Follow Up">
                    Passenger Name
                  </th>
                  <th title="Phone#" className="sorting border-right" tabIndex="0" aria-controls="leads-table" rowSpan="1" colSpan="1" aria-label="Lead Agent: activate to sort column ascending">
                    Phone#
                  </th>
                  <th title="Asset#" className="sorting border-right" tabIndex="0" aria-controls="leads-table" rowSpan="1" colSpan="1" aria-label="Lead Agent: activate to sort column ascending">
                    Asset#
                  </th>
                  <th title="Pickup Drop Off" className="sorting border-right w-30" tabIndex="0" aria-controls="leads-table" rowSpan="1" colSpan="1" aria-label="Lead Agent: activate to sort column ascending">
                    Pickup Drop Off
                  </th>
                  <th title="Date and time" className="sorting border-right" tabIndex="0" aria-controls="leads-table" rowSpan="1" colSpan="1" aria-label="Lead Agent: activate to sort column ascending">
                    Order Date
                  </th>
                  <th title="Status" className="sorting border-right" tabIndex="0" aria-controls="leads-table" rowSpan="1" colSpan="1" aria-label="Lead Agent: activate to sort column ascending">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
              {
                reservation &&
                reservation.pastHistory &&
                reservation.pastHistory.results &&
                reservation.pastHistory.results.length > 0 &&
                reservation.pastHistory.results.map((current, i) => {
                  // if (current.delete === false) {
                  return(
                  <tr role="row" key={i} className="odd border">
                    <td className="border-right">{current.orderId}
                      <i data-toggle="tooltip" className=""></i>
                    </td>
                    <td className="border-right">{current.customerId.firstName+" "+current.customerId.lastName}</td>
                    <td className="border-right">{current.customerId.phone}</td>
                    <td className="border-right">
                      {current.assetId.assetNo}
                      <i data-toggle="tooltip" className=""></i>
                    </td>
                    <td className="border-right w-30">
                      <div className="d-flex justify-content-between">
                        <div>
                          <div>
                            <p className="f-12 font-medium mb-0"><strong>{current.pickUpAddress.address}</strong> </p>
                            {/* <p className="f-12 mb-0">IL 60609, USA</p> */}
                          </div>
                          <div>
                            <time>{moment(current?.pickUpDate?.substring(0, 10)).format("MM/DD/YYYY")} - {current.pickUpTime}{parseInt(current.pickUpTime.substring(0, 2)) > 11 ? "pm" : 'am'}</time>
                          </div>
                        </div>
                        <div className="mt-4 px-4">
                          <div className="hidden sm:block">
                            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="12" viewBox="0 0 40 6" fill="none">
                              <path d="M40 3L35 0.113249V5.88675L40 3ZM0 3.5H35.5V2.5H0V3.5Z" fill="black"></path>
                            </svg>
                          </div>
                          <p className="text-center font-medium">
                            <span>{current.totalMileage}mi</span>
                          </p>
                        </div>
                        <div>
                          <div>
                            <p className="f-12 font-medium mb-0"><strong>{current.dropOffAddress.address}</strong></p>
                            {/* <p className="f-12 mb-0">IL 60623</p> */}
                          </div>
                          <div>
                            <time>{moment(current?.dropOffDate?.substring(0, 10)).format("MM/DD/YYYY")} - {current.dropOffTime}{parseInt(current.dropOffTime.substring(0, 2)) > 11 ? "pm" : 'am'}</time>
                          </div>
                        </div>
                      </div>
                    </td>
                      <td className="border-right">{moment(current.dateForReservation).format('MM/DD/YYYY')}<br /></td>
                      <td className="border-right"> {current.status} </td>
                    </tr>
                    )
                  })
                  }
                </tbody>
              </table>
              {
                reservation &&
                reservation.pastHistory &&
                reservation.pastHistory.results &&
                reservation.pastHistory.results.length === 0 &&
                <div className="dash-stats-list" style={{ textAlign: "center" }}>
                <br />
                <br />
                <img
                    alt=""
                    src={shift1}
                    style={{ width: "145px", opacity: "0.5" }} />
                <br />
                <p style={{ opacity: "0.5" }}>
                    No Data Found
                </p>
                </div>
              }
              <div
                id="leads-table_processing"
                className="dataTables_processing card"
                style={{ display: "none" }}
              >
                Processing...
              </div>
            </div>
          </div>
          <div className="d-flex border">
          {
            reservation.loading === false ?
            <div className="flex-grow-1">
              <div
                className="dataTables_info"
                id="leads-table_info"
                role="status"
                aria-live="polite"
              >
                Showing {1 + limit * (currentPageNumber - 1)} to{" "}
                {reservation.pastHistory.displayingResults + limit * (currentPageNumber - 1)}{" "}
                of {reservation.pastHistory.totalResults} entries
              </div>
            </div>
            :
            <div className="flex-grow-1">
              <div
                className="dataTables_info"
                id="leads-table_info"
                role="status"
                aria-live="polite"
              >
                Loading entries
              </div>
            </div>
          }
          <div>
          <div
            className="dataTables_paginate paging_simple_numbers"
            id="leads-table_paginate"
          >
            <ul className="pagination">
              <li className="page-item">
                <NavLink
                  className="page-link"
                  to="#"
                  aria-label="Previous"
                  onClick={onClickPrev}
                  >
                    <span aria-hidden="true">Previous</span>
                    <span className="sr-only">Previous</span>
                </NavLink>
              </li>
              { 
                reservation &&
                reservation.pastHistory &&
                reservation.pastHistory.paginationArr &&
                reservation.pastHistory.paginationArr.length > 0 &&
                reservation.pastHistory.paginationArr.map((current, i) => {
                  return currentPageNumber === current ? (
                    <li className="page-item active" key={i}>
                      <Link className="page-link" value={current} to="">
                        {current}
                      </Link>
                    </li>
                    ) : (
                    <li className="page-item" key={i}>
                      <Link
                        className="page-link"
                        to=""
                        value={current}
                        onClick={() => handleChangeData(current)}
                      >
                        {current}
                      </Link>
                    </li>
                      );
                    })}
                    <li
                      className="paginate_button page-item next"
                      id="leads-table_next"
                    >
                      <Link
                        to=""
                        aria-controls="leads-table"
                        data-dt-idx="3"
                        tabIndex="0"
                        className="page-link"
                        onClick={onClickNext}
                      >
                        Next
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  )
}
export default DriverMyHistoryTableComponent



{/* <tr id="row-20" role="row" className="odd border">
      <td className="border-right">90987
        <i data-toggle="tooltip" data-original-title="90987" className="fa fa-info-circle fa-w-16 text-info"></i>
      </td>
      <td className="border-right">John Deo</td>
      <td className="border-right"> 612-444-5678</td>
      <td className="border-right">
        109
        <i data-toggle="tooltip" data-original-title="109" className="fa fa-info-circle fa-w-16 text-info"></i>
      </td>
      <td className="border-right w-30">
        <div className="d-flex justify-content-between">
          <div>
            <div>
              <p className="f-12 font-medium mb-0"><strong>1405 W 48th St, Chicago</strong> </p>
              <p className="f-12 mb-0">IL 60609, USA</p>
            </div>
            <div>
              <time dateTime="2022-11-03">11/3/22 : 5:30pm</time>
            </div>
          </div>
          <div className="mt-4 px-4">
            <div className="hidden sm:block">
              <svg xmlns="http://www.w3.org/2000/svg" width="80" height="12" viewBox="0 0 40 6" fill="none">
                <path d="M40 3L35 0.113249V5.88675L40 3ZM0 3.5H35.5V2.5H0V3.5Z" fill="black"></path>
              </svg>
            </div>
            <p className="text-center font-medium">
              <span>1,168.3mi</span>
            </p>
          </div>
          <div>
            <div>
              <p className="f-12 font-medium mb-0"><strong>2425 S Pulaski Rd, Chicago</strong></p>
              <p className="f-12 mb-0">IL 60623</p>
            </div>
            <div>
              <time dateTime="2022-11-03">11/3/22 : 6:35pm</time>
            </div>
          </div>
        </div>
      </td>
      <td className="border-right">6/Feb/2023<br /> 9:30 PM</td>
      <td className="border-right"> Pending </td>
      <td className="border-right">
        <div className="dropdown bootstrap-select form-control select-picker assign_role">
          <select className="form-control select-picker assign_role" name="type_val[]" id="type_val" onchange="openPopup()">
            <option selected="" value="1">Accept</option>
            <option value="1">Decline</option>
          </select>
        </div>
      </td>
    </tr>
    <tr id="row-20" role="row" className="odd border">
      <td className="border-right">90987
        <i data-toggle="tooltip" data-original-title="90987" className="fa fa-info-circle fa-w-16 text-info"></i>
      </td>
      <td className="border-right">John Deo</td>
      <td className="border-right"> 612-444-5678</td>
      <td className="border-right">
        109
        <i data-toggle="tooltip" data-original-title="109" className="fa fa-info-circle fa-w-16 text-info"></i>
      </td>
      <td className="border-right w-30">
        <div className="d-flex justify-content-between">
          <div>
            <div>
              <p className="f-12 font-medium mb-0"><strong>1405 W 48th St, Chicago</strong> </p>
              <p className="f-12 mb-0">IL 60609, USA</p>
            </div>
            <div>
              <time dateTime="2022-11-03">11/3/22 : 5:30pm</time>
            </div>
          </div>
          <div className="mt-4 px-4">
            <div className="hidden sm:block">
              <svg xmlns="http://www.w3.org/2000/svg" width="80" height="12" viewBox="0 0 40 6" fill="none">
                <path d="M40 3L35 0.113249V5.88675L40 3ZM0 3.5H35.5V2.5H0V3.5Z" fill="black"></path>
              </svg>
            </div>
            <p className="text-center font-medium">
              <span>1,168.3mi</span>
            </p>
          </div>
          <div>
            <div>
              <p className="f-12 font-medium mb-0"><strong>2425 S Pulaski Rd, Chicago</strong></p>
              <p className="f-12 mb-0">IL 60623</p>
            </div>
            <div>
              <time dateTime="2022-11-03">11/3/22 : 6:35pm</time>
            </div>
          </div>
        </div>
      </td>
      <td className="border-right">6/Feb/2023<br /> 9:30 PM</td>
      <td className="border-right"> Pending </td>
      <td className="border-right">
        <div className="dropdown bootstrap-select form-control select-picker assign_role">
          <select className="form-control select-picker assign_role" name="type_val[]" id="type_val" onchange="openPopup()">
            <option selected="" value="1">Accept</option>
            <option value="1">Decline</option>
          </select>
        </div>
      </td>
    </tr>
    <tr id="row-20" role="row" className="odd border">
      <td className="border-right">90987
        <i data-toggle="tooltip" data-original-title="90987" className="fa fa-info-circle fa-w-16 text-info"></i>
      </td>
      <td className="border-right">John Deo</td>
      <td className="border-right"> 612-444-5678</td>
      <td className="border-right">
        109
        <i data-toggle="tooltip" data-original-title="109" className="fa fa-info-circle fa-w-16 text-info"></i>
      </td>
      <td className="border-right w-30">
        <div className="d-flex justify-content-between">
          <div>
            <div>
              <p className="f-12 font-medium mb-0"><strong>1405 W 48th St, Chicago</strong> </p>
              <p className="f-12 mb-0">IL 60609, USA</p>
            </div>
            <div>
              <time dateTime="2022-11-03">11/3/22 : 5:30pm</time>
            </div>
          </div>
          <div className="mt-4 px-4">
            <div className="hidden sm:block">
              <svg xmlns="http://www.w3.org/2000/svg" width="80" height="12" viewBox="0 0 40 6" fill="none">
                <path d="M40 3L35 0.113249V5.88675L40 3ZM0 3.5H35.5V2.5H0V3.5Z" fill="black"></path>
              </svg>
            </div>
            <p className="text-center font-medium">
              <span>1,168.3mi</span>
            </p>
          </div>
          <div>
            <div>
              <p className="f-12 font-medium mb-0"><strong>2425 S Pulaski Rd, Chicago</strong></p>
              <p className="f-12 mb-0">IL 60623</p>
            </div>
            <div>
              <time dateTime="2022-11-03">11/3/22 : 6:35pm</time>
            </div>
          </div>
        </div>
      </td>
      <td className="border-right">6/Feb/2023<br /> 9:30 PM</td>
      <td className="border-right"> Pending </td>
      <td className="border-right">
        <div className="dropdown bootstrap-select form-control select-picker assign_role">
          <select className="form-control select-picker assign_role" name="type_val[]" id="type_val" onchange="openPopup()">
            <option selected="" value="1">Accept</option>
            <option value="1">Decline</option>
          </select>
        </div>
      </td>
    </tr> */}