import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { SERVERADDRESS, TOGGLE_SIDEBAR } from "../../../constants";
import { HeaderNotificationModel } from "../../HeaderNotificationModel";
import { Link, useNavigate } from "react-router-dom";
import { createUserLoginInfo, userLogout } from "../../../actions/authActions";
import io from "socket.io-client"
import MessageDropDown from "./MessageDropDown";
import NotificationDropDown from "./NotificationDropDown";
// import { notifyAutoLogout } from "../../toast";
import { useIdleTimer } from 'react-idle-timer' 
import { Device } from '@capacitor/device';
import axios from "axios";
import moment from "moment";
import { driverEarning } from "../../../actions/reservationAction";
// const socket = io('http://localhost:5000')
const socket = io('https://ridetify-cca5568f0479.herokuapp.com')

const AdminHeader = () => {
  const auth = useSelector((state) => state.auth);
  const reservation = useSelector((state) => state.reservation);
  const mail = useSelector((state) => state.mail);
  const dispatch = useDispatch();
  const [showDropDown, setshowDropDown] = useState(false);
  const [opensidedrawer, setopensidedrawer] = useState(false);
  const openDropDown = () => {
    setshowDropDown((state) => !state);
  };
  const toggleDriver = () => {
    setopensidedrawer((state) => !state);
  };
  let navigate = useNavigate();
  const navigateUser = () => {
    navigate("/login");
  };
  const logout = async (e) => {
    await dispatch(
      userLogout({ refreshToken: auth.tokens.refresh.token }, navigateUser)
    );
  };
  const [cc, setCC] = useState(0);
  const [data, setData] = useState([]);
  const [msg, setMsg] = useState([]);
  useEffect(() => {
    if (auth?.user?.jobTitle === 'Driver'){
      socket.emit(
        "driver-notifications",
        { 
          companyId: auth.user.companyId.id,
          driverId: auth.user.id 
        },
        (response) => {
          // console.log(response)
          setData(response.notifications);
          setMsg(response.messages);
          // dispatch(setActiveRoute(response.route));
          return () => {
            socket.off("driver-notifications");
          };
        }
      );
    }
    else if (auth?.user?.jobTitle === 'Admin'){
      socket.emit(
        "admin-notifications",
        { 
          companyId: auth.user.companyId.id,
          userId: auth.user.id
        },
        (response) => {
          setData(response.notifications);
          setMsg(response.messages);
          return () => {
            socket.off("driver-notifications");
          };
        }
      );
    }
  }, [cc]);
  let bb = 0;
  useEffect(() => {
    setInterval(
      () => setCC(bb++),
      // , 2000);
      2000
    );
  }, []);
  // console.log(data, msg, "msg<")
  const onIdle = () => {
    logout()
    // setTimeout(() => 
    //   notifyAutoLogout()
    // , 3000);
  }
  const onActive = (event) => {
  // 
  }
  const {
    start,
    reset,
    activate,
    pause,
    resume,
    isIdle,
    isPrompted,
    isLeader,
    getTabId,
    getRemainingTime,
    getElapsedTime,
    getLastIdleTime,
    getLastActiveTime,
    getTotalIdleTime,
    getTotalActiveTime
  } = useIdleTimer({
  //   onPrompt,
    onIdle,
    onActive,
  //   onAction,
    timeout: 1000 * 60 * 5,
  //   timeout: 1000 * 10,
    promptTimeout: 0,
    events: [
      'mousemove',  
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
      // 'blur'
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
  //   crossTab: false,
    crossTab: true,
    name: 'idle-timer',
    syncTimers: 0,
    leaderElection: false
  })
  let j = 0
  let jj = 0

  const locationOfUser = async () => {
    if (auth?.activeUserCountry === null){
      if (auth?.user?.permissionId?.isAdmin === false){
        const [info, deviceInfo, res] = 
          await Promise.all(
            [
              Device.getId(),
              Device.getInfo(),
              axios.get('https://ipinfo.io/json?token=b59a11ac063a47')
            ]
          ) 
        let [ipAddress, country, state, deviceType] = [res.data.ip, res.data.country, res.data.region, deviceInfo.platform]
        // console.log(info.identifier, ipAddress, country, state, deviceType, "binfo")
        if (country !== 'US') {
          const regionNames = new Intl.DisplayNames(['en'], {type: 'region'});
          await axios.post(`${SERVERADDRESS}/v1/auth/out-of-region-email`, 
            {
              email: auth.user.email,
              name: auth.user.firstName + " "+ auth.user.lastName,
              country: regionNames.of(country),
            }
          )
        }
        // console.log(regionNames.of(country), "country");
        // const currDate = moment().format()
        // const arr = currDate.toString().substring(0,19)
        // const newCurrDate = arr+".000+00:00"
        dispatch(createUserLoginInfo({
            userId: auth.user.id,
            companyId: auth.user.companyId.id,
            loginStatus:true,
            online: true,
            fingerPrintUuid: info.identifier,
            ipAddress: ipAddress,
            country: country,
            state: state,
            deviceType: deviceType,
        }))
      }
    }
  }
  const getDriverEarning = async () => {
    if (auth?.user?.isDriver === true) {
      dispatch(driverEarning({
        driverId: auth.user.id,
        today: moment().format('YYYY-MM-DD'),
        weekFirst: moment().format('dddd') !== 'Sunday' ? moment().startOf('week').add(1, 'days').format('YYYY-MM-DD') : moment().startOf('week').add(-6, 'days').format('YYYY-MM-DD'),
        weekLast: moment().format('dddd') !== 'Sunday' ? moment().endOf('week').add(1, 'days').format('YYYY-MM-DD') : moment().endOf('week').add(-6, 'days').format('YYYY-MM-DD'),
      }))
    }
  }
  useEffect(()=>{
    locationOfUser()
    getDriverEarning()
  },[])
  return (
    <>
      <header className="main-header clearfix bg-white" id="header">
        <div className="navbar-left float-left d-flex align-items-center">
          <div className="page-title d-none d-lg-flex">
            <div className="page-heading">
              {/* <h2 className="mb-0 pr-3 text-dark f-18 font-weight-bold"> */}
                {/* Dashboard */}
                {/* <span className="text-dark f-12 f-w-500 ml-2">
                  <NavLink to="/dashboard" className="text-dark">
                    Home
                  </NavLink>{" "}
                  &bull;Dashboard
                </span> */}
              {/* </h2> */}
            </div>
          </div>

          <div
            className="d-block d-lg-none menu-collapse cursor-pointer position-relative"
            onClick={() => dispatch({ type: TOGGLE_SIDEBAR })}
          >
            <div className="mc-wrap">
              <div className="mcw-line"></div>
              <div className="mcw-line center"></div>
              <div className="mcw-line"></div>
            </div>
          </div>
        </div>

        <div className="page-header-right float-right d-flex align-items-center">
          <ul>
            {
              auth?.user?.isDriver === true &&
              <>
                <li>
                  <div className="notification_box dropdown"><strong>Weekly Earning <span style={{color: 'darkgreen'}}>${reservation?.driverEarning?.weeklyEarning ? reservation?.driverEarning?.weeklyEarning.toFixed(2) : 0.0}</span> | &nbsp;</strong></div>
                </li>
                <li>
                  <div className="notification_box dropdown"><strong>Daily Earning <span style={{color: 'darkgreen'}}>${reservation?.driverEarning?.dailyEarning ? reservation?.driverEarning?.dailyEarning.toFixed(2) : 0.0}</span> | &nbsp; </strong></div>
                </li>
              </>
            }
            {
              auth?.user?.isExternalCompany === false &&
              <li>
                <div className="notification_box dropdown">
                  <Link
                    className="d-block dropdown-toggle header-icon-box show-user-notifications"
                    type="link"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-chat-square-text-fill f-16 text-dark-grey"
                      viewBox="0 0 16 16"
                    >
                      <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-2.5a1 1 0 0 0-.8.4l-1.9 2.533a1 1 0 0 1-1.6 0L5.3 12.4a1 1 0 0 0-.8-.4H2a2 2 0 0 1-2-2V2zm3.5 1a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 2.5a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 2.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5z" />
                    </svg>
                    {/* <span className="badge badge-pill custom-badge" >{3}
                    </span> */}

                    {
                      mail.mailData &&
                      mail.mailData.length > 0 &&
                      mail.mailData.map((item,i)=>{
                        item.messages[item.messages.length-1].view.forEach(e => {
                          if(e.receiver == auth.user.id && e.messageSeen === false){
                            j+=1
                          }
                        });
                        return (
                          i === (mail.mailData.length-1) &&
                          <>
                            {
                              j === 0 ?
                              <></>
                              :
                              <span className="badge badge-pill custom-badge" key={i}>{j}
                              </span>
                            }
                          </>
                          )
                        }
                      )
                    }
                  </Link>
                  <MessageDropDown msg={msg}/>
                </div>
              </li>
            }
            {
              auth?.user?.isExternalCompany === false &&
              <li>
                <div className="notification_box dropdown">
                  <Link
                    className="d-block dropdown-toggle header-icon-box show-user-notifications"
                    type="link"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fa fa-bell f-16 text-dark-grey"></i>
                  </Link>
                  <NotificationDropDown data={data}/>
                </div>
              </li>
            }
            {/* <li>
              <div className="d-flex align-items-center">
                <Link className="d-block header-icon-box open-search">
                  <i className="fa fa-search f-16 text-dark-grey"></i>
                </Link>
              </div>
            </li> */}
            {/* <li>
              <div className="notification_box dropdown">
                <button
                  className="d-block dropdown-toggle header-icon-box show-user-notifications bg-button"
                  type="link"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={openDropDown}
                >
                  <i className="fa fa-bell f-16 text-dark-grey"></i>
                </button>
              </div>
              <HeaderNotificationModel showDropDown={showDropDown} data={data}/>
            </li> */}
            <li>
              <div className="add_box dropdown">
                <Link
                  className="d-block dropdown-toggle bg-gray"
                  type="link"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fa fa-user f-20 text-dark-grey"></i>
                  {/* <img
                    src="https://cdn-icons-png.flaticon.com/512/1077/1077114.png"
                    alt=""
                    className="mr-2 taskEmployeeImg rounded-circle"
                  /> */}
                </Link>
                {/* <!-- DROPDOWN - INFORMATION --> */}
                <div
                  className="dropdown-menu dropdown-menu-right"
                  aria-labelledby="dropdownMenuLink"
                  tabIndex="0"
                  x-placement="bottom-end"
                  style={{
                    position: "absolute",
                    willChange: "transform",
                    top: "0px",
                    left: "0px",
                    transform: "translate3d(38px, 30px, 0px)",
                  }}
                >
                  {
                    // (auth.user?.jobTitle === "Driver" || auth.user?.jobTitle === "Dispatcher") && (
                    <>
                      {" "}
                      {
                        auth?.user?.isExternalCompany === false &&
                        <>
                        <div className="d-flex px-3 justify-content-between align-items-center border-bottom-grey py-1 bg-white">
                          <div className="___class_+?50___">
                              {

                              }
                              <p className="mb-0 text-dark f-w-500" style={{fontSize: '20px'}}>{auth?.user?.permissionId?.name}</p>
                          </div>
                        </div>
                        <div id="notification-list"></div>
                        </>
                      }
                      <Link
                        className="dropdown-item f-16 text-dark openRightModal"
                        to="/my-profile"
                      >
                        <i className="bi bi-person-bounding-box f-w-500 mr-2 f-14"></i>
                        Profile
                      </Link>
                      <Link
                        className="dropdown-item f-16 text-dark openRightModal"
                        to="/change-password"
                      >
                        <i className="bi bi-gear-wide-connected f-w-500 mr-2 f-14"></i>
                        Change Password
                      </Link>
                    </>
                  // )
                  }
                  <Link
                    className="dropdown-item f-16 text-dark openRightModal"
                    to=""
                    onClick={logout}
                  >
                    <i className="side-icon bi bi-power f-w-500 mr-2 f-14"></i>
                    Logout
                  </Link>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </header>
    </>
  );
};
export default AdminHeader;
