import React, { useEffect, useState } from "react";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";

const InvoicingTableRow = ({ current, i, dropDownRow, setDropDownRow }) => {
  const navigate = useNavigate();
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };
  return (
    <>
      <tr
        role="row"
        className="odd border"
        key={i}
        onMouseLeave={() => setshowMoreVertDropDown(false)}
      >
        <td className="border-right">{current?.reservationId?.orderId}</td>
        <td className="border-right">
          {current?.reservationId?.assetId?.assetNo}
          <i data-toggle="tooltip"></i>
        </td>

        <td className="border-right">
          <strong>
            {current.reservationId?.customerId.firstName}{" "}
            {current.customerId?.reservationId.lastName}
          </strong>
          <br />
          {current.reservationId?.customerId.phone}
          <br />
        </td>

        {/* <td className="border-right">{current.type.name}</td> */}
        <td className="border-right">
          <strong>${current?.reservationId?.totalAmount.toFixed(2)}</strong>
          <i data-toggle="tooltip"></i>
        </td>
        <td className="border-right">{current?.reservationId?.status}</td>
        <td className="border-right">
          <button
            onClick={() =>
              navigate(`/invoices/${current?.reservationId?.orderId}`, {
                state: current,
              })
            }
            className="btn btn-sm btn-success"
          >
            View Invoice
          </button>
        </td>
      </tr>
    </>
  );
};
export default InvoicingTableRow;
