import moment from 'moment';
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getAllCardsData, getAllCardsData2 } from '../../../actions/kpiActions';
import { allEmployees, getAllDriver, getAllDriverWithoutPagination, getAllUsers } from '../../../actions/userActions';
import { getAllVehiclesByCompanyId } from '../../../actions/vehicleActions';
import { getMailByUserId } from '../../../actions/mailActions';
import { getAllPermission } from '../../../actions/permissionActions'

const AdminDashboardBookedOrderSummaryCard = () => {
  const kpi = useSelector((state) => state.kpi);
  const user = useSelector((state) => state.user);
  const users = useSelector((state) => state.users);
  const auth = useSelector((state) => state.auth);
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false)
  const openMoreVertDropDown = () => {
      setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown)
  }
  const dispatch = useDispatch()
  // const cardsData = async (e) => {
  //     dispatch(getAllCardsData({companyId: auth.user.companyId.id }))
  // }
  const cardsData2 = async (e) => {
    dispatch(getAllCardsData2({companyId: auth.user.companyId.id, today: moment().format("YYYY-MM-DD") }))
    dispatch(getAllPermission({companyId: auth.user.companyId.id }))
  }
  useEffect(() => {
    cardsData2()
  },[])
  // console.log(kpi, "djdjdjjdjjdjdjdjdjdjdjdjdjdjdjdjdjdjd")

  const userData = async (e) => {
    dispatch(getAllDriver ({companyId: auth.user.companyId.id, isClient: false, jobTitle: 'Driver' }))
  }
  const driverData = async (e) => {
    dispatch(getAllDriverWithoutPagination({companyId: auth.user.companyId.id }))
  }
  const allEmployeesData = async (e) => {
    dispatch(allEmployees({
      companyId: auth.user.companyId.id,
      userId: auth.user.id
    }))
  }
  const allVehicleData = async (e) => {
    dispatch(getAllVehiclesByCompanyId({companyId: auth.user.companyId.id }))
  }
  const clientData = async (e) => {
    dispatch(
      getAllUsers(
        { companyId: auth?.user?.companyId?.id, isClient: true },
        10,
        1
      )
    );
  };
  const mailData = async () => {
    dispatch(getMailByUserId({userId: auth.user.id}))
  }
  useEffect(() => {
      userData()
      driverData()
      allVehicleData()
      clientData()
      allEmployeesData()
      mailData()
  },[])

  return (
    <>
      <div className="col-xl-12 col-lg-12 col-md-12 e-d-info-notices">
        <div className="row">
          <div className="col-md-4">
            <div className="b-shadow-4 rounded p-2 bg-col">
              <div className="card-body p-2 clearfix" style={{minHeight: '185px'}}>
                <div className="driver-text mb-3">
                  <div className="h5 f-21 font-weight-bold font-weight-bold font-xs">DRIVER</div>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <div className="driver-text">
                    <p className="f-16 f-w-400 mb-0">Total Drivers</p>
                  </div>
                  <div className="driver-text">
                    <p className="f-16 f-w-400 mb-0">{kpi?.cardsData2?.driversCount}</p>
                  </div>
                </div>
                  <div className="d-flex justify-content-between mb-2">
                    <div className="driver-text">
                      <p className="f-16 f-w-400 mb-0">Online Drivers</p>
                    </div>
                    <div className="driver-text">
                      <p className="f-16 f-w-400 mb-0">{kpi?.cardsData2?.onlineDriverCount}</p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <div className="driver-text">
                      <p className="f-16 f-w-400 mb-0">Offline Drivers</p>
                    </div>
                    <div className="driver-text">
                      <p className="f-16 f-w-400 mb-0">{kpi?.cardsData2?.offlineDriverCount}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div> 
            <div className="col-md-4">
              <div className="b-shadow-4 rounded p-2 bg-col">
                <div className="card-body p-2 clearfix">
                  <div className="driver-text mb-3">
                    <div className="h5 f-21 font-weight-bold font-weight-bold font-xs">TODAY'S ORDERS</div>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <div className="driver-text">
                      <p className="f-16 f-w-400 mb-0">Total Orders</p>
                    </div>
                    <div className="driver-text">
                      <p className="f-16 f-w-400 mb-0">{kpi?.cardsData2?.totalTodayOrderCount}</p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <div className="driver-text">
                      <p className="f-16 f-w-400 mb-0">Cancel Orders</p>
                    </div>
                    <div className="driver-text">
                      <p className="f-16 f-w-400 mb-0">{kpi?.cardsData2?.totalTodayOrderCancelCount}</p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <div className="driver-text">
                      <p className="f-16 f-w-400 mb-0">Active Orders</p>
                    </div>
                    <div className="driver-text">
                      <p className="f-16 f-w-400 mb-0">{kpi?.cardsData2?.totalTodayOrderActiveCount}</p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <div className="driver-text">
                      <p className="f-16 f-w-400 mb-0">Completed Orders</p>
                    </div>
                    <div className="driver-text">
                      <p className="f-16 f-w-400 mb-0">{kpi?.cardsData2?.totalTodayOrderCompletedCount}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
            <div className="b-shadow-4 rounded p-2 bg-col">
              <div className="card-body p-2 clearfix">
                <div className="driver-text mb-3">
                  <div className="h5 f-21 font-weight-bold font-weight-bold font-xs">TOMORROW SCHEDULED</div>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <div className="driver-text">
                    <p className="f-16 f-w-400 mb-0">Total Orders</p>
                  </div>
                  <div className="driver-text">
                    <p className="f-16 f-w-400 mb-0">{kpi?.cardsData2?.totalTomorrowOrderCount}</p>
                  </div>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <div className="driver-text">
                    <p className="f-16 f-w-400 mb-0">Cancel Orders</p>
                  </div>
                  <div className="driver-text">
                    <p className="f-16 f-w-400 mb-0">{kpi?.cardsData2?.totalTomorrowOrderCancelCount}</p>
                  </div>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <div className="driver-text">
                    <p className="f-16 f-w-400 mb-0">Assigned Orders</p>
                  </div>
                  <div className="driver-text">
                    <p className="f-16 f-w-400 mb-0">{kpi?.cardsData2?.totalTomorrowOrderAssignedCount}</p>
                  </div>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <div className="driver-text">
                    <p className="f-16 f-w-400 mb-0">Unsigned Orders</p>
                  </div>
                  <div className="driver-text">
                    <p className="f-16 f-w-400 mb-0">{kpi?.cardsData2?.totalTomorrowOrderUnassignedCount}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default AdminDashboardBookedOrderSummaryCard