import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { adminCompanyInfoUpdate, updateCompany } from '../../../actions/authActions';
import AWS from 'aws-sdk'
import axios from 'axios';
import { SERVERADDRESS } from '../../../constants';

const S3_BUCKET = 'ridetifyportal';
// const REGION ='YOUR_DESIRED_REGION_HERE';

AWS.config.update({
    accessKeyId: 'AKIAULUGR4XCNQWUXPWO',
    secretAccessKey: 'kYwCYRbw3x64+HtBl8YBsS1p/zDeivp0DHWbxhLY'
})

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET},
    // region: REGION,
})

const AdminSettingCompanyEditInfo = () => {
    const auth = useSelector((state) => state.auth);
    const adminCompanyInfo = useSelector((state) => state.adminCompanyInfo);
    const dispatch = useDispatch()
    const [progress , setProgress] = useState(0);
    const [awsURL, setAwsURL] = useState(null);
    // const [selectedImage, setSelectedImage] = useState(defaultImg);
    // default image wali baat baad mein
    const [selectedImage, setSelectedImage] = useState();
    // const adminCompInfo = async (e) => {
    //     dispatch(getAdminCompanyInfo({userId: auth.user.id }))
    // }
    // useEffect(() => {
    //     adminCompInfo()
    // },[])

    const handlePhone = (fieldName) => (event) =>{  
        if(event.target.value.length === 1){
            if(event.target.value !== "("){
                setValues({
                    ...values, [fieldName]:
                    "(" +event.target.value
                })
            }
        }
        else if (event.target.value.length === 4){
            if(event.target.value !== ")"){
                setValues({
                    ...values, [fieldName]:
                    event.target.value+")-" 
                })
            }
        }
        else if (event.target.value.length === 9){
            if(event.target.value !== "-"){
                setValues({
                    ...values, [fieldName]:
                    event.target.value+"-" 
                })
            }
        }
        else {
            setValues({
                ...values, [fieldName]:
                event.target.value
            })
        }
    }
    const alphabets = [
        'a','b','c','d','e','f','g','h','i','j','k','l', 'm','n','o','p','q','r','s','t',
        'u','v','w','x','y','z','A','B','C','D','E', 'F','G','H','I','J',
        'K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z','+','-',
        '/','*','!','@','#','$','%','^','&','_','(',')',',','.'
    ];

    const [ values, setValues ] = useState({
        userId: '',
        // companyId: '',
        companyName: '',
        phone: '',
        email: '',
        address: '',
        state: '',
        city: '',
        zip: '',
        driverPercentage: '',
    })

    const handleChangePic = async (event) =>{
        const today = new Date();
        const date = today.getTime();
        const params = {
            Bucket: S3_BUCKET,
            // Key: "data/"+auth.user.companyId.companyName+"/ProfilePic/"+auth.user.id+"/"+event.target.files[0].name,
            Key: "data/"+auth.user.companyId.companyName+"/ProfilePic/"+date+"/"+event.target.files[0].name,
            Body: event.target.files[0],
            ACL: 'public-read'
        };
        var upload = myBucket.upload(params)
        .on('httpUploadProgress',(evt) => {
            setProgress(Math.round((evt.loaded / evt.total) * 100))
        })
        .promise();
        let url;
        await upload.then(function(data, err) {
            try {
                url = data.Location
                setAwsURL(url)
                // console.log(url)
            } catch (error) {
                console.log(error, err, "ee");
            }
        });
        const { data } = await axios.post(`${SERVERADDRESS}/v1/fileupload/profilePicUpload`, 
            {
                url: url,
                file: event.target.files[0].name,
                type: event.target.files[0].type,
                userId:auth.user.id,
                companyId:auth.user.companyId.id,
            })
        setSelectedImage(url)
        dispatch(adminCompanyInfoUpdate({userId: auth.user.id, profilePic: data.id}))
    }

    useEffect(() => {
        if (auth.user.companyId !== null){
            setValues({
                // userId: "63cf2534057dae4374e3eb1b",
                companyName: auth.user.companyId.companyName,
                phone: auth.user.companyId.phone,
                email: auth.user.companyId.email,
                address: auth.user.companyId.address,
                state: auth.user.companyId.state,
                city: auth.user.companyId.city,
                driverPercentage: auth.user.companyId.driverPercentage,
                // zip: ,
            })
        }
    },[auth.user.companyId])

    const handleChange = (fieldName) => (event) => {
        setValues({
            ...values, [fieldName]:
            event.target.value
        })
    }
    // console.log(values)
    const adminCompInfoData = {
        userId: auth.user.id,
        // companyName: values.companyName,
        phone: values.phone,
        email: values.email,
        address: values.address,
        state: values.state,
        city: values.city,
        zip: values.zip,
    }   

    // const [values2, setValues2] = useState({
        // companyId: auth.user.companyId.id,
        // driverPercentage: "",
        // driverPercentage: values2.driverPercentage,
    //   });
    
    const adminCompUpdateInfoData = {
        companyId: auth.user.companyId.id,
        // companyName: values.companyName,
        // phone: values.phone,
        // email: values.email,
        // address: values.address,
        // state: values.state,
        // city: values.city,
        // zip: values.zip,
        driverPercentage: values.driverPercentage,

    }   

    const handleSubmit = async (event) => {
        event.preventDefault()
        // console.log("first")
        if (auth.user.email === values.email){
            delete adminCompInfoData.email
        }
        dispatch(updateCompany(adminCompUpdateInfoData))
        setTimeout(() => 
            dispatch(adminCompanyInfoUpdate(adminCompInfoData))
        , 2000);
        // openDropDown()
    }

    // console.log(adminCompanyInfo, "djdjdjjd   jjdjdjdjdjdjd   ssjdjdjdjdjdjdjdjd")
    return (
        <>
            <div className="d-flex flex-column w-tables rounded mt-3 bg-white px-4 pb-3">
                <div id="leads-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div className="row p-3">
                        <div className="col-lg-6">
                            <div className="form-group my-3">
                                <label className="f-14 text-dark-grey mb-12" data-label="" htmlFor="after_days">Company Name</label>
                                <sup className="text-danger">*</sup>
                                <input type="text" className="form-control height-35 f-14" 
                                autoComplete="false" value={values.companyName}
                                // onChange={handleChange('companyName')} 
                                disabled/>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group my-3">
                                <label className="f-14 text-dark-grey mb-12" data-label="" htmlFor="after_days">Email Address</label>
                                <sup className="text-danger">*</sup>
                                <input type="Email" className="form-control height-35 f-14" 
                                autoComplete="false" value={values.email}
                                onChange={handleChange('email')}/>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group my-3">
                                <label className="f-14 text-dark-grey mb-12" data-label="" htmlFor="after_days">Phone</label>
                                <sup className="text-danger">*</sup>
                                <input className="form-control height-35 f-14" 
                                    minLength="14"
                                    maxLength="14"
                                    required
                                    onKeyDown={(e) => {
                                        if (alphabets.includes(e.key)) {
                                            e.preventDefault();
                                        } 
                                        if (e.code === "Backspace" && values.phone.length === 6){
                                            setValues({
                                                ...values, phone:
                                                values.phone.substring(0,4)
                                            })
                                        }
                                        if (e.code === "Backspace" && values.phone.length === 2){
                                            setValues({
                                                ...values, phone:
                                                ''
                                            })
                                        }
                                        if (e.code === "Backspace" && values.phone.length === 10){
                                            setValues({
                                                ...values, phone:
                                                values.phone.substring(0,9)
                                            })
                                        }
                                    }} name="phone" 
                                    value={values.phone}
                                    onChange={handlePhone('phone')}
                                    placeholder="(123)-456-7890"    
                                />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group my-3">
                                <label className="f-14 text-dark-grey mb-12" data-label="" htmlFor="after_days">Secondary phone</label>
                                {/* <sup className="text-danger">*</sup> */}
                                <input className="form-control height-35 f-14" 
                                    minLength="14"
                                    maxLength="14"
                                    required
                                    onKeyDown={(e) => {
                                        if (alphabets.includes(e.key)) {
                                            e.preventDefault();
                                        } 
                                        if (e.code === "Backspace" && values.otherPhone.length === 6){
                                            setValues({
                                                ...values, otherPhone:
                                                values.otherPhone.substring(0,4)
                                            })
                                        }
                                        if (e.code === "Backspace" && values.otherPhone.length === 2){
                                            setValues({
                                                ...values, otherPhone:
                                                ''
                                            })
                                        }
                                        if (e.code === "Backspace" && values.otherPhone.length === 10){
                                            setValues({
                                                ...values, otherPhone:
                                                values.otherPhone.substring(0,9)
                                            })
                                        }
                                    }} name="otherPhone" 
                                    value={values.otherPhone}
                                    onChange={handlePhone('otherPhone')}
                                    placeholder="(123)-456-7890"
                                />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group my-3">
                                <label className="f-14 text-dark-grey mb-12" data-label="" htmlFor="after_days">Address</label>
                                <sup className="text-danger">*</sup>
                                <input type="text" className="form-control height-35 f-14" 
                                autoComplete="false" value={values.address}
                                onChange={handleChange('address')}/>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group my-3">
                                <label className="f-14 text-dark-grey mb-12" data-label="" htmlFor="after_days">City</label>
                                <sup className="text-danger">*</sup>
                                <input type="text" className="form-control height-35 f-14"
                                autoComplete="false" value={values.city}
                                onChange={handleChange('city')}/>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group my-3">
                                <label className="f-14 text-dark-grey mb-12" data-label="" htmlFor="after_days">State</label>
                                <sup className="text-danger">*</sup>
                                <input type="text" className="form-control height-35 f-14" 
                                autoComplete="false" value={values.state}
                                onChange={handleChange('state')}/>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group my-3">
                                <label className="f-14 text-dark-grey mb-12" data-label="" htmlFor="after_days">Zip/Postal Code:</label>
                                <sup className="text-danger">*</sup>
                                <input type="text" className="form-control height-35 f-14"
                                autoComplete="false" value={values.zip}
                                onChange={handleChange('zip')}/>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group my-3">
                                <label className="f-14 text-dark-grey mb-12" data-label="" htmlFor="after_days">Driver Percentage</label>
                                <sup className="text-danger">*</sup>
                                <input type="text" className="form-control height-35 f-14"
                                autoComplete="false" value={values.driverPercentage}
                                onChange={handleChange('driverPercentage')}/>
                            </div>
                        </div>
                        {/* <div className="col-lg-6">
                            <div className="form-group my-3">
                                <label className="f-14 text-dark-grey mb-12" data-label="" htmlFor="after_days">Country</label>
                                <sup className="text-danger">*</sup>
                                <input type="text" className="form-control height-35 f-14" placeholder="" value="USA" name="after_days" id="after_days" />
                            </div>
                        </div> */}
                        <div className="col-md-12">
                            <div className="form-group " style={{marginTop: "1.5em !important"}}>
                                <label className="f-14 text-dark-grey mb-12" data-label="" htmlFor="myfile">Company Logo</label>
                                <sup className="text-danger">*</sup>
                                <input type="file" id="myfile" name="myfile" accept='.jpg, .jpeg, .png'  onChange={handleChangePic}/>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="form-group " style={{marginTop: "1.5em !important"}}>
                                <button type="button" className="btn-primary rounded f-14 p-2 mr-3" onClick={handleSubmit}>
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AdminSettingCompanyEditInfo