import React, { useEffect } from "react";
// import AdminBookingTodayBooking from "../../components/adminComponents/adminBookingComponents/AdminBookingTodayBooking";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getRouteById } from "../../actions/routeActions";
import DriverRouteViewDetailsComponent from "../../components/driverComponents/driverRouteComponents/DriverRouteViewDetailsComponent";
import AdminHeader from "../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminHeader";
import AdminSidebar from "../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminSidebar";
import { navigateUserFunc } from '../../actions/generalActions'
import NotAllowed from "../../components/NotAllowed";

const DriverRouteDetailsPage = () => {
    const routes = useSelector((state) => state.routes);
    const { routeDetails } = routes
    let navigate = useNavigate();
    const auth = useSelector((state) => state?.auth);
    useEffect(() => {
        if (auth?.user?.permissionId?.myRoutes?.isAllow === false){
            navigateUserFunc(auth?.user, navigate)
        }
    },[])
  return (
    <>
        <AdminHeader />
        <AdminSidebar />
        <div className="body-wrapper clearfix">
            <section className="main-container " id="fullscreen">
            <div className="px-4 py-2 border-top-0 emp-dashboard">
                <div className="d-block d-lg-flex d-md-flex justify-content-between action-bar mb-20 mt-3">
                <div
                    id="table-actions"
                    className="flex-grow-1 align-items-center"
                >
                    <h4 className=" mb-0 f-21 text-capitalize font-weight-bold">
                    {" "}
                    Route Details - {routeDetails?.routeNumber}
                    </h4>
                </div>
                <div
                    className="btn-group mt-2 mt-lg-0 mt-md-0 ml-0 ml-lg-3 ml-md-3"
                    role="group"
                ></div>
                </div>
                {
                    auth?.user?.permissionId?.myRoutes?.read === true ?
                    <DriverRouteViewDetailsComponent />
                    :
                    <NotAllowed />
                }
            </div>
            </section>
        </div>
    </>
  )
}
export default DriverRouteDetailsPage