import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { getTodayRoutesByUserId } from '../../../actions/routeActions';
import DriverRouteTodayRouteComponentRow from './DriverRouteTodayRouteComponentRow';
import shift1 from "../../../assets/images/load.png"

const DriverRouteTodayRouteComponent = () => {
  const routes = useSelector((state) => state.routes);
  const auth = useSelector((state) => state.auth);
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false)
  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown)
  }
  const dispatch = useDispatch()
  const todayRouteViewData = async (e) => {
    dispatch(getTodayRoutesByUserId({
        driverId: auth.user.id, 
        day: moment().format('dddd'), 
        today: moment().format('YYYY-MM-DD'),
        limit: limit,
        page: currentPageNumber
    }))
    // dispatch(getTodayRoutesByUserId({
    //     driverId: auth.user.id, 
    //     // day: 'Wednesday', 
    //     // today: '2023-04-26', 
    //     limit: limit, 
    //     page: currentPageNumber}))
  }
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [limit, setLimit] = useState(10);
  const handleLimit = async (event) => {
      setLimit(parseInt(event.target.value));
      dispatch(
          getTodayRoutesByUserId({ 
          driverId: auth.user.id,
        //   day: 'Wednesday', 
        //   today: '2023-04-26', 
          day: moment().format('dddd'), 
          today: moment().format('YYYY-MM-DD'),
          limit: event.target.value,
          page: 1,
          })
      );
      setCurrentPageNumber(1);
  };
  const onClickNext = async () => {
    let i = currentPageNumber;
        i++;
        if (i <= routes.todayRoute.totalPages) {
        setCurrentPageNumber(currentPageNumber + 1);
        dispatch(
            getTodayRoutesByUserId({
            driverId: auth.user.id,
            // day: 'Wednesday', 
            // today: '2023-04-26', 
            day: moment().format('dddd'), 
            today: moment().format('YYYY-MM-DD'),  
            limit: limit,
            page: currentPageNumber + 1,
            })
        );
    }
  };
  const onClickPrev = async () => {
    let i = currentPageNumber;
        i--;
        if (i >= 1) {
        setCurrentPageNumber(currentPageNumber - 1);
        dispatch(
            getTodayRoutesByUserId({
            driverId: auth.user.id,
            // day: 'Wednesday', 
            // today: '2023-04-26', 
            day: moment().format('dddd'), 
            today: moment().format('YYYY-MM-DD'),
            limit: limit,
            page: currentPageNumber - 1,
            }));
        }
    };

    const handleChangeData = async (pageNo) => {
        setCurrentPageNumber(pageNo);
        dispatch(
            getTodayRoutesByUserId({
                driverId: auth.user.id,
                // day: 'Wednesday', 
                // today: '2023-04-26', 
                day: moment().format('dddd'), 
                today: moment().format('YYYY-MM-DD'),    
                limit: limit,
                page: pageNo,
            })
            );
        };

  useEffect(() => {
    todayRouteViewData()
  },[])

  return (
    <>
      <div className="d-flex flex-column w-tables rounded mt-3 bg-white">
          <div className="d-flex border">
              <div className="flex-grow-1">
                  <div className="dataTables_length d-flex" id="leads-table_length">
                      <div className="p-2">
                          <label>Show entries</label>
                      </div>
                        <label>
                            <select
                                name="leads-table_length"
                                aria-controls="leads-table"
                                className="custom-select custom-select-sm form-control form-control-sm"
                                // className="select"
                                autoComplete="false"
                                onChange={handleLimit}
                                >
                                {[10, 25, 50, 100].map((current, i) => {
                                    return (
                                    <option value={current} key={i}>
                                        {current}
                                    </option>
                                    );
                                })}
                            </select>
                        </label>
                  </div>
              </div>
          </div>
          <div id="leads-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
              <div className="row">
                  <div className="col-sm-12">
                      <table className="table border w-100 dataTable no-footer" id="leads-table" role="grid" aria-describedby="leads-table_info" style={{ overflowX: "auto" }}>
                          <thead className="card-header">
                              <tr role="row">
                                  <th title="Route #" className="sorting border-right" tabIndex="0" aria-controls="leads-table" rowSpan="1" colSpan="1" aria-label="Mobile: activate to sort column ascending">
                                      Route #
                                  </th>
                                  <th title="No. of Passengers" className="sorting_disabled border-right" rowSpan="1" colSpan="1" aria-label="Next Follow Up">
                                      No. of Passengers
                                  </th>
                                  <th title="Details" className="sorting border-right" tabIndex="0" aria-controls="leads-table" rowSpan="1" colSpan="1" aria-label="Lead Agent: activate to sort column ascending">
                                      Details
                                  </th>
                                  <th title="Navigation" className="sorting border-right" tabIndex="0" aria-controls="leads-table" rowSpan="1" colSpan="1" aria-label="Lead Agent: activate to sort column ascending">
                                      Navigation
                                  </th>
                                  <th title="Actions" className="sorting border-right w-30" tabIndex="0" aria-controls="leads-table" rowSpan="1" colSpan="1" aria-label="Lead Agent: activate to sort column ascending">
                                      Actions
                                  </th>
                              </tr>
                          </thead>
                          <tbody>
                            {
                                routes &&
                                routes.todayRoute &&
                                routes.todayRoute.results &&
                                routes.todayRoute.results.length > 0 &&
                                routes.todayRoute.results.map((current, i) => {
                                    // if (current.delete === false) {
                                    return (
                                        <DriverRouteTodayRouteComponentRow current={current} i={i} key={i}/>
                                    )
                                    // }
                                })
                            }
                          </tbody>
                      </table>
                      {
                            routes &&
                            routes.todayRoute &&
                            routes.todayRoute.results &&
                            routes.todayRoute.results.length === 0 &&
                            <div className="dash-stats-list" style={{ textAlign: "center" }}>
                            <br />
                            <br />
                            <img
                                alt=""
                                src={shift1}
                                style={{ width: "145px", opacity: "0.5" }} />
                            <br />
                            <p style={{ opacity: "0.5" }}>
                                No Data Found
                            </p>
                            </div>
                        }
                      <div id="leads-table_processing" className="dataTables_processing card" style={{display: "none"}}>Processing...</div>
                  </div>
              </div>
              <div className="d-flex border">
                {
                    routes.loading === false ?
                    <div className="flex-grow-1">
                        <div className="dataTables_info" id="leads-table_info" role="status" aria-live="polite">
                                Showing {1 + limit * (currentPageNumber - 1)} to{" "}
                                {routes.todayRoute.displayingResults + limit * (currentPageNumber - 1)}{" "}
                                of {routes.todayRoute.totalResults} entries
                            </div>
                        </div>
                      :
                      <div className="flex-grow-1">
                        <div
                          className="dataTables_info"
                          id="leads-table_info"
                          role="status"
                          aria-live="polite"
                        >
                          Loading entries
                        </div>
                    </div>
                }  
                  <div>
                      <div className="dataTables_paginate paging_simple_numbers" id="leads-table_paginate">
                        <ul className="pagination">
                            <li className="page-item">
                                <NavLink
                                className="page-link"
                                to="#"
                                aria-label="Previous"
                                onClick={onClickPrev}
                                >
                                <span aria-hidden="true">Previous</span>
                                <span className="sr-only">Previous</span>
                                </NavLink>
                            </li>
                            { 
                            routes &&
                            routes.todayRoute &&
                            routes.todayRoute.paginationArr &&
                            routes.todayRoute.paginationArr.length > 0 &&
                            routes.todayRoute.paginationArr.map((current, i) => {
                                return currentPageNumber === current ? (
                                <li className="page-item active" key={i}>
                                    <Link className="page-link" value={current} to="">
                                    {current}
                                    </Link>
                                </li>
                                ) : (
                                <li className="page-item" key={i}>
                                    <Link
                                    className="page-link"
                                    to=""
                                    value={current}
                                    onClick={() => handleChangeData(current)}
                                    >
                                    {current}
                                    </Link>
                                </li>
                                );
                            })}
                            <li
                                className="paginate_button page-item next"
                                id="leads-table_next"
                            >
                                <Link
                                to=""
                                aria-controls="leads-table"
                                data-dt-idx="3"
                                tabIndex="0"
                                className="page-link"
                                onClick={onClickNext}
                                >
                                Next
                                </Link>
                            </li>
                        </ul>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </>
  )
}
export default DriverRouteTodayRouteComponent