import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import AdminSettingVehicleDeleteModal from './AdminSettingVehicleDeleteModal'

const AdminSettingVehicleTableRow = ({current, i, handleModal, dropDownRow, setDropDownRow}) => {
    const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false)
    const [showDeleteDropDown, setshowDeleteDropDown] = useState(false)
    const openMoreVertDropDown = () => {
        setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown)
    }
    const openDeleteDropDown = () => {
        setshowDeleteDropDown((showDeleteDropDown) => !showDeleteDropDown)
    }
    const navigate = useNavigate()
    const auth = useSelector((x) => x.auth);

    return (
        <>
            <tr id="row-20" role="row" className="odd border" key={i} onMouseLeave={() => setshowMoreVertDropDown(false)}>
                <td className="border-right">{current.assetNo}</td>
                <td className="border-right">{current.vehicleOwnership}</td>
                <td className="border-right">{current?.serviceType?.name}</td>
                {/* <td className="border-right">Bus</td> */}
                <td className="border-right">{current.make}</td>
                <td className="border-right">{current.model}</td>
                <td className="border-right">{current.year}</td>
                <td className="border-right">{current.vin}</td>
                <td className="border-right">{current.numberPlate}</td>
                <td className="border-right">{current.color}</td>
                <td className="border-right">{current.mileage}</td>
                <td className=" text-right pr-20">
                    <div className="task_view">
                        <div className="dropdown">
                            <Link to="" className="task_view_more d-flex align-items-center justify-content-center dropdown-toggle" type="link" id="dropdownMenuLink-20" data-toggle="dropdown" onClick={openMoreVertDropDown} aria-haspopup="true" aria-expanded="false">
                                <i className="icon-options-vertical icons"></i>
                            </Link>
                            <div className={`dropdown-menu dropdown-menu-right ${showMoreVertDropDown ? 'dropdown-menu dropdown-menu-right show' : 'dropdown-menu dropdown-menu-right'}`} aria-labelledby="dropdownMenuLink-20" tabIndex="0">
                                <button onClick={() => navigate(`/vehicle-view/${current.id}`, {
                                    state: current,
                                        })}className="dropdown-item">
                                    <i className="fa fa-eye"> View</i>
                                </button>
                                {
                                    auth?.user?.permissionId?.settings?.update === true &&
                                    <Link className="dropdown-item" onClick={()=>handleModal(current)}>
                                        <i className="fa fa-edit"> Edit</i>
                                    </Link>
                                }
                                {
                                    auth?.user?.permissionId?.settings?.delete === true &&
                                    <Link className="dropdown-item delete-table-row" to="" data-id="20" onClick={openDeleteDropDown}>
                                        <i className="fa fa-trash"> Delete</i>
                                    </Link>
                                }
                            </div>
                        </div>
                    </div>
                </td>
                <AdminSettingVehicleDeleteModal current={current} 
                showDeleteDropDown={showDeleteDropDown}
                openDeleteDropDown={openDeleteDropDown}
                index={i}/>
            </tr>
        </>
    )
}
export default AdminSettingVehicleTableRow