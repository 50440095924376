import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteExternalCompanyUser } from '../../actions/userActions';


const ExternalCompanyUserDeleteModal = ({showDeleteDropDown, openDeleteDropDown,
     current, index, 
    //  isClient
    }) => {
    const [values, setValues] =useState({
        _id: '',
        delete: '',
        // cancelReason: '',
        // status: '',
        })
        const auth = useSelector((state) => state.auth);
        const users = useSelector((state) => state.users);
        useEffect(()=>{
        setValues({...values, _id: current?.id})
        },[current])
        const handleChange = (fieldName) => (event) => {
            setValues({
                ...values,
                [fieldName]: event.target.value,
            });
        };
        const data = {
        _id: values._id,
        delete: true,
        }
        const handleSuccess = () => {
            openDeleteDropDown();
          };
        const dispatch = useDispatch() 
        const handleSubmit = async (event) => {
            event.preventDefault()
            dispatch(deleteExternalCompanyUser(data, handleSuccess))
            // setTimeout(() => 
            //     dispatch(updateExternalCompanyUser(userDataNew))
            // , 2000);
          }
    return (
        <>
            <div
                className={`modal fade ${showDeleteDropDown === true ? "show modal-y-axis" : "hide"}`}
                role="dialog"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <form
                            onSubmit={handleSubmit}
                        >
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">
                                    {" "}
                                    Delete User
                                </h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => openDeleteDropDown()}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body" style={{ width: "50% !important" }}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <p>Are you sure you want to delele? </p>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn-secondary rounded f-14 p-2 mr-3"
                                    data-dismiss="modal"
                                    onClick={() => openDeleteDropDown()}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="btn-primary rounded f-14 p-2 mr-3"
                                >
                                    Yes
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ExternalCompanyUserDeleteModal