import axios from 'axios';
import React, { useEffect, useState } from 'react'
import logo2 from "../../assetsgeneral/images/head-logo.png"
// import logo2 from "../../assetsgeneral/images/CONNECT CARE TRANS-04.png"
// import logo2 from "../../assetsgeneral/images/CONNECT_CARE_TRANS-04small.jpg"
// import logo2 from "../../assetsgeneral/images/CONNECTCARE_WhiteLogo.png"

const Footer = () => {
    const [year, setYear] = useState()
    const getYear = async () => {
        const {data} = await axios.get(`https://worldtimeapi.org/api/timezone/Asia/Karachi`)
        setYear(data.utc_datetime.substring(0,4))
    }
    useEffect(() => {
        getYear()
    },[])
    return (

        <>
            <div className="footer-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="footer-logo">
                                <a href="index">
                                    <img src={logo2} id="footerlog"/>
                                    {/* <img src={logo2} id="footerlog" style={{maxWidth: "60% !important"}}/> */}
                                    {/* <img src={logo2} style={{maxWidth: "60%"}}/> */}
                                </a>
                            </div>
                            <div className="footer-social mt-4">
                                <ul>
                                    <li><a className="btn btn-block btn-social btn-twitter" href=""><span className="fa fa-twitter"></span></a></li>
                                    <li><a className="btn btn-block btn-social btn-facebook" href=""><span className="fa fa-facebook"></span></a></li>
                                    <li><a className="btn btn-block btn-social btn-linkedin" href=""><span className="fa fa-linkedin"></span></a></li>
                                    <li><a className="btn btn-block btn-social btn-instagram" href=""><span className="fa fa-instagram"></span></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="footer-text">
                                <h5>Quick Links</h5>
                            </div>
                            <div className="footer-menu">
                                <ul>
                                    <li>
                                        <i className="fa fa-chevron-right" id="foot-icon"></i>
                                        <a href="index.html">Home</a>
                                    </li>
                                    <li>
                                        <i className="fa fa-chevron-right" id="foot-icon"></i>
                                        <a href="why-us.html">About Us</a>
                                    </li>
                                    <li>
                                        <i className="fa fa-chevron-right" id="foot-icon"></i>
                                        <a href="/privacy-policy">Privacy Policy</a>
                                    </li>
                                    <li>
                                        <i className="fa fa-chevron-right" id="foot-icon"></i>
                                        <a href="contact-us.html">Contact Us</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="footer-text">
                                <h5>Contact Us</h5>
                            </div>
                            <div className="footer-menu">
                                <ul>
                                    <li>
                                        <i className="fa fa-phone" id="foot-icon"></i>
                                        <a href="#">(612) 423-2745</a>
                                    </li>
                                    <li>
                                        <i className="fa fa-envelope" id="foot-icon"></i>
                                        <a href="#">info@ridetify.com</a>
                                    </li>
                                    <li>
                                        <i className="fa fa-map-marker" id="foot-icon"></i>
                                        <a href="#">8900 Penn Ave S Suite#309, Bloomington, MN 55421</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bot">
                <div className="container">
                    <div className="footerb-text">
                        <p>© Copyright Ridetify {year}. All Rights Reserved. Developed By Iconic Tek</p>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Footer