import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateRouteById } from "../../../actions/routeActions";
import { getAllStudents } from "../../../actions/userActions";

const ArrangeOrderModalV2 = ({
  showModal,
  setShowModal,
  selectedRoute,
  setSelectedRoute,

  // ridePattern,
  // setRidePattern,

  // passer,
  // passengerRouteArr,
  // setPassengerRouteArr,

  customerDetailsArr,
  setCustomerDetailsArr,
  // orignalRidePattern,
  // setOrignalRidePattern,
  returnRidePattern,
  setReturnRidePattern,
  setIsRidePatternArranged
}) => {
  const dispatch = useDispatch();
  const auth = useSelector((x) => x.auth);
  const students = useSelector((x) => x.students);

  const [passengerRouteArr, setPassengerRouteArr] = useState([]);

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   // setPassengerRouteArr(orderArr);

  //   dispatch(
  //     updateRouteById(
  //       { _id: selectedRoute.id, ridePattern: ridePattern },
  //       successHandler
  //     )
  //   );
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setShowModal(false);
  };

  const successHandler = () => {
    setShowModal(false);
  };

  const swapRidePattern = (index1, index2) => {
    setReturnRidePattern((array) => {
      let data = [...array];
      let temp = data[index1];
      data[index1] = data[index2];
      data[index2] = temp;
      return data;
    });
  };

  // useEffect(() => {
  //   let tempRidePatternArr = [];
  //   if (selectedRoute)
  //     if (selectedRoute?.ridePattern?.length == 0) {
  //       for (let i = 0; i < selectedRoute.customerDetails.length; i++) {
  //         tempRidePatternArr.push({
  //           ...selectedRoute.customerDetails[i],
  //           routeType: "Pick",
  //         });
  //         tempRidePatternArr.push({
  //           ...selectedRoute.customerDetails[i],
  //           routeType: "Drop",
  //         });
  //       }
  //     }

  //     setReturnRidePattern(
  //     selectedRoute?.ridePattern?.length > 0
  //       ? selectedRoute.ridePattern
  //       : tempRidePatternArr
  //   );
  // }, [selectedRoute]);

  useEffect(() => {
    dispatch(
      getAllStudents({
        companyId: auth.user.companyId.id,
      })
    );
  }, []);

  console.log("selected route==>", selectedRoute);




  useEffect(() => {
    if (
      returnRidePattern.length == 0 ||
      returnRidePattern.length !==
        customerDetailsArr.filter((x) => x.returnRoute).length * 2
    ) {
      let tempReturnRouteArr = [];

      // // tempReturnRouteArr.reverse();

      // for (let i = 0; i < customerDetailsArr.length; i++) {
      //   tempReturnRouteArr.push({ ...customerDetailsArr[i], routeType: "Drop" });
      //   tempReturnRouteArr.push({ ...customerDetailsArr[i], routeType: "Pick" });
      // }

      for (let i = 0; i < customerDetailsArr.length; i++) {
        tempReturnRouteArr.push({
          customerId: customerDetailsArr[i].customerId,
          address: customerDetailsArr[i].dropOffAddress,
          time: customerDetailsArr[i].dropOffTime,
          customerDetails: customerDetailsArr[i],
          routeType: "Drop",
        });

        tempReturnRouteArr.push({
          customerId: customerDetailsArr[i].customerId,
          address: customerDetailsArr[i].pickUpAddress,
          time: customerDetailsArr[i].pickUpTime,
          customerDetails: customerDetailsArr[i],

          routeType: "Pick",
        });
      }

      tempReturnRouteArr.reverse();

      setReturnRidePattern(tempReturnRouteArr);
    }
  }, [customerDetailsArr]);

  useEffect(() => {
    setIsRidePatternArranged(true);
  }, []);

  return (
    <>
      <div
        className={`modal fade ${showModal ? "show modal-y-axis" : "hide"}`}
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                {" "}
                Ride Pattern
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                // onClick={() => openDropDown()}
                onClick={() => setShowModal(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body ">
              <div className="row">
                <div className="col-12 col-md-4">
                  <h6>Students List</h6>
                  <ul className="">
                    {selectedRoute?.customerDetails?.map((x, i) => {
                      // const studentFound = students?.list?.find(
                      //   (customer) => customer.id == x.customerId
                      // );
                      return (
                        <li className=" bg-grey rounded p-2 my-1">
                          {`${i + 1} ) 
                          ${`${x.customerId.firstName} ${x.customerId.lastName}`}
                          `}
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="col-12 col-md-8">
                  <div className="row align-items-center">
                    <div className="col-12 ">
                      <h6>Ride Pattern</h6>
                    </div>
                  </div>
                  <div className="row pt-2">
                  {returnRidePattern.map((x, i) => {
                          const studentFound = students?.list?.find(
                            (customer) => customer.id === x.customerId
                          );
                          return (
                            <div className="col-12 mb-1">
                              <div className="bg-grey p-1 d-flex justify-content-between align-items-center">
                                <p className="m-0">
                                  {`${i + 1}) ${x.routeType} ${
                                    // x.student
                                    studentFound
                                      ? `${studentFound.firstName} ${studentFound.lastName}`
                                      : "Student not selected"
                                  }
                        `}
                                </p>

                                <div>
                                  {i !== 0 ? (
                                    <>
                                      {x.routeType == "Drop" ? (
                                        returnRidePattern
                                          .slice(0, i - 1)
                                          .find(
                                            (z) =>
                                              z.routeType === "Pick" &&
                                              z.customerId === x.customerId
                                          ) ? (
                                          <button
                                            className="btn btn-md"
                                            onClick={() =>
                                              swapRidePattern(i, i - 1)
                                            }
                                          >
                                            <i className="bi bi-chevron-up text-primary"></i>
                                          </button>
                                        ) : (
                                          <button
                                            className="btn btn-md"
                                            disabled
                                          >
                                            <i className="bi bi-chevron-up"></i>
                                          </button>
                                        )
                                      ) : returnRidePattern
                                          .slice(i, returnRidePattern.length)
                                          .find(
                                            (z) =>
                                              z.routeType === "Drop" &&
                                              z.customerId === x.customerId
                                          ) ? (
                                        <button
                                          className="btn btn-md"
                                          onClick={() =>
                                            swapRidePattern(i, i - 1)
                                          }
                                        >
                                          <i className="bi bi-chevron-up text-primary"></i>
                                        </button>
                                      ) : (
                                        <button className="btn btn-md" disabled>
                                          <i className="bi bi-chevron-up"></i>
                                        </button>
                                      )}
                                    </>
                                  ) : (
                                    <button className="btn btn-md" disabled>
                                      <i className="bi bi-chevron-up"></i>
                                    </button>
                                  )}

                                  {i !== returnRidePattern.length - 1 ? (
                                    <>
                                      {x.routeType === "Drop" ? (
                                        returnRidePattern
                                          .slice(0, i + 1)
                                          .find(
                                            (z) =>
                                              z.routeType === "Pick" &&
                                              z.customerId === x.customerId
                                          ) ? (
                                          <button
                                            className="btn btn-md"
                                            onClick={() =>
                                              swapRidePattern(i, i + 1)
                                            }
                                          >
                                            <i className="bi bi-chevron-down text-primary"></i>
                                          </button>
                                        ) : (
                                          <button
                                            className="btn btn-md"
                                            disabled
                                          >
                                            <i className="bi bi-chevron-down"></i>
                                          </button>
                                        )
                                      ) : returnRidePattern
                                          .slice(
                                            i + 2,
                                            returnRidePattern.length
                                          )
                                          .find(
                                            (z) =>
                                              z.routeType === "Drop" &&
                                              z.customerId === x.customerId
                                          ) ? (
                                        <button
                                          className="btn btn-md"
                                          onClick={() =>
                                            swapRidePattern(i, i + 1)
                                          }
                                        >
                                          <i className="bi bi-chevron-down text-primary"></i>
                                        </button>
                                      ) : (
                                        <button className="btn btn-md" disabled>
                                          <i className="bi bi-chevron-down"></i>
                                        </button>
                                      )}
                                    </>
                                  ) : (
                                    <button className="btn btn-md" disabled>
                                      <i className="bi bi-chevron-down"></i>
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn-secondary rounded f-14 p-2 mr-3"
                data-dismiss="modal"
                onClick={() => setShowModal(false)}
              >
                Close
              </button>
              <button
                type="sunmit"
                className="btn-primary rounded f-14 p-2 mr-3"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ArrangeOrderModalV2;
