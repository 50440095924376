import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateRouteDriverStatus } from "../../../actions/routeActions";
import DriverRouteDeclineModal from "./DriverRouteDeclineModal";
// import AssignDriverModal from "./AssignDriverModal"; 

const DriverRouteComponentRow = ({current, i, dropDownRow, setDropDownRow}) => {
  const [showDeclineDropDown, setshowDeclineDropDown] = useState(false);
  const openDeclineDropDown = () => {
    setshowDeclineDropDown((showDeclineDropDown) => !showDeclineDropDown);
  };
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [ values, setValues ] = useState({
    routeId: '',
    responseDate: '',
    driverBookingStatus: '',
    declineReason: '',
    // seenByAdmin: '',
    seenByDriver: '',
  })
  const routeData = {
    routeId: values.routeId,
    responseDate: moment().format().toString().substring(0,19)+".000Z",
    driverBookingStatus: values.driverBookingStatus,
    declineReason: values.declineReason,
    // seenByAdmin: '',
    seenByDriver: true,
  }
  useEffect(() => {
    setValues({
      ...values,
      routeId: current.id
    })
  }, [current])
  const [apiHit, setApiHit] = useState(false)
  const handleChange = (fieldName) => (event) =>{
    setValues({
      ...values, [fieldName]:
      event.target.value
    })
    if (event.target.value === "Assigned"){
      setApiHit((apiHit) => !apiHit)
    }
    if (event.target.value === "Unassigned") {
        openDeclineDropDown();
    }
  }
  const handleSubmit = async () => {
    if (routeData.driverBookingStatus !== ""){
      for (let key of Object.keys(routeData)) {
        if(routeData[key] === ''){
          delete routeData[key]
        }
      }
      dispatch(updateRouteDriverStatus(routeData))
    }
    setshowDeclineDropDown(false)
  }
  useEffect(() => {
    handleSubmit()
  }, [apiHit])
  const auth = useSelector((x) => x.auth);

  return (
    <>
        <tr
        role="row"
        className="odd border"
        key={i}
        onMouseLeave={() => setshowMoreVertDropDown(false)}
      >
        <td className="border-right">{current?.routeNumber} {current.routeNumberReturn && "( Return )"}</td>
        <td className="border-right">{current?.customerDetails?.length}</td>
        <td className="border-right">
          <button
            className="btn btn-sm btn-primary"
            onClick={() => navigate(`/today-route/details/${current.id}`)}
          >
            View
            </button>
        </td>
        <td className="border-right"> {current.driverBookingStatus} </td>
        <td className="border-right">
          <div className="select-picker">
            {
              current.driverBookingStatus === 'Assigned' ?
              <button type="button" className="btn btn-success" style={{cursor:"default", margin: "auto", width: "100%"}} >Accepted</button>
              :
              auth?.user?.permissionId?.myRoutes?.update === true &&
              <select className="form-control select-picker assign_role" style={{ height: '35px' }} value={values.driverBookingStatus} onChange={handleChange('driverBookingStatus')}>
                <option value="">New Request</option>
                <option value="Assigned">Accept</option>
                <option value="Unassigned">Decline</option>
              </select>
            }
          </div>
        </td>
      </tr>
      <DriverRouteDeclineModal showDeclineDropDown={showDeclineDropDown} openDeclineDropDown={openDeclineDropDown} values={values} setValues={setValues} current={current} i={i} handleSubmit={handleSubmit} handleChange={handleChange}/>
    </>
  )
}
export default DriverRouteComponentRow