import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addLocation, getBaseLocation, updateLocation } from '../../../actions/locationActions'
import MyComponent from '../Map/Map'

const AdminSettingPaymentsBaseLocation = () => {
    const [ baseInput, setBaseInput ] = useState(false)
    const location = useSelector((state) => state.location);
    const auth = useSelector((state) => state.auth);
    const [values2, setValues2] = useState({
        _id: '',
        companyId: '',
        name: '',
        map: '',
        latitude: '',
        longitude: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        isBaseLocation: true,
    })
    const handleChange = (fieldName) => (event) => {
        setValues2({
            ...values2, [fieldName]:
            event.target.value
        })
    }
    const dispatch = useDispatch()
    const locationData = {
        companyId: auth.user.companyId.id,
        _id: values2._id,
        name: values2.name,
        map: values2.map,
        latitude: values2.latitude,
        longitude: values2.longitude,
        address: values2.address,
        city: values2.city,
        state: values2.state,
        zip: values2.zip,
        isBaseLocation: true,
    }
    const handleSubmit = async (event) => {
        event.preventDefault()
        delete locationData._id
        dispatch(addLocation(locationData))
    }
    const handleUpdate = async (event) => {
        event.preventDefault()
        delete locationData.companyId
        delete locationData.isBaseLocation
        dispatch(updateLocation(locationData))
    }
    useEffect(() => {
        if (location.baseLocation !== null){
            setValues2({
                _id: location.baseLocation.id,
                companyId: location.baseLocation.companyId,
                name: location.baseLocation.name,
                map: location.baseLocation.map,
                latitude: location.baseLocation.latitude,
                longitude: location.baseLocation.longitude,
                address: location.baseLocation.address,
                city: location.baseLocation.city,
                state: location.baseLocation.state,
                zip: location.baseLocation.zip,
                isBaseLocation: true, 
            })
            openBaseInput()
            // handleUpdate()
        }
    }, [location.baseLocation])
    const openBaseInput = () => {
        setBaseInput((baseInput) => !baseInput)
    }
    // console.log(values2)
    // const baseLocationData = async () => {
    //     dispatch(updateLocation({ companyId: auth.user.companyId.id }));
    //   };
    //   useEffect(() => {
    //     if (auth?.user?.companyId) {
    //       baseLocationData();
    //     }
    //   }, [auth]);
    // const baseLocationData = async () => {
    //     dispatch(getBaseLocation({ companyId: auth.user.companyId.id }));
    //   };
    //   useEffect(() => {
    //     if (auth?.user?.companyId) {
    //       baseLocationData();
    //     }
    //   }, [auth]);
    // useEffect(() => {
    //     dispatch(updateLocation({ companyId: auth.user.companyId.id }));
    //   }, [auth]);
    
    return (
        <>
            <div className="s-b-n-content">
                <div className="tab-content" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="nav-email" role="tabpanel" aria-labelledby="nav-email-tab">
                        <div className="d-flex flex-wrap justify-content-between">
                            <div className="col-lg-12 col-md-12 ntfcn-tab-content-left w-100 p-4 ">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group my-3">
                                            <label className="f-14 text-dark mb-12" data-label="" htmlFor="Name"> Name</label>
                                            <sup className="text-danger">*</sup>
                                            <input type="text" className="form-control height-35 f-14" placeholder="Enter Your Name" id="Name" value={values2.name} onChange={handleChange('name')}/>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group my-3">
                                            <label className="f-14 text-dark mb-12" data-label="" htmlFor="Name">Street Address</label>
                                            <sup className="text-danger">*</sup>
                                            {/* {
                                                values2.address !== '' ?
                                                <input type="text" className="form-control height-35 f-14" placeholder="" id="City" value={values2.address} onClick={()=>openBaseInput()}/>
                                                : */}
                                                <MyComponent values2={values2} setValues2={setValues2} addressss={values2.address}/>
                                            {/* } */}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group my-3">
                                            <label className="f-14 text-dark mb-12" data-label="" htmlFor="City">City</label>
                                            <sup className="text-danger">*</sup>
                                            <input type="text" className="form-control height-35 f-14" placeholder="" id="City" value={values2.city}/>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group my-3">
                                            <label className="f-14 text-dark mb-12" data-label="" htmlFor="cname">State</label>
                                            <sup className="text-danger">*</sup>
                                            <input type="text" className="form-control height-35 f-14" placeholder="" id="State" value={values2.state}/>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group my-3">
                                            <label className="f-14 text-dark mb-12" data-label="" htmlFor="Zipcode">Zip Code</label>
                                            <sup className="text-danger">*</sup>
                                            <input type="text" className="form-control height-35 f-14" placeholder="" id="Zipcode" value={values2.zip}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            {
                                location.baseLocation &&
                                location.baseLocation ?
                                auth?.user?.permissionId?.settings?.update === true &&
                                <button type="button" className="btn-primary rounded f-14 p-2 mr-3" onClick={handleUpdate}>Update</button>
                                :
                                auth?.user?.permissionId?.settings?.create === true &&
                                <button type="button" className="btn-primary rounded f-14 p-2 mr-3" onClick={handleSubmit}>Create</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdminSettingPaymentsBaseLocation