import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getReservationsSchedule, updateReservation } from '../../../actions/reservationAction';

const AdminDispatchAssignModalComponent = ({ assigned, showDropDown, openDropDown, current, index }) => {
  const auth = useSelector((state) => state.auth);
  const reservation = useSelector((state) => state.reservation);
  const users = useSelector((state) => state.users);
  const dispatch = useDispatch()
  const [ values, setValues ] = useState({
    _id: '',
    driverId: '',
    assignedDate: '',
  })
  useEffect(() => {
    setValues({
      ...values,
      _id: current._id
    })
  }, [current])
  const handleChange = (fieldName) => (event) =>{
    setValues({
      ...values, [fieldName]:
      event.target.value
    })
  }
  const reservationData = {
    _id: values._id,
    driverId: values.driverId,
    assigneeId: auth.user.id,
    assignedDate: moment().format().toString().substring(0,19)+".000Z",
  }
  const handleSubmit = async (event) => {
    event.preventDefault()
    // console.log("first")
    if (reservationData.driverId !== ""){
      dispatch(updateReservation(reservationData))
    }
    openDropDown()
    setTimeout(
      () => 
      {
        if (assigned === "unassigned"){
          dispatch(getReservationsSchedule({ 
              companyId: auth?.user?.companyId?.id,
              today: moment().format('YYYY-MM-DD'),
              assigned: assigned, 
              limit: reservation.unassigned.limit,
              page: reservation.unassigned.page === 1 ? 
              reservation.unassigned.page : 
              reservation.unassigned.results.length === 1 ? 
              reservation.unassigned.page - 1:
              reservation.unassigned.page
          }))
        }
      },
      2000
  );
  }
  
  // console.log(current,reservationData, "reservationData<")
  return (
    <>
      <div className={`modal fade ${showDropDown ? 'show modal-y-axis' : 'hide'}`} role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle"> Select The Driver</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => openDropDown()}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group my-3">
                    <label className="f-14 text-dark-grey mb-12" data-label="" htmlFor="cname">Select the Driver
                      <sup className="text-danger">*</sup>
                    </label>
                    <div className="select-picker">
                      <select name="default_task_status" id="default_task_status" className="form-control select-picker" style={{height: '39px'}} value={values.driverId} onChange={handleChange('driverId')}>
                        <option value=""> Select the Driver</option>
                        {
                          users?.allDriversWithoutPagination?.length > 0 &&
                          users?.allDriversWithoutPagination?.map((current, i) => {
                            return(
                              <option value={current.id} key={i}> {current.firstName+" "+current.lastName}</option>
                            )
                          })
                        }
                        {/* <option value="2"> Mohamad Ahmed</option>
                        <option value="3"> James Smith</option>
                        <option value="4"> Stave</option> */}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn-secondary rounded f-14 p-2 mr-3" data-dismiss="modal" onClick={() => openDropDown()}>Close</button>
              {/* <button type="button" className="btn-primary rounded f-14 p-2 mr-3" onClick={handleSubmit}>Save changes</button> */}
              <button type="button" className="btn-primary rounded f-14 p-2 mr-3" onClick={handleSubmit}>Save changes</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default AdminDispatchAssignModalComponent