import {
    LOCATION_VIEW_REQUEST,
    LOCATION_VIEW_SUCCESS,
    LOCATION_VIEW_FAIL,
    LOCATION_CREATE_REQUEST,
    LOCATION_CREATE_SUCCESS,
    LOCATION_CREATE_FAIL,
    LOCATION_BY_USER_REQUEST,
    LOCATION_BY_USER_SUCCESS,
    LOCATION_BY_USER_FAIL,
    BASE_LOCATION_REQUEST,
    BASE_LOCATION_SUCCESS,
    BASE_LOCATION_FAIL,
    LOCATION_UPDATE_REQUEST,
    LOCATION_UPDATE_SUCCESS,
    LOCATION_UPDATE_FAIL,
} from "../constants"

export const locationReducer = (
    state = {
        location: {
            location: null,
            searchedLocation: null,
            baseLocation: null,
        }
    },
    action
) => {
        switch (action.type) {

        // LOCATION VIEW TABLE
        case LOCATION_VIEW_REQUEST : 
            return {
                ...state,
            };
        case LOCATION_VIEW_SUCCESS :
            return {
                ...state,
                location: action.payload,
            };
        case LOCATION_VIEW_FAIL :
            return {
                ...state,
                error: action.payload,
            };

        // LOCATION CREATE 
        case LOCATION_CREATE_REQUEST :
            return {
                ...state,
            }
        case LOCATION_CREATE_SUCCESS :
            return {
                ...state,
                location: [...state.location, action.payload],
            }
        case LOCATION_CREATE_FAIL :
            return {
                ...state,
                error: action.payload,
            }

        // LOCATION BY USER ID
        case LOCATION_BY_USER_REQUEST : 
        return {
            ...state,
        };
        case LOCATION_BY_USER_SUCCESS :
        return {
            ...state,
            searchedLocation: action.payload,
        };
        case LOCATION_BY_USER_FAIL :
        return {
            ...state,
            error: action.payload,
        };

        // BASE LOCATION
        case BASE_LOCATION_REQUEST : 
        return {
            ...state,
        };
        case BASE_LOCATION_SUCCESS :
        return {
            ...state,
            baseLocation: action.payload,
        };
        case BASE_LOCATION_FAIL :
        return {
            ...state,
            error: action.payload,
        };

        // UPDATE LOCATION
        case LOCATION_UPDATE_REQUEST : 
        return {
            ...state,
        };
        case LOCATION_UPDATE_SUCCESS :
        return {
            ...state,
            baseLocation: action.payload,
        //     location: state.location.map((x) =>
        //     x.id === action.payload.id 
        //     ? action.payload : x
        //   ),
        };
        case LOCATION_UPDATE_FAIL :
        return {
            ...state,
            error: action.payload,
        };
        default:
            return state;
    }
}