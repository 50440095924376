import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import mapboxgl from "mapbox-gl";
import moment from "moment";
import SearchedByMap from "../Map/SearchedByMap";
import AdminBookingPriceDetailModal from "./AdminBookingPriceDetailModal";

const AdminBookingReturnTrip = ({
  returnTrip,
  onClickReturnTrip,
  newArrForRT,
  values,
  values2,
  setValues2,
  returnStopArr,
  setReturnStopArr,
  details,
  willCall,
}) => {
  const directionsService = new window.google.maps.DirectionsService();
  mapboxgl.accessToken =
    "pk.eyJ1IjoiaWNvbmljdGVrIiwiYSI6ImNsZW9oZXlsOTAyZmQzcGw4ejA2Zzk2bmYifQ.StmuWRfUB_3kU8yI5fISxQ";
  const location = useSelector((state) => state.location);
  const fare = useSelector((state) => state.fare);
  const auth = useSelector((state) => state.auth);
  const [fareDetails, setFareDetails] = useState();
  const [showDetailDropDown, setshowDetailDropDown] = useState(false);
  const openDetailDropDown = () => {
    setshowDetailDropDown((showDetailDropDown) => !showDetailDropDown);
    if ((useHardCodedFares === false) && (useDirectFare === false)){
      calculateFareFrontEnd()
    }
  };
  const [eta, setEta] = useState();

  // const [returnStopArr, setReturnStopArr] = useState([])
  let temp = [];
  // useEffect(() => {
  //   // console.log(newArrForRT.reverse(), "newArrForRTnewArrForRT", newArrForRT)
  //     if (newArrForRT) {
  //       setReturnStopArr(newArrForRT.reverse())
  //     }
  //     // setReturnStopArr(newArrForRT)
  // }, [newArrForRT])
  const [showDropLoc, setDropLoc] = useState(false);
  const openDropLoc = () => {
    setDropLoc((showDropLoc) => !showDropLoc);
    // console.log(showDropLoc);
  };
  // const [values2, setValues2]= useState({
  //     // "companyId": "63cb14f53f55723694dbd185",
  //     name: '',
  //     customerId: '',
  //     type: '',
  //     bookingDate: '',
  //     seats: "1",
  //     willCall: false,
  //     needAttended: false,
  //     numberOfWheelchairs: '1',
  //     amSeats: '0',
  //     needWheel: false,
  //     needAssigned: false,
  //     pickUpAddress: '',
  //     pickUpDate: '',
  //     pickUpTime: '',
  //     dropOffAddress: '',
  //     dropOffDate: '',
  //     dropOffTime: '',
  //     dateForReservation: '',
  //     driverNotes: '',
  //     dispatchNotes: '',
  //     distanceFromBaseLocation: '',
  //     distanceFromPickToDropOff: '',
  //     totalMileage: '',
  //     loadNumber: '',
  //     clientIdExternal: '',
  //     rideHistory: []
  // })
  // const handleChange = (fieldName) => (event) => {
  //     setValues2({
  //         ...values, [fieldName]:
  //         event.target.value
  //     })
  // }
  useEffect(() => {
    setValues2({
      ...values,
      customerId: values.customerId,
      seats: values.seats,
      willCall: values.willCall,
      needAttended: values.needAttended,
      numberOfWheelchairs: values.numberOfWheelchairs,
      amSeats: values.amSeats,
      needWheel: values.needWheel,
      needAssigned: values.needAssigned,
      pickUpAddress: values.pickUpAddress,
      pickUpDate: values.pickUpDate,
      pickUpTime: values.pickUpTime,
      dropOffAddress: values.dropOffAddress,
      dropOffDate: values.dropOffDate,
      dropOffTime: values.dropOffTime,
      dateForReservation: values.dateForReservation,
      driverNotes: values.driverNotes,
      dispatchNotes: values.dispatchNotes,
      distanceFromBaseLocation: values.distanceFromBaseLocation,
      distanceFromPickToDropOff: values.distanceFromPickToDropOff,
      totalMileage: values.totalMileage,
      rideHistory: values.rideHistory,
      // loadNumber: values.loadNumber,
      // clientIdExternal: values.clientIdExternal,
      externalCompanyId: values.externalCompanyId
        ? details.externalCompanyId
        : "",
    });
  }, [values]);
  const handleStopChange = (index, field) => (event) => {
    setReturnStopArr(
      returnStopArr.map((stop, i) =>
        i === index ? { ...stop, [field]: event.target.value } : { ...stop }
      )
    );
  };
  const increaseStop = () => {
    setReturnStopArr([
      ...returnStopArr,
      {
        address: "",
        date: "",
        time: "",
        // id: returnStopArr.length
      },
    ]);
  };
  const decreaseStop = (index) => {
    setReturnStopArr(returnStopArr.filter((stop, i) => i !== index));
  };
  let newArr = [];
  let [amount, setAmount] = useState(0);
  let distanceFromBaseLocation = 0;
  let distanceFromPickToDropOff = 0;
  let totalMileage = 0;

  // console.log(returnStopArr, "returnStopArr");

  // yahan se new logic
  const [useHardCodedFares, setUseHardCodedFares] = useState(false);
  const [hardCodedFares, setHardCodedFares] = useState({
    flatBaseFare: "",
    farePerMile: "",
    deadHeadThreshold: "",
    baseFarePerMile: "",
    totalMileageAllows: "",
    longDistancePerMile: "",
    offHourFee: "",
    noShowFee: "",
    cancelationFee: "",
    additionalCharges: 0,
    deduction: 0,
  });
  const handleHardCodedFares = (fieldName) => (event) => {
    setHardCodedFares({
      ...hardCodedFares,
      // [fieldName]: event.target.value !== "" ? parseFloat(event.target.value) : 0,
      [fieldName]: event.target.value,
    });
  };
  const recalculate = () => {
    calculateFareFrontEnd();
  };
  // console.log(useHardCodedFares,hardCodedFares, "hardCodedFares")
  const toogleHardCoded = () => {
    if (!useHardCodedFares === true){
      setUseDirectFare(false)
    } 
    setUseHardCodedFares((useHardCodedFares) => !useHardCodedFares);
  };
  const [useDirectFare, setUseDirectFare] = useState(false)
  const [directFare, setDirectFare] = useState(0)
  const toogleDirectFare = () => {
    if (!useDirectFare === true){
      setUseHardCodedFares(false)
    } 
    setUseDirectFare((useDirectFare) => !useDirectFare);
  };
  const handleDirectFare = (event) => {
    setDirectFare(event.target.value)
  }
  const fareDirectlyCalculate = () => {
    let driverAmount = parseFloat(
      (directFare * (auth?.user?.companyId?.driverPercentage / 100)).toFixed(2)
    );
    let companyAmount = parseFloat(
      directFare - driverAmount
    );
    setFareDetails(
      {
        distanceFromBaseLocation: fareDetails.distanceFromBaseLocation,
        distanceFromPickToDropOff: fareDetails.distanceFromPickToDropOff,
        totalMileage: fareDetails.totalMileage,
        fare: parseFloat(directFare),
        amount: parseFloat(directFare),
        estimatedTime: fareDetails.estimatedTime,
        etaTime: fareDetails.etaTime,
        driverAmount: driverAmount,
        companyAmount: companyAmount,
        baseFare: 0,
        rideFare: 0,
        offHourFee: 0,
      }
    )
    setAmount(directFare)
  }
  // console.log(fareDetails, "fareDetails")
  const [useFlatDriverRate, setUseFlatDriverRate] = useState(false);
  const [flatDriverRate, setFlatDriverRate] = useState(0);
  const toogleFlatDriverRate = () => {
    if (!useFlatDriverRate === true) {
      setUseHardCodedFares(false);
      setUseDirectFare(false)
    }
    setUseFlatDriverRate((useFlatDriverRate) => !useFlatDriverRate);
  };
  const handleFlatDriverRate = (event) => {
    setFlatDriverRate(event.target.value);
  };
  const flatDriverRateRecalculate = () => {
    let driverAmount = flatDriverRate
    let companyAmount = parseFloat(fareDetails.amount - driverAmount);
    setFareDetails({
      distanceFromBaseLocation: fareDetails.distanceFromBaseLocation,
      distanceFromPickToDropOff: fareDetails.distanceFromPickToDropOff,
      totalMileage: fareDetails.totalMileage,
      fare: fareDetails.fare,
      amount: fareDetails.amount,
      estimatedTime: fareDetails.estimatedTime,
      etaTime: fareDetails.etaTime,
      driverAmount: driverAmount,
      companyAmount: companyAmount,
      baseFare: 0,
      rideFare: 0,
      offHourFee: 0,
      baseFare: fareDetails.baseFare,
      rideFare: fareDetails.rideFare,
      offHourFee: fareDetails?.offHourFee ? fareDetails.offHourFee : 0,
    });
    // setAmount(directFare);
  };

  // yahan takh new logic

  const calculateFareFrontEnd = async () => {
    const abc = JSON.parse(values.type);
    let newArr = [];
    returnStopArr.forEach((e) => {
      newArr.push(e?.address?.address);
    });
    let obj = {};
    // yahan se new logic
    let eta = { hour: [], mins: [] };
    // yahan takh new logic
    await directionsService
      .route({
        origin: {
          query: location.baseLocation.address,
        },
        destination: {
          query: newArr[0],
        },
        travelMode: window.google.maps.TravelMode.DRIVING,
      })
      .then((response) => {
        console.log("response", response);
        distanceFromBaseLocation = parseFloat(
          response.routes[0].legs[0].distance.text.split(" mi")[0]
        );
        obj.distanceFromBaseLocation = distanceFromBaseLocation;
      })
      .catch((e) => {
        console.log("Directions request failed due to ", e);
        // window.alert("Directions request failed due to ", e)
      });
    // yahan se new logic
    let oldDate = new Date(
      returnStopArr[0].date.substring(0, 10) + "T" + returnStopArr[0].time + ":00.000Z"
    );
    // yahan takh new logic
    for (let i = 0; i < newArr.length - 1; i++) {
      await directionsService
        .route({
          origin: {
            query: newArr[i],
          },
          destination: {
            query: newArr[i + 1],
          },
          travelMode: window.google.maps.TravelMode.DRIVING,
        })
        .then((response) => {
          console.log("response", response);
          distanceFromPickToDropOff += parseFloat(
            response.routes[0].legs[0].distance.text.split(" mi")[0]
          );
          // yahan se new logic
          if (response.routes[0].legs[0].duration.text.length > 7) {
            const timeString = response.routes[0].legs[0].duration.text;
            const hourRegex = /(\d+)\s*hour/;
            const minuteRegex = /(\d+)\s*min/;

            const hourMatch = hourRegex.exec(timeString);
            const minuteMatch = minuteRegex.exec(timeString);

            const hours = hourMatch ? parseInt(hourMatch[1]) : 0;
            const minutes = minuteMatch ? parseInt(minuteMatch[1]) : 0;
            eta.hour.push(hours);
            eta.mins.push(minutes);
            // eta.hour.push(
            //   parseInt(
            //     response.routes[0].legs[0].duration.text.split(" hour")[0]
            //   )
            // );
            // let min =
            //   response.routes[0].legs[0].duration.text.split(" hour")[1];
            // eta.mins.push(parseInt(min.split(" mins")[0]));
            let aaa = (hours * 60 + minutes) * 60 * 1000;
            console.log(aaa, oldDate, "eta aarha hai");
            oldDate = new Date(aaa + new Date(oldDate).getTime()).toUTCString();
            let oldDateTime = oldDate.toString().split(" ")[4];
            let ind = i + 1;
            setReturnStopArr(
              returnStopArr.map((stop, index) =>
                index === ind
                  ? {
                      ...stop,
                      date: new Date(oldDate).toISOString().substring(0, 10),
                      time: oldDateTime.substring(0, 5),
                    }
                  : { ...stop }
              )
            );
          } else {
            eta.mins.push(
              parseInt(
                response.routes[0].legs[0].duration.text.split(" mins")[0]
              )
            );
            let aaa =
              parseInt(
                response.routes[0].legs[0].duration.text.split(" mins")[0]
              ) *
              60 *
              1000;
            oldDate = new Date(aaa + new Date(oldDate).getTime()).toUTCString();
            let oldDateTime = oldDate.toString().split(" ")[4];
            let ind = i + 1;
            setReturnStopArr(
              returnStopArr.map((stop, index) =>
                index === ind
                  ? {
                      ...stop,
                      date: new Date(oldDate).toISOString().substring(0, 10),
                      time: oldDateTime.substring(0, 5),
                    }
                  : { ...stop }
              )
            );
          }
          // yahan takh new logic
        })
        .catch((e) => {
          console.log("Directions request failed due to ", e);
          // window.alert("Directions request failed due to ", e)
        });
    }
    // console.log(distanceFromPickToDropOff, "distanceFromPickToDropOff")

    totalMileage = parseFloat(
      (distanceFromBaseLocation + distanceFromPickToDropOff).toFixed(2)
    );
    obj.distanceFromPickToDropOff = distanceFromPickToDropOff;
    obj.totalMileage = totalMileage;
    let fares = fare.fare;
    let baseFare;
    let rideFare;
    let companyFare = [];
    let serviceFare = [];
    let offHourFee = 0;
    if (details.isCompany === false) {
      fares = fare.fare[0].details;
      fares.forEach((e) => {
        if (e.serviceId.id === abc.id) {
          serviceFare.push(e);
        }
      });
    } else {
      fares.forEach((e) => {
        // console.log(e,e.business.id === details.externalCompanyId, e.business.id,details.externalCompanyId)
        if (e.business.id === details.externalCompanyId) {
          companyFare.push(...e.details);
        }
      });
      companyFare.forEach((e) => {
        if (e.serviceId.id === abc.id) {
          serviceFare.push(e);
        }
      });
    }
    serviceFare.sort((a, b) => a.totalMileageAllows - b.totalMileageAllows);
    let _fare;
    for (let i = 0; i < serviceFare.length; i++) {
      if (totalMileage <= serviceFare[i].totalMileageAllows) {
        _fare = serviceFare[i];
        // console.log(_fare)
        break;
      }
    }
    if (!_fare) {
      _fare = serviceFare[serviceFare.length - 1];
    }
    // yahan se new logic
    if (useHardCodedFares === true) {
      _fare = {
        flatBaseFare: parseFloat(hardCodedFares.flatBaseFare),
        farePerMile: parseFloat(hardCodedFares.farePerMile),
        deadHeadThreshold: parseFloat(hardCodedFares.deadHeadThreshold),
        baseFarePerMile: parseFloat(hardCodedFares.baseFarePerMile),
        totalMileageAllows: parseFloat(hardCodedFares.totalMileageAllows),
        longDistancePerMile: parseFloat(hardCodedFares.longDistancePerMile),
        offHourFee: parseFloat(hardCodedFares.offHourFee),
        noShowFee: parseFloat(hardCodedFares.noShowFee),
        cancelationFee: parseFloat(hardCodedFares.cancelationFee),
      };
    }
    // yahan takh new logic
    obj.fare = _fare;
    if (distanceFromBaseLocation <= _fare.deadHeadThreshold) {
      baseFare = _fare.flatBaseFare;
      obj.baseFare = baseFare;
      obj.baseFareStructure = {
        values: { flatBaseFare: _fare.flatBaseFare },
        valuesFormula: "flatBaseFare",
      };
    } else {
      // yahan se new logic
      if (_fare.deadHeadThreshold) {
        baseFare =
          _fare.flatBaseFare + _fare.baseFarePerMile * distanceFromBaseLocation;
        obj.baseFare = baseFare;
        obj.baseFareStructure = {
          values: {
            flatBaseFare: _fare.flatBaseFare,
            baseFarePerMile: _fare.baseFarePerMile,
            distanceFromBaseLocation: distanceFromBaseLocation,
          },
          valuesFormula:
            "flatBaseFare + (baseFarePerMile * distanceFromBaseLocation)",
        };
      } else {
        baseFare = _fare.flatBaseFare;
        obj.baseFare = baseFare;
        obj.baseFareStructure = {
          values: { flatBaseFare: _fare.flatBaseFare },
          valuesFormula: "flatBaseFare",
        };
      }
      // yahan takh new logic
    }
    if (distanceFromPickToDropOff <= _fare.totalMileageAllows) {
      // console.log(distanceFromPickToDropOff, "distanceFromPickToDropOff")
      rideFare = distanceFromPickToDropOff * _fare.farePerMile;
      obj.rideFare = rideFare;
      obj.rideFareStructure = {
        values: {
          distanceFromPickToDropOff: distanceFromPickToDropOff,
          farePerMile: _fare.farePerMile,
        },
        valuesFormula: "distanceFromPickToDropOff * farePerMile",
      };
    } else {
      // yahan se new logic
      if (_fare.totalMileageAllows) {
        rideFare = distanceFromPickToDropOff * _fare.longDistancePerMile;
        obj.rideFare = rideFare;
        obj.rideFareStructure = {
          values: {
            distanceFromPickToDropOff: distanceFromPickToDropOff,
            longDistancePerMile: _fare.longDistancePerMile,
          },
          valuesFormula: "distanceFromPickToDropOff * longDistancePerMile",
        };
      } else {
        rideFare = distanceFromPickToDropOff * _fare.farePerMile;
        obj.rideFare = rideFare;
        obj.rideFareStructure = {
          values: {
            distanceFromPickToDropOff: distanceFromPickToDropOff,
            farePerMile: _fare.farePerMile,
          },
          valuesFormula: "distanceFromPickToDropOff * farePerMile",
        };
      }
      // yahan takh
    }

    const company = auth?.user?.companyId;
    // console.log(company)
    for (let key of Object.keys(company.availability)) {
      if (key === moment(returnStopArr[0].date).format("dddd")) {
        if (company.availability[key] === false) {
          offHourFee += _fare.offHourFee ? _fare.offHourFee : 0;
          obj.offHourFee = offHourFee;
        }
      }
    }
    // console.log(company.offHours.from.substring(0,2))
    // console.log(company.offHours.from.substring(3,5))
    if (willCall !== true) {
      //wese is condition se kuch ho toh nahi raha kyun ke value phele se aarhi hain
      if (offHourFee === 0) {
        if (
          parseInt(company.offHours.from.substring(0, 2)) <=
            parseInt(returnStopArr[0].time.substring(0, 2)) &&
          parseInt(returnStopArr[0].time.substring(0, 2)) <=
            parseInt(company.offHours.to.substring(0, 2))
        ) {
          if (
            parseInt(company.offHours.from.substring(3, 5)) <=
            parseInt(returnStopArr[0].time.substring(3, 5))
          ) {
            offHourFee += _fare.offHourFee ? _fare.offHourFee : 0;
            obj.offHourFee = offHourFee;
          }
        }
        // else {
        //   console.log("normal")
        // }
      }
    }
    if (offHourFee === 0) {
      amount = (rideFare + baseFare).toFixed(2);
      // yahan se new logic
      if (useHardCodedFares === true) {
        amount = parseFloat(amount) + parseFloat(hardCodedFares.additionalCharges) - parseFloat(hardCodedFares.deduction)
      }
      obj.amount = parseFloat(amount);
      obj.amountStructure = {
        values: {
          rideFare: rideFare,
          baseFare: baseFare,
          ...(
            useHardCodedFares === true &&
            {
              additionalCharges: parseFloat(hardCodedFares.additionalCharges),
              deduction: parseFloat(hardCodedFares.deduction)
            }
          )
        },
        valuesFormula: "rideFare + baseFare ",
      };
      // yahan takh
    } else {
      amount = (rideFare + baseFare + offHourFee).toFixed(2);
      // yahan se new logic
      if (useHardCodedFares === true) {
        amount = parseFloat(amount) + parseFloat(hardCodedFares.additionalCharges) - parseFloat(hardCodedFares.deduction)
      }
      obj.amount = parseFloat(amount);
      obj.amountStructure = {
        values: {
          rideFare: rideFare,
          baseFare: baseFare,
          offHourFee: offHourFee,
          ...(
            useHardCodedFares === true &&
            {
              additionalCharges: parseFloat(hardCodedFares.additionalCharges),
              deduction: parseFloat(hardCodedFares.deduction)
            }
          )
        },
        valuesFormula: "rideFare + baseFare + offHourFee",
      };
      // yahan takh
    }
    console.log("objobjobjobj2", obj);
    // yahan se new logic
    let m = 0;
    let hr = 0;
    let tempHr = 0;
    eta.mins.forEach((e) => {
      m += e;
    });
    tempHr = Math.floor(m / 60);
    m = m % 60;
    eta.hour.forEach((e) => {
      hr += e;
    });
    hr += tempHr;
    let estimatedTime = hr !== 0 ? hr + " h " + m + " m" : m + " m";
    obj.estimatedTime = estimatedTime;
    let newDropOffEta = new Date(
      returnStopArr[0].date.substring(0, 10) +
        "T" +
        returnStopArr[0].time +
        ":00.000Z"
    ).getTime();
    console.log("etaeta,", hr, "hrs", m, "mins ", newDropOffEta);
    let addNewDropOffEta = (hr * 60 + m) * 60 * 1000;
    newDropOffEta = new Date(addNewDropOffEta + newDropOffEta).toUTCString();
    newDropOffEta = newDropOffEta.toString().split(" ")[4];
    let etaTime = newDropOffEta.substring(0, 5);
    obj.etaTime = etaTime;
    // console.log("etaeta,", hr, 'hrs', m, "mins ", newDropOffEta)
    let driverAmount = parseFloat(
      (amount * (auth?.user?.companyId?.driverPercentage / 100)).toFixed(2)
    );
    let companyAmount = parseFloat(
      amount - driverAmount
    );
    obj.driverAmount = driverAmount;
    obj.companyAmount = companyAmount;
    setEta(estimatedTime);
    setHardCodedFares({
      ...hardCodedFares,
      flatBaseFare: _fare.flatBaseFare,
      farePerMile: _fare.farePerMile,
      deadHeadThreshold: _fare.deadHeadThreshold,
      baseFarePerMile: _fare.baseFarePerMile,
      totalMileageAllows: _fare.totalMileageAllows,
      longDistancePerMile: _fare.longDistancePerMile,
      offHourFee: _fare.offHourFee,
      noShowFee: _fare.noShowFee,
      cancelationFee: _fare.cancelationFee,
      // additionalCharges: 0,
      // deduction: 0,
    });
    // yahan takh new logic
    setAmount(obj.amount);
    setMil(obj.distanceFromPickToDropOff);
    setFareDetails(obj);
    setValues2({
      ...values2,
      fareAndDistance: obj,
      totalAmount: obj.amount,
      totalMileage: obj.totalMileage,
      rideHistory: returnStopArr,
      distanceFromBaseLocation: obj.distanceFromBaseLocation,
      distanceFromPickToDropOff: obj.distanceFromPickToDropOff,
      isCompany: details?.isCompany === true ? true : false,
      externalCompanyId: details.externalCompanyId
        ? details.externalCompanyId
        : "",
    });
    // returnStopArr.forEach(e => {
    //   newArr.push(location.searchedLocation.find(c => c.id.includes(e.address)))
    // });
    // const baseToPickUp = await fetch(
    //   `https://api.mapbox.com/directions/v5/mapbox/driving/${location.baseLocation.longitude},${location.baseLocation.latitude};${newArr[0].longitude},${newArr[0].latitude}?steps=true&geometries=geojson&access_token=${mapboxgl.accessToken}`,
    //   { method: 'GET' }
    //   );
    // const json = await baseToPickUp.json();
    // const data = json.routes[0];
    // distanceFromBaseLocation = ((data.distance/1000)/1.609).toFixed(2)
    // let allPoints = ''
    // newArr.forEach(e => {
    //   allPoints += e.longitude+","+e.latitude+";"
    // });
    // allPoints = allPoints.substring(0, allPoints.length-1)
    // console.log(allPoints,"allPoints")
    // const pickUpToDropOff = await fetch(
    //   `https://api.mapbox.com/directions/v5/mapbox/driving/${allPoints}?steps=true&geometries=geojson&access_token=${mapboxgl.accessToken}`,
    //   { method: 'GET' }
    //   );
    // const json2 = await pickUpToDropOff.json();
    // const data2 = json2.routes[0];
    // distanceFromPickToDropOff = ((data2.distance/1000)/1.609).toFixed(2)
    // totalMileage = (parseFloat(distanceFromBaseLocation) + parseFloat(distanceFromPickToDropOff)).toFixed(2)
    // let nn = fare.fare
    // let aaArr = []
    // const abc = JSON.parse(values.type)
    // nn.forEach(e => {
    //   if (e.serviceId.id === abc.id){
    //     aaArr.push(e)
    //   }
    // });
    // aaArr.sort((a,b) => a.totalMileageAllows - b.totalMileageAllows);
    // let _fare = 0;
    // for (let i=0; i< aaArr.length; i++){
    //   if (totalMileage <= aaArr[i].totalMileageAllows){
    //     _fare = aaArr[i]
    //     // console.log(_fare)
    //     break;
    //   }
    // }
    // if(!_fare){
    //   _fare=aaArr[aaArr.length-1]
    // }
    // let baseFare = 0;
    // if (_fare.isFlatBaseFare === true){
    //   baseFare = _fare.flatBaseFare
    // }
    // else {
    //   baseFare = _fare.baseFarePerMile * distanceFromBaseLocation
    // }
    // setAmount(((distanceFromPickToDropOff * _fare.farePerMile) + baseFare).toFixed(2))
    // setMil(totalMileage)
  };
  // console.log(values2, "objobjobjobj2" )
  const [mil, setMil] = useState(0);
  useEffect(() => {
    if (returnStopArr.length >= 2) {
      let cond = true;
      // returnStopArr.forEach(e => {
      //   for (let key of Object.keys(e)) {
      //     if(e[key] === ''){
      //       cond = false
      //     }
      //   }
      // });
      returnStopArr.forEach((e) => {
        if (e.address === "") {
          cond = false;
        }
      });
      if (willCall === false) {
        if (returnStopArr[0].time === "" || returnStopArr[0].date === "") {
          cond = false;
        }
      } else {
        if (returnStopArr[0].date === "") {
          cond = false;
        }
      }
      if (cond === true) {
        calculateFareFrontEnd();
      }
    }
  }, [returnStopArr]);
  console.log(willCall, "condcondcond");
  return (
    <>
      {returnStopArr.length > 0 &&
        returnStopArr.map((current, i) => {
          return (
            <>
              {/* <div className="col-lg-12">
                <div className="bg-white border-0 text-capitalize d-flex justify-content-between px-2">
                  <h4 className="f-18 f-w-500 mb-0">Stop {i+1}</h4>
                </div>
              </div> */}
              <div className="col-sm-4 pl-4">
                <div className="form-group my-3">
                  <div className="d-flex justify-content-between">
                    <div>
                      <div className="d-flex">
                        <div>
                          <label
                            className="f-14 text-dark mb-12"
                            data-label=""
                            htmlFor="clnumber"
                          >
                            {i === 0 ? "Pickup Address" : "Dropoff Address"}
                          </label>
                        </div>
                        <div className="p-2">
                          <sup className="text-danger f-14">*</sup>
                        </div>
                      </div>
                    </div>
                    {/* <div>
                      <button className="bg-button" type="button" data-toggle="modal" data-target="#exampleModalCenter2" onClick={openDropLoc}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-plus-circle-fill fa-w-20 f-20 text-dark" viewBox="0 0 16 16">
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                        </svg>
                      </button>
                    </div> */}
                  </div>
                  <SearchedByMap
                    stopArr={returnStopArr}
                    setStopArr={setReturnStopArr}
                    field="address"
                    index={i}
                    searchAddress={current.address.address}
                  />
                  {/* {
                    values2.customerId ?
                    <select className="form-control select-picker" 
                      style={{ height: "39px", fontSize: "14px" }}
                      value={returnStopArr[i].address} 
                      onChange={handleStopChange(i,'address')}
                      >
                        <option value="" key={"st"}> Select Location</option>
                        { 
                          location.searchedLocation &&
                          location.searchedLocation.length > 0 &&
                          location.searchedLocation.map((current, i) => {
                            return (
                              <option value={current.id} key={i}>{current.name}</option>
                            )
                          })
                        }
                      </select>
                      :
                    <input type="text" className="form-control height-35 f-14" placeholder="Select Customer First" id="driver" min="0" autoComplete="off" disabled/>
                  } */}
                  {/* <input type="text" className="form-control height-35 f-14" placeholder="Search Dropoff Address..." id="driver" min="0" autoComplete="off" /> */}
                </div>
              </div>
              <div className="col-sm-3 pl-4">
                <div className="form-group my-3">
                  <div className="d-flex">
                    <div>
                      <label
                        className="f-14 text-dark mb-12"
                        data-label=""
                        htmlFor="clnumber"
                      >
                        {i === 0 ? "Pickup Date" : "Dropoff Date"}
                      </label>
                    </div>
                    <div className="p-2">
                      <sup className="text-danger f-14">*</sup>
                    </div>
                  </div>
                  {i === 0 ? (
                  <input
                    type="date"
                    className="form-control height-35 f-14"
                    placeholder=""
                    id="driver"
                    min="0"
                    value={returnStopArr[i].date}
                    onChange={handleStopChange(i, "date")}
                  />
                  ) : (
                    <input
                      type="date"
                      className="form-control height-35 f-14"
                      placeholder=""
                      id="driver"
                      min="0"
                      value={returnStopArr[i].date}
                      onChange={handleStopChange(i, "date")}
                      disabled
                    />  
                  )}
                </div>
              </div>
              <div className="col-sm-3 pl-4">
                <div className="form-group my-3">
                  {willCall !== true ? (
                    <>
                      <div className="d-flex">
                        <div>
                          <label
                            className="f-14 text-dark mb-12"
                            data-label=""
                            htmlFor="driver"
                          >
                            {i === 0 ? "Pickup Time" : "Dropoff Time"}
                          </label>
                        </div>
                        <div className="p-2">
                          <sup className="text-danger f-14">*</sup>
                        </div>
                      </div>
                      {i === 0 ? (
                      <input
                        type="time"
                        className="form-control height-35 f-14"
                        placeholder=""
                        id="driver"
                        min="0"
                        value={returnStopArr[i].time}
                        onChange={handleStopChange(i, "time")}
                      />
                      ) : (
                      <input
                        type="time"
                        className="form-control height-35 f-14"
                        placeholder=""
                        id="driver"
                        min="0"
                        value={returnStopArr[i].time}
                        onChange={handleStopChange(i, "time")}
                        disabled
                      />
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              {i === 0 ? (
                amount !== 0 && (
                  <div className="col-md-2 pl-4">
                    <button
                      type="button"
                      className="btn-lightest-grey rounded f-12 p-2 mr-3 "
                      // onClick={increaseStop}
                      style={{ marginTop: "52px", marginLeft: "-20px" }}
                      onClick={openDetailDropDown}
                    >
                      Details
                    </button>
                    <div
                      className="payment-price"
                      id="divMsg"
                      style={{ display: "block" }}
                    >
                      <div className="circle">
                        <p>$ {amount}</p>
                      </div>
                      <div className="mile-text">
                        <p>Order {mil} miles</p>
                      </div>
                      <div className="mile-text2" style={{marginTop: "-136px"}}>
                        <p>ETA {eta}</p>
                      </div>
                    </div>
                  </div>
                )
              ) : (
                <></>
              )}
              {/* <div className="col-md-2 pl-4"> */}
              <div className="form-group my-5">
                {i !== 0 ? (
                  // values.dropOffAddress !== '' && values.dropOffDate !== '' && values.dropOffTime !== ''
                  // ?
                  <button
                    type="button"
                    className="btn-lightest-grey rounded f-12 p-2 mr-3 "
                    onClick={increaseStop}
                  >
                    Add A Stops
                  </button>
                ) : (
                  // :
                  // <button type="button" className="btn-lightest-grey rounded f-12 p-2 mr-3 " disabled>Add A Stops</button>
                  <></>
                )}
                {i >= 2 && (
                  <button
                    type="button"
                    className="btn-lightest-grey rounded f-14 p-2 mr-3 height-35"
                    id="save-form"
                    onClick={() => decreaseStop(i)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-trash3-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                    </svg>
                  </button>
                )}
              </div>
              {/* </div> */}
            </>
          );
        })}
      {/* {
          returnStopArr.length >= 2 &&
          amount !== 0 &&
          <div className="col-lg-12">
            <div className="bg-white  text-capitalize d-flex justify-content-between  border-bottom-grey">
            </div>
            <br />
            <div className="bg-white  text-capitalize d-flex justify-content-between  border-bottom-grey" style={{borderBottom: "#fff"}}>
              <div className="col-lg-4">
              </div>
              <div className="col-lg-4">
              </div>
              <div className="col-lg-3" style={{marginTop:"-5.75rem"}}>
                <a href="#">
                <div className="badges">
                  <br/>
                  <br/>
                    <p>
                        <span className="firstLine">Total Amount</span><br/>
                        <span className="secondLine">${amount}</span><br/>
                        <span className="thirdLine">Distance</span><br/>
                        <span className="fourthLine">{mil}mi</span>
                    </p>
                </div>
                </a>
              </div>
            </div>
          </div>
        } */}
      <AdminBookingPriceDetailModal
        showDetailDropDown={showDetailDropDown}
        openDetailDropDown={openDetailDropDown}
        values={values}
        fareDetails={fareDetails}
        useHardCodedFares={useHardCodedFares}
        toogleHardCoded={toogleHardCoded}
        hardCodedFares={hardCodedFares}
        handleHardCodedFares={handleHardCodedFares}
        recalculate={recalculate}
        useDirectFare={useDirectFare}
        toogleDirectFare={toogleDirectFare}
        directFare={directFare}
        handleDirectFare={handleDirectFare}
        fareDirectlyCalculate={fareDirectlyCalculate}
        useFlatDriverRate={useFlatDriverRate}
        toogleFlatDriverRate={toogleFlatDriverRate}
        flatDriverRate={flatDriverRate}
        handleFlatDriverRate={handleFlatDriverRate}
        flatDriverRateRecalculate={flatDriverRateRecalculate}
      />
    </>
  );
};

export default AdminBookingReturnTrip;
