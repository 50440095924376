import {
    CARDS_DATA_VIEW_REQUEST,
    CARDS_DATA_VIEW_SUCCESS,
    CARDS_DATA_VIEW_FAIL,
    CARDS_DATA2_VIEW_REQUEST,
    CARDS_DATA2_VIEW_SUCCESS,
    CARDS_DATA2_VIEW_FAIL,
    TODAY_RESERVATION_VIEW_REQUEST,
    TODAY_RESERVATION_VIEW_SUCCESS,
    TODAY_RESERVATION_VIEW_FAIL,
    WEEK_RESERVATION_COUNT_VIEW_REQUEST,
    WEEK_RESERVATION_COUNT_VIEW_SUCCESS,
    WEEK_RESERVATION_COUNT_VIEW_FAIL,
    CUSTOMER_SEARCH_VIEW_REQUEST,
    CUSTOMER_SEARCH_VIEW_SUCCESS,
    CUSTOMER_SEARCH_VIEW_FAIL,
    RESERVATION_REPORT_SEARCH_REQUEST,
    RESERVATION_REPORT_SEARCH_SUCCESS,
    RESERVATION_REPORT_SEARCH_FAIL,
} from "../constants"

export const kpiReducer = (
    state = {
        kpi: {
            cardsData: null,
            cardsData2: null,
            todayReservation: null,
            weekReservationCount: null,
            searchedCustomer: null,
            reservationReportSearch: null,
        }
    },
    action
) => {
        switch (action.type) {

        // CARDS DATA VIEW TABLE
        case CARDS_DATA_VIEW_REQUEST : 
            return {
                ...state,
            };
        case CARDS_DATA_VIEW_SUCCESS :
            return {
                ...state,
                cardsData: action.payload,
            };
        case CARDS_DATA_VIEW_FAIL :
            return {
                ...state,
                error: action.payload,
            };

        // CARDS DATA2 VIEW TABLE
        case CARDS_DATA2_VIEW_REQUEST : 
            return {
                ...state,
            };
        case CARDS_DATA2_VIEW_SUCCESS :
            return {
                ...state,
                cardsData2: action.payload,
            };
        case CARDS_DATA2_VIEW_FAIL :
            return {
                ...state,
                error: action.payload,
            };


        // TODAY RESERVATION VIEW TABLE
        case TODAY_RESERVATION_VIEW_REQUEST : 
            return {
                ...state,
            };
        case TODAY_RESERVATION_VIEW_SUCCESS :
            return {
                ...state,
                todayReservation: action.payload,
            };
        case TODAY_RESERVATION_VIEW_FAIL :
            return {
                ...state,
                error: action.payload,
            };


        // WEEK RESERVATION COUNT VIEW TABLE
        case WEEK_RESERVATION_COUNT_VIEW_REQUEST : 
            return {
                ...state,
            };
        case WEEK_RESERVATION_COUNT_VIEW_SUCCESS :
            return {
                ...state,
                weekReservationCount: action.payload,
            };
        case WEEK_RESERVATION_COUNT_VIEW_FAIL :
            return {
                ...state,
                error: action.payload,
            };

        // SEACRH CUSTOMER DATA
        case CUSTOMER_SEARCH_VIEW_REQUEST : 
            return {
                ...state,searchedCustomer: []
            };
        case CUSTOMER_SEARCH_VIEW_SUCCESS :
            return {
                ...state,
                searchedCustomer: action.payload,
            };
        case CUSTOMER_SEARCH_VIEW_FAIL :
            return {
                ...state,
                error: action.payload,
            };

        // ADMIN RESERVATION REPORT SEARCH 
        case RESERVATION_REPORT_SEARCH_REQUEST : 
            return {
                ...state,
            };
        case RESERVATION_REPORT_SEARCH_SUCCESS :
            return {
                ...state,
                reservationReportSearch: action.payload,
            };
        case RESERVATION_REPORT_SEARCH_FAIL :
            return {
                ...state,
                error: action.payload,
            };
    
        default:
            return state;
    }
}


