import axios from "axios";
import {
    TRIP_VIEW_REQUEST,
    TRIP_VIEW_SUCCESS,
    TRIP_VIEW_FAIL,
    TRIP_CREATE_REQUEST,
    TRIP_CREATE_SUCCESS,
    TRIP_CREATE_FAIL,
    // TRIP_UPDATE_REQUEST,
    // TRIP_UPDATE_SUCCESS,
    // TRIP_UPDATE_FAIL,
    SERVERADDRESS,
} from "../constants"

// ALL TRIP DISPLAY VIEW 
export const getAllTrip = (obj) => async (dispatch) => {
    dispatch({
        type: TRIP_VIEW_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/trip/getTripsByCompanyId`, obj);
        localStorage.setItem("readTrip", JSON.stringify(data));
        dispatch({
            type: TRIP_VIEW_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: TRIP_VIEW_FAIL,
            payload: err,
        });
    }
};

// ADD NEW TRIP
export const addTrip = (obj) => async ( dispatch ) => {
    dispatch({
        type: TRIP_CREATE_REQUEST,
    })
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/trip/createTrip`, obj)
        localStorage.setItem("tripCreate", JSON.stringify(data));
        dispatch({
            type: TRIP_CREATE_SUCCESS,
            payload: data,
    })
}
catch (err) {
    console.log(err)
    dispatch({
        type: TRIP_CREATE_FAIL,
        payload: err,
    })
}
}

// // UPDATE TRIP
// export const updateTrip = (obj) => async ( dispatch ) => {
//     dispatch({
//         type: TRIP_UPDATE_REQUEST,
//     })
//     try {
//         const { data } = await axios.put(`${SERVERADDRESS}/v1/trip/updateTrip`, obj)
//         dispatch({
//             type: TRIP_UPDATE_SUCCESS,
//             payload: data,
//     })
// }
// catch (err) {
//     console.log(err)
//     dispatch({
//         type: TRIP_UPDATE_FAIL,
//         payload: err,
//     })
// }
// }