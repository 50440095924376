import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getExternalCompanies } from "../../../actions/companyAction";
import MyComponent from "../Map/Map";
import { updateTrip } from "../../../actions/reservationAction";
import moment from "moment";
import SearchedByMap from "../Map/SearchedByMap";
import { clientSearchFilter } from "../../../actions/kpiActions";
import AdminBookingPriceDetailModal from "../adminBookingComponents/AdminBookingPriceDetailModal";
import Select from "react-select";
import axios from "axios";
import { SERVERADDRESS } from "../../../constants";
import { notifyFailure } from "../../toast";
import SearchedByMapForEdit from "../Map/SearchedByMapForEdit";

const AdminTodayOrderEditComponent = ({ state, handleModal, current }) => {

  const [showDetailDropDown, setshowDetailDropDown] = useState(false);
  const openDetailDropDown = () => {
    setshowDetailDropDown((showDetailDropDown) => !showDetailDropDown);
  };
  const users = useSelector((state) => state.users);

  // check**********************?
  const [details, setDetails] = useState({
    isCompany: false,
    externalCompanyId: "",
  });
  const [fareDetails, setFareDetails] = useState();
  const reservation = useSelector((state) => state.reservation);
  const kpi = useSelector((state) => state.kpi);

  // check*******************?
  const service = useSelector((state) => state.service);
  const [stopArrUseApi, setStopArrUseApi] = useState(false);
  const [stopArr, setStopArr] = useState([
    {
      address: "",
      date: "",
      time: "",
    },
    {
      address: "",
      date: "",
      time: "",
    },
  ]);
  // useEffect(()=> {
  //   setData({...data, 
  //     rideHistory: stopArr
  //   })
  //   setTimeout(() => 
  //     recalculate()
  //   , 3000);
  // }, [stopArr])
  const [showDropLoc, setDropLoc] = useState(false);
  const openDropLoc = () => {
    setDropLoc((showDropLoc) => !showDropLoc);
    // console.log(showDropLoc);
  };
  const handleStopChange = (index, field) => (event) => {
    // yahan se new logic
    setStopArrUseApi(true);
    // yahan takh new logic
    setStopArr(
      stopArr.map((stop, i) =>
        i === index ? { ...stop, [field]: event.target.value } : { ...stop }
      )
    );
  };

  const [showDropDown, setshowDropDown] = useState(false);
  const openDropDown = () => {
    setshowDropDown((showDropDown) => !showDropDown);
  };
  const [displayClient, setDisplayClient] = useState(false);
  const searchClient = async (data) => {
    dispatch(clientSearchFilter(data));
    setDisplayClient(true);
  };
  // const onClickCustomer = (data) => {
  //   const abc = JSON.parse(data);
  //   setDisplayClient(false);
  //   setOldName(abc.firstName + " " + abc.lastName);
  //   setValues({
  //     ...values,
  //     customerId: abc.id,
  //     name: abc.firstName + " " + abc.lastName,
  //   });
  //   console.log(abc.isCompany === false, abc.isCompany, "abc.isCompany");
  //   if (abc.isCompany === false) {
  //     setDetails({ isCompany: abc.isCompany });
  //   } else {
  //     setDetails({
  //       isCompany: abc.isCompany,
  //       externalCompanyId: abc.externalCompanyId,
  //     });
  //   }

  let abc = [];
  const [returnStopArr, setReturnStopArr] = useState([]);
  const [returnTrip, setReturnTrip] = useState(false);
  let [willCall, setWillCall] = useState(false);
  const onClickCloseReturnTrip = () => {
    setReturnTrip((returnTrip) => !returnTrip);
    setWillCall(false);
    setData({
      ...data,
      willCall: false,
    });
  };
  const onClickReturnTrip = () => {
    // console.log(stopArr[0], stopArr[1], "newArrForRTnewArrForRT abaa");
    setReturnTrip((returnTrip) => !returnTrip);
    stopArr.forEach((e, i) => {
      abc.push(stopArr[stopArr.length - 1 - i]);
    });
    setReturnStopArr(abc);
    // console.log(abc, "abcabcabcabcabcabc");
  };

  const navigate = useNavigate();

  // console.log("===>", state);
  let [showModal, setShowModal] = useState();

  const externalCompanies = useSelector((state) => state.externalCompanies);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getExternalCompanies({ companyId: auth.user.companyId.id }));
  }, [auth]);
  let [selectedCompanyId, setSelectedCompanyId] = useState("");
  const [data, setData] = useState({});
  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };
  // console.log("datadatadata", data);
  const handleChangeServiceType = (event) => {
    const type = service?.service?.find(
      (x) => x?.id === event.target.value
    )
    setData({
      ...data,
      [event.target.name]: type,
    });
  };

// console.log(data, "main hoooooon dattaaaa")

    const dispatch = useDispatch();
    const handlePhone = (fieldName) => (event) => {
      if (event.target.value.length === 1) {
        if (event.target.value !== "(") {
          setData({
            ...data,
            [fieldName]: "(" + event.target.value,
          });
        }
      } else if (event.target.value.length === 4) {
        if (event.target.value !== ")") {
          setData({
            ...data,
            [fieldName]: event.target.value + ")-",
          });
        }
      } else if (event.target.value.length === 9) {
        if (event.target.value !== "-") {
          setData({
            ...data,
            [fieldName]: event.target.value + "-",
          });
        }
      } else {
        setData({
          ...data,
          [fieldName]: event.target.value,
        });
      }
    };
    const alphabets = [
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "p",
      "q",
      "r",
      "s",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z",
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
      "+",
      "-",
      "/",
      "*",
      "!",
      "@",
      "#",
      "$",
      "%",
      "^",
      "&",
      "_",
      "(",
      ")",
      ",",
      ".",
    ];
    const handleSubmit = async (event) => {
      event.preventDefault();
      const reservationData = {
        _id: data.id,
        companyId: data.companyId,
        firstName: data.firstName,
        lastName: data.lastName,
        // orderId: data.orderId,
        totalMileage: data.totalMileage,
        // username: data.username,
        phone: data.phone,
        address: data.address,
        // city: data.city,
        // zip: data.zip,
        // summary: data.summary,
        customerId: data.customerId,
        type: data.type,
        bookingDate: moment().format("YYYY-MM-DD"),
        seats: data.seats,
        willCall: data.willCall,
        needAttended: data.needAttended,
        numberOfWheelchairs: data.numberOfWheelchairs,
        amSeats: data.amSeats,
        needWheel: data.needWheel,
        needAssigned: data.needAssigned,
        pickUpAddress: data.pickUpAddress,
        pickUpDate: data.pickUpDate,
        pickUpTime: data.pickUpTime,
        dropOffAddress: data.dropOffAddress,
        dropOffDate: data.dropOffDate,
        dropOffTime: data.dropOffTime,
        dateForReservation: data.dateForReservation,
        driverNotes: data.driverNotes,
        dispatchNotes: data.dispatchNotes,
        distanceFromBaseLocation: fareDetails?.distanceFromBaseLocation,
        distanceFromPickToDropOff: fareDetails?.distanceFromPickToDropOff,
        // totalMileage: mil,
        // totalAmount: amount,
        rideHistory: data.rideHistory,
        loadNumber: data.loadNumber,
        clientIdExternal: data.clientIdExternal,
        isCompany: details.isCompany === true ? true : false,
        fareAndDistance: fareDetails,
        externalCompanyId: details.externalCompanyId
          ? details.externalCompanyId
          : "",
      };
      dispatch(updateTrip(reservationData, handleSuccess));
    };
    const handleSuccess = () => {
      handleModal(null);
    };
    useEffect(() => {
      if (data.rideHistory && stopArr[0].address === '') {
        setStopArr(data.rideHistory)
      } 
      if (data?.rideHistory?.customerId?.externalCompanyId){
        setDetails({
          isCompany: true,
          externalCompanyId: data?.rideHistory?.customerId?.externalCompanyId?.id,
        })
      }
      else {
        setDetails({
          isCompany: false
        })
      }
    },[data])
    // console.log(stopArr, "xsxsxs sssss  e e e e eqq  q q f f f f kwkwkkw w  w wkw kw k")
  
    // useEffect(() => {
    //   if (state) setData(state);
    //   else navigate("/requested-orders");
    // }, [state]);
    useEffect(() => {
      if (state) setData(state);
      else navigate("/requested-orders");
    }, []);
  // const previousLocation = async (id) => {
  //   const { data } = await axios.post(
  //     `${SERVERADDRESS}/v1/reservation/getLastReservationOfCustomerById`,
  //     {customerId: id}
  //   );
  //   console.log(data, "data re data")
  //   setStopArr([
  //     {
  //       address: data?.pickUpAddress ? data?.pickUpAddress : '',
  //       date: "",
  //       time: "",
  //     },
  //     {
  //       address: data?.dropOffAddress ? data?.dropOffAddress : '',
  //       date: "",
  //       time: "",
  //     },
  //   ]);
  // }
  const directionsService = new window.google.maps.DirectionsService();
  const location = useSelector((state) => state.location);
  const fare = useSelector((state) => state.fare);
  let [amount, setAmount] = useState(0);
  const [eta, setEta] = useState();
  const [mil, setMil] = useState(0);
  let distanceFromBaseLocation = 0;
  let distanceFromPickToDropOff = 0;
  let totalMileage = 0;

  // yahan se new logic
  const [useHardCodedFares, setUseHardCodedFares] = useState(false);
  const [hardCodedFares, setHardCodedFares] = useState({
    flatBaseFare: "",
    farePerMile: "",
    deadHeadThreshold: "",
    baseFarePerMile: "",
    totalMileageAllows: "",
    longDistancePerMile: "",
    offHourFee: "",
    noShowFee: "",
    cancelationFee: "",
    additionalCharges: 0,
    deduction: 0,
  });
  const handleHardCodedFares = (fieldName) => (event) => {
    setHardCodedFares({
      ...hardCodedFares,
      // [fieldName]: event.target.value !== "" ? parseFloat(event.target.value) : 0,
      [fieldName]: event.target.value,
    });
  };
  const recalculate = (cusId) => {
    calculateFareFrontEnd(cusId);
  };
  // console.log(useHardCodedFares,hardCodedFares, "hardCodedFares")
  const toogleHardCoded = () => {
    if (!useHardCodedFares === true) {
      setUseDirectFare(false);
      setUseFlatDriverRate(false);
    }
    setUseHardCodedFares((useHardCodedFares) => !useHardCodedFares);
  };
  const [useDirectFare, setUseDirectFare] = useState(false);
  const [directFare, setDirectFare] = useState(0);
  const toogleDirectFare = () => {
    if (!useDirectFare === true) {
      setUseHardCodedFares(false);
      setUseFlatDriverRate(false);
    }
    setUseDirectFare((useDirectFare) => !useDirectFare);
  };
  const handleDirectFare = (event) => {
    setDirectFare(event.target.value);
  };
  const fareDirectlyCalculate = () => {
    let driverAmount = parseFloat(
      (directFare * (auth?.user?.companyId?.driverPercentage / 100)).toFixed(2)
    );
    let companyAmount = parseFloat(directFare - driverAmount);
    setFareDetails({
      distanceFromBaseLocation: fareDetails.distanceFromBaseLocation,
      distanceFromPickToDropOff: fareDetails.distanceFromPickToDropOff,
      totalMileage: fareDetails.totalMileage,
      fare: parseFloat(directFare),
      amount: parseFloat(directFare),
      estimatedTime: fareDetails.estimatedTime,
      etaTime: fareDetails.etaTime,
      driverAmount: driverAmount,
      companyAmount: companyAmount,
      baseFare: 0,
      rideFare: 0,
      offHourFee: 0,
    });
    setAmount(directFare);
  };
  // console.log(fareDetails, "fareDetails");
  const [useFlatDriverRate, setUseFlatDriverRate] = useState(false);
  const [flatDriverRate, setFlatDriverRate] = useState(0);
  const toogleFlatDriverRate = () => {
    if (!useFlatDriverRate === true) {
      setUseHardCodedFares(false);
      setUseDirectFare(false)
    }
    setUseFlatDriverRate((useFlatDriverRate) => !useFlatDriverRate);
  };
  const handleFlatDriverRate = (event) => {
    setFlatDriverRate(event.target.value);
  };
  const flatDriverRateRecalculate = () => {
    let driverAmount = flatDriverRate
    let companyAmount = parseFloat(fareDetails.amount - driverAmount);
    setFareDetails({
      distanceFromBaseLocation: fareDetails.distanceFromBaseLocation,
      distanceFromPickToDropOff: fareDetails.distanceFromPickToDropOff,
      totalMileage: fareDetails.totalMileage,
      fare: fareDetails.fare,
      amount: fareDetails.amount,
      estimatedTime: fareDetails.estimatedTime,
      etaTime: fareDetails.etaTime,
      driverAmount: driverAmount,
      companyAmount: companyAmount,
      baseFare: 0,
      rideFare: 0,
      offHourFee: 0,
      baseFare: fareDetails.baseFare,
      rideFare: fareDetails.rideFare,
      offHourFee: fareDetails?.offHourFee ? fareDetails.offHourFee : 0,
    });
    // setAmount(directFare);
  };
  // yahan takh new logic
  const calculateFareFrontEnd = async (cusId) => {
    // console.log(useHardCodedFares,hardCodedFares, "hardCodedFares")
    // console.log("calculateFareFrontEnd")
    const abc = data?.type;
    let newArr = [];
    stopArr.forEach((e) => {
      newArr.push(e?.address?.address);
    });
    let obj = {};
    // yahan se new logic
    let eta = { hour: [], mins: [] };
    // yahan takh new logic
    await directionsService
      .route({
        origin: {
          query: location.baseLocation.address,
        },
        destination: {
          query: newArr[0],
        },
        travelMode: window.google.maps.TravelMode.DRIVING,
      })
      .then((response) => {
        // console.log("response", response);
        distanceFromBaseLocation = parseFloat(
          response.routes[0].legs[0].distance.text.split(" mi")[0]
        );
        obj.distanceFromBaseLocation = distanceFromBaseLocation;
      })
      .catch((e) => {
        notifyFailure(e.message);
        // console.log("Directions request failed due to ", e.message);
        // window.alert("Directions request failed due to ", e)
      });
    // yahan se new logic
    let oldDate = new Date(
      stopArr[0].date.substring(0, 10) + "T" + stopArr[0].time + ":00.000Z"
    );
    // yahan takh new logic
    for (let i = 0; i < newArr.length - 1; i++) {
      await directionsService
        .route({
          origin: {
            query: newArr[i],
          },
          destination: {
            query: newArr[i + 1],
          },
          travelMode: window.google.maps.TravelMode.DRIVING,
        })
        .then((response) => {
          // console.log("response2", response);
          distanceFromPickToDropOff += parseFloat(
            response.routes[0].legs[0].distance.text.split(" mi")[0]
          );
          // yahan se new logic
          if (response.routes[0].legs[0].duration.text.length > 7) {
            const timeString = response.routes[0].legs[0].duration.text;
            const hourRegex = /(\d+)\s*hour/;
            const minuteRegex = /(\d+)\s*min/;

            const hourMatch = hourRegex.exec(timeString);
            const minuteMatch = minuteRegex.exec(timeString);

            const hours = hourMatch ? parseInt(hourMatch[1]) : 0;
            const minutes = minuteMatch ? parseInt(minuteMatch[1]) : 0;
            eta.hour.push(hours);
            eta.mins.push(minutes);

            // eta.hour.push(
            //   parseInt(
            //     response.routes[0].legs[0].duration.text.split(" hour")[0]
            //   )
            // );
            // let min =
            //   response.routes[0].legs[0].duration.text.split(" hour")[1];
            // eta.mins.push(parseInt(min.split(" mins")[0]));

            // let aaa =
            //   (parseInt(
            //     response.routes[0].legs[0].duration.text.split(" hour")[0]
            //   ) *
            //     60 +
            //     parseInt(min.split(" mins")[0])) *
            //   60 *
            //   1000;

            //old comment hai let oldDate wala
            // let oldDate = new Date(stopArr[0].date.substring(0, 10) + "T"+stopArr[0].time+":00.000Z").getTime();

            let aaa = (hours * 60 + minutes) * 60 * 1000;
            // console.log(aaa, oldDate, "eta aarha hai");
            oldDate = new Date(aaa + new Date(oldDate).getTime()).toUTCString();
            let oldDateTime = oldDate.toString().split(" ")[4];
            let ind = i + 1;
            setStopArr(
              stopArr.map((stop, index) =>
                index === ind
                  ? {
                      ...stop,
                      date: new Date(oldDate).toISOString().substring(0, 10),
                      time: oldDateTime.substring(0, 5),
                    }
                  : { ...stop }
              )
            );
            // console.log(
            //   aaa,
            //   new Date(oldDate).toISOString(),
            //   oldDateTime.substring(0, 5),
            //   "eta aarha hai"
            // );
          } else {
            eta.mins.push(
              parseInt(
                response.routes[0].legs[0].duration.text.split(" mins")[0]
              )
            );
            // let oldDate = new Date(stopArr[0].date.substring(0, 10) + "T"+stopArr[0].time+":00.000Z").getTime();
            let aaa =
              parseInt(
                response.routes[0].legs[0].duration.text.split(" mins")[0]
              ) *
              60 *
              1000;
            oldDate = new Date(aaa + new Date(oldDate).getTime()).toUTCString();
            let oldDateTime = oldDate.toString().split(" ")[4];
            let ind = i + 1;
            setStopArr(
              stopArr.map((stop, index) =>
                index === ind
                  ? {
                      ...stop,
                      date: new Date(oldDate).toISOString().substring(0, 10),
                      time: oldDateTime.substring(0, 5),
                    }
                  : { ...stop }
              )
            );
            // console.log(
            //   aaa,
            //   oldDate,
            //   oldDateTime.substring(0, 5),
            //   "eta aarha hai"
            // );
          }
          // console.log("etaeta,", eta);
          // yahan takh new logic
          // distanceFromPickToDropOff = distanceFromPickToDropOff + parseFloat(response.routes[0].legs[0].distance.text.split(" mi")[0])
          // console.log(distanceFromPickToDropOff, "distanceFromPickToDropOff")
        })
        .catch((e) => {
          notifyFailure(e.message);
          // console.log("Directions request failed due to ", e);
          // window.alert("Directions request failed due to ", e)
        });
    }
    // console.log(distanceFromPickToDropOff, "distanceFromPickToDropOff")

    totalMileage = parseFloat(
      (distanceFromBaseLocation + distanceFromPickToDropOff).toFixed(2)
    );
    obj.distanceFromPickToDropOff = distanceFromPickToDropOff;
    obj.totalMileage = totalMileage;
    let fares = fare.fare;
    let baseFare;
    let rideFare;
    let companyFare = [];
    let serviceFare = [];
    let offHourFee = 0;

    if (details.isCompany === false) {
      fares = fare.fare[0].details;
      fares.forEach((e) => {
        if (e.serviceId.id === abc.id) {
          serviceFare.push(e);
        }
      });
    } else {
      fares.forEach((e) => {
        // console.log(e,e.business.id === details.externalCompanyId, e.business.id,details.externalCompanyId)
        if (e.business.id === details.externalCompanyId) {
          companyFare.push(...e.details);
        }
      });
      companyFare.forEach((e) => {
        if (e.serviceId.id === abc.id) {
          serviceFare.push(e);
        }
      });
    }
    serviceFare.sort((a, b) => a.totalMileageAllows - b.totalMileageAllows);
    let _fare;
    // console.log(fares,companyFare,details.isCompany,serviceFare, "serviceFare[i]")
    for (let i = 0; i < serviceFare.length; i++) {
      if (totalMileage <= serviceFare[i].totalMileageAllows) {
        _fare = serviceFare[i];
        break;
      }
    }
    if (!_fare) {
      _fare = serviceFare[serviceFare.length - 1];
    }
    // yahan se new logic
    if (useHardCodedFares === true) {
      _fare = {
        flatBaseFare: parseFloat(hardCodedFares.flatBaseFare),
        farePerMile: parseFloat(hardCodedFares.farePerMile),
        deadHeadThreshold: parseFloat(hardCodedFares.deadHeadThreshold),
        baseFarePerMile: parseFloat(hardCodedFares.baseFarePerMile),
        totalMileageAllows: parseFloat(hardCodedFares.totalMileageAllows),
        longDistancePerMile: parseFloat(hardCodedFares.longDistancePerMile),
        offHourFee: parseFloat(hardCodedFares.offHourFee),
        noShowFee: parseFloat(hardCodedFares.noShowFee),
        cancelationFee: parseFloat(hardCodedFares.cancelationFee),
      };
    }
    // yahan takh new logic
    obj.fare = _fare;
    if (distanceFromBaseLocation <= _fare?.deadHeadThreshold) {
      baseFare = _fare.flatBaseFare;
      obj.baseFare = baseFare;
      obj.baseFareStructure = {
        values: { flatBaseFare: _fare.flatBaseFare },
        valuesFormula: "flatBaseFare",
      };
    } else {
      // yahan se new logic
      if (_fare.deadHeadThreshold) {
        baseFare =
          _fare.flatBaseFare + _fare.baseFarePerMile * distanceFromBaseLocation;
        obj.baseFare = baseFare;
        obj.baseFareStructure = {
          values: {
            flatBaseFare: _fare.flatBaseFare,
            baseFarePerMile: _fare.baseFarePerMile,
            distanceFromBaseLocation: distanceFromBaseLocation,
          },
          valuesFormula:
            "flatBaseFare + (baseFarePerMile * distanceFromBaseLocation)",
        };
      } else {
        baseFare = _fare.flatBaseFare;
        obj.baseFare = baseFare;
        obj.baseFareStructure = {
          values: { flatBaseFare: _fare.flatBaseFare },
          valuesFormula: "flatBaseFare",
        };
      }
      // yahan takh new logic
    }
    if (distanceFromPickToDropOff <= _fare.totalMileageAllows) {
      // console.log(distanceFromPickToDropOff, "distanceFromPickToDropOff")
      rideFare = distanceFromPickToDropOff * _fare.farePerMile;
      obj.rideFare = rideFare;
      obj.rideFareStructure = {
        values: {
          distanceFromPickToDropOff: distanceFromPickToDropOff,
          farePerMile: _fare.farePerMile,
        },
        valuesFormula: "distanceFromPickToDropOff * farePerMile",
      };
    } else {
      // yahan se new logic
      if (_fare.totalMileageAllows) {
        rideFare = distanceFromPickToDropOff * _fare.longDistancePerMile;
        obj.rideFare = rideFare;
        obj.rideFareStructure = {
          values: {
            distanceFromPickToDropOff: distanceFromPickToDropOff,
            longDistancePerMile: _fare.longDistancePerMile,
          },
          valuesFormula: "distanceFromPickToDropOff * longDistancePerMile",
        };
      } else {
        rideFare = distanceFromPickToDropOff * _fare.farePerMile;
        obj.rideFare = rideFare;
        obj.rideFareStructure = {
          values: {
            distanceFromPickToDropOff: distanceFromPickToDropOff,
            farePerMile: _fare.farePerMile,
          },
          valuesFormula: "distanceFromPickToDropOff * farePerMile",
        };
      }
      // yahan takh
    }

    const company = auth?.user?.companyId;
    // console.log(company)
    for (let key of Object.keys(company.availability)) {
      if (key === moment(stopArr[0].date).format("dddd")) {
        if (company.availability[key] === false) {
          offHourFee += _fare.offHourFee ? _fare.offHourFee : 0;
          obj.offHourFee = offHourFee;
        }
      }
    }
    // console.log(company.offHours.from.substring(0,2))
    // console.log(company.offHours.from.substring(3,5))

    if (offHourFee === 0) {
      if (
        parseInt(company.offHours.from.substring(0, 2)) <=
          parseInt(stopArr[0].time.substring(0, 2)) &&
        parseInt(stopArr[0].time.substring(0, 2)) <=
          parseInt(company.offHours.to.substring(0, 2))
      ) {
        if (
          parseInt(company.offHours.from.substring(3, 5)) <=
          parseInt(stopArr[0].time.substring(3, 5))
        ) {
          offHourFee += _fare.offHourFee ? _fare.offHourFee : 0;
          obj.offHourFee = offHourFee;
        }
      }
      // else {
      //   console.log("normal")
      // }
    }
    if (offHourFee === 0) {
      amount = (rideFare + baseFare).toFixed(2);
      // yahan se new logic
      if (useHardCodedFares === true) {
        amount =
          parseFloat(amount) +
          parseFloat(hardCodedFares.additionalCharges) -
          parseFloat(hardCodedFares.deduction);
      }
      obj.amount = parseFloat(amount);
      obj.amountStructure = {
        values: {
          rideFare: rideFare,
          baseFare: baseFare,
          ...(useHardCodedFares === true && {
            additionalCharges: parseFloat(hardCodedFares.additionalCharges),
            deduction: parseFloat(hardCodedFares.deduction),
          }),
        },
        valuesFormula: "rideFare + baseFare ",
      };
      // yahan takh
    } else {
      amount = (rideFare + baseFare + offHourFee).toFixed(2);
      // yahan se new logic
      if (useHardCodedFares === true) {
        amount =
          parseFloat(amount) +
          parseFloat(hardCodedFares.additionalCharges) -
          parseFloat(hardCodedFares.deduction);
      }
      obj.amount = parseFloat(amount);
      obj.amountStructure = {
        values: {
          rideFare: rideFare,
          baseFare: baseFare,
          offHourFee: offHourFee,
          ...(useHardCodedFares === true && {
            additionalCharges: parseFloat(hardCodedFares.additionalCharges),
            deduction: parseFloat(hardCodedFares.deduction),
          }),
        },
        valuesFormula: "rideFare + baseFare + offHourFee",
      };
      // yahan takh
    }
    // yahan se new logic
    let m = 0;
    let hr = 0;
    let tempHr = 0;
    eta.mins.forEach((e) => {
      m += e;
    });
    tempHr = Math.floor(m / 60);
    m = m % 60;
    eta.hour.forEach((e) => {
      hr += e;
    });
    hr += tempHr;
    let estimatedTime = hr !== 0 ? hr + " h " + m + " m" : m + " m";
    obj.estimatedTime = estimatedTime;
    let newDropOffEta = new Date(
      stopArr[0].date.substring(0, 10) + "T" + stopArr[0].time + ":00.000Z"
    ).getTime();
    // console.log("etaeta,", hr, "hrs", m, "mins ", newDropOffEta);
    let addNewDropOffEta = (hr * 60 + m) * 60 * 1000;
    newDropOffEta = new Date(addNewDropOffEta + newDropOffEta).toUTCString();
    newDropOffEta = newDropOffEta.toString().split(" ")[4];
    let etaTime = newDropOffEta.substring(0, 5);
    obj.etaTime = etaTime;
    // console.log("etaeta,", hr, 'hrs', m, "mins ", newDropOffEta)
    let driverAmount = parseFloat(
      (amount * (auth?.user?.companyId?.driverPercentage / 100)).toFixed(2)
    );
    let companyAmount = parseFloat(amount - driverAmount);
    obj.driverAmount = driverAmount;
    obj.companyAmount = companyAmount;
    // console.log("objobjobjobj", obj);
    // setEta(etaTime)
    setEta(estimatedTime);
    setHardCodedFares({
      ...hardCodedFares,
      flatBaseFare: _fare.flatBaseFare,
      farePerMile: _fare.farePerMile,
      deadHeadThreshold: _fare.deadHeadThreshold,
      baseFarePerMile: _fare.baseFarePerMile,
      totalMileageAllows: _fare.totalMileageAllows,
      longDistancePerMile: _fare.longDistancePerMile,
      offHourFee: _fare.offHourFee,
      noShowFee: _fare.noShowFee,
      cancelationFee: _fare.cancelationFee,
      // additionalCharges: 0,
      // deduction: 0,
    });
    // yahan takh new logic
    setAmount(obj.amount);
    setMil(obj.distanceFromPickToDropOff);
    setFareDetails(obj);
    if (cusId) {
      setData({...data,
        customerId: cusId,
        fareAndDistance:obj,
        totalMileage:obj.distanceFromPickToDropOff,
        totalAmount:obj.amount,
      })
    }
    else {
      setData({...data,
        fareAndDistance:obj,
        totalMileage:obj.distanceFromPickToDropOff,
        totalAmount:obj.amount,
      })
    }
    // setData({
    //   ...data,
    //   ['totalAmount']: obj.amount,
    //   ['fareAndDistance']: obj
    // })
    // yahan se new logic
    setStopArrUseApi(false);
    // yahan takh new logic
  };
  
  return (
    <>
        <div className="d-flex flex-column w-tables rounded mt-3 bg-white p-3">
        <h4>Edit Order</h4>
        <div
          id="leads-table_wrapper"
          className="dataTables_wrapper dt-bootstrap4 no-footer"
        >
          <div className="row p-3">
            <div className="col-lg-1">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark-grey mb-12"
                  data-label=""
                  htmlFor="after_days"
                >
                  Order ID
                </label>
                <sup className="text-danger">*</sup>
                <input
                  type="text"
                  className="form-control height-35 f-14"
                  placeholder="Enter Your First Name"
                  autoComplete="false"
                  value={data.orderId}
                  // name="orderId"
                  // onChange={handleChange}
                  disabled
                />
              </div>
            </div>
            <div className="form-group my-3">
              <div className="d-flex">
                <div>
                  <label
                    className="f-14 text-dark mb-12"
                    data-label=""
                    htmlFor="default_task_status"
                  >
                    Load Number
                  </label>
                </div>
              </div>
              <div className="dropdown bootstrap-select form-control select-picker">
                <input
                  type="text"
                  className="form-control height-35 f-14"
                  placeholder="Load Number"
                  onKeyDown={(e) => {
                    if (alphabets.includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  value={data.loadNumber}
                  name="loadNumber"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark-grey mb-12"
                  data-label=""
                  htmlFor="after_days"
                >
                  Customer's Name
                </label>
                <sup className="text-danger">*</sup>
                {/* <input
                  type="text"
                  className="form-control height-35 f-14"
                  placeholder="Enter The Customer's Name"
                  autoComplete="false"
                  value={data.customerId?.firstName +" "+ data.customerId?.lastName}
                  // name="lastName"
                  onChange={handleChange}
                  required
                /> */}
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={''}
                    value={users?.allClients
                      ?.map((x) => ({
                        label: `${x.firstName} ${x.lastName}`,
                        value: x.id,
                      }))
                      .find(
                        (customer) => customer.value === data?.customerId?.id
                      )}
                    isDisabled={false}
                    // isLoading={searchingClients}
                    isClearable={false}
                    isRtl={false}
                    isSearchable={true}
                    required={true}
                    name="pickUp"
                    // onInputChange={(e) =>
                    //   setValues((prev) => ({ ...prev, name: e }))
                    // }
                    // value={data?.customerId?.id}
                    onChange={(e) =>
                      {
                        setData({
                          ...data,
                          customerId: e.value,
                          name: e.label,
                          isCompany: e?.isCompany && e?.isCompany,
                          externalCompanyId:
                            e?.externalCompanyId
                              ? e.externalCompanyId
                              : "",
                        })
                        if (e.isCompany === false) {
                          setDetails({ isCompany: e.isCompany })
                        } else {
                          setDetails({
                            isCompany: e.isCompany,
                            externalCompanyId: e.externalCompanyId,
                          })
                        }
                        recalculate(e.value)
                        // previousLocation(e.value)
                      }
                    }
                    options={users?.allClients  ?.map((x) => ({
                      label: `${x.firstName} ${x.lastName} `,
                      value: x.id,
                      isCompany: x?.isCompany && x?.isCompany,
                      externalCompanyId:
                        x?.externalCompanyId
                          ? x.externalCompanyId
                          : "",
                    }))}
                  />
              </div>
            </div>

                {/* <button
                  className="bg-button"
                  type="button"
                  data-toggle="modal"
                  data-target="#exampleModalCenter"
                  onClick={openDropDown}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-plus-circle-fill fa-w-20 f-20 text-dark"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                  </svg>
                </button>
              </div>
            </div>
            <input
              type="text"
              className="form-control height-35 f-14"
              placeholder="Search Customer Name..."
              value={data.name}
              onChange={handleChange}
              required
            />
            <br />
            {displayClient === true && (
              <div style={{ marginTop: "-22px" }}>
                <ul className="ul-css">
                  {kpi.searchedCustomer &&
                  kpi.searchedCustomer.length > 0 ? (
                    kpi.searchedCustomer.map((current, i) => {
                      return (
                        <li
                          className="li-css"
                          style={{ cursor: "pointer" }}
                          key={i}
                          onClick={() =>
                            onClickCustomer(
                              JSON.stringify({
                                id: current.id,
                                firstName: current.firstName,
                                lastName: current.lastName,
                                isCompany: current.isCompany,
                                externalCompanyId:
                                  current.externalCompanyId
                                    ? current.externalCompanyId
                                    : "",
                              })
                            )
                          }
                        >
                          {current.firstName +
                            " " +
                            current.lastName}
                        </li>
                      );
                    })
                  ) : (
                    <li className="li-css" key={"emp"}>
                      No data
                    </li>
                  )}
                </ul>
              </div>
            )} */}
          {/* </div>
        </div> */}




            {/* <div className="col-lg-4">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark-grey mb-12"
                  data-label=""
                  htmlFor="after_days"
                >
                  Phone
                </label>
                <sup className="text-danger">*</sup>
                <input
                  className="form-control height-35 f-14"
                  minLength="14"
                  maxLength="14"
                  required
                  onKeyDown={(e) => {
                    if (alphabets.includes(e.key)) {
                      e.preventDefault();
                    }
                    if (e.code === "Backspace" && data.phone.length === 6) {
                      setData({
                        ...data,
                        phone: data.phone.substring(0, 4),
                      });
                    }
                    if (e.code === "Backspace" && data.phone.length === 2) {
                      setData({
                        ...data,
                        phone: "",
                      });
                    }
                    if (e.code === "Backspace" && data.phone.length === 10) {
                      setData({
                        ...data,
                        phone: data.phone.substring(0, 9),
                      });
                    }
                  }}
                  value={data.customerId?.phone}
                  name="phone"
                  onChange={handleChange}
                  placeholder="(123)-456-7890"
                />
              </div>
            </div> */}
            <div className="col-sm-3 pl-4">
              <div className="form-group my-3">
                <div className="d-flex">
                  <div>
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="clnumber"
                    >
                      Type
                    </label>
                  </div>
                  <div className="p-2">
                    <sup className="text-danger f-14">*</sup>
                  </div>
                </div>
                <div className="select-picker">
                  <select
                    className="form-control select-picker"
                    style={{ height: "39px", fontSize: "14px" }}
                    name="type"
                    value={data.type?.id}
                    onChange={handleChangeServiceType}
                    required
                  >
                    {/* <option value="" key={"st"}> */}
                      {/* {" "} */}
                      {/* Select Type */}
                      {/* {data.type?.id} */}

                    {/* </option> */}
                    {service.service &&
                      service.service.length > 0 &&
                      service.service.map((current, i) => {
                        return (
                          <option
                            // value={JSON.stringify({
                            //   id: current.id,
                            //   name: current.name,
                            // })}
                            value={current.id}
                            key={i}
                          >
                            {current.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
            </div>
            {data &&
              data.type &&
              data.type !== "" &&
              data.type?.name === "Ambulatory" && (
                <div className="col-sm-2 details ambulatory show">
                  <div className="form-group my-3">
                    <div className="d-flex">
                      <div>
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="clnumber"
                        >
                          Seats
                        </label>
                      </div>
                      <div className="p-2">
                        <sup className="text-danger f-14">*</sup>
                      </div>
                    </div>
                    <div className="select-picker">
                      <select
                        className="form-control select-picker"
                        name="seats"
                        style={{ height: "39px" }}
                        value={data.seats}
                        onChange={handleChange}
                      >
                        <option value="1"> 1</option>
                        <option value="2"> 2</option>
                        <option value="3"> 3</option>
                        <option value="4"> 4</option>
                      </select>
                    </div>
                  </div>
                </div>
              )}
              {data &&
                data.type &&
                data.type !== "" &&
                data.type?.name === "Wheelchair" && (
                  <div className="col-sm-2 pl-4 details wheelchair show">
                    <div className="form-group my-3">
                      <div className="d-flex">
                        <div>
                          <label
                            className="f-14 text-dark mb-12"
                            data-label=""
                            htmlFor="clnumber"
                          >
                            Wheelchairs
                          </label>
                        </div>
                        <div className="p-2">
                          <sup className="text-danger f-14">*</sup>
                        </div>
                      </div>
                      <div className="select-picker">
                        <select
                          name="numberOfWheelchairs"
                          id="default_task_status"
                          className="form-control select-picker"
                          style={{ height: "39px" }}
                          data-size="8"
                          tabIndex="null"
                          value={data.numberOfWheelchairs}
                          onChange={handleChange}
                        >
                          <option value="1"> 1</option>
                          <option value="2"> 2</option>
                          <option value="3"> 3</option>
                          <option value="4"> 4</option>
                        </select>
                      </div>
                    </div>
                  </div>
              )}
              {data &&
                data.type &&
                data.type !== "" &&
                data.type?.name === "Ambulatory" && (
                  <div className="col-xl-4 col-lg-12 col-md-12 e-d-tasks-projects-events details ambulatory show">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group my-3">
                          <div className="d-flex">
                            <div>
                              <label
                                className="f-14 text-dark mb-12"
                                data-label=""
                                htmlFor="clnumber"
                              >
                                Will Call
                              </label>
                            </div>
                            <div className="p-2">
                              <sup className="text-danger f-14">*</sup>
                            </div>
                          </div>
                          <div className="btn-choice">
                            <label className="switch">
                              <input
                                type="checkbox"
                                className="switch-input"
                                checked={data.willCall}
                                onClick={() => {
                                  setWillCall((willCall) => !willCall);
                                  setData({
                                    ...data,
                                    willCall: !data.willCall,
                                  });
                                  if (returnTrip === false) {
                                    onClickReturnTrip();
                                  }
                                }}
                                // required
                              />
                              <span
                                className="switch-label"
                                data-on="YES"
                                data-off="NO"
                              ></span>
                              <span className="switch-handle"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group my-3">
                          <div className="d-flex">
                            <div>
                              <label
                                className="f-14 text-dark mb-12"
                                data-label=""
                                htmlFor="clnumber"
                              >
                                Need Attended
                              </label>
                            </div>
                            <div className="p-2">
                              <sup className="text-danger f-14">*</sup>
                            </div>
                          </div>
                          <div className="btn-choice">
                            <label className="switch">
                              <input
                                type="checkbox"
                                className="switch-input"
                                onClick={() =>
                                  setData({
                                    ...data,
                                    needAttended: !data.needAttended,
                                  })
                                }
                                // required
                              />
                              <span
                                className="switch-label"
                                data-on="YES"
                                data-off="NO"
                              ></span>
                              <span className="switch-handle"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {data &&
                  data.type &&
                  data.type !== "" &&
                  data.type?.name === "Wheelchair" && (
                    <div className="col-xl-4 col-lg-12 col-md-12 e-d-tasks-projects-events details wheelchair show">
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group my-3">
                            <div className="d-flex">
                              <div>
                                <label
                                  className="f-14 text-dark mb-12"
                                  data-label=""
                                  htmlFor="clnumber"
                                >
                                  AM Seats
                                </label>
                              </div>
                              <div className="p-2">
                                <sup className="text-danger f-14">*</sup>
                              </div>
                            </div>
                            <div className="select-picker">
                              <select
                                name="amSeats"
                                style={{ height: "39px" }}
                                id="default_task_status"
                                className="form-control select-picker"
                                data-size="8"
                                tabIndex="null"
                                value={data.amSeats}
                                onChange={handleChange}
                              >
                                <option value="0"> 0</option>
                                <option value="1"> 1</option>
                                <option value="2"> 2</option>
                                <option value="3"> 3</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                {data &&
                  data.type &&
                  data.type !== "" &&
                  data.type?.name === "Wheelchair" && (
                    <div className="col-md-12 details wheelchair show">
                      <div className="row">
                        <div className="col-xl-6 col-lg-12 col-md-12 e-d-info-notices">
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group my-3">
                                <div className="d-flex">
                                  <div>
                                    <label
                                      className="f-14 text-dark mb-12"
                                      data-label=""
                                      htmlFor="clnumber"
                                    >
                                      {" "}
                                      Need Wheel
                                    </label>
                                  </div>
                                  <div className="p-2">
                                    <sup className="text-danger f-14">*</sup>
                                  </div>
                                </div>
                                <div className="btn-choice">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      className="switch-input"
                                      onClick={() =>
                                        setData({
                                          ...data,
                                          needWheel: !data.needWheel,
                                        })
                                      }
                                      // required
                                    />
                                    <span
                                      className="switch-label"
                                      data-on="YES"
                                      data-off="NO"
                                    ></span>
                                    <span className="switch-handle"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group my-3">
                                <div className="d-flex">
                                  <div>
                                    <label
                                      className="f-14 text-dark mb-12"
                                      data-label=""
                                      htmlFor="clnumber"
                                    >
                                      {" "}
                                      Will Call
                                    </label>
                                  </div>
                                  <div className="p-2">
                                    <sup className="text-danger f-14">*</sup>
                                  </div>
                                </div>
                                <div className="btn-choice">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      className="switch-input"
                                      checked={data.willCall}
                                      onClick={() => {
                                        setWillCall((willCall) => !willCall);
                                        setData({
                                          ...data,
                                          willCall: !data.willCall,
                                        });
                                        if (returnTrip === false) {
                                          onClickReturnTrip();
                                        }
                                      }}
                                      // required
                                    />
                                    <span
                                      className="switch-label"
                                      data-on="YES"
                                      data-off="NO"
                                    ></span>
                                    <span className="switch-handle"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-12 col-md-12 e-d-tasks-projects-events">
                          <div className="row">
                            <div className="col-sm-6 pl-4">
                              <div className="form-group my-3">
                                <div className="d-flex">
                                  <div>
                                    <label
                                      className="f-14 text-dark mb-12"
                                      data-label=""
                                      htmlFor="clnumber"
                                    >
                                      Need Assigned
                                    </label>
                                  </div>
                                  <div className="p-2">
                                    <sup className="text-danger f-14">*</sup>
                                  </div>
                                </div>
                                <div>
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      className="switch-input"
                                      onClick={() =>
                                        setData({
                                          ...data,
                                          needAssigned: !data.needAssigned,
                                        })
                                      }
                                      // required
                                    />
                                    <span
                                      className="switch-label"
                                      data-on="YES"
                                      data-off="NO"
                                    ></span>
                                    <span className="switch-handle"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 pl-4">
                              <div className="form-group my-3">
                                <div className="d-flex">
                                  <div>
                                    <label
                                      className="f-14 text-dark mb-12"
                                      data-label=""
                                      htmlFor="clnumber"
                                    >
                                      Need Attended
                                    </label>
                                  </div>
                                  <div className="p-2">
                                    <sup className="text-danger f-14">*</sup>
                                  </div>
                                </div>
                                <div>
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      className="switch-input"
                                      onClick={() =>
                                        setData({
                                          ...data,
                                          needAttended: !data.needAttended,
                                        })
                                      }
                                      // required
                                    />
                                    <span
                                      className="switch-label"
                                      data-on="YES"
                                      data-off="NO"
                                    ></span>
                                    <span className="switch-handle"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                <div className="row">
                {stopArr.length > 0 &&
                  stopArr.map((current, i) => {
                    return (
                      <>
                        {/* <div className="col-sm-3 pl-4"> */}
                        <div className="col-sm-4 pl-4">
                          <div className="form-group my-3">
                            <div className="d-flex justify-content-between">
                              <div>
                                <div className="d-flex">
                                  <div>
                                    <label
                                      className="f-14 text-dark mb-12"
                                      data-label=""
                                      htmlFor="clnumber"
                                    >
                                      {i === 0
                                        ? "Pickup Address"
                                        : "Dropoff Address"}
                                    </label>
                                  </div>
                                  <div className="p-2">
                                    <sup className="text-danger f-14">*</sup>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <button
                                  className="bg-button"
                                  type="button"
                                  data-toggle="modal"
                                  data-target="#exampleModalCenter2"
                                  onClick={openDropLoc}
                                ></button>
                              </div>
                            </div>
                            <SearchedByMapForEdit
                              stopArr={stopArr}
                              setStopArr={setStopArr}
                              data={data}
                              setData={setData}
                              field="address"
                              index={i}
                              setStopArrUseApi={setStopArrUseApi}
                              searchAddress={current?.address?.address}
                              recalculate={recalculate}
                            />
                            {/* <SearchedByMap
                              stopArr={stopArr}
                              setStopArr={setStopArr}
                              field="address"
                              index={i}
                              setStopArrUseApi={setStopArrUseApi}
                              searchAddress={current?.address?.address}
                            /> */}
                          </div>
                        </div>
                        <div className="col-sm-3 pl-4">
                          <div className="form-group my-3">
                            <div className="d-flex">
                              <div>
                                <label
                                  className="f-14 text-dark mb-12"
                                  data-label=""
                                  htmlFor="clnumber"
                                >
                                  {i === 0 ? "Pickup Date" : "Dropoff Date"}
                                </label>
                              </div>
                              <div className="p-2">
                                <sup className="text-danger f-14">*</sup>
                              </div>
                            </div>
                            {i === 0 ? (
                              <input
                                type="date"
                                className="form-control height-35 f-14"
                                placeholder=""
                                id="driver"
                                min="0"
                                // autoComplete="off"
                                value={stopArr[i]?.date?.toString().substring(0,10)}
                                onChange={handleStopChange(i, "date")}
                                required
                              />
                            ) : (
                              <input
                                type="date"
                                className="form-control height-35 f-14"
                                placeholder=""
                                id="driver"
                                min="0"
                                // autoComplete="off"
                                value={stopArr[i]?.date?.toString().substring(0,10)}
                                onChange={handleStopChange(i, "date")}
                                required
                                disabled
                              />
                            )}
                          </div>
                        </div>
                        <div className="col-sm-3 pl-4">
                          <div className="form-group my-3">
                            <div className="d-flex">
                              <div>
                                <label
                                  className="f-14 text-dark mb-12"
                                  data-label=""
                                  htmlFor="driver"
                                >
                                  {i === 0 ? "Pickup Time" : "Dropoff Time"}
                                </label>
                              </div>
                              <div className="p-2">
                                <sup className="text-danger f-14">*</sup>
                              </div>
                            </div>
                            {i === 0 ? (
                              <input
                                type="time"
                                className="form-control height-35 f-14"
                                placeholder=""
                                id="driver"
                                min="0"
                                // autoComplete="off"
                                value={stopArr[i].time}
                                onChange={handleStopChange(i, "time")}
                                required
                              />
                            ) : (
                              <input
                                type="time"
                                className="form-control height-35 f-14"
                                placeholder=""
                                id="driver"
                                min="0"
                                // autoComplete="off"
                                value={stopArr[i].time}
                                onChange={handleStopChange(i, "time")}
                                required
                                disabled
                              />
                            )}
                          </div>
                        </div>
                        </>
                    );
                  })}
                </div>
            <div className="col-md-3">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark mb-12"
                  data-label=""
                  htmlFor="Zipcode"
                >
                  Price
                </label>
                <sup className="text-danger">*</sup>
                <input
                  type="text"
                  className="form-control height-35 f-14"
                  placeholder=""
                  value={data.totalAmount}
                  name="totalAmount"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group my-5">
                {/* <label
                  className="f-14 text-dark mb-12"
                  data-label=""
                  htmlFor="Zipcode"
                >
                  Price
                </label> */}
                {/* <sup className="text-danger">*</sup> */}
                <button
                  type="button"
                  className="btn-lightest-grey rounded f-12 p-2 mr-3 "
                  style={{
                    backgroundColor: "#f69b31",
                    fontSize: "14px",
                    color: "#fff",
                    // marginLeft: "-20px",
                  }}
                  onClick={openDetailDropDown}
                >
                  Details
                </button>
              </div>
            </div>

            <div className="col-lg-12">
            
            </div>
            <br />
            <div className="col-lg-12">
              <div
                className="form-group "
                style={{ marginTop: "1.5em !important" }}
              >
                <button
                  type="button"
                  className="btn-primary rounded f-14 p-2 mr-3"
                  onClick={handleSubmit}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AdminBookingPriceDetailModal
        showDetailDropDown={showDetailDropDown}
        openDetailDropDown={openDetailDropDown}
        // fareDetails={fareDetails}
        // useHardCodedFares={useHardCodedFares}
        // toogleHardCoded={toogleHardCoded}
        // hardCodedFares={hardCodedFares}
        // handleHardCodedFares={handleHardCodedFares}
        // recalculate={recalculate}
      />
    </>
  )
}
export default AdminTodayOrderEditComponent