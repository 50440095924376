import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReservationsByUserId } from "../../../actions/reservationAction";
import DriverMyOrderTableRowComponent from "./DriverMyOrderTableRowComponent";
import { Link, NavLink } from "react-router-dom";
import shift1 from "../../../assets/images/load.png"

// import { setNewRequest } from "../../../actions/reservationAction";
// import io from "socket.io-client";
// const socket = io('http://localhost:5000')
// const socket = io("https://ridetify-cca5568f0479.herokuapp.com");

const DriverMyOrderTableComponent = () => {
  const auth = useSelector((state) => state.auth);
  const reservation = useSelector((state) => state.reservation);
  // const [cc, setCC] = useState(0);
  // const [data, setData] = useState([]);
  // useEffect(() => {
  //   socket.emit(
  //     "driver-reservations",
  //     { driverId: auth.user.id },
  //     (response) => {
  //       // console.log(response)
  //       setData(response.status);
  //       dispatch(setNewRequest(response.status));
  //       return () => {
  //         socket.off("driver-reservations");
  //       };
  //     }
  //   );
  // }, [cc]);
  // let bb = 0;
  // useEffect(() => {
  //   setInterval(
  //     () => setCC(bb++),
  //     // , 2000);
  //     2000
  //   );
  // }, []);
  // console.log(data, "data hn mein");
  // useEffect(() => {
  // dispatch(setNewRequest(data))
  // },[data])


  
  const dispatch = useDispatch();
  const myOrderData = async (e) => {
    dispatch(getReservationsByUserId({driverId: auth.user.id, 
      limit: limit,
      page: currentPageNumber }))
    }
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [limit, setLimit] = useState(10);
  const handleLimit = async (event) => {
    setLimit(parseInt(event.target.value));
    dispatch(
      getReservationsByUserId({ 
        driverId: auth.user.id,
        limit: event.target.value,
        page: 1,
        })
      );
    setCurrentPageNumber(1);
    };
  const onClickNext = async () => {
    let i = currentPageNumber;
      i++;
      if (i <= reservation.reservationRequest.totalPages) {
        setCurrentPageNumber(currentPageNumber + 1);
        dispatch(
          getReservationsByUserId({
            driverId: auth.user.id,
            limit: limit,
            page: currentPageNumber + 1,
          })
        );
      }
    };
  const onClickPrev = async () => {
    let i = currentPageNumber;
      i--;
      if (i >= 1) {
        setCurrentPageNumber(currentPageNumber - 1);
        dispatch(
          getReservationsByUserId({
            driverId: auth.user.id,
            limit: limit,
            page: currentPageNumber - 1,
          })
        );
      }
    };
  const handleChangeData = async (pageNo) => {
    setCurrentPageNumber(pageNo);
    dispatch(
        getReservationsByUserId({
          driverId: auth.user.id,
          limit: limit,
          page: pageNo,
        })
      );
    };

  
    useEffect(() => {
      myOrderData()
    },[])


  return (
    <>
      <div className="d-flex flex-column w-tables rounded mt-3 bg-white">
        <div className="d-flex border">
          <div className="flex-grow-1">
            <div className="dataTables_length d-flex" id="leads-table_length">
              <div className="p-2">
                <label>Show entries</label>
              </div>
              <label>
                <select
                  name="leads-table_length"
                  aria-controls="leads-table"
                  className="custom-select custom-select-sm form-control form-control-sm"
                  // className="select"
                  autoComplete="false"
                  onChange={handleLimit}
                  >
                  {[10, 25, 50, 100].map((current, i) => {
                      return (
                      <option value={current} key={i}>
                          {current}
                      </option>
                      );
                  })}
                </select>
              </label>
            </div>
          </div>
          {/* <!-- SEARCH BY TASK START -->
                <div className="task-search d-flex  py-1 px-lg-3 px-0 border-right-grey align-items-center">
                    <form className="w-100 mr-1 mr-lg-0 mr-md-1 ml-md-1 ml-0 ml-lg-0">
                        <div className="input-group bg-grey rounded">
                            <div className="input-group-prepend">
                                <span className="input-group-text border-0 bg-additional-grey">
                                    <i className="fa fa-search f-13 text-dark-grey"></i>
                                </span>
                            </div>
                            <input type="text" className="form-control f-14 p-1 border-additional-grey" id="search-text-field" placeholder="Start typing to search"/>
                        </div>
                    </form>
                </div>
                <!-- SEARCH BY TASK END --> */}
        </div>
        <div
          id="leads-table_wrapper"
          className="dataTables_wrapper dt-bootstrap4 no-footer"
        >
          <div className="row">
            <div className="col-sm-12">
              <table
                className="table border w-100 dataTable no-footer"
                id="leads-table"
                role="grid"
                aria-describedby="leads-table_info"
                style={{ overflowX: "auto" }}
              >
                <thead className="card-header">
                  <tr role="row">
                    <th
                      title="order"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Mobile: activate to sort column ascending"
                    >
                      Order ID
                    </th>
                    <th
                      title="Passenger Name"
                      className="sorting_disabled border-right"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Next Follow Up"
                    >
                      Passenger Name
                    </th>
                    <th
                      title="Phone#"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Phone#
                    </th>
                    <th
                      title="Asset#"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Asset#
                    </th>
                    <th
                      title="Pickup Drop Off"
                      className="sorting border-right w-30"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Pickup - Drop Off
                    </th>
                    <th
                      title="Date and time"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Order Date
                    </th>
                    <th
                      title="Date and time"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Fare
                    </th>
                    <th
                      title="Status"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Status
                    </th>
                    <th
                      title="Action"
                      className="text-right pr-20 sorting_disabled border-right"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Action"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {reservation &&
                    reservation.reservationRequest &&
                    reservation.reservationRequest.results &&
                    reservation.reservationRequest.results.length > 0 &&
                    reservation.reservationRequest.results.map((current, i) => {
                      // if (current.delete === false) {
                      return (
                        <DriverMyOrderTableRowComponent
                          current={current}
                          i={i}
                          key={i}
                        />
                      );
                      // }
                    })}
                </tbody>
              </table>
              {
                reservation &&
                reservation.reservationRequest &&
                reservation.reservationRequest.results &&
                reservation.reservationRequest.results.length === 0 &&
                <div className="dash-stats-list" style={{ textAlign: "center" }}>
                <br />
                <br />
                <img
                    alt=""
                    src={shift1}
                    style={{ width: "145px", opacity: "0.5" }} />
                <br />
                <p style={{ opacity: "0.5" }}>
                    No Reservations Found
                </p>
                </div>
              }
              <div
                id="leads-table_processing"
                className="dataTables_processing card"
                style={{ display: "none" }}
              >
                Processing...
              </div>
            </div>
          </div>
          <div className="d-flex border">
          {
            reservation.loading === false ?
            <div className="flex-grow-1">
              <div
                className="dataTables_info"
                id="leads-table_info"
                role="status"
                aria-live="polite"
              >
                Showing {1 + limit * (currentPageNumber - 1)} to{" "}
                  {reservation?.reservationRequest?.displayingResults + limit * (currentPageNumber - 1)}{" "}
                  of {reservation?.reservationRequest?.totalResults} entries
              </div>
            </div>
            :
            <div className="flex-grow-1">
              <div
                className="dataTables_info"
                id="leads-table_info"
                role="status"
                aria-live="polite"
              >
                Loading entries
              </div>
            </div>
          }
            <div>
              <div
                className="dataTables_paginate paging_simple_numbers"
                id="leads-table_paginate"
              >
                <ul className="pagination">
                  <li className="page-item">
                    <NavLink
                      className="page-link"
                      to="#"
                      aria-label="Previous"
                      onClick={onClickPrev}
                    >
                      <span aria-hidden="true">Previous</span>
                      <span className="sr-only">Previous</span>
                    </NavLink>
                  </li>
                  { 
                  reservation &&
                  reservation.reservationRequest &&
                  reservation.reservationRequest.paginationArr &&
                  reservation.reservationRequest.paginationArr.length > 0 &&
                  reservation.reservationRequest.paginationArr.map((current, i) => {
                    return currentPageNumber === current ? (
                      <li className="page-item active" key={i}>
                        <Link className="page-link" value={current} to="">
                          {current}
                        </Link>
                      </li>
                    ) : (
                      <li className="page-item" key={i}>
                        <Link
                          className="page-link"
                          to=""
                          value={current}
                          onClick={() => handleChangeData(current)}
                        >
                          {current}
                        </Link>
                      </li>
                    );
                  })}
                  <li
                    className="paginate_button page-item next"
                    id="leads-table_next"
                  >
                    <Link
                      to=""
                      aria-controls="leads-table"
                      data-dt-idx="3"
                      tabIndex="0"
                      className="page-link"
                      onClick={onClickNext}
                    >
                      Next
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DriverMyOrderTableComponent;
