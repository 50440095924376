import {
    RESERVATION_VIEW_REQUEST,
    RESERVATION_VIEW_SUCCESS,
    RESERVATION_VIEW_FAIL,
    RESERVATION_CREATE_REQUEST,
    RESERVATION_CREATE_SUCCESS,
    RESERVATION_CREATE_FAIL,
    RESERVATION_UPDATE_REQUEST,
    RESERVATION_UPDATE_SUCCESS,
    RESERVATION_UPDATE_FAIL,
    RESERVATION_DELETE_REQUEST,
    RESERVATION_DELETE_SUCCESS,
    RESERVATION_DELETE_FAIL,
    DRIVER_RESERVATION_REQUEST,
    DRIVER_RESERVATION_SUCCESS,
    DRIVER_RESERVATION_FAIL,
    RESERVATION_VIEW_SCHEDULE_REQUEST,
    RESERVATION_VIEW_SCHEDULE_SUCCESS,
    RESERVATION_VIEW_SCHEDULE_FAIL,
    RESERVATION_DRIVER_STATUS_UPDATE_REQUEST,
    RESERVATION_DRIVER_STATUS_UPDATE_SUCCESS,
    RESERVATION_DRIVER_STATUS_UPDATE_FAIL,
    RESERVATION_DRIVER_STATUS_BY_USERID_REQUEST,
    RESERVATION_DRIVER_STATUS_BY_USERID_SUCCESS,
    RESERVATION_DRIVER_STATUS_BY_USERID_FAIL,
    DRIVER_EARNING_REQUEST,
    DRIVER_EARNING_SUCCESS,
    DRIVER_EARNING_FAIL,
    DRIVER_TODAY_RESERVATION_VIEW_REQUEST,
    DRIVER_TODAY_RESERVATION_VIEW_SUCCESS,
    DRIVER_TODAY_RESERVATION_VIEW_FAIL,
    DRIVER_RIDE_UPDATE_REQUEST,
    DRIVER_RIDE_UPDATE_SUCCESS,
    DRIVER_RIDE_UPDATE_FAIL,
    DRIVER_PAST_RESERVATION_VIEW_REQUEST,
    DRIVER_PAST_RESERVATION_VIEW_SUCCESS,
    DRIVER_PAST_RESERVATION_VIEW_FAIL,
    TRIP_CREATE_SUCCESS,
} from "../constants"

export const reservationReducer = (
    state = {
        reservation: {
            reservation: null,
            assigned: null,
            unassigned: null,
            pending: null,
            // newRequest: null,
            // history: null,
            pastHistory: null,
            reservationsSchedule: null,
            todayReservationView: null,
            markedLocation: null,
            reservationRequest: null,
            userNotificationList: null,
            driverEarning: null,
        }
    },
    action
) => {
        switch (action.type) {

        // RESERVATION VIEW TABLE
        case RESERVATION_VIEW_REQUEST : 
            return {
                ...state,
                loading : true,
            };
        case RESERVATION_VIEW_SUCCESS :
            return {
                ...state,
                loading : false,
                reservation: action.payload,
                today: action.payload.today,
                previous: action.payload.previous,
            };
        case RESERVATION_VIEW_FAIL :
            return {
                ...state,
                loading : false,
                error: action.payload,
            };

        // RESERVATION CREATE 
        case RESERVATION_CREATE_REQUEST :
            return {
                ...state,
            }
        case RESERVATION_CREATE_SUCCESS :
            return {
                ...state,
                // reservationsSchedule: {...state.reservationsSchedule, unassigned:[action.payload, ...state.reservationsSchedule.unassigned]},
                unassigned: {...state.unassigned, results:[action.payload, ...state.unassigned.results]},
            }
        case RESERVATION_CREATE_FAIL :
            return {
                ...state,
                error: action.payload,
            }

        case RESERVATION_UPDATE_REQUEST :
            return {
                ...state,
            };
        case RESERVATION_UPDATE_SUCCESS :
            return {
                ...state,
                // policyTable: [...state.policyTable, action.payload],
                // reservation: {...state.reservation, results:state.reservation.results.map((x) =>
                //     x.id === action.payload.id ? action.payload : x
                // )},
                reservationsSchedule: {
                    ...state.reservationsSchedule, 
                    unassigned: {
                        ...state.unassigned,
                        results: state.unassigned.results.map((x) =>
                            x._id === action.payload.id ? action.payload : x
                        )
                    },
                    assigned: {
                        ...state.assigned,
                        results: state.assigned.results.map((x) =>
                            x._id === action.payload.id ? action.payload : x
                        )
                    },
                },
                unassigned: {
                    ...state.unassigned,
                    results: state.unassigned.results.map((x) =>
                        x._id === action.payload.id ? action.payload : x
                    )
                },
                assigned: {
                    ...state.assigned,
                    results: state.assigned.results.map((x) =>
                        x._id === action.payload.id ? action.payload : x
                    )
                },
                // reservation: action.payload
            };
        case RESERVATION_UPDATE_FAIL :
            return {
                ...state,
                error: action.payload,
            };
        case RESERVATION_DELETE_REQUEST :
            return {
                ...state,
            };
        case RESERVATION_DELETE_SUCCESS :
            return {
                ...state,
                reservationsSchedule: {
                    ...state.reservationsSchedule, 
                    unassigned: {
                        ...state.unassigned,
                        results: state.unassigned.results.filter((x) => x._id !== action.payload.id)
                    },
                    assigned: {
                        ...state.assigned,
                        results: state.assigned.results.filter((x) => x._id !== action.payload.id)
                    },
                },
                unassigned: {
                    ...state.unassigned,
                    results: state.unassigned.results.filter((x) => x._id !== action.payload.id)
                },
                assigned: {
                    ...state.assigned,
                    results: state.assigned.results.filter((x) => x._id !== action.payload.id)
                },
                reservation: {
                    ...state.reservationsSchedule, 
                    today: {
                        ...state.today,
                        results: state.today.results.filter((x) => x._id !== action.payload.id)
                    },
                },
                today: {
                    ...state.today,
                    results: state.today.results.filter((x) => x._id !== action.payload.id)
                },
                // reservation: action.payload
            };
        case RESERVATION_DELETE_FAIL :
            return {
                ...state,
                error: action.payload,
            };
    
              // RESERVATION VIEW TABLE
        // case DRIVER_RESERVATION_REQUEST : 
        //     return {
        //         ...state,
        //     };
        // case DRIVER_RESERVATION_SUCCESS :
        //     return {
        //         ...state,
        //         newRequest: action.payload.newRequest,
        //         history: action.payload.history,
        //     };
        // case DRIVER_RESERVATION_FAIL :
        //     return {
        //         ...state,
        //         error: action.payload,
        //     };
        case DRIVER_RESERVATION_REQUEST : 
            return {
                ...state,
                loading : true,
            };
        case DRIVER_RESERVATION_SUCCESS :
            return {
                ...state,
                loading : false,
                reservationRequest: action.payload,
            };
        case DRIVER_RESERVATION_FAIL :
            return {
                ...state,
                loading : false,
                error: action.payload,
            };

        case RESERVATION_VIEW_SCHEDULE_REQUEST : 
            return {
                ...state,
                loading : true,
            };
        case RESERVATION_VIEW_SCHEDULE_SUCCESS :
            return {
                ...state,
                loading : false,
                reservationsSchedule: action.payload,
                assigned: action.payload.assigned,
                unassigned: action.payload.unassigned,
                pending: action.payload.pending,
            };
        case RESERVATION_VIEW_SCHEDULE_FAIL :
            return {
                ...state,
                loading : false,
                error: action.payload,
            };

        case "SET_NEWREQUEST" :
            return {
                ...state,
                newRequest: action.payload,
            };

        // RESERVATION STATUS UPDATE BY RESERVATION ID
        case RESERVATION_DRIVER_STATUS_UPDATE_REQUEST :
            return {
                ...state,
            };
        case RESERVATION_DRIVER_STATUS_UPDATE_SUCCESS :
            return {
                ...state,
                reservationRequest: {...state.reservationRequest, results:state.reservationRequest.results.map((x) =>
                x.id === action.payload.reservationId && action.payload.driverBookingStatus=== "Assigned"? {...x, driverBookingStatus: "Assigned", isAssigned: true, status: "Scheduled"} : x
                )
                .filter((x) => !(x.id === action.payload.reservationId && action.payload.driverBookingStatus=== "Unassigned"))
            }
            };
        case RESERVATION_DRIVER_STATUS_UPDATE_FAIL :
            return {
                ...state,
                error: action.payload,
            };

        case "SET_RESERVATIONS" :
            return {
                ...state,
                reservation: action.payload,
                assigned: action.payload.assigned,
                unassigned: action.payload.unassigned,
            };

        // RESERVATION VIEW TABLE
        case DRIVER_TODAY_RESERVATION_VIEW_REQUEST : 
            return {
                ...state,
                loading : true,
            };
        case DRIVER_TODAY_RESERVATION_VIEW_SUCCESS :
            return {
                ...state,
                loading : false,
                todayReservationView: action.payload,
            };
        case DRIVER_TODAY_RESERVATION_VIEW_FAIL :
            return {
                ...state,
                loading : false,
                error: action.payload,
            };  
            
        // TRIP UPDATE
        case DRIVER_RIDE_UPDATE_REQUEST :
            return {
                ...state,
            }
        case DRIVER_RIDE_UPDATE_SUCCESS :
            return {
                ...state,
                todayReservationView: {...state.todayReservationView, 
                        results:state.todayReservationView.results.map((x) => 
                        x.trip?.id === action.payload.id ? {reservation: x.reservation, trip: action.payload} : x
                    )}
                // ]
                // todayReservationView: [action.payload.id]
                // readTrip: action.payload,
            }
        case DRIVER_RIDE_UPDATE_FAIL :
            return {
                ...state,
                error: action.payload,
            }
        case TRIP_CREATE_SUCCESS :
            return {
                ...state,
                todayReservationView: {...state.todayReservationView, 
                    results:state.todayReservationView.results.map((x) => 
                        x.reservation?.id === action.payload.reservationId ? {reservation: x.reservation, trip: action.payload} : x
                    )}
            }
        case "MARKED_LOCATION" :
            return {
                ...state,
                markedLocation : action.payload
            };

        case DRIVER_PAST_RESERVATION_VIEW_REQUEST : 
            return {
                ...state,
                loading : true,
            };
        case DRIVER_PAST_RESERVATION_VIEW_SUCCESS :
            return {
                ...state,
                loading : false,
                pastHistory: action.payload,
            };
        case DRIVER_PAST_RESERVATION_VIEW_FAIL :
            return {
                ...state,
                loading : false,
                error: action.payload,
            };  

        case RESERVATION_DRIVER_STATUS_BY_USERID_REQUEST : 
            return {
                ...state,
            };
        case RESERVATION_DRIVER_STATUS_BY_USERID_SUCCESS :
            return {
                ...state,
                userNotificationList: action.payload,
            };
        case RESERVATION_DRIVER_STATUS_BY_USERID_FAIL :
            return {
                ...state,
                error: action.payload,
            };

            
        case DRIVER_EARNING_REQUEST : 
            return {
                ...state,
            };
        case DRIVER_EARNING_SUCCESS :
            return {
                ...state,
                driverEarning: action.payload,
            };
        case DRIVER_EARNING_FAIL :
            return {
                ...state,
                error: action.payload,
            };
            

        default:
            return state;
    }
}