export const tabBaseLocation = () => {
  return {
    type: "TAB_BASELOCATION",
  };
};

export const tabAddServices = () => {
  return {
    type: "TAB_ADDSERVICES",
  };
};
export const tabAmbulatory = () => {
  return {
    type: "TAB_AMBULATORY",
  };
};

export const tabWheelchair = () => {
  return {
    type: "TAB_WHEELCHAIR",
  };
};

export const tabStretcher = () => {
  return {
    type: "TAB_STRETCHER",
  };
};

export const tabFareList = () => {
  return {
    type: "TAB_FARELIST",
  };
};

export const tabBasicReportRow = () => {
  return {
    type: "TAB_BASIC_REPORT_ROW",
  };
};
export const tabReportSearch = () => {
  return {
    type: "TAB_REPORT_SEARCH",
  };
};

export const tabCalculatorReportRow = () => {
  return {
    type: "TAB_CALCULATOR_REPORT_ROW",
  };
};


// general website tab actions
export const tabHome = () => {
  return {
      type: "TAB_HOME"
  }
}
export const tabWhyUs = () => {
  return {
      type: "TAB_WHYUS"
  }
}
export const tabServices = () => {
  return {
      type: "TAB_SERVICES"
  }
}
export const tabFaq = () => {
  return {
      type: "TAB_FAQPAGE"
  }
}
export const tabContactUs = () => {
  return {
      type: "TAB_CONTACTUS"
  }
}
export const tabSignIn = () => {
  return {
      type: "TAB_SIGNIN"
  }
}
