import * as FileSaver from 'file-saver';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import XLSX from 'sheetjs-style'

const ExcelExport = ({ excelData, fileName }) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreedsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  // let arr2 = []
  // let [arr, setArr] = useState([])
  // const arrFill = (temp) => {
  //   temp?.forEach(e => {
  //     arr2.push(e.reservation)
  //   });
  //   setArr(arr2)
  // }
  // console.log(arr, "arr2")
  //   excelData?.forEach(e => {
  //     arr.push(e.reservation)
  //   });
  // }, [excelData])
  console.log(excelData, "excelDataexcelData")
  
  const exportToExcel = async () => {
    console.log(excelData, "excelDataexcelData")
    // arrFill(excelData)
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array'});
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, fileName + fileExtension)
  }
  
  return (
    <NavLink to='/report'
    className="btn btn-primary btn-block fa fa-file-excel-o" 
    onClick={(e) => exportToExcel(fileName)} style={{maxWidth:"6%"}}>
      {/* XLSX */}
    </NavLink>
  )
}

export default ExcelExport