import React from 'react'
import AdminHeader from '../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminHeader'
import AdminSidebar from '../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminSidebar'
import ExternalCompanyUserCreateView from '../../components/externalCompanyComponents/ExternalCompanyUserCreateView'

const ExternalCompanyUserCreatePage = () => {
    return (
        <>
            <AdminHeader />
            <AdminSidebar />
            <div className="body-wrapper clearfix">
                <section className="main-container " id="fullscreen">
                    <div className="px-4 py-2 border-top-0 emp-dashboard">
                        <div className="d-block d-lg-flex d-md-flex justify-content-between action-bar mb-20 mt-3">
                            <div
                                id="table-actions"
                                className="flex-grow-1 align-items-center"
                            >
                                <h4 className=" mb-0 f-21 text-capitalize font-weight-bold">
                                    {" "}
                                    Create Client{" "}
                                </h4>
                            </div>
                            <div
                                className="btn-group mt-2 mt-lg-0 mt-md-0 ml-0 ml-lg-3 ml-md-3"
                                role="group"
                            >
                            </div>
                        </div>
                        <ExternalCompanyUserCreateView />
                        {/* {
                            auth?.user?.permissionId?.clients?.read === true ?
                            <AdminClientViewTable />
                            :
                            <NotAllowed />
                        } */}
                    </div>
                </section>
            </div>
        </>
    )
}
export default ExternalCompanyUserCreatePage