import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { deleteFare, getFaresByCompanyId } from "../../../actions/fareActions";

const AdminSettingPaymentsFareTableRow = ({
  current,
  i,
  dropDownRow,
  setDropDownRow,
  setModal,
}) => {
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };
  const auth = useSelector((x) => x.auth);
  const dispatch = useDispatch()
  const handleDelete = async (id) => {
    dispatch(deleteFare({_id: id, delete: true}, successHandler));
  }
  const successHandler = () => {
    dispatch(getFaresByCompanyId({ companyId: auth.user.companyId.id }));
  };
  return (
    <>
      <tr
        id="row-20"
        role="row"
        className="odd border"
        key={i}
        onMouseLeave={() => setshowMoreVertDropDown(false)}
      >
        <td className="border-right">{current.name}</td>
        <td className="border-right">
          {current.serviceId && current.serviceId.name}
        </td>
        {/* <td className="border-right">
          {current.isFlatBaseFare === true ? "Yes" : "No"}
        </td> */}
        <td className="border-right">{current.flatBaseFare}</td>
        <td className="border-right">{current.farePerMile}</td>
        <td className="border-right">{current.deadHeadThreshold}</td>
        <td className="border-right">{current.baseFarePerMile}</td>
        <td className="border-right">{current.totalMileageAllows}</td>
        <td className="border-right">{current.longDistancePerMile}</td>
        <td className="border-right">{current.offHourFee}</td>
        <td className="border-right">{current.noShowFee}</td>
        <td className="border-right">{current.cancelationWindow}</td>
        <td className="border-right">{current.cancelationFee}</td>
        <td className=" text-right pr-20">
          <div className="task_view">
            <div className="dropdown">
              <Link
                className="task_view_more d-flex align-items-center justify-content-center dropdown-toggle"
                type="link"
                id="dropdownMenuLink-20"
                data-toggle="dropdown"
                onClick={openMoreVertDropDown}
                aria-haspopup="true"
                aria-expanded="false"
                to=""
              >
                <i className="icon-options-vertical icons"></i>
              </Link>
              <div
                className={`dropdown-menu dropdown-menu-right ${
                  showMoreVertDropDown
                    ? "dropdown-menu dropdown-menu-right show"
                    : "dropdown-menu dropdown-menu-right"
                }`}
                aria-labelledby="dropdownMenuLink-20"
                tabIndex="0"
              >
                {/* <Link to="user-view" className="dropdown-item">
                  <i className="fa fa-eye"> View</i>
                </Link> */}
                {
                  auth?.user?.permissionId?.settings?.update === true &&
                  <Link
                    className="dropdown-item"
                    to=""
                    onClick={() => setModal({ open: true, data: current })}
                  >
                    <i className="fa fa-edit"> Edit</i>
                  </Link>
                }
                {
                  auth?.user?.permissionId?.settings?.delete === true &&
                  <Link
                    className="dropdown-item"
                    to=""
                    onClick={() => handleDelete(current.id)}
                  >
                    <i className="fa fa-trash"> Delete</i>
                  </Link>
                }
              </div>
            </div>
          </div>
        </td>
      </tr>
    </>
  );
};
export default AdminSettingPaymentsFareTableRow;
