import axios from "axios";
import {
    ROUTETRIP_VIEW_REQUEST,
    ROUTETRIP_VIEW_SUCCESS,
    ROUTETRIP_VIEW_FAIL,
    ROUTETRIP_CREATE_REQUEST,
    ROUTETRIP_CREATE_SUCCESS,
    ROUTETRIP_CREATE_FAIL,
    // ROUTETRIP_UPDATE_REQUEST,
    // ROUTETRIP_UPDATE_SUCCESS,
    // ROUTETRIP_UPDATE_FAIL,
    SERVERADDRESS,
} from "../constants"

// ALL ROUTETRIP DISPLAY VIEW 
export const getAllRouteTrip = (obj) => async (dispatch) => {
    dispatch({
        type: ROUTETRIP_VIEW_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/routeTrip/getRouteTripsByCompanyId`, obj);
        localStorage.setItem("readRouteTrip", JSON.stringify(data));
        dispatch({
            type: ROUTETRIP_VIEW_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: ROUTETRIP_VIEW_FAIL,
            payload: err,
        });
    }
};

// ADD NEW ROUTETRIP
export const addRouteTrip = (obj) => async ( dispatch ) => {
    dispatch({
        type: ROUTETRIP_CREATE_REQUEST,
    })
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/routeTrip/createRouteTrip`, obj)
        localStorage.setItem("routeTripCreate", JSON.stringify(data));
        dispatch({
            type: ROUTETRIP_CREATE_SUCCESS,
            payload: data,
    })
}
catch (err) {
    console.log(err)
    dispatch({
        type: ROUTETRIP_CREATE_FAIL,
        payload: err,
    })
}
}

// // UPDATE ROUTETRIP
// export const updateRouteTrip = (obj) => async ( dispatch ) => {
//     dispatch({
//         type: ROUTETRIP_UPDATE_REQUEST,
//     })
//     try {
//         const { data } = await axios.put(`${SERVERADDRESS}/v1/routeTrip/updateRouteTrip`, obj)
//         dispatch({
//             type: ROUTETRIP_UPDATE_SUCCESS,
//             payload: data,
//     })
// }
// catch (err) {
//     console.log(err)
//     dispatch({
//         type: ROUTETRIP_UPDATE_FAIL,
//         payload: err,
//     })
// }
// }