import moment from "moment";
import React from "react";

const AdminBookingPastBookingRideLogModal = ({
  showRideLogDropDown,
  openRideLogDropDown,
  current,
  i,
}) => {
  return (
    <div
      className={`modal fade ${
        showRideLogDropDown ? "show modal-y-axis" : "hide"
      }`}
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header bg-additional-grey">
            <h5 className="modal-title" id="exampleModalLongTitle">
              {" "}
              Ride Logs
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => openRideLogDropDown()}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-5">
                  <div className="form-group ">
                      <p className="f-18 text-dark mb-12" htmlFor="Name">
                          Assigning Details:
                      </p>
                  </div>
              </div>
              <table
                  className="table border dataTable no-footer"
                  id="leads-table"
                  role="grid"
                  aria-describedby="leads-table_info"
                  style={{ overflowX: "auto", width: "95%", margin: "auto" }}
              >
                  <thead className="card-header">
                      <tr role="row">
                          <th
                              title="AName"
                              className="sorting border-right"
                              tabIndex="0"
                              aria-controls="leads-table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Mobile: activate to sort column ascending"
                          >
                              Assignee's Name
                          </th>
                          <th
                              title="Assignedon"
                              className="sorting_disabled border-right"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Next Follow Up"
                          >
                              Assigned On
                          </th>
                          <th
                              title="Assignedto"
                              className="sorting_disabled border-right"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Next Follow Up"
                          >
                              Assigned to
                          </th>
                          <th
                              title="Response"
                              className="sorting_disabled border-right"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Next Follow Up"
                          >
                              Response Date
                          </th>
                          <th
                              title="status"
                              className="sorting_disabled border-right"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Next Follow Up"
                          >
                              Status
                          </th>
                          <th
                              title="Reason"
                              className="sorting_disabled border-right"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Next Follow Up"
                          >
                              Reason to Reject
                          </th>
                      </tr>
                  </thead>
                  <tbody>
                      {
                          current.resStatus &&
                          current.resStatus.length > 0 &&
                          current.resStatus.map((e, i) => {
                              return (
                                  <tr
                                      role="row"
                                      className="odd border" key={i}
                                  >
                                      <td className="border-right">
                                          {e?.assigneeId?.firstName + " " + e?.assigneeId?.lastName}
                                      </td>
                                      <td className="border-right">
                                          {moment(e.assignedDate).format('MM/DD/YYYY')}
                                      </td>
                                      <td className="border-right">
                                          {e.driverId.firstName + " " + e.driverId.lastName}
                                      </td>
                                      <td className="border-right">
                                          {moment(e.responseDate).format('MM/DD/YYYY')}
                                      </td>
                                      <td className="border-right">
                                          {e.driverBookingStatus === 'Assigned' ? "Accepeted" : "Rejected"}
                                      </td>
                                      <td className="border-right">
                                          {e.declineReason}
                                      </td>
                                  </tr>
                              )
                          })}
                  </tbody>
              </table>
          </div>
            {/* <div className="row border-bottom-grey">
              <div className="col-md-5">
                <div className="form-group ">
                  <p className="f-18 text-dark mb-12" htmlFor="Name">
                    {" "}
                    Assigning Details:
                  </p>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group ">
                  {current.resStatus &&
                    current.resStatus.length > 0 &&
                    current.resStatus.map((e, i) => {
                      return (
                        <p
                          className="f-18 text-dark mb-12"
                          key={i}
                          htmlFor="Name"
                        >
                          &bull; Admin assigned this ride to {e.driverId.firstName + " " + e.driverId.lastName} on{" "}
                          {moment(e.assignedDate).format("MM/DD/YYYY")}. The
                          Driver has{" "}
                          {e.driverBookingStatus === "Assigned"
                            ? "Accepeted"
                            : `Rejected due to reason: ${e.declineReason}`}{" "}
                          on {moment(e.responseDate).format("MM/DD/YYYY")}.
                        </p>
                      );
                    })}
                </div>
              </div>
            </div> */}
                  {/* {<p className="f-18 text-dark mb-12" htmlFor="Name"> Admin assigned this ride to {current?.resStatus[0]?.driverId} on {moment(current?.resStatus[0]?.assignedDate).format('MM/DD/YYYY')}. The Driver has {current?.resStatus[0]?.driverBookingStatus === 'Assigned' ? "Accepeted" : `Rejected due to reason: ${current?.resStatus[0]?.declineReason}`}</p>} */}
            <br />
            <div className="row border-bottom-grey">
              <div className="col-md-5">
                <div className="form-group ">
                  <p className="f-18 text-dark mb-12" htmlFor="Name">
                    Trip Details:
                  </p>
                </div>
              </div>
              <table
              className="table border dataTable no-footer"
              id="leads-table"
              role="grid"
              aria-describedby="leads-table_info"
              style={{ overflowX: "auto", width: "95%", margin: "auto" }}
            >
              <thead className="card-header">
                <tr role="row">
                  <th
                    title="(Res#)"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Mobile: activate to sort column ascending"
                  >
                    Driver Intial Point to Pickup Point Time
                  </th>
                  <th
                    title="Vehicle"
                    className="sorting_disabled border-right"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Next Follow Up"
                  >
                    Driver Arrived At Pickup Point
                  </th>
                  <th
                    title="Vehicle"
                    className="sorting_disabled border-right"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Next Follow Up"
                  >
                    Driver Waiting Time
                  </th>
                  <th
                    title="Vehicle"
                    className="sorting_disabled border-right"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Next Follow Up"
                  >
                    Ride Started With Customer Time
                  </th>
                  <th
                    title="Vehicle"
                    className="sorting_disabled border-right"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Next Follow Up"
                  >
                    Dropoff Time
                  </th>
                  <th
                    title="Vehicle"
                    className="sorting_disabled border-right"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Next Follow Up"
                  >
                    Trip Duration Time
                  </th>
                  <th
                    title="Vehicle"
                    className="sorting_disabled border-right"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Next Follow Up"
                  >
                    Pickup Longitude
                  </th>
                  <th
                    title="Vehicle"
                    className="sorting_disabled border-right"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Next Follow Up"
                  >
                    Pickup Latitude
                  </th>
                  <th
                    title="Vehicle"
                    className="sorting_disabled border-right"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Next Follow Up"
                  >
                    Dropoff Longitude
                  </th>
                  <th
                    title="Vehicle"
                    className="sorting_disabled border-right"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Next Follow Up"
                  >
                    Dropoff Latitude
                  </th>
                  </tr>
              </thead>
              <tbody>
                <tr
                  role="row"
                  className="odd border"
                  // onMouseLeave={() => setshowMoreVertDropDown(false)}
                >
                  <td className="border-right">
                    {moment(
                      current?.trip?.tripStartTime?.substring(0, 10)
                    ).format("MM/DD/YYYY")}{" "}
                    {current?.trip?.tripStartTime?.substring(11, 16)}
                  </td>
                  <td className="border-right">
                    {moment(current?.trip?.arrivedTime?.substring(0, 10)).format(
                      "MM/DD/YYYY"
                    )}{" "}
                    {current?.trip?.arrivedTime?.substring(11, 16)}
                  </td>
                  <td className="border-right">
                    {current?.trip?.waitingTimePickUp}
                  </td>
                  <td className="border-right">
                    {moment(current?.trip?.pickUpTime?.substring(0, 10)).format(
                      "MM/DD/YYYY"
                    )}{" "}
                    {current?.trip?.pickUpTime?.substring(11, 16)}
                  </td>
                  <td className="border-right">
                    {moment(current?.trip?.dropOffTime?.substring(0, 10)).format(
                      "MM/DD/YYYY"
                    )}{" "}
                    {current?.trip?.dropOffTime?.substring(11, 16)}
                  </td>
                  <td className="border-right">
                    {current?.trip?.tripDuration}
                  </td>
                  <td className="border-right">
                    {current?.trip?.pickUpLng}
                  </td>
                  <td className="border-right">
                    {current?.trip?.pickUpLat}
                  </td>
                  <td className="border-right">
                    {current?.trip?.dropOffLng}
                  </td>
                  <td className="border-right">
                    {current?.trip?.dropOffLat}
                  </td>
                  </tr>
              </tbody>
            </table>
              {/* <div className="col-md-7"></div> */}
              
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn-primary rounded f-14 p-2 mr-3">
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminBookingPastBookingRideLogModal;



// <div className="col-md-5">
//                 <div className="form-group ">
//                   <p className="f-18 text-dark mb-12" htmlFor="Name">
//                     Driver Intial Point to Pickup Point Time
//                   </p>
//                 </div>
//               </div>
//               <div className="col-md-7">
//                 <div className="form-group  border-bottom">
//                   <p className="f-16 text-dark mb-12" htmlFor="Name">
//                     {moment(
//                       current?.trip?.tripStartTime?.substring(0, 10)
//                     ).format("MM/DD/YYYY")}{" "}
//                     {current?.trip?.tripStartTime?.substring(11, 16)}
//                   </p>
//                 </div>
//               </div>
//               <div className="col-md-5">
//                 <div className="form-group ">
//                   <p className="f-18 text-dark mb-12" htmlFor="Name">
//                     Driver Arrived At Pickup Point
//                   </p>
//                 </div>
//               </div>
//               <div className="col-md-7">
//                 <div className="form-group  border-bottom">
//                   <p className="f-16 text-dark mb-12" htmlFor="Name">
//                     {moment(current?.trip?.arrivedTime?.substring(0, 10)).format(
//                       "MM/DD/YYYY"
//                     )}{" "}
//                     {current?.trip?.arrivedTime?.substring(11, 16)}
//                   </p>
//                 </div>
//               </div>
//               <div className="col-md-5">
//                 <div className="form-group ">
//                   <p className="f-18 text-dark mb-12" htmlFor="Name">
//                     Driver Waiting Time
//                   </p>
//                 </div>
//               </div>
//               <div className="col-md-7">
//                 <div className="form-group  border-bottom">
//                   <p className="f-16 text-dark mb-12" htmlFor="Name">
//                     {" "}
//                     {current?.trip?.waitingTimePickUp}
//                   </p>
//                 </div>
//               </div>
//               <div className="col-md-5">
//                 <div className="form-group ">
//                   <p className="f-18 text-dark mb-12" htmlFor="Name">
//                     Ride Started With Customer Time
//                   </p>
//                 </div>
//               </div>
//               <div className="col-md-7">
//                 <div className="form-group  border-bottom">
//                   <p className="f-16 text-dark mb-12" htmlFor="Name">
//                     {moment(current?.trip?.pickUpTime?.substring(0, 10)).format(
//                       "MM/DD/YYYY"
//                     )}{" "}
//                     {current?.trip?.pickUpTime?.substring(11, 16)}
//                   </p>
//                 </div>
//               </div>
//               <div className="col-md-5">
//                 <div className="form-group ">
//                   <p className="f-18 text-dark mb-12" htmlFor="Name">
//                     {" "}
//                     Dropoff Time
//                   </p>
//                 </div>
//               </div>
//               <div className="col-md-7">
//                 <div className="form-group  border-bottom">
//                   <p className="f-16 text-dark mb-12" htmlFor="Name">
//                     {moment(current?.trip?.dropOffTime?.substring(0, 10)).format(
//                       "MM/DD/YYYY"
//                     )}{" "}
//                     {current?.trip?.dropOffTime?.substring(11, 16)}
//                   </p>
//                 </div>
//               </div>
//               <div className="col-md-5">
//                 <div className="form-group ">
//                   <p className="f-18 text-dark mb-12" htmlFor="Name">
//                     {" "}
//                     Trip Duration Time
//                   </p>
//                 </div>
//               </div>
//               <div className="col-md-7">
//                 <div className="form-group  border-bottom">
//                   <p className="f-16 text-dark mb-12" htmlFor="Name">
//                     {" "}
//                     {current?.trip?.tripDuration}
//                   </p>
//                 </div>
//               </div>

//               <div className="col-md-5">
//                 <div className="form-group ">
//                   <p className="f-18 text-dark mb-12" htmlFor="Name">
//                     {" "}
//                     Longitude
//                   </p>
//                 </div>
//               </div>
//               <div className="col-md-7">
//                 <div className="form-group  border-bottom">
//                   <p className="f-16 text-dark mb-12" htmlFor="Name">
//                     {" "}
//                     {current?.trip?.tripDuration}
//                   </p>
//                 </div>
//               </div>
//               <div className="col-md-5">
//                 <div className="form-group ">
//                   <p className="f-18 text-dark mb-12" htmlFor="Name">
//                     {" "}
//                     Latitude
//                   </p>
//                 </div>
//               </div>
//               <div className="col-md-7">
//                 <div className="form-group  border-bottom">
//                   <p className="f-16 text-dark mb-12" htmlFor="Name">
//                     {" "}
//                     {current?.trip?.tripDuration}
//                   </p>
//                 </div>
//               </div>