import React, { useEffect } from "react";
import AdminHeader from "../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminHeader";
import AdminSidebar from "../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminSidebar";
import { useDispatch, useSelector } from "react-redux";
import { getFaresByCompanyId } from "../../actions/fareActions";
import { getBaseLocation } from "../../actions/locationActions";
import { getServicesByCompanyId } from "../../actions/serviceActions";
import AdminDashboardBookedOrderSummaryCard from "../../components/adminComponents/adminDashboardComponents/AdminDashboardBookedOrderSummaryCard";
import { getAllClients } from "../../actions/userActions";
import { getReservationsSchedule } from "../../actions/reservationAction";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { navigateUserFunc } from "../../actions/generalActions";
import AdminDashboardTracking from "../../components/adminComponents/adminDashboardComponents/AdminDashboardCards";
import NotAllowed from "../../components/NotAllowed";
// import AdminDashboardTracking from '../../components/adminComponents/adminDashboardComponents/AdminDashboardTracking'

const AdminDashboardPage = () => {
  const auth = useSelector((x) => x.auth);
  const dispatch = useDispatch();
  const serviceData = async () => {
    dispatch(getServicesByCompanyId({ companyId: auth.user.companyId.id }));
  };
  const baseLocationData = async () => {
    dispatch(getBaseLocation({ companyId: auth.user.companyId.id }));
  };
  const fareData = async () => {
    // console.log("disp===>", auth.user.companyId.id);
    dispatch(getFaresByCompanyId({ companyId: auth.user.companyId.id }));
  };
  useEffect(() => {
    if (auth?.user?.companyId) {
      serviceData();
      baseLocationData();
      fareData();
      reservationsScheduleData()
    }
  }, [auth]);
  const reservationsScheduleData = async (e) => {
    dispatch(
      getReservationsSchedule({
        companyId: auth.user.companyId.id,
        today: moment().format('YYYY-MM-DD'),
        assigned: "unassigned",
        limit: 10,
        page: 1
      })
    );
  };
  useEffect(() => {
    dispatch(
      getAllClients({
        companyId: auth.user.companyId.id,
      })
    );
  }, []);


  let navigate = useNavigate();
  useEffect(() => {
    if (auth?.user?.permissionId?.dashboard?.isAllow === false){
      navigateUserFunc(auth?.user, navigate)
    }
  },[])

  return (
    <>
      <AdminHeader />
      <AdminSidebar />
      <div className="body-wrapper clearfix">
        <section className="main-container " id="fullscreen">
          <div className="px-4 py-2 border-top-0 emp-dashboard">
            <div className="d-lg-flex d-md-flex d-block py-4">
              <div>
                <h4 className=" mb-0 f-21 text-capitalize font-weight-bold">
                  Welcome To Ridetify
                </h4>
              </div>
            </div>
          </div>
          {
            auth?.user?.permissionId?.dashboard?.read === true ?
            <>
              <AdminDashboardBookedOrderSummaryCard />
              <AdminDashboardTracking />
            </>
            :
            <NotAllowed />
          }
        </section>
      </div>
    </>
  );
};
export default AdminDashboardPage;
