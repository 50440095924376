import React, { useEffect, useState } from 'react'
import { DirectionsRenderer, GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useSelector } from 'react-redux';

const containerStyle = {
    width: '100%',
    height: '80vh'
};

const NavigationTab = () => {
    const reservation = useSelector((state) => state.reservation);

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyC653TecaJSucmBmqXPr9twuP_jG55NACA"
      })

    const directionsService = new window.google.maps.DirectionsService();
    const directionsRenderer = new window.google.maps.DirectionsRenderer();
    // console.log(map)
    const [map, setMap] = React.useState(null)
    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])
    // directionsRenderer.setMap(map);
    const [dir, setDir] = useState()
    const [dir2, setDir2] = useState()
    const [dir3, setDir3] = useState([])
    const [showMap, setShowMap] = useState(false)
    const newArr = reservation?.markedLocation?.reservation?.rideHistory
    const findDirections = async () => {
        if (reservation?.markedLocation?.reservation?.rideHistory) {
            for (let i = 0; i < newArr.length - 1; i++) {
                console.log(newArr[i].address.latitude,"newArr")
                await directionsService.route(
                    {
                        origin: {lat: newArr[i].address.latitude, lng: newArr[i].address.longitude},
                        destination: {lat: newArr[i+1].address.latitude, lng: newArr[i+1].address.longitude},
                        travelMode: window.google.maps.TravelMode.DRIVING
                    },
                    (result, status) => {
                        if (status === window.google.maps.DirectionsStatus.OK) {
                            // this.setState({
                            //     directions: result
                            // });
                            setDir3([...dir3,result])
                            directionsRenderer.setDirections(result);
                            console.log(result, "resultresult")
                        } else {
                            console.error(`error fetching directions ${result}`);
                        }
                    }
                );
            }
            // directionsService.route(
            //     {
            //         origin: origin,
            //         destination: destination,
            //         travelMode: window.google.maps.TravelMode.DRIVING
            //     },
            //     (result, status) => {
            //         if (status === window.google.maps.DirectionsStatus.OK) {
            //             // this.setState({
            //             //     directions: result
            //             // });
            //             setDir(result)
            //             directionsRenderer.setDirections(result);
            //             console.log(result, "resultresult")
            //         } else {
            //             console.error(`error fetching directions ${result}`);
            //         }
            //     }
            // );
            // directionsService.route(
            //     {
            //         origin: origin2,
            //         destination: destination2,
            //         travelMode: window.google.maps.TravelMode.DRIVING
            //     },
            //     (result, status) => {
            //         if (status === window.google.maps.DirectionsStatus.OK) {
            //             // this.setState({
            //             //     directions: result
            //             // });
            //             setDir2(result)
            //             // directionsRenderer.setDirections(result);
            //             console.log(result, "resultresult")
            //         } else {
            //             console.error(`error fetching directions ${result}`);
            //         }
            //     }
            // );
            setShowMap(true)
        }
    }
    useEffect(() => {
        findDirections()
    }, [newArr]);
    console.log(dir3, "resultresult")

    // var positionMarker = {
    //     // path: 'M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z',
    //     url: 'https://cdn-icons-png.flaticon.com/512/2776/2776067.png',
    //     scaledSize: new window.google.maps.Size(40, 40),
    //     // fillColor: '#64be67',
    //     // fillOpacity: 1,
    //     // scale: 0.05, //to reduce the size of icons
    // };
    return isLoaded ? (
        <>
            {/* {
                showMap === true && */}
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={{lat: reservation?.markedLocation?.reservation?.rideHistory[0].address.latitude, lng: reservation?.markedLocation?.reservation?.rideHistory[0].address.longitude}}
                    zoom={12}
                    onUnmount={onUnmount}
                    // onClick={_onClick}
                >
                    {/* <Marker
                        label={{text:"Headquarter", color: "#fff" , className: 'labels2'}}
                        // icon={positionMarker}
                        position={{
                            lat: reservation?.markedLocation?.reservation?.rideHistory[0].address.latitude, lng: reservation?.markedLocation?.reservation?.rideHistory[0].address.longitude
                        }}
                    /> */}
                {
                    dir3 &&
                    dir3.length > 0 &&
                    dir3.map((stop, i) => {
                        return(
                            <DirectionsRenderer
                                key={i}
                                directions={stop}
                            />
                        )
                    })
                }
                    {/* {dir && 
                    <DirectionsRenderer
                        directions={dir}
                    />
                    }
                    {dir2 && 
                    <DirectionsRenderer
                        directions={dir2}
                    />
                    } */}
                </GoogleMap>
            {/* } */}
        </>
    ) : <></>
}

export default NavigationTab
