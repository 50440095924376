import moment from "moment";
import React from "react";

const AdminBookingTodayBookingRideLogModal = ({
  showRideLogDropDown,
  openRideLogDropDown,
  current,
  i,
}) => {
  return (
    <>
      <div
        className={`modal fade ${
          showRideLogDropDown ? "show modal-y-axis" : "hide"
        }`}
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header bg-additional-grey">
              <h5 className="modal-title" id="exampleModalLongTitle">
                {" "}
                Ride Logs
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => openRideLogDropDown()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-5">
                    <div className="form-group ">
                        <p className="f-18 text-dark mb-12" htmlFor="Name">
                            Assigning Details:
                        </p>
                    </div>
                </div>
                <table
                    className="table border dataTable no-footer"
                    id="leads-table"
                    role="grid"
                    aria-describedby="leads-table_info"
                    style={{ overflowX: "auto", width: "95%", margin: "auto" }}
                >
                    <thead className="card-header">
                        <tr role="row">
                            <th
                                title="AName"
                                className="sorting border-right"
                                tabIndex="0"
                                aria-controls="leads-table"
                                rowSpan="1"
                                colSpan="1"
                                aria-label="Mobile: activate to sort column ascending"
                            >
                                Assignee's Name
                            </th>
                            <th
                                title="Assignedon"
                                className="sorting_disabled border-right"
                                rowSpan="1"
                                colSpan="1"
                                aria-label="Next Follow Up"
                            >
                                Assigned On
                            </th>
                            <th
                                title="Assignedto"
                                className="sorting_disabled border-right"
                                rowSpan="1"
                                colSpan="1"
                                aria-label="Next Follow Up"
                            >
                                Assigned to
                            </th>
                            <th
                                title="Response"
                                className="sorting_disabled border-right"
                                rowSpan="1"
                                colSpan="1"
                                aria-label="Next Follow Up"
                            >
                                Response Date
                            </th>
                            <th
                                title="status"
                                className="sorting_disabled border-right"
                                rowSpan="1"
                                colSpan="1"
                                aria-label="Next Follow Up"
                            >
                                Status
                            </th>
                            <th
                                title="Reason"
                                className="sorting_disabled border-right"
                                rowSpan="1"
                                colSpan="1"
                                aria-label="Next Follow Up"
                            >
                                Reason to Reject
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            current.resStatus &&
                            current.resStatus.length > 0 &&
                            current.resStatus.map((e, i) => {
                                return (
                                    <tr
                                        role="row"
                                        className="odd border" key={i}
                                    >
                                        <td className="border-right">
                                            {e?.assigneeId?.firstName + " " + e?.assigneeId?.lastName}
                                        </td>
                                        <td className="border-right">
                                            {moment(e.assignedDate).format('MM/DD/YYYY')}
                                        </td>
                                        <td className="border-right">
                                            {e.driverId.firstName + " " + e.driverId.lastName}
                                        </td>
                                        <td className="border-right">
                                            {moment(e.responseDate).format('MM/DD/YYYY')}
                                        </td>
                                        <td className="border-right">
                                            {e.driverBookingStatus === 'Assigned' ? "Accepeted" : "Rejected"}
                                        </td>
                                        <td className="border-right">
                                            {e.declineReason}
                                        </td>
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
            </div>
              {/* <div className="row border-bottom-grey">
                <div className="col-md-5">
                  <div className="form-group ">
                    <p className="f-18 text-dark mb-12" htmlFor="Name">
                      {" "}
                      Assigning Details:
                    </p>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group ">
                    {current.resStatus &&
                      current.resStatus.length > 0 &&
                      current.resStatus.map((e, i) => {
                        return (
                          <p className="f-18 text-dark mb-12" htmlFor="Name">
                            &bull; Admin assigned this ride to {e.driverId.firstName + " " + e.driverId.lastName} on{" "}
                            {moment(e.assignedDate).format("MM/DD/YYYY")}. The
                            Driver has{" "}
                            {e.driverBookingStatus === "Assigned"
                              ? "Accepeted"
                              : `Rejected due to reason: ${e.declineReason}`}{" "}
                            on {moment(e.responseDate).format("MM/DD/YYYY")}.
                          </p>
                        );
                      })}
                  </div>
                </div>
              </div> */}
              <br />
              <div className="row border-bottom-grey">
                <div className="col-md-5">
                  <div className="form-group ">
                    <p className="f-18 text-dark mb-12" htmlFor="Name">
                      Trip Details:
                    </p>
                  </div>
                </div>
                <div className="col-md-7"></div>
                <div className="col-md-5">
                  <div className="form-group ">
                    <p className="f-18 text-dark mb-12" htmlFor="Name">
                      {" "}
                      Driver Intial Point to Pickup Point Time
                    </p>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="form-group  border-bottom">
                    <p className="f-16 text-dark mb-12" htmlFor="Name">
                      {current?.trip &&
                        moment(
                          current?.trip?.tripStartTime?.substring(0, 10)
                        ).format("MM/DD/YYYY")}{" "}
                      {current?.trip?.tripStartTime?.substring(11, 16)}
                    </p>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="form-group ">
                    <p className="f-18 text-dark mb-12" htmlFor="Name">
                      {" "}
                      Driver Arrived At Pickup Point
                    </p>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="form-group  border-bottom">
                    <p className="f-16 text-dark mb-12" htmlFor="Name">
                      {current?.trip &&
                        moment(
                          current?.trip?.arrivedTime?.substring(0, 10)
                        ).format("MM/DD/YYYY")}{" "}
                      {current?.trip?.arrivedTime?.substring(11, 16)}
                    </p>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="form-group ">
                    <p className="f-18 text-dark mb-12" htmlFor="Name">
                      {" "}
                      Driver Waiting Time
                    </p>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="form-group  border-bottom">
                    <p className="f-16 text-dark mb-12" htmlFor="Name">
                      {" "}
                      {current?.trip?.waitingTimePickUp}
                    </p>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="form-group ">
                    <p className="f-18 text-dark mb-12" htmlFor="Name">
                      {" "}
                      Ride Started With Customer Time{" "}
                    </p>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="form-group  border-bottom">
                    <p className="f-16 text-dark mb-12" htmlFor="Name">
                      {" "}
                      {current?.trip &&
                        moment(
                          current?.trip?.pickUpTime?.substring(0, 10)
                        ).format("MM/DD/YYYY")}{" "}
                      {current?.trip?.pickUpTime?.substring(11, 16)}
                    </p>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="form-group ">
                    <p className="f-18 text-dark mb-12" htmlFor="Name">
                      {" "}
                      Dropoff Time
                    </p>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="form-group  border-bottom">
                    <p className="f-16 text-dark mb-12" htmlFor="Name">
                      {current?.trip &&
                        moment(
                          current?.trip?.dropOffTime?.substring(0, 10)
                        ).format("MM/DD/YYYY")}{" "}
                      {current?.trip?.dropOffTime?.substring(11, 16)}
                    </p>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="form-group ">
                    <p className="f-18 text-dark mb-12" htmlFor="Name">
                      Trip Duration Time
                    </p>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="form-group  border-bottom">
                    <p className="f-16 text-dark mb-12" htmlFor="Name">
                      {" "}
                      {current?.trip?.tripDuration}
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="row mt-3">
                                <div className="col-md-5">
                                    <div className="form-group ">
                                        <p className="f-18 text-dark mb-12" htmlFor="Name"> Mileage In</p>
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <div className="form-group border-bottom">
                                        <p className="f-16 text-dark mb-12" htmlFor="Name"> 0</p>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="form-group ">
                                        <p className="f-18 text-dark mb-12" htmlFor="Name"> Mileage Out</p>
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <div className="form-group border-bottom">
                                        <p className="f-16 text-dark mb-12" htmlFor="Name"> 0</p>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="form-group ">
                                        <p className="f-18 text-dark mb-12" htmlFor="Name"> Total</p>
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <div className="form-group border-bottom">
                                        <p className="f-16 text-dark mb-12" htmlFor="Name"> 0 Miles</p>
                                    </div>
                                </div>
                            </div> */}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn-primary rounded f-14 p-2 mr-3"
              >
                OK
              </button>
            </div>
            {/* <div className="modal-footer">
                            <button type="button" className="btn-secondary rounded f-14 p-2 mr-3" data-dismiss="modal" onClick={() => openRideLogDropDown()}>Close</button>
                            <button type="button" className="btn-primary rounded f-14 p-2 mr-3">Save changes</button>
                        </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminBookingTodayBookingRideLogModal;
