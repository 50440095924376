import React, { useEffect, useState } from 'react'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { Marker } from '@react-google-maps/api';
import io from "socket.io-client"
import { SERVERADDRESS } from '../../../constants';
import onGoingRide from "../../../assets/images/Untitled-1-02.png"
import notWorking from "../../../assets/images/Untitled-1-01.png"
import newhomeicon from "../../../assets/images/home.png"

// import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import CustomMarker from './CustomMarker';
const containerStyle = {
  width: '100%',
  height: '80vh'
};

// const socket = io('http://localhost:5000')
const socket = io('https://ridetify-cca5568f0479.herokuapp.com')

const DriverTrackingMap = ({allData, onGoing, notOnTrip}) => {
    const auth = useSelector((state) => state.auth);
    const location = useSelector((state) => state.location);
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        // googleMapsApiKey: "AIzaSyDv5TZ4_il1sukhMFSFChda0UHcL0js0Zg"
        googleMapsApiKey: "AIzaSyC653TecaJSucmBmqXPr9twuP_jG55NACA"
    })
    // const params = useParams()
    const [center, setCenter] = useState({
        lat: 0,
        lng: 0,
    });
    const [places, setPlaces] = useState([]);
    const [map, setMap] = React.useState(null)
    const [coordinates, setCoordinates] = useState({
        lat: 0,
        lng: 0,
    });
    const [cc, setCC] = useState(0)
    const [ trackingData, setTrackingData ] = useState([])
    useEffect(() => {
        // console.log(socket.id, "useeddecr")
        socket.emit("user-location", { companyId: auth.user.companyId.id }, (response) => {
            setTrackingData(response.status)
            // setCoordinates({
            //     lat: response.status.latitude,
            //     lng: response.status.longitude,
            // });
            setHqPoint(response.baseLoc)
            return () => {
                socket.off("user-location")
            }
        })

    }, [cc, coordinates.lat, coordinates.lng,])
    useEffect(() => {
        setCurrentLocation()
    }, [location])
    let bb = 0
    useEffect(()=>{
      setInterval(() => 
          setCC(bb++)
      , 100);
    },[])
    const [ hqPoint, setHqPoint ] = useState(
        []
    );
    const setCurrentLocation = async () => {
        // const res = await axios.post(`${SERVERADDRESS}/v1/track/trackData`, 
        //     {
        //         userId: params.id
        //     }
        // )
        // if (res.data.latitude !== null || res.data.longitude !== null){
        //     setCenter({
        //         lat: res.data.latitude,
        //         lng: res.data.longitude,
        //     })
        //     setCoordinates({
        //         lat: res.data.latitude,
        //         lng: res.data.longitude,
        //     });
        // }
        // else {
        //     if ("geolocation" in navigator) {
        //         navigator.geolocation.getCurrentPosition((position) => {
        //             // setCenter([position.coords.latitude, position.coords.longitude])
        //             setCenter({
        //                 lat: position.coords.latitude,
        //                 lng: position.coords.longitude,
        //             })
        //             setCoordinates({
        //                 lat: position.coords.latitude,
        //                 lng: position.coords.longitude,
        //             });
        //         });
        //     }
        // }
        setCenter({
            lat: location.baseLocation.latitude,
            lng: location.baseLocation.longitude,
            // lng: 67.14,
            // lat: 24.93,
        })
        // setHqPoint({
        //     lat: location.baseLocation.latitude,
        //     lng: location.baseLocation.longitude,
        // })
    }
    // console.log(hqPoint,"ssssssssssssssss")
    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])
    
    var iconPin = {
        // path: 'M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z',
        // url: 'https://cdn-icons-png.flaticon.com/128/75/75782.png',
        url: notWorking,
        scaledSize: new window.google.maps.Size(40, 40),
        // fillColor: '#64be67',
        // fillOpacity: 1,
        // scale: 0.05, //to reduce the size of icons
    };
    var movingPin = {
        // path: 'M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z',
        // url: 'https://cdn-icons-png.flaticon.com/512/2113/2113098.png',
        url: onGoingRide,
        scaledSize: new window.google.maps.Size(40, 40),
        // fillColor: '#64be67',
        // fillOpacity: 1,
        // scale: 0.05, //to reduce the size of icons
    };
    var hqIcon = {
        // url: 'https://cdn-icons-png.flaticon.com/512/25/25694.png',
        url: newhomeicon,
        scaledSize: new window.google.maps.Size(40, 40),
    }
    const onClickDetails = (i) => {
        console.log(i, "details hain bhai")
    }
    const [c, setC] = useState({
        lat: 41.92445,
        lng: -87.76675
    })
    useEffect(() => {
        setTimeout(() => 
            setC({
                lat: c.lat+0.000001,
                lng: c.lng+0.000001,
            })    
        , 1000);
    },[c.lat, c.lng])
    return isLoaded ? (
        <>
        <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={15}
        onUnmount={onUnmount}
        options={{ scrollwheel: true}}
        // onClick={_onClick}
        >
            
            {
                // (allData === true || headquarter === true) &&
                hqPoint.length > 0 &&
                hqPoint.map((stop, i) => {
                return(
                <>
                <Marker
                    key={i}
                    label={{text:"Headquarter", color: "#fff" , className: 'labels2'}}
                    icon={hqIcon}
                    position={{
                        lat: stop.latitude,
                        lng: stop.longitude
                    }}
                />
                </>
                )
            })
            }
        {
            (allData === true || onGoing === true) &&
            trackingData.length > 0 &&
            trackingData.map((stop, i) => {
                return(
                    stop.onGoingRide === true &&
                    <>
                    <Marker
                        key={i}
                        label={{text:" ("+ stop.driverId.vehicleId.assetNo +") "+stop.driverId.firstName + " " + stop.driverId.lastName, color: "#fff" , className: 'labels2'}}
                        icon={movingPin}
                        position={{lat: stop.latitude,
                            lng: stop.longitude}}
                    />
                    </>
                )
            })
        }
        {
            (allData === true || notOnTrip === true) &&
            trackingData.length > 0 &&
            trackingData.map((stop, i) => {
                return(
                    stop.onGoingRide === false &&
                    <>
                    <Marker
                        key={i}
                        // title='ssssssssssss'
                        label={{text:" ("+ stop.driverId.vehicleId.assetNo +") "+stop.driverId.firstName + " " + stop.driverId.lastName, color: "#fff" , className: 'labels2'}}
                        icon={iconPin}
                        position={{lat: stop.latitude,
                            lng: stop.longitude}}
                            // onClick={()=>onClickDetails(stop)}
                            // onMouseOver={()=>onClickDetails(stop)}
                    />
                    {/* <CustomMarker 
                        lat={stop.latitude}
                        lng= {stop.longitude}
                        name="My Marker"
            color="blue"/> */}
                    </>
                )
            })
        }
        {
        c &&    
        <Marker
            icon={movingPin}
            position={{lat: c.latitude,
            lng: c.longitude}}
        />
        }
        </GoogleMap>
        </>
    ) : <></>
}
export default DriverTrackingMap