import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getRouteById } from "../../../actions/routeActions";
import moment from "moment";
import StudentsList from "./StudentsList";
import RidePatternTable from "./RidePatternTable";
import LoadingComponent from "../../../components/LoadingComponent";

function RouteDetailsComponent() {
  const dispatch = useDispatch();
  const params = useParams();

  const routes = useSelector((state) => state.routes);
  const service = useSelector((state) => state.service);

  const { loading, routeDetails } = routes;

  useEffect(() => {
    dispatch(getRouteById(params.id));
  }, []);

  return (
    <>
      {loading && <LoadingComponent />}

      <div className="d-flex flex-column w-tables rounded mt-3 bg-white p-2 ">
        <h5 className="py-3">Basic Info</h5>
        <div className="row p-2">
          <div className="col-12 col-md-4 col-lg-3 p-1">
            <div className="border p-2 card-header ">
              <h6 className="p-0">Booking date: </h6>
              <h6 className="p-0 font-weight-bold text-right">
                {moment(routeDetails?.bookingDate)?.format("DD MMMM, YYYY")}
              </h6>
            </div>
          </div>
          <div className="col-12 col-md-4 col-lg-3 p-1">
            <div className="border p-2 card-header ">
              <h6 className="p-0">Total mileage: </h6>
              <h6 className="p-0 font-weight-bold text-right">
                {routeDetails?.totalMileage} miles
              </h6>
            </div>
          </div>
          <div className="col-12 col-md-4 col-lg-3 p-1">
            <div className="border p-2 card-header ">
              <h6 className="p-0">Total driven mileage: </h6>
              <h6 className="p-0 font-weight-bold text-right">
                {routeDetails?.totalDrivenMileage} miles
              </h6>
            </div>
          </div>
          <div className="col-12 col-md-4 col-lg-3 p-1">
            {" "}
            <div className="border p-2 card-header">
              <h6 className="p-0">Total Amount: </h6>
              <h6 className="p-0 font-weight-bold text-right">
                $ {routeDetails?.totalAmount.toFixed(2)}
              </h6>
            </div>
          </div>
          <div className="col-12 col-md-4 col-lg-3 p-1">
            <div className="border p-2 card-header ">
              <h6 className="p-0">Status: </h6>
              <h6 className="p-0 font-weight-bold text-right">
                {routeDetails?.driverBookingStatus}
              </h6>
            </div>
          </div>{" "}
          <div className="col-12 col-md-4 col-lg-3 p-1">
            <div className="border p-2 card-header ">
              <h6 className="p-0">Driver assigned: </h6>
              <h6 className="p-0 font-weight-bold text-right">
                {routeDetails?.isAssigned ? "Yes" : "No"}
              </h6>
            </div>
          </div>{" "}
          <div className="col-12 col-md-4 col-lg-3 p-1">
            <div className="border p-2 card-header ">
              <h6 className="p-0">No. of Passengers: </h6>
              <h6 className="p-0 font-weight-bold text-right">
                {routeDetails?.customerDetails?.length}
              </h6>
            </div>
          </div>{" "}
          {routeDetails?.routeNumberReturn ? (
            <div className="col-12 col-md-4 col-lg-3 p-1">
              <div className="border p-2 card-header ">
                <h6 className="p-0">Return Route: </h6>
                <h6 className="p-0 font-weight-bold text-right">{"Yes"}</h6>
              </div>
            </div>
          ) : (
            <div className="col-12 col-md-4 col-lg-3 p-1">
              <div className="border p-2 card-header ">
                <h6 className="p-0">Return Route Available: </h6>
                <h6 className="p-0 font-weight-bold text-right">
                  {routeDetails?.isReturnRoute ? "Yes" : "No"}
                </h6>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="d-flex flex-column w-tables rounded mt-3 bg-white">
        {/* <div className="d-flex border">
          <div className="flex-grow-1">
            <div className="dataTables_length d-flex" id="leads-table_length"> */}
        <h5 className="p-2 py-3">Passengers List</h5>
        {/* </div>
          </div>
        </div> */}
        <div
          id="leads-table_wrapper"
          className="dataTables_wrapper dt-bootstrap4 no-footer"
        >
          <div className="row">
            <div className="col-sm-12">
              <StudentsList />
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex flex-column w-tables rounded mt-3 bg-white">
        {/* <div className="d-flex border">
          <div className="flex-grow-1">
            <div className="dataTables_length d-flex" id="leads-table_length"> */}
        <h5 className="p-2 py-3">Ride Pattern</h5>
        {/* </div>
          </div>
        </div> */}
        <div
          id="leads-table_wrapper"
          className="dataTables_wrapper dt-bootstrap4 no-footer"
        >
          <div className="row">
            <div className="col-sm-12">
              <RidePatternTable />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RouteDetailsComponent;
