import React, { useState } from 'react'
import DriverTrackingMap from '../adminTrackingMap/DriverTrackingMap'
import img1 from "../../../assets/images/Untitled-1-02.png"
import img2 from "../../../assets/images/Untitled-1-01.png"
const AdminDashboardTracking = () => {
    const [allData, setAllData] = useState(true)
    // const [headquarter, setHeadquarter] = useState(false)
    const [onGoing, setOnGoing] = useState(false)
    const [notOnTrip, setNotOnTrip] = useState(false)
    const openAllData = () => {
        setAllData((allData) => !allData)
    }
    // const openHeadquarter = () => {
    //     setHeadquarter((headquarter) => !headquarter)
    // }
    const openOnGoing = () => {
        setOnGoing((onGoing) => !onGoing)
    }
    const openNotOnTrip = () => {
        setNotOnTrip((notOnTrip) => !notOnTrip)
    }
    return (
        <>
            <div className="col-xl-12 col-lg-12 col-md-12 e-d-info-notices">
                <div className="row mb-3 mt-xl-0 mt-lg-4 mt-md-4 mt-4">
                    <div className="col-md-12 mt-md-4">
                        <div className="card">
                            <div className="card-header justify-content-between">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-map" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M15.817.113A.5.5 0 0 1 16 .5v14a.5.5 0 0 1-.402.49l-5 1a.502.502 0 0 1-.196 0L5.5 15.01l-4.902.98A.5.5 0 0 1 0 15.5v-14a.5.5 0 0 1 .402-.49l5-1a.5.5 0 0 1 .196 0L10.5.99l4.902-.98a.5.5 0 0 1 .415.103zM10 1.91l-4-.8v12.98l4 .8V1.91zm1 12.98 4-.8V1.11l-4 .8v12.98zm-6-.8V1.11l-4 .8v12.98l4-.8z" />
                                </svg>
                                <span className="f-18 text-dark mx-1">Live Tracking</span>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row mt-3 pb-3">
                                        <div className="col-md-2">
                                            <div className="form-check">
                                                <input type="checkbox" checked={allData} onClick={openAllData}/>
                                                <label className="form-check-label pl-2 f-14 text-dark mx-1">
                                                    All
                                                </label>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-2">
                                            <div className="form-check">
                                                <input type="checkbox" checked={headquarter} onClick={openHeadquarter}/>
                                                <label className="form-check-label pl-2 f-14 text-dark mx-1">
                                                    Headquarter
                                                </label>
                                            </div>
                                        </div> */}
                                        <div className="col-md-3">
                                            <div className="form-check">
                                                <input type="checkbox" checked={onGoing} onClick={openOnGoing}/>
                                                <label className="form-check-label pl-2 f-14 text-dark mx-1" htmlFor="flexCheckDefault">
                                                    Ongoing Trip
                                                </label>
                                                    <img data-toggle="tooltip" src={img1} style={{maxHeight: "27px"}}/>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-check">
                                                <input type="checkbox" checked={notOnTrip} onClick={openNotOnTrip}/>
                                                <label className="form-check-label pl-2 f-14 text-dark mx-1" htmlFor="flexCheckDefault">
                                                    Not on trip Drivers
                                                </label>
                                                <img data-toggle="tooltip" src={img2} style={{maxHeight: "27px"}}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body border-top">
                                {/* <div id="map"></div> */}
                                <DriverTrackingMap allData={allData} onGoing={onGoing} notOnTrip={notOnTrip}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AdminDashboardTracking