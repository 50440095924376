import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getExternalCompanies } from "../../../actions/companyAction";
import { editClientAction } from "../../../actions/userActions";
import AdminSettingPaymentAddCompanyModal from "../adminSettingComponents/AdminSettingPaymentAddCompanyModal";
import MyComponent from "../Map/Map";

const AdminClientEditNewModal = ({ showDropDown, openDropDown, current }) => {
    const navigate = useNavigate();
    const externalCompanies = useSelector((state) => state.externalCompanies);
    const auth = useSelector((state) => state.auth);

    const [values2, setValues2] = useState({
        // companyId: "63cb14f53f55723694dbd185",
        // isClient: true,
        // type: "",
        // isCompany: "",
        // externalCompanyId: "",
        id: '',
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        phone: "",
        // workPhone: '',
        // otherPhone: '',
        // role: "",
        // clientWorkInformation: '',
        address: "",
        state: '',
        city: "",
        zip: "",
        summary: "",
  });
  const handleChange = (fieldName) => (event) =>{
    setValues2({
       ...values2, [fieldName]:
       event.target.value
    })
  }
  const currentClient = async () => {
    try {
        setValues2({
            id: '',
            // companyId: "",
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            phone: "",
            // workPhone: "",
            // otherPhone: "",
            // clientWorkInformation: "",
            // role: "",
            address: "",
            city: "",
            zip: "",
            summary: "",
            state: '',
            // isClient: true,
            })
            setValues2({
            id: current.id,
            // companyId: current.companyId,
            firstName: current.firstName,
            lastName: current.lastName,
            email: current.email,
            password: '',
            // phone: current.phone,
            // workPhone: current.workPhone,
            // otherPhone: current.otherPhone,
            // clientWorkInformation: current.clientWorkInformation,
            // role: current.role,
            address: current.address,
            city: current.city,
            state: current.state,
            zip: current.zip,
            summary: current.summary,
            })
        } catch (error) {
        console.log(error)
        }
    }
    useEffect(() => {
        currentClient()
    }, [current])
    const dispatch = useDispatch();
    const clientData = {
        userId: values2.id,
        firstName: values2.firstName,
        lastName: values2.lastName,
        // email: values2.email,
        phone: values2.phone,
        // workPhone: values2.workPhone,
        // otherPhone: values2.otherPhone,
        // clientWorkInformation: values2.clientWorkInformation,
        address: values2.address,
        state: values2.state,
        city: values2.city,
        zip: values2.zip,
        summary: values2.summary,
    };

    const handleSuccess = () => {
        openDropDown();
    };
  
    useEffect(() => {
      dispatch(getExternalCompanies({ companyId: auth.user.companyId.id }));
    }, [auth]);
  
    let [selectedCompanyId, setSelectedCompanyId] = useState("");
    const [data, setData] = useState({});


    const handlePhone = (fieldName) => (event) => {
    if (event.target.value.length === 1) {
        if (event.target.value !== "(") {
        setData({
            ...data,
            [fieldName]: "(" + event.target.value,
        });
        }
    } else if (event.target.value.length === 4) {
        if (event.target.value !== ")") {
        setData({
            ...data,
            [fieldName]: event.target.value + ")-",
        });
        }
    } else if (event.target.value.length === 9) {
        if (event.target.value !== "-") {
        setData({
            ...data,
            [fieldName]: event.target.value + "-",
        });
        }
    } else {
        setData({
        ...data,
        [fieldName]: event.target.value,
        });
    }
    };
    const alphabets = [
    'a','b','c','d','e','f','g','h','i','j','k','l', 'm','n','o','p','q','r','s','t',
    'u','v','w','x','y','z','A','B','C','D','E', 'F','G','H','I','J',
    'K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z','+','-',
    '/','*','!','@','#','$','%','^','&','_','(',')',',','.'
    ];

    const handleSubmit = async (event) => {
        event.preventDefault();
        dispatch(editClientAction(clientData, handleSuccess));
    };
    // useEffect(() => {
    // if (state) setData(state);
    // else navigate("/clients");
    // }, [state]);
  return (
    <>
        <div
        className={`modal fade ${showDropDown ? "show modal-y-axis" : "hide"}`}
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document" style={{top: "8%"}}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                {" "}
                Edit User
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => openDropDown()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
              <div className="col-lg-6">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark-grey mb-12"
                  data-label=""
                  htmlFor="after_days"
                >
                  First Name
                </label>
                <sup className="text-danger">*</sup>
                <input
                  type="text"
                  className="form-control height-35 f-14"
                  placeholder="Enter Your First Name"
                  autoComplete="false"
                  value={values2.firstName}
                  name="firstName"
                  onChange={handleChange('firstName')}
                  required
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark-grey mb-12"
                  data-label=""
                  htmlFor="after_days"
                >
                  Last Name
                </label>
                <sup className="text-danger">*</sup>
                <input
                  type="text"
                  className="form-control height-35 f-14"
                  placeholder="Enter Your Last Name"
                  autoComplete="false"
                  value={values2.lastName}
                  name="lastName"
                  onChange={handleChange('lastName')}
                  required
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark-grey mb-12"
                  data-label=""
                  htmlFor="after_days"
                >
                  Email
                </label>
                <sup className="text-danger">*</sup>
                <input
                  type="Email"
                  className="form-control height-35 f-14"
                  placeholder="Enter Your Email"
                  autoComplete="false"
                  value={values2.email}
                  name="email"
                  onChange={handleChange('email')}
                  required
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark-grey mb-12"
                  data-label=""
                  htmlFor="after_days"
                >
                  Phone
                </label>
                {/* <sup className="text-danger">*</sup> */}
                <input
                  className="form-control height-35 f-14"
                  minLength="14"
                  maxLength="14"
                  // required
                  onKeyDown={(e) => {
                    if (alphabets.includes(e.key)) {
                      e.preventDefault();
                    }
                    if (e.code === "Backspace" && values2.phone.length === 6) {
                      setData({
                        ...data,
                        phone: values2.phone.substring(0, 4),
                      });
                    }
                    if (e.code === "Backspace" && values2.phone.length === 2) {
                      setData({
                        ...data,
                        phone: "",
                      });
                    }
                    if (e.code === "Backspace" && values2.phone.length === 10) {
                      setData({
                        ...data,
                        phone: values2.phone.substring(0, 9),
                      });
                    }
                  }}
                  value={values2.phone}
                  name="phone"
                  onChange={handleChange('phone')}
                  placeholder="(123)-456-7890"
                />
              </div>
            </div>
            {/* <div className="col-lg-6">
                            <div className="form-group my-3">
                                <label className="f-14 text-dark-grey mb-12" data-label="" htmlFor="after_days">Work Phone</label>
                                <sup className="text-danger">*</sup>
                                <input className="form-control height-35 f-14"
                                     minLength="14"
                                     maxLength="14"
                                     required
                                     onKeyDown={(e) => {
                                        if (alphabets.includes(e.key)) {
                                            e.preventDefault();
                                        } 
                                        if (e.code === "Backspace" && values.workPhone.length === 6){
                                            setValues({
                                                ...values, workPhone:
                                                values.workPhone.substring(0,4)
                                            })
                                        }
                                        if (e.code === "Backspace" && values.workPhone.length === 2){
                                            setValues({
                                                ...values, workPhone:
                                                ''
                                            })
                                        }
                                        if (e.code === "Backspace" && values.workPhone.length === 10){
                                            setValues({
                                                ...values, workPhone:
                                                values.workPhone.substring(0,9)
                                            })
                                        }
                                    }} name="workPhone" 
                                     value={values.workPhone}
                                     onChange={handlePhone('workPhone')}
                                     placeholder="(123)-456-7890"
                                />
                            </div>
                        </div> */}
            {/* <div className="col-lg-6">
                            <div className="form-group my-3">
                                <label className="f-14 text-dark-grey mb-12" data-label="" htmlFor="after_days">Other phone</label>
                                <sup className="text-danger">*</sup>
                                <input className="form-control height-35 f-14" 
                                    minLength="14"
                                    maxLength="14"
                                    required
                                    onKeyDown={(e) => {
                                        if (alphabets.includes(e.key)) {
                                            e.preventDefault();
                                        }
                                        if (e.code === "Backspace" && values.otherPhone.length === 6){
                                            setValues({
                                                ...values, otherPhone:
                                                values.otherPhone.substring(0,4)
                                            })
                                        }
                                        if (e.code === "Backspace" && values.otherPhone.length === 2){
                                            setValues({
                                                ...values, otherPhone:
                                                ''
                                            })
                                        }
                                        if (e.code === "Backspace" && values.otherPhone.length === 10){
                                            setValues({
                                                ...values, otherPhone:
                                                values.otherPhone.substring(0,9)
                                            })
                                        }
                                    }} name="otherPhone" 
                                    value={values.otherPhone}
                                    onChange={handlePhone('otherPhone')}
                                    placeholder="(123)-456-7890"
                                />
                            </div>
                        </div> */}
            {/* <div className="col-lg-6">
                            <label className="f-14 text-dark-grey mb-12 mt-3" data-label="" htmlFor="default_task_status">Work Information</label>
                            <sup className="text-danger">*</sup>
                            <div className="form-group mb-0">
                                <div className="select-picker">
                                    <select name="default_task_status" className="form-control select-picker" style={{height: '39px'}}>
                                        <option selected="" value="1"> Company Type:</option>
                                        <option value="2"> Individual Contacts</option>
                                        <option value="3"> Corporate Contact</option>
                                    </select>
                                </div>
                            </div>
                        </div> */}
            {/* <div className="col-lg-6">
                            <div className="form-group my-3">
                                <label className="f-14 text-dark-grey mb-12" data-label="" htmlFor="after_days">Work Information</label>
                                <sup className="text-danger">*</sup>
                                <input type="text" className="form-control height-35 f-14" placeholder="Enter Your Work Details"
                                autoComplete="false" value={values.clientWorkInformation} onChange={handleChange('clientWorkInformation')}/>
                            </div>
                        </div> */}

            <div className="col-md-12">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark mb-12"
                  data-label=""
                  htmlFor="Name"
                >
                  Street Address
                </label>
                {/* <sup className="text-danger">*</sup> */}
                {/* <input type="text" className="form-control height-35 f-14" placeholder="Enter Your Address" id="Name"/> */}
                <MyComponent
                  values2={data}
                  setValues2={setData}
                  addressss={values2.address}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark mb-12"
                  data-label=""
                  htmlFor="City"
                >
                  City
                </label>
                {/* <sup className="text-danger">*</sup> */}
                <input
                  type="text"
                  className="form-control height-35 f-14"
                  placeholder=""
                  value={values2.city}
                  name="city"
                  onChange={handleChange('city')}
                  required
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark mb-12"
                  data-label=""
                  htmlFor="cname"
                >
                  State
                </label>
                {/* <sup className="text-danger">*</sup> */}
                <input
                  type="text"
                  className="form-control height-35 f-14"
                  placeholder=""
                  value={values2.state}
                  name="state"
                  onChange={handleChange('state')}
                  required
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark mb-12"
                  data-label=""
                  htmlFor="Zipcode"
                >
                  Zip Code
                </label>
                {/* <sup className="text-danger">*</sup> */}
                <input
                  type="text"
                  className="form-control height-35 f-14"
                  placeholder=""
                  value={values2.zip}
                  name="zip"
                  onChange={handleChange('zip')}
                  required
                />
              </div>
            </div>

            <div className="col-lg-12">
              <label htmlFor="allowed_file_types"> Summary </label>
              <textarea
                type="text"
                placeholder="Note"
                className="form-control f-14"
                autoComplete="false"
                value={values2.summary}
                name="summary"
                onChange={handleChange('summary')}
              ></textarea>
            </div>
            <div className="col-lg-12">
              {/* <div className="form-group my-3">
                                <label className="f-14 text-dark-grey mb-12" data-label="" htmlFor="after_days">Active/unactive</label>
                                <input type="texta" className="form-control height-35 f-14" autoComplete="off" />
                            </div> */}
            </div>
            <br />
            <div className="col-lg-12">
              <div
                className="form-group "
                style={{ marginTop: "1.5em !important" }}
              >
                <button
                  type="button"
                  className="btn-primary rounded f-14 p-2 mr-3"
                  onClick={handleSubmit}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      </div>


    </>
  )
}
export default AdminClientEditNewModal