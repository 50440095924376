import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addUser, getAllUser } from '../../../actions/userActions';
import moment from 'moment';
import defaultimg from '../../../assets/images/profile.jpg'
import { useNavigate } from 'react-router-dom';
import AWS from 'aws-sdk'
import axios from 'axios';
import { SERVERADDRESS } from '../../../constants';

const S3_BUCKET = 'ridetifyportal';
// const REGION ='YOUR_DESIRED_REGION_HERE';

AWS.config.update({
    accessKeyId: 'AKIAULUGR4XCNQWUXPWO',
    secretAccessKey: 'kYwCYRbw3x64+HtBl8YBsS1p/zDeivp0DHWbxhLY'
})

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET},
    // region: REGION,
})

const AdminAddUserComponent = () => {
    const auth = useSelector((state) => state.auth);
    const permission = useSelector((state) => state.permission);
    const users = useSelector((state) => state.users);
    const vehicle = useSelector((state) => state.vehicle);
    const [ values, setValues ] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        role: '',
        hireDate: '',
        // jobTitle: 'Admin',
        permissionId: permission?.permission[0]?.permission?.id,
        vehicleId: '',
        serviceType: '',
        paymentType: '',
        percentageRage: '',
        phone: '',
        address: '',
        state: '',
        city: '',
        username: '',
        isClient: false,
        profilePic: '',
    })
    const navigate = useNavigate()
    const successHandler = () => {
        dispatch(getAllUser({ companyId: auth.user.companyId.id, isClient: false }))
        navigate("/users");
    };
    const handleChange = (fieldName) => (event) => {
        setValues({
            ...values, [fieldName]:
            event.target.value
        })
    }
    // console.log(values)
    // console.log(values, "vallllllllllllllll")c
    const dispatch = useDispatch()
    const [ isDriver, setIsDriver] = useState(false)
    const userData = {
        companyId: auth.user.companyId.id,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
        role: "user",
        isDriver: isDriver,
        hireDate: moment().format('YYYY-MM-DD'),
        // jobTitle: values.jobTitle,
        vehicleId: isDriver === true ? values.vehicleId : "",
        // serviceType: values.jobTitle === 'Driver' ? values.serviceType : "",
        // paymentType: values.paymentType,
        // percentageRage: values.percentageRage,
        permissionId: values.permissionId,
        phone: values.phone,
        address: values.address,
        state: values.state,
        city: values.city,
        username: values.username,
        isClient: false,
        profilePic: values.profilePic,
        // isClient: false,
    }

    const handlePhone = (fieldName) => (event) =>{  
        if(event.target.value.length === 1){
            if(event.target.value !== "("){
                setValues({
                    ...values, [fieldName]:
                    "(" +event.target.value
                })
            }
        }
        else if (event.target.value.length === 4){
            if(event.target.value !== ")"){
                setValues({
                    ...values, [fieldName]:
                    event.target.value+")-" 
                })
            }
        }
        else if (event.target.value.length === 9){
            if(event.target.value !== "-"){
                setValues({
                    ...values, [fieldName]:
                    event.target.value+"-" 
                })
            }
        }
        else {
            setValues({
                ...values, [fieldName]:
                event.target.value
            })
        }
    }
    const alphabets = [
        'a','b','c','d','e','f','g','h','i','j','k','l', 'm','n','o','p','q','r','s','t',
        'u','v','w','x','y','z','A','B','C','D','E', 'F','G','H','I','J',
        'K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z','+','-',
        '/','*','!','@','#','$','%','^','&','_','(',')',',','.'
    ];

    const [selectedImage, setSelectedImage] = useState(defaultimg);
    const [progress , setProgress] = useState(0);
    const [awsURL, setAwsURL] = useState(null);
    const handleChangePic = async (event) =>{
        const today = new Date();
        const date = today.getTime();
        const params = {
            Bucket: S3_BUCKET,
            // Key: "data/"+auth.user.companyId.companyName+"/ProfilePic/"+auth.user.id+"/"+event.target.files[0].name,
            Key: "data/"+auth.user.companyId.companyName+"/ProfilePic/"+date+"/"+event.target.files[0].name,
            Body: event.target.files[0],
            ACL: 'public-read'
        };
        var upload = myBucket.upload(params)
        .on('httpUploadProgress',(evt) => {
            setProgress(Math.round((evt.loaded / evt.total) * 100))
        })
        .promise();
        let url;
        await upload.then(function(data, err) {
            try {
                url = data.Location
                setAwsURL(url)
                // console.log(url)
            } catch (error) {
                console.log(error, err, "ee");
            }
        });
        const { data } = await axios.post(`${SERVERADDRESS}/v1/fileupload/profilePicUpload`, 
            {
                url: url,
                file: event.target.files[0].name,
                type: event.target.files[0].type,
                userId:auth.user.id,
                companyId:auth.user.companyId.id,
            })
        setSelectedImage(url)
        setValues({
            ...values,
            ['profilePic']: data.id
        })
        // dispatch(adminCompanyInfoUpdate({userId: auth.user.id, profilePic: data.id}))
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        // console.log("first")
        for (let key of Object.keys(userData)) {
            if (userData[key] === "") {
              delete userData[key];
            }
        }
        dispatch(addUser(userData, successHandler))
        // setValues({
        //     companyId: '',
        //     firstName: '',
        //     lastName: '',
        //     email: '',
        //     password: '',
        //     role: '',
        //     hireDate: '',
        //     jobTitle: '',
        //     vehicleId: '',
        //     serviceType: '',
        //     paymentType: '',
        //     percentageRage: '',
        //     phone: '',
        //     address: '',
        //     state: '',
        //     city: '',
        //     username: '',
        //     isClient: false,
        // })
        // openDropDown()
    }

    // const handleChangePic = async (event) =>{
    //     const today = new Date();
    //     const date = today.getTime();
    //     const params = {
    //         Bucket: S3_BUCKET,
    //         Key: "data/"+auth.user.companyId.companyName+"/ProfilePic/"+date+"/"+event.target.files[0].name,
    //         Body: event.target.files[0],
    //         ACL: 'public-read'
    //     };
    //     var upload = myBucket.upload(params)
    //     .on('httpUploadProgress',(evt) => {
    //         setProgress(Math.round((evt.loaded / evt.total) * 100))
    //     })
    //     .promise();
    //     let url;
    //     await upload.then(function(data, err) {
    //         try {
    //             url = data.Location
    //             setAwsURL(url)
    //             console.log(url)
    //         } catch (error) {
    //             console.log(error, err, "ee");
    //         }
    //     });
    //     const { data } = await axios.post(`${SERVERADDRESS}/v1/fileupload/profilePicUpload`, 
    //         {
    //             url: url,
    //             file: event.target.files[0].name,
    //             type: event.target.files[0].type,
    //             userId:auth.user.id,
    //             companyId:auth.user.companyId.id,
    //         })
    //     setSelectedImage(url)
    //     // dispatch(adminCompanyInfoUpdate({userId: auth.user.id, profilePic: data.id}))
    // }
    return (
        <>
            <div className="row emp-dash-detail">
                <div className="col-xl-4 col-lg-12 col-md-12 e-d-info-notices">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card p-3 mb-3">
                                <div className="text-center">
                                    <img src={selectedImage} alt="" />
                                </div>
                                <div className="form-group mx-3">
                                    <input type="file" className="input-group-text" style={{width: "90%"}} accept='.jpg, .jpeg, .png'  onChange={handleChangePic}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-8 col-lg-12 col-md-12 e-d-info-notices">
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card p-3 mb-3">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group my-3">
                                                <label className="f-14 text-dark-grey mb-12">First Name</label>
                                                <sup className="text-danger">*</sup>
                                                <input type="text" className="form-control height-35 f-14" placeholder="Enter Your First Name" required
                                                autoComplete="false" value={values.firstName} onChange={handleChange('firstName')}/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group my-3">
                                                <label className="f-14 text-dark-grey mb-12">Last Name</label>
                                                <sup className="text-danger">*</sup>
                                                <input type="text" className="form-control height-35 f-14" placeholder="Enter Your Last Name" required
                                                autoComplete="false" value={values.lastName} onChange={handleChange('lastName')}/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <label className="f-14 text-dark-grey mb-12" >Permission</label>
                                            <sup className="text-danger">*</sup>
                                            <div className="form-group mb-0">
                                                <div className="select-picker">
                                                    <select name="default_task_status" className="form-control select-picker" style={{height: '39px'}} required
                                                        autoComplete='false' defaultValue={''}
                                                        onChange={handleChange('permissionId')}
                                                    >
                                                    {
                                                        permission?.permission?.length > 0 &&
                                                        permission?.permission?.map((current, i) => {
                                                            return(
                                                                <option value={current.permission.id} key={i}> {current.permission.name}</option>
                                                            )
                                                        })
                                                    }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <label className="f-14 text-dark-grey mb-12" >Is this User Driver?</label>
                                            <sup className="text-danger">*</sup>
                                            <div className="form-group mb-0">
                                                <div className="select-picker">
                                                    <div className="btn-choice">
                                                        <label className="switch">
                                                        <input
                                                            type="checkbox"
                                                            className="switch-input"
                                                            id="toggle"
                                                            checked={isDriver} 
                                                            onClick={() => setIsDriver((isDriver) => !isDriver)}
                                                        />
                                                        <span className="switch-label" data-on="" data-off=""></span>
                                                        <span className="switch-handle"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                        </div>
                                        {/* <div className="col-lg-6">
                                            <label className="f-14 text-dark-grey mb-12" >User Type</label>
                                            <sup className="text-danger">*</sup>
                                            <div className="form-group mb-0">
                                                <div className="select-picker">
                                                    <select name="default_task_status" className="form-control select-picker" style={{height: '39px'}}
                                                    autoComplete='false' value={values.jobTitle}
                                                    onChange={handleChange('jobTitle')}>
                                                        <option value="Admin"> Administrator</option>
                                                        <option value="Dispatcher"> Dispatcher</option>
                                                        <option value="Driver"> Driver</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div> */}
                                                        {/* <option value="Operator"> Operator</option>
                                                        <option value="HR Specialist"> HR Specialist</option>
                                                        <option value="Mechanic"> Mechanic</option>
                                                        <option value="Biller"> Biller</option> */}
                                                        {/* <option value="Driver Group Owner"> Driver Group Owner</option>
                                                        <option value="Driver assistant"> Driver assistant</option> */}
                                        {
                                            // values.type !== "" &&
                                            // JSON.parse(values.type) === "Driver" && 
                                            // values &&
                                            // values.jobTitle ===  "Driver" &&
                                            isDriver === true &&
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label className="f-14 text-dark-grey mb-12">Asset#</label>
                                                    <sup className="text-danger">*</sup>
                                                    <div className="select-picker">
                                                        <select name="default_task_status" className="form-control select-picker" style={{height: '39px'}} required
                                                        value={values.vehicleId} onChange={handleChange('vehicleId')}>
                                                            <option value=""> Select the Asset#</option>
                                                            {
                                                            vehicle?.allVehicle?.length > 0 &&
                                                            vehicle?.allVehicle?.map((current, i) => {
                                                                return(
                                                                <option value={current.id}> {current.assetNo}</option>
                                                                )
                                                            })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            // values &&
                                            // values.jobTitle ===  "Driver" &&
                                            isDriver === true &&
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label className="f-14 text-dark-grey mb-12">Hire Date</label>
                                                    <sup className="text-danger">*</sup>
                                                    <input type="date" className="form-control height-35 f-14" placeholder="Enter Hire Date" required
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {/* {
                                            values &&
                                            values.jobTitle ===  "Driver" &&
                                            <div className="col-lg-6">
                                                <label className="f-14 text-dark-grey mb-12" >Default Payment Type</label>
                                                <sup className="text-danger">*</sup>
                                                <div className="form-group mb-0">
                                                    <div className="select-picker">
                                                        <select name="default_task_status" className="form-control select-picker" style={{height: '39px'}}
                                                        autoComplete='false' value={values.paymentType}
                                                        onChange={handleChange('paymentType')}>
                                                            <option value="0"> Select Mode of Payment</option>
                                                            <option value="flatrate"> Flat Rate </option>
                                                            <option value="hourlyrate"> Hourly Rate </option>
                                                            <option value="paymile"> Pay Per Mile</option>
                                                            <option value="dailyrate"> Daily Rate</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        } */}
                                        {
                                            values &&
                                            values.paymentType ===  "paymile" &&
                                            <div className="col-lg-6">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="form-group ">
                                                            <label className="f-14 text-dark-grey mb-12">Percentage %</label>
                                                            <sup className="text-danger">*</sup>
                                                            <input type="number" className="form-control height-35 f-14" placeholder="Enter %" 
                                                            autoComplete="false" value={values.percentageRage} onChange={handleChange('percentageRage')}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            values &&
                                            values.paymentType ===  "hourlyrate" &&
                                            <div className="col-lg-6">
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="f-14 text-dark-grey mb-12">Rate per Hour</label>
                                                            <sup className="text-danger">*</sup>
                                                            <input type="number" className="form-control height-35 f-14" placeholder="Enter the Amount Here"
                                                            autoComplete="false" value={values.hourlyRate} onChange={handleChange('hourlyRate')}
                                                            style={{width: '370px'}}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            values &&
                                            values.paymentType ===  "flatrate" &&
                                            <div className="col-lg-6">
                                                <div className="form-group ">
                                                    <label className="f-14 text-dark-grey mb-12">Rate</label>
                                                    <sup className="text-danger">*</sup>
                                                    <input type="text" className="form-control height-35 f-14" placeholder="Enter the Amount Here"
                                                    autoComplete="false" value={values.flatRate} onChange={handleChange('flatRate')}/>
                                                </div>
                                            </div>
                                        }
                                        {
                                            // values &&
                                            // values.jobTitle ===  "Driver" &&
                                            // <div className="col-lg-6">
                                            //     <div className="form-group">
                                            //         <label className="f-14 text-dark-grey mb-12">Services Type</label>
                                            //         <sup className="text-danger">*</sup>
                                            //         <div className="select-picker">
                                            //             <select name="default_task_status" className="form-control select-picker" style={{height: '39px'}}>
                                            //                 <option value="Ambulatory"> Ambulatory</option>
                                            //                 <option value="Wheelchair"> Wheelchair</option>
                                            //                 <option value="Stretcher"> Stretcher</option>
                                            //             </select>
                                            //         </div>
                                            //     </div>
                                            // </div>
                                        }
                                            {/* </div> */}
                                        {/* </div> */}
                                        <div className="col-md-12">
                                            <div className="form-group my-3">
                                                <label className="f-14 text-dark-grey mb-12">Address</label>
                                                {/* <sup className="text-danger">*</sup> */}
                                                <input type="text" className="form-control height-35 f-14" placeholder="Enter Your Address"
                                                autoComplete="false" value={values.address} onChange={handleChange('address')}/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group my-3">
                                                <label className="f-14 text-dark-grey mb-12">City</label>
                                                {/* <sup className="text-danger">*</sup> */}
                                                <input type="text" className="form-control height-35 f-14" placeholder="Enter Your City"
                                                autoComplete="false" value={values.city} onChange={handleChange('city')}/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group my-3">
                                                <label className="f-14 text-dark-grey mb-12">State</label>
                                                {/* <sup className="text-danger">*</sup> */}
                                                <input type="text" className="form-control height-35 f-14" placeholder="Enter Your State"
                                                autoComplete="false" value={values.state} onChange={handleChange('state')}/>
                                            </div>
                                        </div>
                                        {
                                            isDriver === false &&
                                            <div className="col-md-6">
                                                <div className="form-group my-3">
                                                    <label className="f-14 text-dark-grey mb-12">Phone Number</label>
                                                    {/* <sup className="text-danger">*</sup> */}
                                                    <input className="form-control height-35 f-14" 
                                                        minLength="14"
                                                        maxLength="14"
                                                        // required
                                                        onKeyDown={(e) => {
                                                            if (alphabets.includes(e.key)) {
                                                                e.preventDefault();
                                                            } 
                                                            if (e.code === "Backspace" && values.phone.length === 6){
                                                                setValues({
                                                                    ...values, phone:
                                                                    values.phone.substring(0,4)
                                                                })
                                                            }
                                                            if (e.code === "Backspace" && values.phone.length === 2){
                                                                setValues({
                                                                    ...values, phone:
                                                                    ''
                                                                })
                                                            }
                                                            if (e.code === "Backspace" && values.phone.length === 10){
                                                                setValues({
                                                                    ...values, phone:
                                                                    values.phone.substring(0,9)
                                                                })
                                                            }
                                                        }} name="phone" 
                                                        value={values.phone}
                                                        onChange={handlePhone('phone')}
                                                        placeholder="(123)-456-7890"
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {
                                            isDriver === true &&
                                            <div className="col-md-6">
                                                <div className="form-group my-3">
                                                    <label className="f-14 text-dark-grey mb-12">Phone Number</label>
                                                    <sup className="text-danger">*</sup>
                                                    <input className="form-control height-35 f-14" 
                                                        minLength="14"
                                                        maxLength="14"
                                                        required
                                                        onKeyDown={(e) => {
                                                            if (alphabets.includes(e.key)) {
                                                                e.preventDefault();
                                                            } 
                                                            if (e.code === "Backspace" && values.phone.length === 6){
                                                                setValues({
                                                                    ...values, phone:
                                                                    values.phone.substring(0,4)
                                                                })
                                                            }
                                                            if (e.code === "Backspace" && values.phone.length === 2){
                                                                setValues({
                                                                    ...values, phone:
                                                                    ''
                                                                })
                                                            }
                                                            if (e.code === "Backspace" && values.phone.length === 10){
                                                                setValues({
                                                                    ...values, phone:
                                                                    values.phone.substring(0,9)
                                                                })
                                                            }
                                                        }} name="phone" 
                                                        value={values.phone}
                                                        onChange={handlePhone('phone')}
                                                        placeholder="(123)-456-7890"
                                                    />
                                                </div>
                                            </div>
                                        }
                                        <div className="col-md-6">
                                            <div className="form-group my-3">
                                                <label className="f-14 text-dark-grey mb-12">Email</label>
                                                <sup className="text-danger">*</sup>
                                                <input type="email" className="form-control height-35 f-14" placeholder="Enter Email" required
                                                autoComplete="false" value={values.email} onChange={handleChange('email')}/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group my-3">
                                                <label className="f-14 text-dark-grey mb-12">Username</label>
                                                <sup className="text-danger">*</sup>
                                                <input type="text" className="form-control height-35 f-14" placeholder="Enter Username" required
                                                autoComplete="false" value={values.username} onChange={handleChange('username')}/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group my-3">
                                                <label className="f-14 text-dark-grey mb-12">Password</label>
                                                <sup className="text-danger">*</sup>
                                                <input type="password" className="form-control height-35 f-14" placeholder="Must contain atleast 1 number and letter. (min length: 8)" required
                                                autoComplete="false" value={values.password} onChange={handleChange('password')}/>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group " style={{marginTop: "1.5em !important"}}>
                                                <button type="submit" className="btn-primary rounded f-14 p-2 mr-3" 
                                                    // onClick={handleSubmit}
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
export default AdminAddUserComponent