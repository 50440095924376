import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { getAllUser } from "../../../actions/userActions";
import defaultimg from "../../../assets/images/profile.jpg";
import { useLocation } from "react-router-dom";
import AdminClientEditNewModal from "./AdminClientEditNewModal";

const AdminClientFullDetailComponent = () => {
  const { state } = useLocation();
  const users = useSelector((state) => state.users);
  const auth = useSelector((state) => state.auth);
  const [selectedImage, setSelectedImage] = useState(defaultimg);

  const dispatch = useDispatch();
  const userData = async (e) => {
    dispatch(
      getAllUser({ companyId: auth.user.companyId.id, isClient: true })
    );
  };
  useEffect(() => {
  if (auth.user !== null){
      
      // if (state.profilePic) {
      //     setSelectedImage(state.profilePic.fileUrl)
      // }
    }
  }, [auth.user])

  useEffect(() => {
    userData();
  }, []);

  const [showDropDown, setshowDropDown] = useState(false)
  const openDropDown = () => {
      setshowDropDown((showDropDown) => !showDropDown)
  }
  return (
    <>
      <div className="row emp-dash-detail">
        <div className="col-xl-12 col-md-6 mb-4 mb-lg-0">
          <div className="card border-0 b-shadow-4" >
            <div className="card-horizontal align-items-center">
              <div className="modal-body">
            {/* <div className="card-img" style={{borderRadius: "50%", margin: "0 auto 30px"}}> */}
                {/* <div className="profile-img-wrap edit-img">
                  {   
                    selectedImage &&
                    <img className="inline-block" src={selectedImage} alt="Profile Pic" />
                  }
                </div> */}
              </div>
              <div className="card-body border-0 pl-0">
                <div className="row">
                    {/* row kam ki? */}
                </div>
                    {/* <a className="edit-icon" onClick={openDropDown}>
                        <i className="fa fa-pencil"></i>
                    </a> */}
                {
                  auth?.user?.permissionId?.user?.update === true &&
                  <a className="edit-icon" onClick={openDropDown}>
                      <i className="fa fa-pencil"></i>
                  </a>
                }
                <div className="row p-3">
                  <div className="col-lg-6">
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                      <p className="mb-0  f-14 text-capitalize" ><strong>Full Name: {" "}</strong>{state?.firstName} {state?.lastName}</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                      {/* <div className="pro-edit"> */}
                        {/* <a className="edit-icon" onClick={openDropDown}>
                                <i className="fa fa-pencil"></i>
                            </a> */}
                        {/* </div> */}
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                      <p className="mb-0 f-14 text-capitalize"><strong>Phone: {" "}</strong>{state?.phone}</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                      <p className="mb-0  f-14 w-30 text-capitalize"><strong>Job Title: {" "}</strong>{state?.jobTitle}</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                      <p className="mb-0  f-14 text-capitalize"><strong>Email: {" "}</strong>{state?.email}</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                        <p className="mb-0  f-14 w-30 text-capitalize"><strong>User Type: {" "}</strong>{state?.role}</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                      <p className="mb-0  f-14 text-capitalize"><strong>Address: {" "}</strong>{state?.address}</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                      <p className="mb-0  f-14 text-capitalize"><strong>Username: {" "}</strong>{state?.username}</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                      <p className="mb-0  f-14 text-capitalize"><strong>City: {" "}</strong>{state?.city}</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                      <p className="mb-0  f-14 text-capitalize"><strong>Work Information: {" "}</strong>{state?.clientWorkInformation}</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                      <p className="mb-0  f-14 text-capitalize"><strong>State: {" "}</strong>{state?.state}</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                      <p className="mb-0  f-14 text-capitalize"><strong>Summary: {" "}</strong>{state?.summary}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AdminClientEditNewModal current={state} showDropDown={showDropDown} openDropDown={openDropDown}/>
    </>
  );
};
export default AdminClientFullDetailComponent;



// <div className="row emp-dash-detail">
// <div className="col-xl-6 col-md-6 mb-4 mb-lg-0">
//   <div className="card border-0 b-shadow-4">
//     <div className="card-horizontal align-items-center">
//       <div className="card-img">
//         <img className="" src={img1} alt="" />
//       </div>
//       <div className="card-body border-0 pl-0">
//         <div className="row">
//           <div className="col-10">
//             <h4 className="card-title f-15 f-w-500 text-darkest-grey mb-0">
//               {state?.firstName} {state?.lastName}
//             </h4>
//           </div>
//         </div>
//         <p className="f-13 font-weight-normal text-dark-grey mb-0">
//           {" "}
//           Job Title • {state?.jobTitle}{" "}
//         </p>
//         <p className="card-text f-12 ">Last login at -- </p>
//         <div className="card-footer bg-white border-top-grey pl-0">
//           <div className="d-flex flex-wrap justify-content-between">
//             <span>
//               <label
//                 className="f-11 text-dark-grey mb-12 text-capitalize"
//                 htmlFor="usr"
//               >
//                 Administrator
//               </label>
//               <p className="mb-0 f-18 f-w-500">{state?.jobTitle}</p>
//             </span>
//             <span>
//               <label
//                 className="f-11 text-dark-grey mb-12 text-capitalize"
//                 htmlFor="usr"
//               >
//                 Hours Logged
//               </label>
//               <p className="mb-0 f-18 f-w-500">13</p>
//             </span>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
// <div className="col-xl-6 col-md-6 mb-4 mb-lg-0">
//   <div className="card bg-white border-0 b-shadow-4 mb-4">
//     <div className="card-header bg-white border-0 text-capitalize d-flex justify-content-between p-20">
//       <h4 className="f-18 f-w-500 mb-0">Profile Info</h4>
//     </div>
//     <div className="card-body ">
//       <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
//         <p className="mb-0  f-14 w-30 text-capitalize">Full Name</p>
//         <p className="mb-0 text-dark-grey f-14 w-70 text-wrap">
//           {state?.firstName} {state?.lastName}
//         </p>
//       </div>
//       <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
//         <p className="mb-0  f-14 w-30 text-capitalize">Email</p>
//         <p className="mb-0 text-dark-grey f-14 w-70 text-wrap">
//           {state?.email}{" "}
//         </p>
//       </div>
//       <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
//         <p className="mb-0  f-14 w-30 text-capitalize">Phone Number</p>
//         <p className="mb-0 text-dark-grey f-14 w-70 text-wrap">
//           {state?.phone}
//         </p>
//       </div>
//       <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
//         <p className="mb-0 f-14 w-30 text-capitalize">User Type</p>
//         <p className="mb-0 text-dark-grey f-14 w-70 text-wrap">
//           {state?.role}
//         </p>
//       </div>{" "}
//       <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
//         <p className="mb-0  f-14 w-30 text-capitalize">Department</p>
//         <p className="mb-0 text-dark-grey f-14 w-70 text-wrap">
//           {state?.jobTitle}
//         </p>
//       </div>
//       <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
//         <p className="mb-0  f-14 w-30 text-capitalize">Address</p>
//         <p className="mb-0 text-dark-grey f-14 w-70 text-wrap">
//           {state?.address}
//         </p>
//       </div>
//       <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
//         <p className="mb-0  f-14 w-30 text-capitalize">City</p>
//         <p className="mb-0 text-dark-grey f-14 w-70 text-wrap">
//           {state?.city}{" "}
//         </p>
//       </div>
//       <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
//         <p className="mb-0  f-14 w-30 text-capitalize">State</p>
//         <p className="mb-0 text-dark-grey f-14 w-70 text-wrap">
//           {state?.state}
//         </p>
//       </div>
//       <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
//         <p className="mb-0  f-14 w-30 text-capitalize">Username</p>
//         <p className="mb-0 text-dark-grey f-14 w-70 text-wrap">
//           {state.username}
//         </p>
//       </div>
//     </div>
//   </div>
// </div>
// </div>