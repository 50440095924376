import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import AdminReportSearchTableRow from './AdminReportSearchTableRow'
import moment from 'moment'
import ExcelExport from '../../../ExcelExport'
// import { useEffect } from 'react'
// import shift1 from "../../../assets/images/load.png"
// import { reservationReportSearch } from '../../../actions/kpiActions'

const AdminReportSearchTable = () => {
    const kpi = useSelector((state) => state.kpi)
    const auth = useSelector((state) => state.auth)
    const [ dropDownRow, setDropDownRow ] = useState(null)
    const [ closeDropDownRow, setCloseDropDownRow ] = useState(null)
    const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false)
    const openMoreVertDropDown = () => {
        setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown)
    }
    const dispatch = useDispatch()
    // const reportData = async (e) => {
    //     dispatch(reservationReportSearch({companyId: auth.user.companyId.id }))
    // }
    // useEffect(() => {
    //     reportData()
    // },[])
    // console.log(kpi, "djdjdjjdjjdjdjdjdjdjdjdjdjdjdjdjdjdjd")
    const data = kpi?.reservationReportSearch?.map(item=>({
        "First Name": item.reservation?.customerId?.firstName,
        "Last Name": item.reservation?.customerId?.lastName,
        "Order Id": item.reservation?.orderId,
        "Order Mode": item.reservation?.type.name,
        "Pick Up Address": item.reservation?.pickUpAddress?.address,
        "Pick Up Latitude": item.reservation?.pickUpAddress?.latitude,
        "Pick Up Longitude": item.reservation?.pickUpAddress?.longitude,
        "Order Drop Off Address": item.reservation?.dropOffAddress?.address,
        "Order Drop Off Latitude": item.reservation?.dropOffAddress?.latitude,
        "Order Drop Off Longitude": item.reservation?.dropOffAddress?.longitude,
        "Pick Up Date": item.reservation?.pickUpDate.toString().substring(0,10),
        "Pick Up Time": item.reservation?.pickUpTime,
        "Order Drop Off Date": item.reservation?.dropOffDate.toString().substring(0,10),
        "Order Drop Off Time": item.reservation?.dropOffTime,
        "Asset No": item.reservation?.assetId?.assetNo,
        "Driver First Name": item.reservation?.driverId?.firstName,
        "Driver Last Name": item.reservation?.driverId?.lastName,
        "Distance of Base Location to Pickup": item.reservation?.distanceFromBaseLocation,
        "Distance of Pickup to Dropoff": item.reservation?.distanceFromPickToDropOff,
        "Total Order Distance": item.reservation?.totalMileage,
        "Price": item.reservation?.totalAmount,
        "Status": item.reservation?.status,
        "Load Number": item.reservation?.loadNumber,
        "External Client Number": item.reservation?.externalClientId,
        "Trip Status": item.trip?.externalClientId,
        "Trip Start Time": item.trip?.tripStartTime?.toString().substring(0,10) + item.trip?.tripStartTime?.toString().substring(11,16),
        "Arrived At Customer Pick Up": item.trip?.arrivedTime?.toString().substring(0,10) + item.trip?.arrivedTime?.toString().substring(11,16),
        "Ride Start": item.trip?.pickUpTime?.toString().substring(0,10) + item.trip?.pickUpTime?.toString().substring(11,16),
        "Ride End": item.trip?.dropOffTime?.toString().substring(0,10) + item.trip?.dropOffTime?.toString().substring(11,16),
        "Actual Pick Up Latitude": item.trip?.pickUpLat,
        "Actual Pick Up Longitude": item.trip?.pickUpLng,
        "Actual Order Drop Latitude": item.trip?.dropOffLat,
        "Actual Order Drop Longitude": item.trip?.dropOffLng,
        "Min Distance By Google": item?.min,
        "Max Distance By Google": item?.max,
        "Avg Distance of all the Routes By Google": item?.avg,
    }))
    return (
        <>
            <div className="d-flex flex-column w-tables rounded mt-3 bg-white">
                <div className="d-flex border">
                    <div className="flex-grow-1">
                        <div className="dataTables_length d-flex" id="leads-table_length">
                            <div className="col-md-12 text-right">
                            {
                                kpi &&
                                kpi.reservationReportSearch &&
                                kpi.reservationReportSearch.length > 0 &&
                                <ExcelExport excelData={data} fileName={`Report ${moment().format('MM/DD/YYYY')}`}/>
                            }
                            </div>
                        </div>
                    </div>
                </div>
                <div id="leads-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div className="row">
                        <div className="col-sm-12">
                            <table className="table border w-100 dataTable no-footer" id="leads-table" role="grid" aria-describedby="leads-table_info" style={{ overflowX: "auto" }}>
                                <thead className="card-header">
                                    <tr role="row">
                                        <th title="(Res#)" className="sorting border-right" tabIndex="0" aria-controls="leads-table" rowSpan="1" colSpan="1" aria-label="Mobile: activate to sort column ascending">
                                            Order ID
                                        </th>
                                        <th title="Vehicle" className="sorting_disabled border-right" rowSpan="1" colSpan="1" aria-label="Next Follow Up">
                                            Asset#
                                        </th>
                                        <th title="Time" className="sorting border-right" tabIndex="0" aria-controls="leads-table" rowSpan="1" colSpan="1" aria-label="Lead Agent: activate to sort column ascending">
                                            Time
                                        </th>
                                        <th title="Passenger" className="sorting border-right" tabIndex="0" aria-controls="leads-table" rowSpan="1" colSpan="1" aria-label="Lead Agent: activate to sort column ascending">
                                            Passenger
                                        </th>
                                        <th title="Pickup Drop Off" className="sorting border-right w-30" tabIndex="0" aria-controls="leads-table" rowSpan="1" colSpan="1" aria-label="Lead Agent: activate to sort column ascending">
                                            Pickup Drop Off
                                        </th>
                                        <th title="Vehicle/Driver" className="sorting border-right" tabIndex="0" aria-controls="leads-table" rowSpan="1" colSpan="1" aria-label="Lead Agent: activate to sort column ascending">
                                            Vehicle Type
                                        </th>
                                        <th title="Price" className="sorting border-right" tabIndex="0" aria-controls="leads-table" rowSpan="1" colSpan="1" aria-label="Lead Agent: activate to sort column ascending">
                                            Price
                                        </th>
                                        <th title="Status" className="sorting border-right" tabIndex="0" aria-controls="leads-table" rowSpan="1" colSpan="1" aria-label="Lead Agent: activate to sort column ascending">
                                            Status
                                        </th>
                                        {/* <th title="Action" className="text-right pr-20 sorting_disabled border-right" rowSpan="1" colSpan="1" aria-label="Action">
                                            Actions
                                        </th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        kpi &&
                                        kpi.reservationReportSearch &&
                                        // kpi.reservationReportSearch.reservation &&
                                        kpi.reservationReportSearch.length > 0 &&
                                        kpi.reservationReportSearch.map((current, i) => {
                                            // if (current.delete === false) {
                                            return(
                                            <AdminReportSearchTableRow current={current} i={i}/>
                                            )
                                        // }
                                        })
                                    }
                                </tbody>
                            </table>
                            {/* {
                                <div className="dash-stats-list" style={{ textAlign: "center" }}>
                                    <br />
                                    <br />
                                    <img
                                        alt=""
                                        src={shift1}
                                        style={{ width: "145px", opacity: "0.5" }} />
                                    <br />
                                    <p style={{ opacity: "0.5" }}>
                                        No Search or No Report Found
                                    </p>
                                </div>
                            } */}
                            <div id="leads-table_processing" className="dataTables_processing card" style={{ display: "none" }}>Processing...</div>
                        </div>
                    </div>
                    <div className="d-flex border">
                        {/* <div className="flex-grow-1">
                            <div className="dataTables_info" id="leads-table_info" role="status" aria-live="polite">Showing 1 to 10 of 20 entries</div>
                        </div>
                        <div>
                            <div className="dataTables_paginate paging_simple_numbers" id="leads-table_paginate">
                                <ul className="pagination">
                                    <li className="paginate_button page-item previous disabled" id="leads-table_previous">
                                        <Link to="" aria-controls="leads-table" data-dt-idx="0" tabIndex="0" className="page-link">Previous</Link>
                                    </li>
                                    <li className="paginate_button page-item active"><Link to="" aria-controls="leads-table" data-dt-idx="1" tabIndex="0" className="page-link">1</Link>
                                    </li>
                                    <li className="paginate_button page-item "><Link to="" aria-controls="leads-table" data-dt-idx="2" tabIndex="0" className="page-link">2</Link>
                                    </li>
                                    <li className="paginate_button page-item next" id="leads-table_next"><Link to="" aria-controls="leads-table" data-dt-idx="3" tabIndex="0" className="page-link">Next</Link>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}
export default AdminReportSearchTable