import React, { useEffect, useState } from 'react'
// import defaultMusic from '../../../assets/music/notification_default.mp3'
// import tune2 from '../../../assets/music/notifications-sound-2.mp3'
// import tune3 from '../../../assets/music/notification-sound-3.mp3'
import { SERVERADDRESS } from '../../../constants'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { updateCompany } from '../../../actions/authActions'

const AdminOtherSettingsComponent = () => {
  const auth = useSelector((state) => state.auth);
  let play = document.getElementById("play")
  // const data = [
  //   {
  //     "id": 1,
  //     "tune": defaultMusic,
  //     "name": "default"
  //   },
  //   {
  //     "id": 2,
  //     "tune": tune2,
  //     "name": "tune 2"
  //   },
  //   {
  //     "id": 3,
  //     "tune": tune3,
  //     "name": "tune 3"
  //   }
  // ]
  const [ data, setData ] = useState([])
  const tuneDate = async () => {
    const res = await axios.get(`${SERVERADDRESS}/v1/tune/allTunes`) 
    // console.log("res.data=>", res.data)
    setData(res.data)
  }
  const [values, setValues] = useState({
    id : "",
    tune : "",
    name: ""
  })
  useEffect(() => {
    tuneDate()
    setValues(auth?.user?.companyId?.tuneId)
  },[])
  const handleChange = (fieldName) => (event) => {
    const newTune = data.find(x => x.id == event.target.value)
    // console.log("newTune=>", newTune)
    setValues(newTune)
  }
  function playMusic(event) {
    event.preventDefault();
    document.getElementById("musicButton").disabled = true;
    let audio = new Audio(values.tune)
    // console.log(values.tune, defaultMusic, audio.play())
    audio.addEventListener('ended', () => {
      document.getElementById("musicButton").disabled = false;
    });
    audio.play()
    setTimeout(function () {
      document.getElementById("musicButton").disabled = false;
    }, audio.duration * 10000)
  }
  // play.addEventListener("click", playMusic)
  const [isPlaying, setIsPlaying] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const handlePlay = () => {
    setIsPlaying(true);
    setIsButtonDisabled(true);
    const audio = new Audio(values.tune);
    audio.addEventListener('ended', () => {
      setIsPlaying(false);
      setIsButtonDisabled(false);
    });
    audio.play();
  };
  const dispatch = useDispatch()
  const handleSubmit = async (event) => {
    event.preventDefault()
    dispatch(updateCompany({
      companyId: auth.user.companyId.id,
      tuneId: values.id,
    }))
  }
  return (
    <>
      {
        data.length > 0 &&
        <div className="d-flex flex-column w-tables rounded mt-3 bg-white">
          <div
            id="leads-table_wrapper"
            className="dataTables_wrapper dt-bootstrap4 no-footer"
            >
            <form 
              onSubmit={handleSubmit}
              >
              <div className="row p-20">
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-xl-8 col-lg-12 col-md-12 e-d-info-notices">
                      <div className="row">
                        <div className="col-sm-6 pl-5">
                        <div
                          className="form-group "
                          style={{ marginTop: "1.5em !important" }}
                          >
                          <div className="form-group my-3">
                            <div className="d-flex">
                              <div>
                                <label
                                  className="f-17 text-dark mb-12"
                                  data-label=""
                                  htmlFor="clnumber"
                                  >
                                  Set Notification Tune
                                </label>
                              </div>
                              <div className="p-2">
                              </div>
                            </div>
                            <div className="row">
                              {/* <button className='fa fa-play-circle-o tn-lightest-grey rounded f-12 p-2 mr-3' style={{fontSize: "22px", color: "#686868"}}
                                onClick={playMusic} id='musicButton'
                              >  </button> */}

                              <button className='fa fa-play-circle-o tn-lightest-grey rounded f-12 p-2 mr-3' style={{fontSize: "22px", color: "#686868"}}
                              onClick={handlePlay} disabled={isButtonDisabled}
                              >  </button>
                              {/* <button onClick={handlePlay} disabled={isButtonDisabled}>
                                {isPlaying ? 'Playing...' : 'Play'}
                              </button> */}
                            <div className="select-picker">
                              <select 
                                className="form-control select-picker"
                                style={{ height: "39px", fontSize: "14px" }}
                                defaultValue={values.id}
                                onChange={handleChange("id")}
                                // required
                                >
                                {/* <option value="" key={"st"}>
                                  {" "}
                                  <i className="icon-options-vertical icons">
                                    Default Notification
                                  </i> 
                                </option>
                              <option value="">Default Notification</option>
                              <option value="">2</option>
                              <option value="">3</option> */}
                              {
                                data.map((current, i) => {
                                  return(
                                    <option value={current.id} key={i}>{current.name}</option>
                                  )
                                })
                              }
                              </select>
                            </div>
                            </div>
                          </div>
                        </div>
                      </div>
                        
                      </div>
                      <br />
                      <div className="col-lg-12">
                        <div
                          className="form-group "
                          style={{ marginTop: "1.5em !important" }}
                          >
                          <button
                            type="submit"
                            className="btn-primary rounded f-14 p-2 mr-3"
                            // onClick={handleSubmit}
                            >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      }
    </>
  )
}
export default AdminOtherSettingsComponent