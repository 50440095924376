const initialState = {
  viewPayment: "baselocation",
  viewReport: "basicReportRow",
  viewNavBar: "home",
};

export const changeTheTab = (state = initialState, action) => {
  switch (action.type) {
    case "TAB_BASELOCATION":
      return {
        ...state,
        viewPayment: "baselocation",
      };
    case "TAB_ADDSERVICES":
      return {
        ...state,
        viewPayment: "AddServices",
      };
    case "TAB_AMBULATORY":
      return {
        ...state,
        viewPayment: "Ambulatory",
      };
    case "TAB_WHEELCHAIR":
      return {
        ...state,
        viewPayment: "Wheelchair",
      };
    case "TAB_STRETCHER":
      return {
        ...state,
        viewPayment: "Stretcher",
      };
    case "TAB_FARELIST":
      return {
        ...state,
        viewPayment: "fareList",
      };

    case "TAB_BASIC_REPORT_ROW":
      return {
        ...state,
        viewReport: "basicReportRow",
      };
    case "TAB_REPORT_SEARCH":
      return {
        ...state,
        viewReport: "basicReportSearch",
      };

    case "TAB_CALCULATOR_REPORT_ROW":
      return {
        ...state,
        viewReport: "calculatorReportRow",
      };
    
    // general website tab actions
    case "TAB_HOME" : return {
        ...state,
        viewNavBar : "home" 
    }
    case "TAB_WHYUS" : return {
        ...state,
        viewNavBar : "whyus"
    }
    case "TAB_SERVICES" : return {
        ...state,
        viewNavBar : "services"
    }

    case "TAB_FAQPAGE" : return {
        ...state,
        viewNavBar : "faq"
    }
    case "TAB_CONTACTUS" : return {
        ...state,
        viewNavBar : "contactus"
    }
    case "TAB_SIGNIN" : return {
        ...state,
        viewNavBar : "signin"
    }

    default:
      return state;
  }
};
