import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { navigateUserFunc } from '../../../actions/generalActions'
import AdminHeader from '../../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminHeader'
import AdminSidebar from '../../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminSidebar'
import NotAllowed from '../../../components/NotAllowed'
import AdminRouteLogTableComponent from './AdminRouteLogTableComponent'

const AdminRoutesLogPage = () => {
    const auth = useSelector((x) => x.auth);
    let navigate = useNavigate();
    useEffect(() => {
        if (auth?.user?.permissionId?.dashboard?.isAllow === false){
            navigateUserFunc(auth?.user, navigate)
        }
    },[])
    return (
        <>
            <AdminHeader />
            <AdminSidebar />
            <div className="body-wrapper clearfix">
                <section className="main-container " id="fullscreen">
                    <div className="px-4 py-2 border-top-0 emp-dashboard">
                        <div className="d-block d-lg-flex d-md-flex justify-content-between action-bar mb-20 mt-3">
                            <div id="table-actions" className="flex-grow-1 align-items-center">
                                <h4 className=" mb-0 f-21 text-capitalize font-weight-bold"> Routes Log </h4>
                            </div>
                        </div>
                        {
                            auth?.user?.permissionId?.routes?.read === true ?
                            <AdminRouteLogTableComponent />
                            :
                            <NotAllowed />
                        }
                    </div>
                </section>
            </div>
        </>
    )
}
export default AdminRoutesLogPage