import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AdminClientnUserDelete from "../adminUserComponents/AdminClientnUserDelete";
import AdminClientEditNewModal from "./AdminClientEditNewModal";

const AdminClientViewTableRow = ({
  current,
  i,
  dropDownRow,
  setDropDownRow,
  handleModal,
}) => {
  const vehicle = useSelector((state) => state.vehicle);
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };
  const navigate = useNavigate();
  const [showDropDown, setshowDropDown] = useState(false)
  const [showDeleteDropDown, setshowDeleteDropDown] = useState(false)
  const dispatch = useDispatch()
  // const openMoreVertDropDown = () => {
      // setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown)
  // }
  const openDropDown = () => {
      setshowDropDown((showDropDown) => !showDropDown)
  }
  const openDeleteDropDown = () => {
      setshowDeleteDropDown((showDeleteDropDown) => !showDeleteDropDown)
  }
  const auth = useSelector((state) => state.auth);

  return (
    <>
      <tr
        id="row-20"
        role="row"
        className="odd border"
        key={i}
        onMouseLeave={() => setshowMoreVertDropDown(false)}
      >
        <td className="border-right">
          {current.firstName} {current.lastName}
        </td>
        <td className="border-right">{current.email}</td>
        <td className="border-right">{current.phone}</td>
        {/* <td className="border-right">{current.workPhone}</td>
        <td className="border-right">{current.otherPhone}</td>
        <td className="border-right">{current.clientWorkInformation}</td> */}
        <td className="border-right">{current.address}</td>
        <td className="border-right">{current.summary}</td>
        <td className="border-right">
          {current.disable.toString() === "false" ? "Active" : "InActive"}
        </td>
        <td className=" text-right pr-20">
          <div className="task_view">
            <div className="dropdown">
              <Link
                className="task_view_more d-flex align-items-center justify-content-center dropdown-toggle"
                type="link"
                data-toggle="dropdown"
                onClick={openMoreVertDropDown}
                aria-haspopup="true"
                aria-expanded="false"
                to=""
              >
                <i className="icon-options-vertical icons"></i>
              </Link>
              <div
                className={`dropdown-menu dropdown-menu-right ${
                  showMoreVertDropDown
                    ? "dropdown-menu dropdown-menu-right show"
                    : "dropdown-menu dropdown-menu-right"
                }`}
                aria-labelledby="dropdownMenuLink-20"
              >
                <button
                  onClick={() =>
                    navigate(`/client-view/${current.id}`, {
                      state: current,
                    })
                  }
                  className="dropdown-item"
                >
                  <i className="fa fa-eye"> View</i>
                </button>
                {
                  auth?.user?.permissionId?.clients?.update === true &&
                  <button
                    // to=""
                    className="dropdown-item"
                    // onClick={() => navigate(`/edit-client`, { state: current })}
                    // onClick={() => handleModal(current)}
                    onClick={openDropDown}
                  >
                    <i className="fa fa-edit"> Edit</i>
                  </button>
                }
                {
                  auth?.user?.permissionId?.clients?.delete === true &&
                  <Link
                    className="dropdown-item delete-table-row"
                    to=""
                    data-id="20"
                    onClick={openDeleteDropDown}
                  >
                    <i className="fa fa-trash"> Delete</i>
                  </Link>
                }
              </div>
            </div>
          </div>
        </td>
        <AdminClientEditNewModal current={current} 
        showDropDown={showDropDown} 
        openDropDown={openDropDown}/>

        <AdminClientnUserDelete current={current} 
        showDeleteDropDown={showDeleteDropDown} 
        openDeleteDropDown={openDeleteDropDown}
        index={i}
        isClient={"true"}/>
      </tr>
    </>
  );
};
export default AdminClientViewTableRow;
