import React from "react";
import { Link } from "react-router-dom";

function RightModal({ open, handleModal, children }) {
  return (
    <>
      <div
        className={`task-overlay w-100 h-100 ${open ? "in" : ""}`}
        id="close-task-detail-overlay"
        onClick={() => handleModal(null)}
      ></div>
      <div
        className={`task-detail-panel bg-additional-grey ${open ? "in" : ""}`}
        id="task-detail-1"
      >
        <Link
          className={`close-task-detail ${open ? "in" : ""}`}
          id="close-task-detail"
          onClick={() => handleModal(null)}
          // style={{ display: "none" }}
        >
          <span>
            <i className="fa fa-times"></i>
          </span>
        </Link>
        <div className="p-4 t-d-inner-panel">
          <h3 className="heading-h1 mb-0" id="right-modal-title"></h3>

          <div id="right-modal-content" className="mt-4">
            {children}
          </div>
        </div>
      </div>
    </>
  );
}

export default RightModal;
