import React, { useEffect, useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import AdminUserEditNewModal from './AdminUserEditNewModal'
import AdminClientnUserDelete from './AdminClientnUserDelete'
import defaultimg from '../../../assets/images/profile.jpg'
import { editClientAction } from '../../../actions/userActions'

const AdminUserTableComponentRow = ({current, i, handleModal, 
    dropDownRow, setDropDownRow}) => {
    const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false)
    const openMoreVertDropDown = () => {
        setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown)
    }
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [selectedImage, setSelectedImage] = useState(defaultimg);
    const [showDropDown, setshowDropDown] = useState(false)
    const [showDeleteDropDown, setshowDeleteDropDown] = useState(false)
    const openDropDown = () => {
        setshowDropDown((showDropDown) => !showDropDown)
    }
    const openDeleteDropDown = () => {
        setshowDeleteDropDown((showDeleteDropDown) => !showDeleteDropDown)
    }
    const auth = useSelector((x) => x.auth);

    const [values, setValues] = useState({
        disable: false,
    });

    const currentUserData = async () => {
        try {
            setValues({
                disable: '',
            })
            setValues({
                id: current.id,
                disable: current.disable,
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        currentUserData()
    }, [current]);

    const userData = {
        disable: values.disable,
        userId: values.id,

    }
    const handleChange = (fieldName) => (event) => {
        setValues({
            ...values, [fieldName]:
            event.target.value
        })
    dispatch(editClientAction({
        disable: event.target.value,
        userId: values.id
    }))
    }
    // console.log(current, "yhy hy h yh yh yh yh");

  return (
    <>
        <tr id="row-20" role="row" className="odd border" key={i} onMouseLeave={() => setshowMoreVertDropDown(false)}>
            <td className="border-right">
                <h2 className="table-avatar">
                    <NavLink to={`/profile/${current.id}`} className="avatarr">
                        {
                            current.profilePic &&
                            current.profilePic.fileUrl ?
                            <img className="inline-block" src={current?.profilePic?.fileUrl} alt="Profile Pic" style={{borderRadius: "50%", height: "100%"}}/>
                                :
                            <img className="inline-block" src={selectedImage} alt="Profile Pic" style={{borderRadius: "50%", height: "100%"}}/>
                        }
                    </NavLink>
                    <NavLink to="#">{" "}{`${current.firstName} ${current.lastName}`}</NavLink>
                </h2>
            </td>
            {/* <td className="border-right">{current.lastName}</td> */}
            <td className="border-right">{current?.permissionId?.name}</td>
            {/* <td className="border-right">{current.address}</td> */}
            <td className="border-right">{current.city}</td>
            <td className="border-right">{current.state}</td>
            <td className="border-right">{current.phone}</td>
            <td className="border-right">{current.email}</td>
            <td className="border-right">{current.username}</td>
            <td className="border-right">
                <div className="select-picker">
                    {
                    current.disable === false ?
                        <select
                            className="form-control select-picker assign_role"
                            style={{ height: "35px" }}
                            value={values.disable}
                            onChange={handleChange("disable")}
                        >
                            <option value="false">Active</option>
                            <option value="true">InActive</option>
                        </select>
                    :
                        <select
                            className="form-control select-picker assign_role"
                            style={{ height: "35px" }}
                            value={values.disable}
                            onChange={handleChange("disable")}
                        >
                            <option value="false">Active</option>
                            <option value="true">InActive</option>
                        </select>
                    }
                </div>
            </td>
            <td className=" text-right pr-20">
                <div className="task_view"> 
                    <div className="dropdown"> 
                        <Link className="task_view_more d-flex align-items-center justify-content-center dropdown-toggle" type="link" data-toggle="dropdown" onClick={openMoreVertDropDown} aria-haspopup="true" aria-expanded="false"> 
                            <i className="icon-options-vertical icons"></i> 
                        </Link> 
                        <div className={`dropdown-menu dropdown-menu-right ${showMoreVertDropDown ? 'dropdown-menu dropdown-menu-right show' : 'dropdown-menu dropdown-menu-right'}`} aria-labelledby="dropdownMenuLink-20">
                            <button onClick={() => navigate(`/user-view/${current.id}`, 
                            {
                                state: current,
                                    }
                                    )}
                                    className="dropdown-item">
                                <i className="fa fa-eye"> View</i>
                            </button>
                            {
                                auth?.user?.permissionId?.user?.update === true &&
                                <Link className="dropdown-item" onClick={openDropDown}> 
                                    <i className="fa fa-edit"> Edit</i>
                                </Link>
                            }
                            {
                                auth?.user?.permissionId?.user?.delete === true &&
                                <Link className="dropdown-item delete-table-row" to="" data-id="20" onClick={openDeleteDropDown}> 
                                    <i className="fa fa-trash"> Delete</i>
                                </Link>
                            }
                        </div> 
                    </div> 
                </div>
            </td>
        <AdminUserEditNewModal current={current} showDropDown={showDropDown} openDropDown={openDropDown}/>
        <AdminClientnUserDelete current={current} 
        showDeleteDropDown={showDeleteDropDown} 
        openDeleteDropDown={openDeleteDropDown}
        index={i}
        isClient={"false"}/>
        </tr>
        {/* <RightModal open={modal.open} handleModal={handleModal}>
        {modal.open && (
          <AdminClientEditComponent
            state={modal.data}
            handleModal={handleModal}
          />
        )}
      </RightModal> */}
    </>
  )
}
export default AdminUserTableComponentRow