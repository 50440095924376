import React, { forwardRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { reservationReportSearch } from '../../../actions/kpiActions';
import { tabReportSearch } from '../../../actions/tabActions';
// import ExcelExport from '../../../ExcelExport';

const AdminReportSearchandFilter = () => {
    const auth = useSelector((state) => state.auth);
    const kpi = useSelector((state) => state.kpi);
    const dispatch = useDispatch()
    const [values, setValues] = useState({
        companyId: '',
        name: '',
        loadNumber:'',
        // date: '',
        from:'',
        to:'',
    })
    const handleChange = (fieldName) => (event) => {
        setValues({
            ...values, [fieldName]:
                event.target.value
        })
    }
    const [fromDate, setFromDate] = useState(new Date(moment().format('YYYY-MM-DD')));
    const [toDate, setToDate] = useState(new Date(moment().format('YYYY-MM-DD')));
    const From = forwardRef(({ value, onClick }, ref) => (
        <div className="form-group form-focus focused" onClick={onClick} ref={ref}>
            <div className="cal-icon" >
                <input className="form-control floating datetimepicker" type="text" value={value} />
            </div>
            <label className="focus-label">From</label>
        </div>
    ));
    const To = forwardRef(({ value, onClick }, ref) => (
        <div className="form-group form-focus focused" onClick={onClick} ref={ref}>
            <div className="cal-icon" >
                <input className="form-control floating datetimepicker" type="text" value={value} />
            </div>
            <label className="focus-label">To</label>
        </div>
    ));
    let reportSearch;
    // if (values.location !== ''){
    reportSearch = {
        companyId: auth.user.companyId.id,
        // companyId: auth.user.companyId.id,
        name: values.name,
        loadNumber: values.loadNumber,
        // date: moment().format('YYYY-MM-DD'),
        from: values.from,
        // from: fromDate.toISOString().substring(0, 10),
        // to: toDate.toISOString().substring(0, 10),
        to:values.to,
    }
    // console.log(toDate.toISOString(), "toDate.toISOString()")
    // console.log(kpi, "djdjdjdjdjjd jddjjdjd jd dj dj djd jd dj")
    // console.log(values)

    const [ reportTypeObj, setReportTypeObj ] = useState([])
    // const handleLeaveType = (fieldName) => (event) =>{
    // console.log(event.target.value)
    // const abc = JSON.parse(event.target.value)
    //     if (abc !== null){
    //         if (values.reportType.includes(abc.id) === false){
    //             console.log(abc)
    //             setReportTypeObj([...reportTypeObj,abc])
    //             setValues({
    //                 ...values, [fieldName]:
    //                 [...values.reportType, abc.id]
    //             })
    //         }
    //     }
    // }
    const handleClick = async (event) => {
        event.preventDefault()
        for (let key of Object.keys(reportSearch)) {
            if(reportSearch[key] === '' ){
                delete reportSearch[key]
            }
            // console.log(key,"54121421521", reportSearch[key])
        }
        
        dispatch(tabReportSearch())
        // dispatch(reservationReportSearch(reportSearch, arrReassign))
        dispatch(reservationReportSearch(reportSearch))
    }
    // const [newData, setNewData] = useState([])
    // const arrReassign = () => {
        // const data = kpi?.reservationReportSearch?.map(item=>({
        //     "First Name": item.reservation?.customerId?.firstName,
        //     "Last Name": item.reservation?.customerId?.lastName,
        //     "Order Id": item.reservation?.orderId,
        //     "Order Mode": item.reservation?.type.name,
        //     "Pick Up Address": item.reservation?.pickUpAddress?.address,
        //     "Pick Up Latitude": item.reservation?.pickUpAddress?.latitude,
        //     "Pick Up Longitude": item.reservation?.pickUpAddress?.longitude,
        //     "Order Drop Off Address": item.reservation?.dropOffAddress?.address,
        //     "Order Drop Off Latitude": item.reservation?.dropOffAddress?.latitude,
        //     "Order Drop Off Longitude": item.reservation?.dropOffAddress?.longitude,
        //     "Pick Up Date": item.reservation?.pickUpDate.toString().substring(0,10),
        //     "Pick Up Time": item.reservation?.pickUpTime,
        //     "Order Drop Off Date": item.reservation?.dropOffDate.toString().substring(0,10),
        //     "Order Drop Off Time": item.reservation?.dropOffTime,
        //     "Asset No": item.reservation?.assetId?.assetNo,
        //     "Driver First Name": item.reservation?.driverId?.firstName,
        //     "Driver Last Name": item.reservation?.driverId?.lastName,
        //     "Distance of Base Location to Pickup": item.reservation?.distanceFromBaseLocation,
        //     "Distance of Pickup to Dropoff": item.reservation?.distanceFromPickToDropOff,
        //     "Total Order Distance": item.reservation?.totalMileage,
        //     "Price": item.reservation?.totalAmount,
        //     "Status": item.reservation?.status,
        //     "Load Number": item.reservation?.loadNumber,
        //     "External Client Number": item.reservation?.externalClientId,
        //     "Trip Status": item.trip?.externalClientId,
        //     "Trip Start Time": item.trip?.tripStartTime.toString().substring(0,10) + item.trip?.tripStartTime.toString().substring(11,16),
        //     "Arrived At Customer Pick Up": item.trip?.arrivedTime.toString().substring(0,10) + item.trip?.arrivedTime.toString().substring(11,16),
        //     "Ride Start": item.trip?.pickUpTime.toString().substring(0,10) + item.trip?.pickUpTime.toString().substring(11,16),
        //     "Ride End": item.trip?.dropOffTime.toString().substring(0,10) + item.trip?.dropOffTime.toString().substring(11,16),
        //     "Actual Pick Up Latitude": item.trip?.pickUpLat,
        //     "Actual Pick Up Longitude": item.trip?.pickUpLng,
        //     "Actual Order Drop Latitude": item.trip?.dropOffLat,
        //     "Actual Order Drop Longitude": item.trip?.dropOffLng,
        //     "Min Distance By Google": item?.min,
        //     "Max Distance By Google": item?.max,
        //     "Avg Distance of all the Routes By Google": item?.avg,
        // }))
    //     setNewData(data)
    // }
    // console.log(newData, "newData newData")
    
    return (
        <>
            <div className="row p-3">
                <div className="col-lg-3">
                    <div className="form-group my-3">
                        <label className="f-14 text-dark-grey mb-12" data-label="">Client Name</label>
                        <input type="text" placeholder="Search Customer Name" className="form-control height-35 f-14"
                        value={values.name} onChange={handleChange('name')}/>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="form-group my-3">
                        <label className="f-14 text-dark-grey mb-12" data-label="">Load Number</label>
                        <input type="number" placeholder="Search Load Number"
                        value={values.loadNumber} onChange={handleChange('loadNumber')}
                        className="form-control height-35 f-14" />
                    </div>
                </div>
                <div className="col-lg-2">
                    <div className="form-group my-3">
                        <div className="d-flex">
                            <div>
                                <label className="f-14 text-dark mb-12" data-label="" htmlFor="clnumber">From</label>
                            </div>
                        </div>
                        <input type="date" className="form-control height-35 f-14" 
                        value={values.from} onChange={handleChange('from')}
                        autoComplete="off" />
                    </div>
                </div>
                <div className="col-lg-2">
                    <div className="form-group my-3">
                        <div className="d-flex">
                            <div>
                                <label className="f-14 text-dark mb-12" data-label="" htmlFor="clnumber">To</label>
                            </div>
                        </div>
                        <input type="date" className="form-control height-35 f-14" placeholder="" id="driver" min="0" 
                        value={values.to} onChange={handleChange('to')}
                        autoComplete="off" />
                    </div>
                </div>
                <div className="col-lg-2">
                    <div className="form-group my-2">
                        {/* <label className="f-14 text-dark-grey mb-12" data-label="" ></label> */}
                        <br /><br />
                        {/* <ExcelExport excelData={data} fileName={`Report ${moment().format('DD-MM-YYYY')}`}/> */}
                        <button className="btn btn-success btn-block" onClick={handleClick}> Search </button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AdminReportSearchandFilter