import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getExternalCompanies } from "../../../actions/companyAction";
import { addClient, addStudentAction } from "../../../actions/userActions";
import AdminSettingPaymentAddCompanyModal from "../../../components/adminComponents/adminSettingComponents/AdminSettingPaymentAddCompanyModal";

const AddStudentModal = ({
  showDropDown,
  openDropDown,
  setSelectedStudent,
}) => {
  let [showModal, setShowModal] = useState();
  // let [selectedCompany, setSelectedCompany] = useState();
  const externalCompanies = useSelector((state) => state.externalCompanies);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getExternalCompanies({ companyId: auth.user.companyId.id }));
  }, [auth]);

  let [selectedCompanyId, setSelectedCompanyId] = useState("");
  const [values, setValues] = useState({
    // companyId: "63cb14f53f55723694dbd185",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    // workPhone: '',
    // otherPhone: '',
    // clientWorkInformation: '',
    address: "",
    // state: '',
    city: "",
    zip: "",
    type: "",
    notes: "",
    externalCompanyId: "",
  });

  const dispatch = useDispatch();

  const studentData = {
    companyId: auth.user.companyId.id,
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email,
    phone: values.phone,
    notes: values.notes,
  
    address: values.address,
    // state: values.state,
    city: values.city,
    zip: values.zip,
    isCompany: values.type === "Company" ? true : false,
    externalCompanyId: values.type === "Company" ? selectedCompanyId : "",
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    for (let key of Object.keys(studentData)) {
      if (studentData[key] === "") {
        delete studentData[key];
      }
    }
    dispatch(addStudentAction(studentData, successHandler));
  };

  // const submitHandler = (e) => {
  //   e.preventDefault();
  //   dispatch(addStudentAction(studentData, successHandler));
  // };
  const successHandler = (studentId) => {
    setSelectedStudent((prev) => ({ ...prev, studentId }));
    openDropDown();
  };

  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };

  const handlePhone = (fieldName) => (event) => {
    if (event.target.value.length === 1) {
      if (event.target.value !== "(") {
        setValues({
          ...values,
          [fieldName]: "(" + event.target.value,
        });
      }
    } else if (event.target.value.length === 4) {
      if (event.target.value !== ")") {
        setValues({
          ...values,
          [fieldName]: event.target.value + ")-",
        });
      }
    } else if (event.target.value.length === 9) {
      if (event.target.value !== "-") {
        setValues({
          ...values,
          [fieldName]: event.target.value + "-",
        });
      }
    } else {
      setValues({
        ...values,
        [fieldName]: event.target.value,
      });
    }
  };
  const alphabets = [
    "a",
    "b",
    "c",
    "d",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
    "A",
    "B",
    "C",
    "D",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "+",
    "-",
    "/",
    "*",
    "!",
    "@",
    "#",
    "$",
    "%",
    "^",
    "&",
    "_",
    "(",
    ")",
    ",",
    ".",
  ];

  return (
    <>
      <div
        className={`modal fade ${showDropDown ? "show modal-y-axis" : "hide"}`}
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                {" "}
                Add Student
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => openDropDown()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
           
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group my-3">
                      <label
                        className="f-14 text-dark-grey mb-12"
                        data-label=""
                        htmlFor="after_days"
                      >
                        First Name
                      </label>
                      <sup className="text-danger">*</sup>
                      <input
                        type="text"
                        className="form-control height-35 f-14"
                        placeholder="Enter Your First Name"
                        autoComplete="false"
                        value={values.firstName}
                        onChange={handleChange("firstName")}
                        required
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group my-3">
                      <label
                        className="f-14 text-dark-grey mb-12"
                        data-label=""
                        htmlFor="after_days"
                      >
                        Last Name
                      </label>
                      <sup className="text-danger">*</sup>
                      <input
                        type="text"
                        className="form-control height-35 f-14"
                        placeholder="Enter Your Last Name"
                        name="lastName"
                        autoComplete="false"
                        value={values.lastName}
                        onChange={handleChange("lastName")}
                        required
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group my-3">
                      <label
                        className="f-14 text-dark-grey mb-12"
                        data-label=""
                        htmlFor="after_days"
                      >
                        Email
                      </label>
                      {/* <sup className="text-danger">*</sup> */}
                      <input
                        type="Email"
                        className="form-control height-35 f-14"
                        placeholder="Enter Your Email"
                        autoComplete="false"
                        value={values.email}
                        onChange={handleChange("email")}
                        // required
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group my-3">
                      <label
                        className="f-14 text-dark-grey mb-12"
                        data-label=""
                        htmlFor="after_days"
                      >
                        Primary Phone
                      </label>
                      {/* <sup className="text-danger">*</sup> */}
                      <input
                        type="tel"
                        className="form-control height-35 f-14"
                        minLength="14"
                        maxLength="14"
                        // required
                        onKeyDown={(e) => {
                          if (alphabets.includes(e.key)) {
                            e.preventDefault();
                          }
                          if (
                            e.code === "Backspace" &&
                            values.phone.length === 6
                          ) {
                            setValues({
                              ...values,
                              phone: values.phone.substring(0, 4),
                            });
                          }
                          if (
                            e.code === "Backspace" &&
                            values.phone.length === 2
                          ) {
                            setValues({
                              ...values,
                              phone: "",
                            });
                          }
                          if (
                            e.code === "Backspace" &&
                            values.phone.length === 10
                          ) {
                            setValues({
                              ...values,
                              phone: values.phone.substring(0, 9),
                            });
                          }
                        }}
                        name="phone"
                        value={values.phone}
                        onChange={handlePhone("phone")}
                        placeholder="(123)-456-7890"
                      />
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                    <div className="form-group my-3 ">
                      <div className="d-flex justify-content-between">
                        <div className="d-flex">
                          <label
                            className="f-14 text-dark mb-12"
                            data-label=""
                            htmlFor="Name"
                          >
                            {" "}
                            Select School
                          </label>
                          <sup className="text-danger">*</sup>
                        </div>
                        <div>
                          <button
                            className="bg-button"
                            type="button"
                            data-toggle="modal"
                            data-target="#exampleModalCenter"
                            onClick={() => setShowModal(true)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="currentColor"
                              className="bi bi-plus-circle-fill fa-w-20 f-20 text-dark"
                              viewBox="0 0 16 16"
                            >
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                            </svg>
                          </button>
                        </div>
                      </div>
                      <select
                        className="form-control height-35 f-14"
                        name="school"
                        onChange={(e) => setSelectedCompanyId(e.target.value)}
                        value={selectedCompanyId}
                        required
                  
                      >
                        <option value="">Select School</option>
                        {externalCompanies.list.map((x) => (
                          <option value={x.id}>{x.name}</option>
                        ))}
                      </select>
                    </div>
                  </div> */}
                  <div className="col-md-6">
                    <div className="form-group my-3 ">
                      <div className="d-flex justify-content-between">
                        <div className="d-flex">
                          <label
                            className="f-14 text-dark mb-12"
                            data-label=""
                            htmlFor="Name"
                          >
                            {" "}
                            Service Type
                          </label>
                          <sup className="text-danger">*</sup>
                        </div>
                      </div>
                      <select
                        className="form-control height-35 f-14"
                        onChange={handleChange("type")}
                        required
                      >
                        <option value="">Select Type</option>
                        <option value="Individual">Individual</option>
                        <option value="Company">Payer Name/Company</option>
                      </select>
                      {/* )} */}
                    </div>
                  </div>

                  {values.type === "Company" && (
                    <div className="col-md-6">
                      <div className="form-group my-3 ">
                        <div className="d-flex justify-content-between">
                          <div className="d-flex">
                            <label
                              className="f-14 text-dark mb-12"
                              data-label=""
                              htmlFor="Name"
                            >
                              {" "}
                              Select Payer Name/Company
                            </label>
                            <sup className="text-danger">*</sup>
                          </div>
                          <div>
                            <button
                              className="bg-button"
                              type="button"
                              data-toggle="modal"
                              data-target="#exampleModalCenter"
                              onClick={() => setShowModal(true)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor"
                                className="bi bi-plus-circle-fill fa-w-20 f-20 text-dark"
                                viewBox="0 0 16 16"
                              >
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                              </svg>
                            </button>
                          </div>
                        </div>
                        {/* {serviceValue && serviceValue.name && ( */}
                        <select
                          //   type="text"
                          className="form-control height-35 f-14"
                          id="Name"
                          // onChange={handleChange("externalCompanyId")}
                          onChange={(e) => setSelectedCompanyId(e.target.value)}
                          value={selectedCompanyId}
                          required
                          //   value={serviceValue.name}
                          //   disabled
                        >
                          <option value="">Select Client</option>
                          {externalCompanies.list.map((x) => (
                            <option value={x.id}>{x.name}</option>
                          ))}
                        </select>
                        {/* )} */}
                      </div>
                    </div>
                  )}

                  <div className="col-md-12">
                    <label htmlFor="allowed_file_types">Note: </label>
                    <textarea
                      type="text"
                      name="allowed_file_types"
                      id="allowed_file_types"
                      placeholder="Enter The Note"
                      className="form-control f-14"
                      autoComplete="false"
                      value={values.notes}
                      onChange={handleChange("notes")}
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn-secondary rounded f-14 p-2 mr-3"
                  data-dismiss="modal"
                  onClick={() => openDropDown()}
                >
                  Close
                </button>
                <button
                  // type="submit"
                  className="btn-primary rounded f-14 p-2 mr-3"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
          </div>
        </div>
      </div>
      <AdminSettingPaymentAddCompanyModal
        {...{ showModal, setShowModal, setSelectedCompanyId }}
      />
    </>
  );
};
export default AddStudentModal;
