import React, { useState } from "react";

const AdminBookingPriceDetailModal = ({
  showDetailDropDown,
  openDetailDropDown,
  fareDetails,
  useHardCodedFares,
  toogleHardCoded,
  hardCodedFares,
  handleHardCodedFares,
  recalculate,
  useDirectFare,
  toogleDirectFare,
  directFare,
  handleDirectFare,
  fareDirectlyCalculate,
  useFlatDriverRate,
  toogleFlatDriverRate,
  flatDriverRate,
  handleFlatDriverRate,
  flatDriverRateRecalculate,
}) => {
  // console.log("fareDetails=>",fareDetails)
  // const [values, setValues] = useState({
  //     flatBaseFare: "",
  //     farePerMile: "",
  //     deadHeadThreshold: "",
  //     baseFarePerMile: "",
  //     totalMileageAllows: "",
  //     longDistancePerMile: "",
  //     offHourFee: "",
  //     noShowFee: "",
  //     cancelationFee: "",
  // });
  const alphabets = [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "+",
    "-",
    "/",
    "*",
    "!",
    "@",
    "#",
    "$",
    "%",
    "^",
    "&",
    "_",
    "(",
    ")",
  ];

  return (
    <>
      <div
        className={`modal fade ${
          showDetailDropDown ? "show modal-y-axis" : "hide"
        }`}
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header bg-additional-grey">
              <h5 className="modal-title" id="exampleModalLongTitle">
                {" "}
                Pricing Details
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => openDetailDropDown()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <br />
            {/* <div className="modal-body">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="form-group my-3">
                                        <label
                                            className="f-14 text-dark-grey mb-12"
                                            data-label=""
                                            htmlFor="after_days"
                                        >
                                            HQ to Pickup
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control height-35 f-14"
                                            placeholder="Enter Your First Name"
                                            autoComplete="false"
                                            value={fareDetails?.distanceFromBaseLocation + " mi"}
                                            required />
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group my-3">
                                        <label
                                            className="f-14 text-dark-grey mb-12"
                                            data-label=""
                                            htmlFor="after_days"
                                        >
                                            Ride Distance
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control height-35 f-14"
                                            placeholder="Enter Your Last Name"
                                            autoComplete="false"
                                            value={fareDetails?.distanceFromPickToDropOff + " mi"}
                                            required />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group my-3">
                                        <label
                                            className="f-14 text-dark-grey mb-12"
                                            data-label=""
                                            htmlFor="after_days"
                                        >
                                            Base Fare
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control height-35 f-14"
                                            placeholder="Enter Your First Name"
                                            autoComplete="false"
                                            value={"$ " + fareDetails?.baseFare.toFixed(2)}
                                            required />
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group my-3">
                                        <label
                                            className="f-14 text-dark-grey mb-12"
                                            data-label=""
                                            htmlFor="after_days"
                                        >
                                            Ride Fare
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control height-35 f-14"
                                            placeholder="Enter Your Last Name"
                                            autoComplete="false"
                                            value={"$ " + fareDetails?.rideFare.toFixed(2)}
                                            required />
                                    </div>
                                </div>
                                
                                <div className="col-md-3">
                                    <div className="form-group my-3">
                                        <label
                                            className="f-14 text-dark-grey mb-12"
                                            data-label=""
                                            htmlFor="after_days"
                                        >
                                            Driver Amount
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control height-35 f-14"
                                            placeholder="Enter Your Last Name"
                                            autoComplete="false"
                                            value={"$ " + fareDetails?.driverAmount}
                                            required />
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group my-3">
                                        <label
                                            className="f-14 text-dark-grey mb-12"
                                            data-label=""
                                            htmlFor="after_days"
                                        >
                                            OffHour Fee
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control height-35 f-14"
                                            placeholder="Enter Your Last Name"
                                            autoComplete="false"
                                            value={fareDetails?.offHourFee ? "$ "+fareDetails?.offHourFee : "$ "+0}
                                            required />
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group my-3">
                                        <label
                                            className="f-14 text-dark-grey mb-12"
                                            data-label=""
                                            htmlFor="after_days"
                                        >
                                            Company Amount
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control height-35 f-14"
                                            placeholder="Enter Your Last Name"
                                            autoComplete="false"
                                            value={"$ " + fareDetails?.companyAmount}
                                            required />
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group my-3">
                                        <label
                                            className="f-14 text-dark-grey mb-12"
                                            data-label=""
                                            htmlFor="after_days"
                                        >
                                            Total Amount
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control height-35 f-14"
                                            placeholder="Enter Your Last Name"
                                            autoComplete="false"
                                            value={"$ " + fareDetails?.amount}
                                            required />
                                    </div>
                                </div>

                            </div>
                        </div> */}
            <table
              className="table border dataTable no-footer"
              id="leads-table"
              role="grid"
              aria-describedby="leads-table_info"
              style={{ overflowX: "auto", width: "95%", margin: "auto" }}
            >
              <thead className="card-header">
                <tr role="row">
                  <th
                    title="(Res#)"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Mobile: activate to sort column ascending"
                  >
                    HQ to Pickup
                  </th>
                  <th
                    title="Vehicle"
                    className="sorting_disabled border-right"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Next Follow Up"
                  >
                    Ride Distance
                  </th>
                  <th
                    title="Time"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Lead Agent: activate to sort column ascending"
                  >
                    Base Fare
                  </th>
                  <th
                    title="Passenger"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Lead Agent: activate to sort column ascending"
                  >
                    Ride Fare
                  </th>
                  <th
                    title="Pickup Drop Off"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Lead Agent: activate to sort column ascending"
                  >
                    Driver Amount
                  </th>
                  <th
                    title="Vehicle/Driver"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Lead Agent: activate to sort column ascending"
                  >
                    OffHour Fee
                  </th>
                  <th
                    title="Price"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Lead Agent: activate to sort column ascending"
                  >
                    Company Amount
                  </th>
                  <th
                    title="Status"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Lead Agent: activate to sort column ascending"
                  >
                    Total Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  role="row"
                  className="odd border"
                  // onMouseLeave={() => setshowMoreVertDropDown(false)}
                >
                  <td className="border-right">
                    {fareDetails?.distanceFromBaseLocation + " mi"}
                  </td>
                  <td className="border-right">
                    {fareDetails?.distanceFromPickToDropOff + " mi"}
                  </td>
                  <td className="border-right">
                    {"$ " + fareDetails?.baseFare.toFixed(2)}
                  </td>
                  <td className="border-right">
                    {"$ " + fareDetails?.rideFare.toFixed(2)}
                  </td>
                  <td className="border-right">
                    {"$ " + fareDetails?.driverAmount}
                  </td>
                  <td className="border-right">
                    {fareDetails?.offHourFee
                      ? "$ " + fareDetails?.offHourFee
                      : "$ " + 0}
                  </td>
                  <td className="border-right">
                    {"$ " + fareDetails?.companyAmount}
                  </td>
                  <td className="border-right">{"$ " + fareDetails?.amount}</td>
                </tr>
              </tbody>
            </table>
            <br />
            <div className="row" style={{ width: "95%", margin: "auto" }}>
              <div className="col-md-3">
                <div className="form-group my-3">
                  <div className="d-flex">
                    <div>
                      <label
                        className="f-14 text-dark mb-12"
                        data-label=""
                        htmlFor="clnumber"
                      >
                        Add Custom Pricing
                      </label>
                    </div>
                  </div>
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        className="switch-input"
                        id="toggle"
                        checked={useHardCodedFares}
                        onClick={() => toogleHardCoded()}
                      />
                      <span className="switch-label" data-on="" data-off=""></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group my-3">
                  <div className="d-flex">
                    <div>
                      <label
                        className="f-14 text-dark mb-12"
                        data-label=""
                        htmlFor="clnumber"
                      >
                        Flat Rate
                      </label>
                    </div>
                  </div>
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        className="switch-input"
                        id="toggle"
                        checked={useDirectFare}
                        onClick={() => toogleDirectFare()}
                      />
                      <span className="switch-label" data-on="" data-off=""></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group my-3">
                  <div className="d-flex">
                    <div>
                      <label
                        className="f-14 text-dark mb-12"
                        data-label=""
                        htmlFor="clnumber"
                      >
                        Driver Flat Rate
                      </label>
                    </div>
                  </div>
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        className="switch-input"
                        id="toggle"
                        checked={useFlatDriverRate}
                        onClick={() => toogleFlatDriverRate()}
                      />
                      <span className="switch-label" data-on="" data-off=""></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {useHardCodedFares === true && (
              <div className="row" style={{ width: "95%", margin: "auto" }}>
                <div className="col-md-3">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="Name"
                    >
                      {" "}
                      Load Fee
                    </label>
                    {/* <sup className="text-danger">*</sup> */}
                    <input
                      type="text"
                      className="form-control height-35 f-14"
                      placeholder="Enter Load Fee"
                      id="Name"
                      onKeyDown={(e) => {
                        if (alphabets.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      name="flatBaseFare"
                      value={hardCodedFares?.flatBaseFare}
                      onChange={handleHardCodedFares("flatBaseFare")}
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="Name"
                    >
                      {" "}
                      Standard Mileage Rate
                    </label>
                    {/* <sup className="text-danger">*</sup> */}
                    <input
                      type="text"
                      className="form-control height-35 f-14"
                      placeholder="Enter Standard Mileage Rate"
                      id="Name"
                      onKeyDown={(e) => {
                        if (alphabets.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      name="farePerMile"
                      value={hardCodedFares?.farePerMile}
                      onChange={handleHardCodedFares("farePerMile")}
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="Name"
                    >
                      {" "}
                      Dead Head Threshold
                    </label>
                    {/* <sup className="text-danger">*</sup> */}
                    <input
                      type="text"
                      className="form-control height-35 f-14"
                      placeholder="Enter Dead Head Threshold"
                      id="Name"
                      onKeyDown={(e) => {
                        if (alphabets.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      name="deadHeadThreshold"
                      value={hardCodedFares?.deadHeadThreshold}
                      onChange={handleHardCodedFares("deadHeadThreshold")}
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="Name"
                    >
                      {" "}
                      Deadhead Mileage Rate
                    </label>
                    {/* <sup className="text-danger">*</sup> */}
                    <input
                      type="text"
                      className="form-control height-35 f-14"
                      placeholder="Enter Deadhead Mileage Rate"
                      id="Name"
                      onKeyDown={(e) => {
                        if (alphabets.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      name="baseFarePerMile"
                      value={hardCodedFares?.baseFarePerMile}
                      onChange={handleHardCodedFares("baseFarePerMile")}
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="Name"
                    >
                      {" "}
                      Long Distance Threshold
                    </label>
                    {/* <sup className="text-danger">*</sup> */}
                    <input
                      type="text"
                      className="form-control height-35 f-14"
                      placeholder="Enter Long Distance Threshold"
                      id="Name"
                      onKeyDown={(e) => {
                        if (alphabets.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      name="totalMileageAllows"
                      value={hardCodedFares?.totalMileageAllows}
                      onChange={handleHardCodedFares("totalMileageAllows")}
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="Name"
                    >
                      {" "}
                      Long Distance Per Mile
                    </label>
                    {/* <sup className="text-danger">*</sup> */}
                    <input
                      type="text"
                      className="form-control height-35 f-14"
                      placeholder="Enter Long Distance Per Mile"
                      id="Name"
                      onKeyDown={(e) => {
                        if (alphabets.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      name="longDistancePerMile"
                      value={hardCodedFares?.longDistancePerMile}
                      onChange={handleHardCodedFares("longDistancePerMile")}
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="Name"
                    >
                      {" "}
                      Off-hours Fee
                    </label>
                    {/* <sup className="text-danger">*</sup> */}
                    <input
                      type="text"
                      className="form-control height-35 f-14"
                      placeholder="Enter Off-hours Fee"
                      id="Name"
                      onKeyDown={(e) => {
                        if (alphabets.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      name="offHourFee"
                      value={hardCodedFares?.offHourFee}
                      onChange={handleHardCodedFares("offHourFee")}
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="Name"
                    >
                      {" "}
                      No-show Fee
                    </label>
                    {/* <sup className="text-danger">*</sup> */}
                    <input
                      type="text"
                      className="form-control height-35 f-14"
                      placeholder="Enter No-show Fee"
                      id="Name"
                      onKeyDown={(e) => {
                        if (alphabets.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      name="noShowFee"
                      value={hardCodedFares?.noShowFee}
                      onChange={handleHardCodedFares("noShowFee")}
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="Name"
                    >
                      {" "}
                      Cancelation Fee
                    </label>
                    {/* <sup className="text-danger">*</sup> */}
                    <input
                      type="text"
                      className="form-control height-35 f-14"
                      placeholder="Enter Cancelation Fee"
                      id="Name"
                      onKeyDown={(e) => {
                        if (alphabets.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      name="cancelationFee"
                      value={hardCodedFares?.cancelationFee}
                      onChange={handleHardCodedFares("cancelationFee")}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="Name"
                    >
                      {" "}
                      Additional Charges
                    </label>
                    {/* <sup className="text-danger">*</sup> */}
                    <input
                      type="text"
                      className="form-control height-35 f-14"
                      placeholder="Enter Cancelation Fee"
                      id="Name"
                      onKeyDown={(e) => {
                        if (alphabets.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      name="additionalCharges"
                      value={hardCodedFares?.additionalCharges}
                      onChange={handleHardCodedFares("additionalCharges")}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="Name"
                    >
                      {" "}
                      Deduction
                    </label>
                    {/* <sup className="text-danger">*</sup> */}
                    <input
                      type="text"
                      className="form-control height-35 f-14"
                      placeholder="Enter Cancelation Fee"
                      id="Name"
                      onKeyDown={(e) => {
                        if (alphabets.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      name="deduction"
                      value={hardCodedFares?.deduction}
                      onChange={handleHardCodedFares("deduction")}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="Name"
                    >
                      &nbsp;
                    </label>
                    <br />
                    <button
                      type="button"
                      className="btn-primary rounded f-14 p-2 mr-3"
                      onClick={() => recalculate()}
                    >
                      Recalculate
                    </button>
                  </div>
                </div>
              </div>
            )}

            {useDirectFare === true && (
              <div className="row" style={{ width: "95%", margin: "auto" }}>
                <div className="col-md-3">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="Name"
                    >
                      {" "}
                      Flat Rate Amount
                    </label>
                    {/* <sup className="text-danger">*</sup> */}
                    <input
                      type="text"
                      className="form-control height-35 f-14"
                      placeholder="Enter Flat Rate"
                      id="Name"
                      onKeyDown={(e) => {
                        if (alphabets.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      name="directFare"
                      value={directFare}
                      onChange={handleDirectFare}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="Name"
                    >
                      &nbsp;
                    </label>
                    <br />
                    <button
                      type="button"
                      className="btn-primary rounded f-14 p-2 mr-3"
                      onClick={() => fareDirectlyCalculate()}
                    >
                      Recalculate
                    </button>
                  </div>
                </div>
              </div>
            )}
            {useFlatDriverRate === true && (
              <div className="row" style={{ width: "95%", margin: "auto" }}>
                <div className="col-md-3">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="Name"
                    >
                      {" "}
                      Driver Flat Rate Amount
                    </label>
                    {/* <sup className="text-danger">*</sup> */}
                    <input
                      type="text"
                      className="form-control height-35 f-14"
                      placeholder="Enter Flat Rate"
                      id="Name"
                      onKeyDown={(e) => {
                        if (alphabets.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      name="directFare"
                      value={flatDriverRate}
                      onChange={handleFlatDriverRate}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="Name"
                    >
                      &nbsp;
                    </label>
                    <br />
                    <button
                      type="button"
                      className="btn-primary rounded f-14 p-2 mr-3"
                      onClick={() => flatDriverRateRecalculate()}
                    >
                      Recalculate
                    </button>
                  </div>
                </div>
              </div>
            )}

            <div className="modal-footer">
              <button
                type="button"
                className="btn-primary rounded f-14 p-2 mr-3"
                onClick={() => openDetailDropDown()}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminBookingPriceDetailModal;
