import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getExternalCompanies } from "../../../actions/companyAction";
import { editClientAction } from "../../../actions/userActions";
import AdminSettingPaymentAddCompanyModal from "../adminSettingComponents/AdminSettingPaymentAddCompanyModal";
import MyComponent from "../Map/Map";

const AdminClientEditComponent = ({ state, handleModal }) => {
  //   const { state } = useLocation();
  const navigate = useNavigate();

  // console.log("===>", state);
  let [showModal, setShowModal] = useState();

  const externalCompanies = useSelector((state) => state.externalCompanies);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getExternalCompanies({ companyId: auth.user.companyId.id }));
  }, [auth]);

  let [selectedCompanyId, setSelectedCompanyId] = useState("");
  const [data, setData] = useState({});

  // const [values2, setValues2] = useState({
  //   // companyId: "63cb14f53f55723694dbd185",
  //   firstName: "",
  //   lastName: "",
  //   email: "",
  //   password: "",
  //   phone: "",
  //   // workPhone: '',
  //   // otherPhone: '',
  //   role: "",
  //   // clientWorkInformation: '',
  //   address: "",
  //   // state: '',
  //   city: "",
  //   zip: "",
  //   summary: "",
  //   type: "",
  //   isClient: true,
  //   isCompany: "",
  //   externalCompanyId: "",
  // });

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };
  // console.log(values2, "tere yeh ishq mere fitoor")
  const dispatch = useDispatch();

  const handlePhone = (fieldName) => (event) => {
    if (event.target.value.length === 1) {
      if (event.target.value !== "(") {
        setData({
          ...data,
          [fieldName]: "(" + event.target.value,
        });
      }
    } else if (event.target.value.length === 4) {
      if (event.target.value !== ")") {
        setData({
          ...data,
          [fieldName]: event.target.value + ")-",
        });
      }
    } else if (event.target.value.length === 9) {
      if (event.target.value !== "-") {
        setData({
          ...data,
          [fieldName]: event.target.value + "-",
        });
      }
    } else {
      setData({
        ...data,
        [fieldName]: event.target.value,
      });
    }
  };
  const alphabets = [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "+",
    "-",
    "/",
    "*",
    "!",
    "@",
    "#",
    "$",
    "%",
    "^",
    "&",
    "_",
    "(",
    ")",
    ",",
    ".",
  ];
  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log("first")
    // for (let key of Object.keys(clientData)) {
    //   if (clientData[key] === "") {
    //     delete clientData[key];
    //   }
    // }
    // const {
    //   role,
    //   externalCompanyId,
    //   companyRepresentative,
    //   isEmailVerified,

    //   id,
    //   ...rest
    // } = data;

    const clientData = {
      userId: data.id,
      firstName: data.firstName,
      lastName: data.lastName,
      // email: data.email,

      phone: data.phone,
      // workPhone: values2.workPhone,
      // otherPhone: values2.otherPhone,
      // clientWorkInformation: values2.clientWorkInformation,
      address: data.address,
      // state: values2.state,
      city: data.city,
      zip: data.zip,
      summary: data.summary,
    };
    dispatch(editClientAction(clientData, handleSuccess));

    // setValues2({
    //   companyId: "",
    //   firstName: "",
    //   lastName: "",
    //   email: "",
    //   password: "",
    //   phone: "",
    //   workPhone: "",
    //   otherPhone: "",
    //   role: "",
    //   clientWorkInformation: "",
    //   address: "",
    //   city: "",
    //   zip: "",
    //   summary: "",
    //   isClient: true,
    //   // state: '',
    // });
  };

  const handleSuccess = () => {
    handleModal(null);
  };

  useEffect(() => {
    if (state) setData(state);
    else navigate("/clients");
  }, [state]);

  return (
    <>
      <div className="d-flex flex-column w-tables rounded mt-3 bg-white p-3">
        <h4>Edit Client</h4>
        <div
          id="leads-table_wrapper"
          className="dataTables_wrapper dt-bootstrap4 no-footer"
        >
          <div className="row p-3">
            <div className="col-lg-6">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark-grey mb-12"
                  data-label=""
                  htmlFor="after_days"
                >
                  First Name
                </label>
                <sup className="text-danger">*</sup>
                <input
                  type="text"
                  className="form-control height-35 f-14"
                  placeholder="Enter Your First Name"
                  autoComplete="false"
                  value={data.firstName}
                  name="firstName"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark-grey mb-12"
                  data-label=""
                  htmlFor="after_days"
                >
                  Last Name
                </label>
                <sup className="text-danger">*</sup>
                <input
                  type="text"
                  className="form-control height-35 f-14"
                  placeholder="Enter Your Last Name"
                  autoComplete="false"
                  value={data.lastName}
                  name="lastName"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark-grey mb-12"
                  data-label=""
                  htmlFor="after_days"
                >
                  Email
                </label>
                <sup className="text-danger">*</sup>
                <input
                  type="Email"
                  className="form-control height-35 f-14"
                  placeholder="Enter Your Email"
                  autoComplete="false"
                  value={data.email}
                  name="email"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark-grey mb-12"
                  data-label=""
                  htmlFor="after_days"
                >
                  Primary Phone
                </label>
                {/* <sup className="text-danger">*</sup> */}
                <input
                  className="form-control height-35 f-14"
                  minLength="14"
                  maxLength="14"
                  // required
                  onKeyDown={(e) => {
                    if (alphabets.includes(e.key)) {
                      e.preventDefault();
                    }
                    if (e.code === "Backspace" && data.phone.length === 6) {
                      setData({
                        ...data,
                        phone: data.phone.substring(0, 4),
                      });
                    }
                    if (e.code === "Backspace" && data.phone.length === 2) {
                      setData({
                        ...data,
                        phone: "",
                      });
                    }
                    if (e.code === "Backspace" && data.phone.length === 10) {
                      setData({
                        ...data,
                        phone: data.phone.substring(0, 9),
                      });
                    }
                  }}
                  value={data.phone}
                  name="phone"
                  onChange={handleChange}
                  placeholder="(123)-456-7890"
                />
              </div>
            </div>
            {/* <div className="col-lg-6">
                            <div className="form-group my-3">
                                <label className="f-14 text-dark-grey mb-12" data-label="" htmlFor="after_days">Work Phone</label>
                                <sup className="text-danger">*</sup>
                                <input className="form-control height-35 f-14"
                                     minLength="14"
                                     maxLength="14"
                                     required
                                     onKeyDown={(e) => {
                                        if (alphabets.includes(e.key)) {
                                            e.preventDefault();
                                        } 
                                        if (e.code === "Backspace" && values.workPhone.length === 6){
                                            setValues({
                                                ...values, workPhone:
                                                values.workPhone.substring(0,4)
                                            })
                                        }
                                        if (e.code === "Backspace" && values.workPhone.length === 2){
                                            setValues({
                                                ...values, workPhone:
                                                ''
                                            })
                                        }
                                        if (e.code === "Backspace" && values.workPhone.length === 10){
                                            setValues({
                                                ...values, workPhone:
                                                values.workPhone.substring(0,9)
                                            })
                                        }
                                    }} name="workPhone" 
                                     value={values.workPhone}
                                     onChange={handlePhone('workPhone')}
                                     placeholder="(123)-456-7890"
                                />
                            </div>
                        </div> */}
            {/* <div className="col-lg-6">
                            <div className="form-group my-3">
                                <label className="f-14 text-dark-grey mb-12" data-label="" htmlFor="after_days">Other phone</label>
                                <sup className="text-danger">*</sup>
                                <input className="form-control height-35 f-14" 
                                    minLength="14"
                                    maxLength="14"
                                    required
                                    onKeyDown={(e) => {
                                        if (alphabets.includes(e.key)) {
                                            e.preventDefault();
                                        }
                                        if (e.code === "Backspace" && values.otherPhone.length === 6){
                                            setValues({
                                                ...values, otherPhone:
                                                values.otherPhone.substring(0,4)
                                            })
                                        }
                                        if (e.code === "Backspace" && values.otherPhone.length === 2){
                                            setValues({
                                                ...values, otherPhone:
                                                ''
                                            })
                                        }
                                        if (e.code === "Backspace" && values.otherPhone.length === 10){
                                            setValues({
                                                ...values, otherPhone:
                                                values.otherPhone.substring(0,9)
                                            })
                                        }
                                    }} name="otherPhone" 
                                    value={values.otherPhone}
                                    onChange={handlePhone('otherPhone')}
                                    placeholder="(123)-456-7890"
                                />
                            </div>
                        </div> */}
            {/* <div className="col-lg-6">
                            <label className="f-14 text-dark-grey mb-12 mt-3" data-label="" htmlFor="default_task_status">Work Information</label>
                            <sup className="text-danger">*</sup>
                            <div className="form-group mb-0">
                                <div className="select-picker">
                                    <select name="default_task_status" className="form-control select-picker" style={{height: '39px'}}>
                                        <option selected="" value="1"> Company Type:</option>
                                        <option value="2"> Individual Contacts</option>
                                        <option value="3"> Corporate Contact</option>
                                    </select>
                                </div>
                            </div>
                        </div> */}
            {/* <div className="col-lg-6">
                            <div className="form-group my-3">
                                <label className="f-14 text-dark-grey mb-12" data-label="" htmlFor="after_days">Work Information</label>
                                <sup className="text-danger">*</sup>
                                <input type="text" className="form-control height-35 f-14" placeholder="Enter Your Work Details"
                                autoComplete="false" value={values.clientWorkInformation} onChange={handleChange('clientWorkInformation')}/>
                            </div>
                        </div> */}

            <div className="col-md-12">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark mb-12"
                  data-label=""
                  htmlFor="Name"
                >
                  Street Address
                </label>
                {/* <sup className="text-danger">*</sup> */}
                {/* <input type="text" className="form-control height-35 f-14" placeholder="Enter Your Address" id="Name"/> */}
                <MyComponent
                  values2={data}
                  setValues2={setData}
                  addressss={data.address}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark mb-12"
                  data-label=""
                  htmlFor="City"
                >
                  City
                </label>
                {/* <sup className="text-danger">*</sup> */}
                <input
                  type="text"
                  className="form-control height-35 f-14"
                  placeholder=""
                  value={data.city}
                  name="city"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark mb-12"
                  data-label=""
                  htmlFor="cname"
                >
                  State
                </label>
                {/* <sup className="text-danger">*</sup> */}
                <input
                  type="text"
                  className="form-control height-35 f-14"
                  placeholder=""
                  value={data.state}
                  name="state"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark mb-12"
                  data-label=""
                  htmlFor="Zipcode"
                >
                  Zip Code
                </label>
                {/* <sup className="text-danger">*</sup> */}
                <input
                  type="text"
                  className="form-control height-35 f-14"
                  placeholder=""
                  value={data.zip}
                  name="zip"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="col-lg-12">
              <label htmlFor="allowed_file_types"> Summary </label>
              <textarea
                type="text"
                placeholder="Note"
                className="form-control f-14"
                autoComplete="false"
                value={data.summary}
                name="summary"
                onChange={handleChange}
              ></textarea>
            </div>
            <div className="col-lg-12">
              {/* <div className="form-group my-3">
                                <label className="f-14 text-dark-grey mb-12" data-label="" htmlFor="after_days">Active/unactive</label>
                                <input type="texta" className="form-control height-35 f-14" autoComplete="off" />
                            </div> */}
            </div>
            <br />
            <div className="col-lg-12">
              <div
                className="form-group "
                style={{ marginTop: "1.5em !important" }}
              >
                <button
                  type="button"
                  className="btn-primary rounded f-14 p-2 mr-3"
                  onClick={handleSubmit}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AdminSettingPaymentAddCompanyModal
        {...{ showModal, setShowModal, setSelectedCompanyId }}
      />
    </>
  );
};
export default AdminClientEditComponent;
