import moment from 'moment';
import React from 'react'
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';

const MessageDropDown = ({msg}) => {
    const auth = useSelector((state) => state.auth);
    let arr =[]
    return (
        <div
            // className="dropdown-menu dropdown-menu-right notification-dropdown border-0 shadow-lg py-0 bg-additional-grey"
            className="dropdown-menu dropdown-menu-right notification-dropdown shadow-lg py-0 bg-additional-grey"
            tabIndex="0"
        >
            {/* <div className="d-flex px-3 justify-content-between align-items-center border-bottom-grey py-1 bg-white">
                <div className="___class_+?50___">
                    <p className="f-14 mb-0 text-dark f-w-500">
                    New Messages
                    </p>
                </div>
            </div> */}
            <ul class="dropdown3-menu" role="menu" aria-labelledby="dLabel">
            {/* <div class="notification-heading"><h4 class="menu-title">Messages</h4><h4 class="menu-title pull-right">View all<i class="glyphicon glyphicon-circle-arrow-right"></i></h4>
            </div> */}
            <div className="d-flex px-3 justify-content-between align-items-center border-bottom-grey py-1 bg-white">
                <div className="___class_+?50___">
                    <p className="f-14 mb-0 text-dark f-w-500">
                        <h4>Messages</h4>
                    </p>
                </div>
            </div>
            <li class="divider"></li>
            {/* <div id="notification-list"></div> */}
            <ul className="notification-list">
                {
                    msg.map((item, index) => {
                        arr=[]
                        return (

                            <li className={`notification-message ${item.messages[item.messages.length-1].view.find((x) => x.messageSeen === false && x.receiver === auth.user.id) ? "bg-msgs" : ''}`} key={index} style={{width: "100%"}}>
                                <NavLink to={`/messaging-thread/${item.id}`}>
                                    <div className="media">
                                        <span className="avatar">
                                        {
                                        item.members[0].id !== auth.user.id ?
                                        item.members[0]?.profilePic ?
                                        item.members[0]?.profilePic.fileUrl &&
                                        <>
                                            <img alt="Profile Picture" src={item.members[0]?.profilePic.fileUrl} style={{objectFit:"fill", height:"100%", borderRadius: "50%"}} />
                                        </>
                                        :
                                            <img alt="" src={require("../../../assets/images/profile.jpg")} />
                                        :
                                        item.members[1]?.profilePic ?
                                        item.members[1]?.profilePic.fileUrl &&
                                        <>
                                            <img alt="Profile Picture" src={item.members[1]?.profilePic.fileUrl} style={{objectFit:"fill", height:"100%", borderRadius: "50%"}} />
                                        </>
                                        :
                                        <>
                                            <img alt="" src={require("../../../assets/images/profile.jpg")} style={{objectFit:"fill", height:"100%", borderRadius: "50%"}}/>
                                        </>
                                        }
                                        </span>
                                        <div className="media-body">
                                            <p className="noti-details">
                                                <span className="noti-title" style={{fontWeight: 'bold'}}>
                                                {

                                                item.condition ?
                                                item.condition :
                                                item.members.forEach(e => {
                                                    // console.log("typeof=>", typeof (e.id), typeof (auth.user.id), e.id, auth.user.id, (e.id !== auth.user.id))
                                                    if (e.id !== auth.user.id) {
                                                        arr.push(e.firstName, ", ")
                                                    }
                                                })}
                                                {arr.pop() ? arr : ""}
                                                </span> 
                                            </p>
                                            <p className="noti-details">{item.messages[item.messages.length-1].message}</p>
                                            <p className="noti-time"><span className="notification-time">{moment().format('DD-MM-YYYY') === moment(item.messages[item.messages.length-1].timestamps).format('DD-MM-YYYY') ? moment(item.messages[item.messages.length-1].timestamps).format('LT') : moment(item.messages[item.messages.length-1].timestamps).format('DD MMM')}
                                            </span></p>
                                        </div>
                                    </div>
                                </NavLink>
                            </li>
                        );
                    })
                }
            </ul>
            <li class="divider"></li>
            {/* <div class="notification-footer3"><h4 class="menu-title">View all<i class="glyphicon glyphicon-circle-arrow-right"></i></h4></div> */}
            <div className="d-flex px-3 justify-content-between align-items-center border-bottom-grey">
                <div className="___class_+?50___">
                </div>
            </div>            
            
            <div className="topnav-dropdown-footer bg-white" style={{display: "block", textAlign: "center", paddingBottom: "10px", paddingTop: "3px"}}>
                <Link to="/compose-message">New Message</Link>
                {" | "}<Link to="/inbox">View all Messages</Link>
            </div>
            </ul>
            
        </div>
    )
}
export default MessageDropDown