import React, { useState } from "react";
import moment from "moment";
import AdminBookingPastBookingRideLogModal from "./AdminBookingPastBookingRideLogModal";
import { Link, useNavigate } from "react-router-dom";
// import { useSelector } from "react-redux";

const AdminBookingPastBookingRow = ({
  current,
  i,
  dropDownRow,
  setDropDownRow,
}) => {
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };
  const [showRideLogDropDown, setshowRideLogDropDown] = useState(false);
  const openRideLogDropDown = () => {
    setshowRideLogDropDown((showRideLogDropDown) => !showRideLogDropDown);
  };
  // const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  return (
    <>
      <tr
        role="row"
        className="odd border"
        key={i}
        onMouseLeave={() => setshowMoreVertDropDown(false)}
      >
        <td className="border-right">{current?.orderId}</td>
        <td className="border-right">
          {current?.assetId?.assetNo}
          <i data-toggle="tooltip"></i>
        </td>
        <td className="border-right">
          <strong>Pickup</strong> {current?.pickUpTime}
          {parseInt(current?.pickUpTime.substring(0, 2)) > 11 ? "pm" : "am"}
          <br />
          <strong>Dropoff</strong> {current?.dropOffTime}
          {parseInt(current?.dropOffTime.substring(0, 2)) > 11 ? "pm" : "am"}
          <br /> 
          {/* 1 hrs 5 mint */}
          {current?.fareAndDistance?.estimatedTime}
        </td>
        <td className="border-right">
          <strong>
            {current?.customerId?.firstName} {current?.customerId?.lastName}
          </strong>
          <br />
          {current?.customerId?.phone}
          <br />
        </td>
        <td className="border-right w-30">
          <div className="d-flex justify-content-between">
            <div>
              <div>
                <p className="f-12 font-medium mb-0">
                  <strong>{current?.pickUpAddress?.address}</strong>{" "}
                </p>
              </div>
              <div>
                <time>
                  {" "}
                  {moment(current?.pickUpDate?.substring(0, 10)).format("MM/DD/YYYY")} -{" "}
                  {current?.pickUpTime}
                  {parseInt(current?.pickUpTime.substring(0, 2)) > 11
                    ? "pm"
                    : "am"}
                </time>
              </div>
            </div>
            <div className="mt-4 px-4">
              <div className="hidden sm:block">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="80"
                  height="12"
                  viewBox="0 0 40 6"
                  fill="none"
                >
                  <path
                    d="M40 3L35 0.113249V5.88675L40 3ZM0 3.5H35.5V2.5H0V3.5Z"
                    fill="black"
                  ></path>
                </svg>
              </div>
              <p className="text-center font-medium">
                {/* <span>{current.distanceFromBaseLocation} {current.distanceFromPickToDropOff} {current.totalMileage} mi</span> */}
                <span>{current?.totalMileage} mi</span>
              </p>
            </div>
            <div>
              <div>
                <p className="f-12 font-medium mb-0">
                  <strong>{current?.dropOffAddress?.address}</strong>
                </p>
              </div>
              <div>
                <time>
                  {" "}
                  {moment(current?.dropOffDate?.substring(0, 10)).format("MM/DD/YYYY")} -{" "}
                  {current?.dropOffTime}
                  {parseInt(current?.dropOffTime.substring(0, 2)) > 11
                    ? "pm"
                    : "am"}
                </time>
              </div>
            </div>
          </div>
        </td>
        <td className="border-right">{current?.type?.name}</td>
        <td className="border-right">
          <strong>${current?.totalAmount.toFixed(2)}</strong>
          <i data-toggle="tooltip"></i>
        </td>
        <td className="border-right">{current?.status}</td>
        <td className=" text-right pr-20">
          <div className="task_view">
            <div className="dropdown">
              <Link
                to=""
                className="task_view_more d-flex align-items-center justify-content-center dropdown-toggle"
                type="link"
                data-toggle="dropdown"
                onClick={openMoreVertDropDown}
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="icon-options-vertical icons"></i>
              </Link>
              <div
                className={`dropdown-menu dropdown-menu-right ${
                  showMoreVertDropDown
                    ? "dropdown-menu dropdown-menu-right show"
                    : "dropdown-menu dropdown-menu-right"
                }`}
                aria-labelledby="dropdownMenuLink-20"
              >
                <button onClick={() => navigate(`/booking/details/${current._id}`, 
                    {
                      state: current,
                    }
                  )}
                  className="dropdown-item"
                >
                  <i className="fa fa-eye"> View</i>
                </button>
                {/* {
                  auth?.user?.permissionId?.booking?.update === true &&
                  <Link className="dropdown-item" to="">
                    <i className="fa fa-edit"> Edit</i>
                  </Link>
                }
                {
                  auth?.user?.permissionId?.booking?.delete === true &&
                  <Link
                    className="dropdown-item delete-table-row"
                    to=""
                    data-id="20"
                  >
                    <i className="fa fa-trash"> Delete</i>
                  </Link>
                } */}
                <Link
                  className="dropdown-item delete-table-row"
                  to=""
                  onClick={openRideLogDropDown}
                  data-id="20"
                >
                  <i className="fa fa-address-card-o"> Ride Logs</i>
                </Link>
              </div>
            </div>
          </div>
        </td>
      </tr>
      <AdminBookingPastBookingRideLogModal
        showRideLogDropDown={showRideLogDropDown}
        openRideLogDropDown={openRideLogDropDown}
        current={current}
        index={i}
      />
    </>
  );
};
export default AdminBookingPastBookingRow;
