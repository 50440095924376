import React, { useEffect } from 'react'
import AdminHeader from '../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminHeader'
import AdminSidebar from '../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminSidebar'
// import AdminTrackAllDriverListCard from '../../components/adminComponents/adminTrackViewComponents/AdminTrackAllDriverListCard'
// import AdminTrackAllDriverMapView from '../../components/adminComponents/adminTrackViewComponents/AdminTrackAllDriverMapView'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { navigateUserFunc } from '../../actions/generalActions'
import AdminDashboardTracking from '../../components/adminComponents/adminDashboardComponents/AdminDashboardCards'
import NotAllowed from '../../components/NotAllowed'

const AdminTrackPage = () => {
    let navigate = useNavigate();
    const auth = useSelector((state) => state?.auth);
    useEffect(() => {
      if (auth?.user?.permissionId?.track?.isAllow === false){
        navigateUserFunc(auth?.user, navigate)
      }
    },[])
    return (
        <>
            <AdminHeader />
            <AdminSidebar />
            <div className="body-wrapper clearfix">
                <section className="main-container " id="fullscreen">
                    <div className="px-4 py-2 border-top-0 emp-dashboard">
                        <div className="d-lg-flex d-md-flex d-block py-4">
                            <div>
                                <h4 className=" mb-0 f-21 text-capitalize font-weight-bold">Track Drivers</h4>
                            </div>
                        </div>
                        {/* <AdminTrackAllDriverListCard /> */}
                        {/* <AdminTrackAllDriverMapView /> */}
                        {
                            auth?.user?.permissionId?.track?.read === true ?
                            <AdminDashboardTracking />
                            :
                            <NotAllowed />
                        }
                    </div>
                </section>
            </div>
        </>
    )
}
export default AdminTrackPage