import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AssignDriverModal from "./AssignDriverModal";
import { useDispatch, useSelector } from "react-redux";
import { ROUTE_DETAILS_SUCCESS } from "../../../constants";
import AdminRouteDeleteModal from "./AdminRouteDeleteModal";
// import AdminBookingTodayBookingRideLogModal from './AdminBookingTodayBookingRideLogModal'
// import moment from "moment";

const RoutesTableRow = ({
  current,
  i,
  dropDownRow,
  setDropDownRow,
  editOrderHandler,
  deleteRouteHandler,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);

  // const [showDeleteDropDown, setshowDeleteDropDown] = useState(false)

  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };

  const [showRideLogDropDown, setshowRideLogDropDown] = useState(false);

  const openRideLogDropDown = () => {
    setshowRideLogDropDown((showRideLogDropDown) => !showRideLogDropDown);
  };

  const [showDropDown, setshowDropDown] = useState(false);
  const openDropDown = () => {
    // console.log(openDropDown, "main open drop oper wala")
    setshowDropDown((showDropDown) => !showDropDown);
    //   console.log(openDropDown, "main open drop neecha wala")
  };

  const handleChange = (event) => {
    if (event.target.value === "Assigned") {
      // setValues({
      //   ...values,
      //   [e.target]: "Unassigned",
      // });
    }
    if (event.target.value === "Assigned") {
      openDropDown();
      // openDropDown.push({Assigned:event.target.value})
    }
    //     if(fieldName === 'date'){
    //       rideArr.push({date:event.target.value})
    //     }
    //     if(fieldName === 'time'){
    //       rideArr.push({time:event.target.value})
  };
  const createReturnRouteHandler = () => {
    dispatch({
      type: ROUTE_DETAILS_SUCCESS,
      payload: current,
    });
    navigate(`create-return/${current.id}`);
  };
  const auth = useSelector((state) => state.auth);

  return (
    <>
      <tr
        role="row"
        className="odd border"
        key={i}
        onMouseLeave={() => setshowMoreVertDropDown(false)}
      >
        <td className="border-right">
          {current?.routeNumber} {current.routeNumberReturn && "( Return )"}
        </td>
        <td className="border-right">{current?.customerDetails.length}</td>
        {/* <td>
        -
        </td> */}
        <td className="border-right">{current?.status}</td>
        <td className="border-right">
          <button
            className="btn btn-sm btn-primary"
            onClick={() => navigate(`/routes/details/${current.id}`)}
          >
            View
          </button>
        </td>
        <td className="border-right">
          {current.driverId ? (
            <>
              {current.driverId.firstName} {current.driverId?.lastName}
            </>
          ) : (
            "-"
          )}
        </td>
        <td className="border-right">{current.driverBookingStatus}</td>

        <td className="border-right">
          <button className="btn btn-success" onClick={openDropDown}>
            {current.driverBookingStatus !== "Unassigned"
              ? "Re-assign Driver"
              : "Assign Driver"}
          </button>
        </td>
        {/* <td className="border-right">
          <div className="select-picker">
            <select
              className="form-control select-picker assign_role"
              style={{ height: "35px" }}
              value={current.driverBookingStatus}
              onChange={handleChange}
            >
              <option value="Unassigned">Unassigned</option>
              <option value="Assigned">
                {current.driverBookingStatus == "Assigned"
                  ? "Assigned"
                  : current.driverBookingStatus == "Pending"
                  ? "Re-assign"
                  : "Assign"}
              </option>
              <option value="Pending">Pending</option>
            </select>
          </div>
        </td> */}
        <td className=" text-right pr-20">
          <div className="task_view">
            <div className="dropdown">
              <Link
                to=""
                className="task_view_more d-flex align-items-center justify-content-center dropdown-toggle"
                type="link"
                data-toggle="dropdown"
                onClick={openMoreVertDropDown}
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="icon-options-vertical icons"></i>
              </Link>
              <div
                className={`dropdown-menu dropdown-menu-right ${
                  showMoreVertDropDown
                    ? "dropdown-menu dropdown-menu-right show"
                    : "dropdown-menu dropdown-menu-right"
                }`}
                aria-labelledby="dropdownMenuLink-20"
              >
                {auth?.user?.permissionId?.routes?.create === true &&
                  !current.routeNumberReturn &&
                  !current.isReturnRoute && (
                    <Link
                      className="dropdown-item"
                      to={`create-return/${current.id}`}
                      onClick={() => createReturnRouteHandler(current)}
                    >
                      <i className="fa fa-plus"> Create return route</i>
                    </Link>
                  )}
                {auth?.user?.permissionId?.routes?.update === true && (
                  <Link to={`edit/${current.id}`} className="dropdown-item">
                    <i className="fa fa-edit"> Edit</i>
                  </Link>
                )}
                {auth?.user?.permissionId?.routes?.update === true && (
                  <Link
                    className="dropdown-item"
                    to=""
                    onClick={() => editOrderHandler(current)}
                  >
                    <i className="fa fa-edit"> Edit Ride Pattern</i>
                  </Link>
                )}
                <Link
                  className="dropdown-item"
                  to={`/route-log/${current.id}`}
                  // onClick={() => navigate(`/route-log/${current.id}`)}
                >
                  <i className="fa fa-address-card-o"> View Route Log</i>
                </Link>

                {auth?.user?.permissionId?.routes?.delete === true && (
                  <Link
                    className="dropdown-item delete-table-row"
                    to=""
                    data-id="20"
                    onClick={() => deleteRouteHandler(current)}
                  >
                    <i className="fa fa-trash"> Delete</i>
                  </Link>
                )}

                {/* <Link
                  className="dropdown-item delete-table-row"
                  to=""
                  onClick={openRideLogDropDown}
                  data-id="20"
                >
                  <i className="fa fa-address-card-o"> Ride Logs</i>
                </Link> */}
              </div>
            </div>
          </div>
        </td>
      </tr>

      <AdminRouteDeleteModal
        showDropDown={showDropDown}
        openDropDown={openDropDown}
        current={current}
        index={i}
      />
    </>
  );
};
export default RoutesTableRow;
