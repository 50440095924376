import React from 'react'
import shift1 from "../../../assets/images/load.png"

const AdminBasicReportTable = () => {
    return (
        <>
            <div className="d-flex flex-column w-tables rounded mt-3 bg-white">
                <div className="d-flex border">
                    <div className="flex-grow-1">
                        <div className="dataTables_length d-flex" id="leads-table_length">
                            {/* <div className="p-2">
                                <label>Show entries</label>
                            </div>
                            <label>
                                <select name="leads-table_length" aria-controls="leads-table" className="custom-select custom-select-sm form-control form-control-sm">
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </label> */}
                            <br />
                        </div>
                    </div>
                </div>
                <div id="leads-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div className="row">
                        <div className="col-sm-12">
                            <table className="table border w-100 dataTable no-footer" id="leads-table" role="grid" aria-describedby="leads-table_info" style={{ overflowX: "auto" }}>
                                <thead className="card-header">
                                    <tr role="row">
                                        <th title="(Res#)" className="sorting border-right" tabIndex="0" aria-controls="leads-table" rowSpan="1" colSpan="1" aria-label="Mobile: activate to sort column ascending">
                                            Order ID
                                        </th>
                                        <th title="Vehicle" className="sorting_disabled border-right" rowSpan="1" colSpan="1" aria-label="Next Follow Up">
                                            Asset#
                                        </th>
                                        <th title="Time" className="sorting border-right" tabIndex="0" aria-controls="leads-table" rowSpan="1" colSpan="1" aria-label="Lead Agent: activate to sort column ascending">
                                            Time
                                        </th>
                                        <th title="Passenger" className="sorting border-right" tabIndex="0" aria-controls="leads-table" rowSpan="1" colSpan="1" aria-label="Lead Agent: activate to sort column ascending">
                                            Passenger
                                        </th>
                                        <th title="Pickup Drop Off" className="sorting border-right w-30" tabIndex="0" aria-controls="leads-table" rowSpan="1" colSpan="1" aria-label="Lead Agent: activate to sort column ascending">
                                            Pickup Drop Off
                                        </th>
                                        <th title="Vehicle/Driver" className="sorting border-right" tabIndex="0" aria-controls="leads-table" rowSpan="1" colSpan="1" aria-label="Lead Agent: activate to sort column ascending">
                                            Vehicle Type
                                        </th>
                                        <th title="Price" className="sorting border-right" tabIndex="0" aria-controls="leads-table" rowSpan="1" colSpan="1" aria-label="Lead Agent: activate to sort column ascending">
                                            Price
                                        </th>
                                        <th title="Status" className="sorting border-right" tabIndex="0" aria-controls="leads-table" rowSpan="1" colSpan="1" aria-label="Lead Agent: activate to sort column ascending">
                                            Status
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                </tbody>
                            </table>
                            {<div className="dash-stats-list" style={{ textAlign: "center" }}>
                                <br />
                                <br />
                                <img
                                    alt=""
                                    src={shift1}
                                    style={{ width: "145px", opacity: "0.5" }} />
                                <br />
                                <p style={{ opacity: "0.5" }}>
                                    No Search or No Report Found
                                </p>
                            </div>}
                            <div id="leads-table_processing" className="dataTables_processing card" style={{ display: "none" }}>Processing...</div>
                        </div>
                    </div>
                    <div className="d-flex border">
                        {/* <div className="flex-grow-1">
                            <div className="dataTables_info" id="leads-table_info" role="status" aria-live="polite">Showing 1 to 10 of 20 entries</div>
                        </div> */}
                        {/* <div>
                            <div className="dataTables_paginate paging_simple_numbers" id="leads-table_paginate">
                                <ul className="pagination">
                                    <li className="paginate_button page-item previous disabled" id="leads-table_previous">
                                        <Link to="" aria-controls="leads-table" data-dt-idx="0" tabIndex="0" className="page-link">Previous</Link>
                                    </li>
                                    <li className="paginate_button page-item active"><Link to="" aria-controls="leads-table" data-dt-idx="1" tabIndex="0" className="page-link">1</Link>
                                    </li>
                                    <li className="paginate_button page-item "><Link to="" aria-controls="leads-table" data-dt-idx="2" tabIndex="0" className="page-link">2</Link>
                                    </li>
                                    <li className="paginate_button page-item next" id="leads-table_next"><Link to="" aria-controls="leads-table" data-dt-idx="3" tabIndex="0" className="page-link">Next</Link>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}
export default AdminBasicReportTable