import axios from "axios";
// import { useSelector } from "react-redux";
import { notifyFailure, notifySuccess } from "../components/toast";
import {
  USER_AUTH_REQUEST,
  USER_AUTH_SUCCESS,
  USER_AUTH_FAIL,
  COMPANY_AUTH_REQUEST,
  COMPANY_AUTH_SUCCESS,
  COMPANY_AUTH_FAIL,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_FAIL,
  GET_USER_FAIL,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  FORGOT_PASSWORD_CREATE_REQUEST,
  FORGOT_PASSWORD_CREATE_SUCCESS,
  FORGOT_PASSWORD_CREATE_FAIL,
  RESET_PASSWORD_CREATE_REQUEST,
  RESET_PASSWORD_CREATE_SUCCESS,
  RESET_PASSWORD_CREATE_FAIL,
  GET_TOKEN_CREATE_REQUEST,
  GET_TOKEN_CREATE_SUCCESS,
  GET_TOKEN_CREATE_FAIL,
//   USER_LOGIN_INFO_CREATE_REQUEST,
//   USER_LOGIN_INFO_CREATE_SUCCESS,
//   USER_LOGIN_INFO_CREATE_FAIL,
//   UPDATE_USER_LOGIN_INFO_REQUEST,
//   UPDATE_USER_LOGIN_INFO_SUCCESS,
//   UPDATE_USER_LOGIN_INFO_FAIL,
//   SEND_VERIFICATION_EMAIL_REQUEST,
//   SEND_VERIFICATION_EMAIL_SUCCESS,
//   SEND_VERIFICATION_EMAIL_FAIL,
  ADMIN_COMPANY_INFO_VIEW_REQUEST,
  ADMIN_COMPANY_INFO_VIEW_SUCCESS,
  ADMIN_COMPANY_INFO_VIEW_FAIL,
  COMPANY_UPDATE_REQUEST,
  COMPANY_UPDATE_SUCCESS,
  COMPANY_UPDATE_FAIL,
  USER_LOGIN_INFO_CREATE_REQUEST,
  USER_LOGIN_INFO_CREATE_SUCCESS,
  USER_LOGIN_INFO_CREATE_FAIL,
  USER_LOGIN_ACTIVITY_VIEW_REQUEST,
  USER_LOGIN_ACTIVITY_VIEW_SUCCESS,
  USER_LOGIN_ACTIVITY_VIEW_FAIL,
  SERVERADDRESS,
} from "../constants"
// import { navigateUserFunc } from "./generalActions";


// USER LOGIN
export const userLogin = (obj, navigate) => async (dispatch) => {
  dispatch({
    type: USER_AUTH_REQUEST,
  });
  try {
    const { data } = await axios.post(`${SERVERADDRESS}/v1/auth/login`, obj);
    localStorage.setItem("tokens", JSON.stringify(data.tokens));
    localStorage.setItem("user", JSON.stringify(data.user));
    dispatch({
      type: USER_AUTH_SUCCESS,
      payload: data,
    });
    // navigateUser();
    // if (data.user === "dues not clear"){
    //   navigate('/dues')
    // }
    // else if (data.user.disable === false){
      // navigateUserFunc(data.user, navigate)
      if (data?.user?.permissionId?.dashboard?.isAllow === true) {
        navigate('/dashboard-admin')
      }
      else if (data?.user?.permissionId?.dashboardDriver?.isAllow === true) {
        navigate('/dashboard-driver')
      }
      else if (data?.user?.permissionId?.dashboardDispatcher?.isAllow === true) {
        navigate('/dashboard-dispatcher')
      }
      else if (data?.user?.isExternalCompany === true) {
        navigate('/clients-list')
      }
      // navigate('/dashboard')
    //   navigate()
    // }
    // else {
    //   navigate('/account-disable')
    // }
  }
  catch (err) {
    console.log(err)
    dispatch({
      type: USER_AUTH_FAIL,
      payload: err,
    });
  }
};


//USER LOGOUT
export const userLogout = (obj, navigateUser, idAdminLoginInfo) => async (dispatch) => {
  try {
    await axios.post(`${SERVERADDRESS}/v1/auth/logout`, obj);
    localStorage.removeItem("tokens");
    localStorage.removeItem("user");
    if (idAdminLoginInfo){
      await axios.put(`${SERVERADDRESS}/v1/userLoginInfo/updateUserLoginInfo`, 
        {
          _id: idAdminLoginInfo,
          loginStatus:false
        }
      )
    }
    // localStorage.removeItem("selectedPackage");
    dispatch({
      type: USER_LOGOUT_SUCCESS,
    });
    // console.log(localStorage, "dabba")
    localStorage.clear()
    // console.log(localStorage, "dabba2")
    // window.location.reload()
    navigateUser()
  } catch (err) {
    dispatch({
      type: USER_LOGOUT_FAIL,
      payload: err.response.data.message,
    });
  }
};


export const getUserByToken = (token, navigate, auth) => async (dispatch) => {
  navigate('/checkUser')
  dispatch({
    type: GET_USER_REQUEST,  
  });
  try {
    // const auth = useSelector((state) => state.auth);
    // console.log("ggggggggggggggggggggggggggg", auth)
    const { data } = await axios.post(`${SERVERADDRESS}/v1/auth/refresh-tokens`, {
      refreshToken: token,
    });
    // console.log(data, "dikha")
    localStorage.setItem("tokens", JSON.stringify(data));
    dispatch({
      type: GET_USER_SUCCESS,
      payload: data,
    });
    if (auth?.user?.permissionId?.dashboard?.isAllow === true) {
      navigate('/dashboard-admin')
    }
    else if (auth?.user?.permissionId?.dashboardDriver?.isAllow === true) {
      navigate('/dashboard-driver')
    }
    else if (auth?.user?.permissionId?.dashboardDispatcher?.isAllow === true) {
      navigate('/dashboard-dispatcher')
    }
    else if (auth?.user?.isExternalCompany === true) {
      navigate('/clients-list')
    }
    // navigateUserFunc(data.user, navigate)
    // navigate('/dashboard')
  } catch (err) {
    // localStorage.clear()
    // console.log("err=>", err);
    dispatch({
      type: GET_USER_FAIL,
      payload: err?.response?.data?.message,
    });
    navigate('/login')
    localStorage.clear()
    // console.log(localStorage)
  }
};


// USER UPDATE BY USER
export const updateUserById = (obj) => async (dispatch) => {
  dispatch({
    type: UPDATE_USER_REQUEST,
  });
  try {
    const { data } = await axios.patch(`${SERVERADDRESS}/v1/users/userById`, obj);
    localStorage.setItem("user", JSON.stringify(data));
    dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: data,
    });
  }
  catch (err) {
    console.log(err)
    dispatch({
      type: UPDATE_USER_FAIL,
      payload: err,
    });
  }
};


// COMPANY AUTH
export const companyById = (obj) => async (dispatch) => {
  dispatch({
    type: COMPANY_AUTH_REQUEST,
  });
  try {
    const { data } = await axios.post(`${SERVERADDRESS}/v1/company/companyById`, obj);
    localStorage.setItem("company", JSON.stringify(data));
      dispatch({
      type: COMPANY_AUTH_SUCCESS,
      payload: data,
    });
    // navigateUser();
  }
  catch (err) {
    console.log(err)
     dispatch({
      type: COMPANY_AUTH_FAIL,
      payload: err,
    });
  }
};


// FORGOT PASSWORD
export const forgotPassword = (obj) => async ( dispatch ) => {
  dispatch({
    type: FORGOT_PASSWORD_CREATE_REQUEST,
  })
  try {
    // const { data } = await axios.post(`${SERVERADDRESS}/v1/auth/forgot-password`, obj)
    const data2 = await axios.post(`${SERVERADDRESS}/v1/auth/forgot-password`, obj)
    // console.log(data2.status, 'jigaaaaaaa lala huuu jigaaaaa lalaaa')
      dispatch({
      type: FORGOT_PASSWORD_CREATE_SUCCESS,
      // payload: data,
    })
  }
  catch (err) {
    console.log(err)
    dispatch({
      type: FORGOT_PASSWORD_CREATE_FAIL,
      payload: err,
    })
  }
}


// RESET PASSWORD
export const resetPassword = (obj, token, navigate) => async ( dispatch ) => {
  dispatch({
    type: RESET_PASSWORD_CREATE_REQUEST,
  })
  try {
    const data = await axios.post(`${SERVERADDRESS}/v1/auth/reset-password?token=${token}`, obj)
    // console.log(data.status, 'maaain hooon reset password kay reducer ehhhh')
    dispatch({
    type: RESET_PASSWORD_CREATE_SUCCESS,
      // payload: data,
    })
  navigate('/login')
  }
  catch (err) {
    console.log(err)
    dispatch({
      type: RESET_PASSWORD_CREATE_FAIL,
      payload: err,
    })
  }
}


// GET TOKEN CREATE
export const getToken = (obj, navigate) => async ( dispatch ) => {
  dispatch({
      type: GET_TOKEN_CREATE_REQUEST,
  })
  try {
    const { data } = await axios.post(`${SERVERADDRESS}/v1/auth/getToken`, obj)
    if (data === 'Not Found'){
      navigate('/login')
    }
    dispatch({
      type: GET_TOKEN_CREATE_SUCCESS,
      payload: data,
    })
  }
  catch (err) {
    console.log(err)
    dispatch({
      type: GET_TOKEN_CREATE_FAIL,
      payload: err,
    })
  }
}

// ALL ADMIN COMPANY INFO DISPLAY VIEW
export const adminCompanyInfoUpdate = (obj) => async (dispatch) => {
  dispatch({
    type: ADMIN_COMPANY_INFO_VIEW_REQUEST,
  });
  try {
    const { data } = await axios.patch(
      `${SERVERADDRESS}/v1/users/userById`,
      obj
    );
    // localStorage.setItem("adminCompanyInfo", JSON.stringify(data));
    dispatch({
      type: ADMIN_COMPANY_INFO_VIEW_SUCCESS,
      payload: data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: ADMIN_COMPANY_INFO_VIEW_FAIL,
      payload: err,
    });
  }
};


export const updateCompany = (obj) => async (dispatch) => {
  dispatch({
    type: COMPANY_UPDATE_REQUEST,
  });
  try {
    const { data } = await axios.put(
      `${SERVERADDRESS}/v1/company/updateCompany`,
      obj
    );
    notifySuccess("Company updated successfully !");

    // handleSuccess();
    dispatch({
      type: COMPANY_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    console.log(err);
    notifyFailure(err?.response?.data?.message);

    dispatch({
      type: COMPANY_UPDATE_FAIL,
      payload: err,
    });
  }
};

// USER LOGIN INFO CREATE
export const createUserLoginInfo = (obj) => async ( dispatch ) => {
  dispatch({
    type: USER_LOGIN_INFO_CREATE_REQUEST,
  })
  try {
    const { data } = await axios.post(`${SERVERADDRESS}/v1/userLoginInfo/createUserLoginInfo`, obj)
    localStorage.setItem("activeUserCountry", JSON.stringify(data));
    dispatch({
      type: USER_LOGIN_INFO_CREATE_SUCCESS,
      payload: data,
    })
  }
  catch (err) {
    console.log(err)
    dispatch({
      type: USER_LOGIN_INFO_CREATE_FAIL,
      payload: err,
    })
  }
}


// // UPDATE USER LOGIN INFO 
// export const updateUserLoginInfo = (obj) => async (dispatch) => {
//   dispatch({
//       type: UPDATE_USER_LOGIN_INFO_REQUEST,
//   });
//   try {
//     const { data } = await axios.put(`${SERVERADDRESS}/v1/userLoginInfo/updateUserLoginInfo`, obj);
//     dispatch({
//       type: UPDATE_USER_LOGIN_INFO_SUCCESS,
//       payload: data,
//     });
//   }
//   catch (err) {
//     console.log(err)
//     dispatch({
//       type: UPDATE_USER_LOGIN_INFO_FAIL,
//       payload: err,
//     });
//   }
// };

// USER LOGIN ACTIVITY VIEW 
export const getAllUserLoginInforByCompanyId = (obj) => async (dispatch) => {
  dispatch({
      type: USER_LOGIN_ACTIVITY_VIEW_REQUEST,
  });
  try {
      const { data } = await axios.post(`${SERVERADDRESS}/v1/userLoginInfo/getAllUserLoginInforByCompanyId`, obj);
      localStorage.setItem("allUserLoginInActivity", JSON.stringify(data));
      dispatch({
          type: USER_LOGIN_ACTIVITY_VIEW_SUCCESS,
          payload: data,
      });
  }
  catch (err) {
      console.log(err)
      dispatch({
          type: USER_LOGIN_ACTIVITY_VIEW_FAIL,
          payload: err,
      });
  }
};
