import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { deletePermissionAction, getAllPermission } from '../../../actions/permissionActions'

const AdminPermissionDeleteModal = ({showDeleteDropDown, openDeleteDropDown, current, index}) => {
  const auth = useSelector((state) => state.auth);
  const permission = useSelector((state) => state.permission);
  const [values, setValues] =useState({
    _id: '',
    delete: '',
  })
  useEffect(()=>{
    setValues({...values, _id: current?.permission?.id})
  },[current])
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  const data = {
    _id: values._id,
    delete: true,
  }
  const dispatch = useDispatch()
  const valueUpdate = () => {
    if(current !== undefined){
        setValues({
            _id: current?.permission?.id,
            delete: true,
        })
    }
  }
    useEffect(() => {
      valueUpdate()
  }, [current])
  const handleSubmit = async (event) => {
    event.preventDefault()
    dispatch(deletePermissionAction(values))
    openDeleteDropDown()
  }
  return (
    <>
      <div
        className={`modal fade ${showDeleteDropDown === true ? "show modal-y-axis" : "hide"}`}
        role="dialog"
        >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <form
            onSubmit={handleSubmit}
            >
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  {" "}
                  Delete Permission
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => openDeleteDropDown()}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body" style={{width: "50% !important"}}>
                <div className="row">
                  <div className="col-md-12">
                    <p>Are you sure you want to delele? </p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn-secondary rounded f-14 p-2 mr-3"
                  data-dismiss="modal"
                  onClick={() => openDeleteDropDown()}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn-primary rounded f-14 p-2 mr-3"
                >
                  Yes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
export default AdminPermissionDeleteModal