import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getExternalCompanies } from "../../../actions/companyAction";
import { editVehicleAction } from "../../../actions/vehicleActions";
import moment from "moment";

const AdminSettingVehicleEditComponent = ({ state, handleModal }) => {
    const navigate = useNavigate();
    const service = useSelector((state) => state.service);
    const vehicle = useSelector((state) => state.vehicle);


    const externalCompanies = useSelector((state) => state.externalCompanies);
    const auth = useSelector((state) => state.auth);
  
    useEffect(() => {
      dispatch(getExternalCompanies({ companyId: auth.user.companyId.id }));
    }, [auth]);
  
    let [selectedCompanyId, setSelectedCompanyId] = useState("");
    const [data, setData] = useState({});
    const handleChange = (event) => {
        setData({
          ...data,
          [event.target.name]: event.target.value,
        });
      };
      const handleChangeType = (fieldName) => (event) =>{
        setData({
          ...data, [fieldName]:
          event.target.value
        })
      }


      const [serviceNames, setServiceNames] = useState([])
      const handleChangeServiceName = (fieldName) => (event) => {
        const sNames = service?.service.find((x) => x.id === event.target.value)
        setData({
            ...data, [fieldName]:
            event.target.value,
            ['fullName']: sNames?.serviceName[0]?.fullName,
        })
        setServiceNames(sNames.serviceName)
      }

      const dispatch = useDispatch();
      
      const vehicleData = {
        _id: data.id,
        // assetNo: data.assetNo,

        vehicleOwnership: data.vehicleOwnership,
        serviceType: data.serviceType,
        serviceName: (data.serviceName === '' || !data.serviceName) ? serviceNames[0]?.fullName : data.serviceName,

        make: data.make,
        model: data.model,
        year: data.year,
        vin: data.vin,
        numberPlate: data.numberPlate,
        color: data.color,
        mileage: data.mileage,
        seats: data.seats,
        // inspectionExpiration: "2023-12-31",
        inspectionExpiration: data.inspectionExpiration,
        insurancePolicyNumber: data.insurancePolicyNumber,
        insuranceCompanyName: data.insuranceCompanyName,
        // insuranceExpirationDate: "2023-12-31",
        insuranceExpirationDate: data.insuranceExpirationDate,
        //   brakesExpiration: "2023-12-31",
        brakesExpiration: data.brakesExpiration,
      };
      // console.log(data, "serviceTypeserviceTypeserviceTypeserviceType")
      
      const handleSubmit = async (event) => {
        event.preventDefault();
        if (vehicleData?.serviceType?.id){
          delete vehicleData.serviceType
        }
        dispatch(editVehicleAction(vehicleData, handleSuccess));
      };
      const handleSuccess = () => {
        handleModal(null);
      };
      
    
      useEffect(() => {
        if (state) { setData(state);
          const sNames = service?.service.find((x) => x.id === state.serviceType.id)
        setServiceNames(sNames.serviceName)

        }
        else navigate("/vehcile");

      }, [state]);

  return (
    <>
        <div className="d-flex flex-column w-tables rounded mt-3 bg-white p-3">
        <h4>Edit Vehicle</h4>
        <div
          id="leads-table_wrapper"
          className="dataTables_wrapper dt-bootstrap4 no-footer"
        >
          <div className="row p-3">
            <div className="col-lg-12">
                <div className="col-12 px-0 d-lg-flex d-md-flex d-block">
                  <p className="mb-0 f-14 text-capitalize"><strong style={{fontSize: "18px"}}>Basic {" "}</strong></p>
                </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark-grey mb-12"
                  data-label=""
                  htmlFor="after_days"
                >
                  Asset#
                </label>
                <sup className="text-danger">*</sup>
                <input
                  type="text"
                  className="form-control height-35 f-14"
                  placeholder="Enter The Asset No."
                  autoComplete="false"
                  value={data.assetNo}
                  name="assetNo"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark-grey mb-12"
                  data-label=""
                  htmlFor="after_days"
                >
                  Type
                </label>
                <sup className="text-danger">*</sup>
                <div className="select-picker">
                  <select
                    className="form-control select-picker"
                    style={{ height: "39px" }}
                    value={data.vehicleOwnership}
                    onChange={handleChangeType('vehicleOwnership')}
                  >
                    <option value="Office"> Office</option> 
                    <option value="Contractor"> Contractor</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark-grey mb-12"
                  data-label=""
                  htmlFor="after_days"
                >
                  Service Type
                </label>
                <sup className="text-danger">*</sup>
                <div className="select-picker">
                  <select
                    className="form-control select-picker"
                    style={{ height: "39px" }}
                    value={data?.serviceType?.id}
                    onChange={handleChangeServiceName('serviceType')}
                  >
                    {
                      service &&
                      service.service &&
                      service.service.length > 0 &&
                      service.service.map((current, i) => {
                        return(
                          <option value={current.id} key={i}> {current.name}</option> 
                        )
                      })
                    }
                  </select>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark-grey mb-12"
                  data-label=""
                  htmlFor="after_days"
                >
                  Service Name
                </label>
                <sup className="text-danger">*</sup>
                 <div className="select-picker">
                  <select
                    className="form-control select-picker"
                    style={{ height: "39px" }}
                    value={data?.serviceName}
                    onChange={handleChangeType('serviceName')}
                  >
                  {
                    serviceNames.length > 0 &&
                    serviceNames.map((current, i) => {
                        return(
                          <option value={current?.fullName} key={i}> {current?.fullName}</option> 
                        )
                    })
                  } 
                  </select>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark-grey mb-12"
                  data-label=""
                  htmlFor="after_days"
                >
                  Seats
                </label>
                <sup className="text-danger">*</sup>
                <input
                  type="text"
                  className="form-control height-35 f-14"
                  placeholder="Seats"
                  autoComplete="false"
                  value={data.seats}
                  name="seats"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>


            <div className="col-lg-12">
                <div className="col-12 px-0 d-lg-flex d-md-flex d-block">
                  <p className="mb-0 f-14 text-capitalize"><strong style={{fontSize: "18px"}}>Vehicle Information {" "}</strong></p>
                </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark-grey mb-12"
                  data-label=""
                  htmlFor="after_days"
                >
                  Make
                </label>
                <sup className="text-danger">*</sup>
                <input
                  type="text"
                  className="form-control height-35 f-14"
                  placeholder="Enter The Make"
                  autoComplete="false"
                  value={data.make}
                  name="make"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark-grey mb-12"
                  data-label=""
                  htmlFor="after_days"
                >
                  Model
                </label>
                <sup className="text-danger">*</sup>
                <input
                  type="text"
                  className="form-control height-35 f-14"
                  placeholder="Enter The Model"
                  autoComplete="false"
                  value={data.model}
                  name="model"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark-grey mb-12"
                  data-label=""
                  htmlFor="after_days"
                >
                  Year
                </label>
                <sup className="text-danger">*</sup>
                <input
                  type="text"
                  className="form-control height-35 f-14"
                  placeholder="Enter The Year"
                  autoComplete="false"
                  value={data.year}
                  name="year"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark-grey mb-12"
                  data-label=""
                  htmlFor="after_days"
                >
                  VIN
                </label>
                <sup className="text-danger">*</sup>
                <input
                  type="text"
                  className="form-control height-35 f-14"
                  placeholder="Enter The VIN"
                  autoComplete="false"
                  value={data.vin}
                  name="vin"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark-grey mb-12"
                  data-label=""
                  htmlFor="after_days"
                >
                  Plate
                </label>
                <sup className="text-danger">*</sup>
                <input
                  type="text"
                  className="form-control height-35 f-14"
                  placeholder="Enter The Plate"
                  autoComplete="false"
                  value={data.numberPlate}
                  name="plate"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark-grey mb-12"
                  data-label=""
                  htmlFor="after_days"
                >
                  Color
                </label>
                <sup className="text-danger">*</sup>
                <input
                  type="text"
                  className="form-control height-35 f-14"
                  placeholder="Enter The Color"
                  autoComplete="false"
                  value={data.color}
                  name="color"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark-grey mb-12"
                  data-label=""
                  htmlFor="after_days"
                >
                  Mileage
                </label>
                <sup className="text-danger">*</sup>
                <input
                  type="text"
                  className="form-control height-35 f-14"
                  placeholder="Enter The Mileage"
                  autoComplete="false"
                  value={data.mileage}
                  name="mileage"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark-grey mb-12"
                  data-label=""
                  htmlFor="after_days"
                >
                  Vehicle Inspections Expiration
                </label>
                <sup className="text-danger">*</sup>
                <input
                  type="date"
                  className="form-control height-35 f-14"
                  placeholder=""
                  // autoComplete="false"
                  defaultValue={data?.inspectionExpiration?.substring(0,10)}
                  // name="inspectionExpiration"
                  onChange={handleChange}
                  // required
                  // disabled
                />
              </div>
            </div>
            <div className="col-lg-12">
                <div className="col-12 px-0 d-lg-flex d-md-flex d-block">
                  <p className="mb-0 f-14 text-capitalize"><strong style={{fontSize: "18px"}}>Insurance {" "}</strong></p>
                </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark-grey mb-12"
                  data-label=""
                  htmlFor="after_days"
                >
                  Insurance Company Name
                </label>
                <sup className="text-danger">*</sup>
                <input
                  type="text"
                  className="form-control height-35 f-14"
                  placeholder="Insurance Company Name"
                  autoComplete="false"
                  value={data.insuranceCompanyName}
                  name="insuranceCompanyName"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark-grey mb-12"
                  data-label=""
                  htmlFor="after_days"
                >
                  Insurance Policy Number
                </label>
                <sup className="text-danger">*</sup>
                <input
                  type="text"
                  className="form-control height-35 f-14"
                  placeholder="Insurance Policy Number"
                  autoComplete="false"
                  value={data.insurancePolicyNumber}
                  name="insurancePolicyNumber"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark-grey mb-12"
                  data-label=""
                  htmlFor="after_days"
                >
                  Insurance Expiration Date
                </label>
                <sup className="text-danger">*</sup>
                <input
                  type="date"
                  className="form-control height-35 f-14"
                  placeholder=""
                  // autoComplete="false"
                  defaultValue={data?.insuranceExpirationDate?.substring(0,10)}
                  name="insuranceExpirationDate"
                  onChange={handleChange}
                  // required
                  // disabled
                />
              </div>
            </div>
            <div className="col-lg-12">
                <div className="col-12 px-0 d-lg-flex d-md-flex d-block">
                  <p className="mb-0 f-14 text-capitalize"><strong style={{fontSize: "18px"}}>Brakes Expiration {" "}</strong></p>
                </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark-grey mb-12"
                  data-label=""
                  htmlFor="after_days"
                >
                  Brakes Expiration Date
                </label>
                <sup className="text-danger">*</sup>
                <input
                  type="date"
                  className="form-control height-35 f-14"
                  placeholder=""
                  // autoComplete="false"
                  defaultValue={data?.brakesExpiration?.substring(0,10)}
                  name="brakesExpiration"
                  onChange={handleChange}
                  // required
                  // disabled
                />
              </div>
            </div>

            <div className="col-lg-12">            
            </div>
            <br />
            <div className="col-lg-12">
              <div
                className="form-group "
                style={{ marginTop: "1.5em !important" }}
              >
                <button
                  type="button"
                  className="btn-primary rounded f-14 p-2 mr-3"
                  onClick={handleSubmit}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default AdminSettingVehicleEditComponent