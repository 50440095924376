import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { getAllRoutesOfCompany } from "../../../actions/routeActions";
import RoutesTableRow from "./RoutesTableRow";
import { Link } from "react-router-dom";
import ArrangeOrderModalV2 from "./ArrangeOrderModalV2";
import LoadingComponent from "../../../components/LoadingComponent";
import shift1 from "../../../assets/images/load.png";
import AdminRouteDeleteModal from "./AdminRouteDeleteModal";

// import ArrangeOrderModal from "./ArrangeOrderModal";
// import moment from "moment";

const RoutesTable = () => {
  const auth = useSelector((state) => state.auth);
  const routes = useSelector((state) => state.routes);

  const { loading, results, error } = routes;

  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [selectedRoutePassengersArr, setSelectedRoutePassengersArr] = useState(
    []
  );

  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);

  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };

  const dispatch = useDispatch();

  const editOrderHandler = (routeData) => {
    setSelectedRoute(routeData);
    setSelectedRoutePassengersArr(routeData.customerDetails);
    setShowModal(true);
  };

  const deleteRouteHandler = (routeData) => {
    setSelectedRoute(routeData);
    // setSelectedRoutePassengersArr(routeData.customerDetails);
    setShowDeleteModal(true);
  };

  useEffect(() => {
    // console.log("ccccccc")
    dispatch(
      getAllRoutesOfCompany({
        companyId: auth.user.companyId.id,
        limit,
        page: currentPageNumber,
      })
    );
  }, []);

  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [limit, setLimit] = useState(10);
  const handleLimit = async (event) => {
    setLimit(parseInt(event.target.value));
    // console.log(typeof(auth?.user?.companyId?.id), "typeof")
    dispatch(
      getAllRoutesOfCompany({
        companyId: auth.user.companyId.id,
        limit: event.target.value,
        page: 1,
      })
    );
    setCurrentPageNumber(1);
  };
  const onClickNext = async () => {
    let i = currentPageNumber;
    i++;
    if (i <= routes.totalPages) {
      setCurrentPageNumber(currentPageNumber + 1);
      dispatch(
        getAllRoutesOfCompany({
          companyId: auth?.user?.companyId?.id,
          limit,
          page: currentPageNumber + 1,
        })
      );
    }
  };
  const onClickPrev = async () => {
    let i = currentPageNumber;
    i--;
    if (i >= 1) {
      setCurrentPageNumber(currentPageNumber - 1);
      dispatch(
        getAllRoutesOfCompany({
          companyId: auth?.user?.companyId?.id,
          limit,
          page: currentPageNumber - 1,
        })
      );
    }
  };
  const handleChangeData = async (pageNo) => {
    setCurrentPageNumber(pageNo);
    dispatch(
      getAllRoutesOfCompany({
        companyId: auth?.user?.companyId?.id,
        limit,
        page: pageNo,
      })
    );
  };
  // console.log(reservation, "djdjdjjdjjdjdjdjdjdjdjdjdjdjdjdjdjdjd")
  return (
    <>
      {" "}
      {loading && results?.length == 0 && <LoadingComponent />}
      <div className="d-flex flex-column w-tables rounded mt-3 bg-white">
        <div className="d-flex border">
          <div className="flex-grow-1">
            <div className="dataTables_length d-flex" id="leads-table_length">
              <div className="p-2">
                <label>Show entries</label>
              </div>
              <label>
                <select
                  name="leads-table_length"
                  aria-controls="leads-table"
                  className="custom-select custom-select-sm form-control form-control-sm"
                  autoComplete="false"
                  onChange={handleLimit}
                >
                  {[10, 25, 50, 100].map((current, i) => {
                    return (
                      <option value={current} key={i}>
                        {current}
                      </option>
                    );
                  })}
                </select>
              </label>
            </div>
          </div>
        </div>
        <div
          id="leads-table_wrapper"
          className="dataTables_wrapper dt-bootstrap4 no-footer"
        >
          <div className="row">
            <div className="col-sm-12">
              <table
                className="table border w-100 dataTable no-footer"
                id="leads-table"
                role="grid"
                aria-describedby="leads-table_info"
                style={{ overflowX: "auto" }}
              >
                <thead className="card-header">
                  <tr role="row">
                    <th
                      title="(Res#)"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Mobile: activate to sort column ascending"
                    >
                      Route #
                    </th>

                    <th
                      title="Time"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      No. of Passengers
                    </th>
                    {/* <th
                      title="Passenger"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      School
                    </th> */}

                    <th
                      title="Status"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Status
                    </th>
                    <th
                      title="Passenger"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Details
                    </th>
                    <th
                      title="Passenger"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Driver
                    </th>
                    <th
                      title="Passenger"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Driver status
                    </th>
                    <th
                      title="Passenger"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Change status
                    </th>
                    <th
                      title="Action"
                      className="text-right pr-20 sorting_disabled border-right"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Action"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {results &&
                    results.length > 0 &&
                    results
                      .filter((x) => !x.delete)
                      ?.map((current, i) => {
                        return (
                          <RoutesTableRow
                            current={current}
                            i={i}
                            key={i}
                            editOrderHandler={editOrderHandler}
                            deleteRouteHandler={deleteRouteHandler}
                          />
                        );
                      })}
                </tbody>
              </table>
              {results && results.length === 0 && (
                <div
                  className="dash-stats-list"
                  style={{ textAlign: "center" }}
                >
                  <br />
                  <br />
                  <img
                    alt=""
                    src={shift1}
                    style={{ width: "145px", opacity: "0.5" }}
                  />
                  <br />
                  <p style={{ opacity: "0.5" }}>No Data Found</p>
                </div>
              )}
            </div>
          </div>
          <div classNxame="d-flex border">
          {
            routes.loading === false ?
            <div className="flex-grow-1">
              <div
                className="dataTables_info"
                id="leads-table_info"
                role="status"
                aria-live="polite"
              >
                Showing {1 + limit * (currentPageNumber - 1)} to{" "}
                {routes.displayingResults + limit * (currentPageNumber - 1)} of{" "}
                {routes.totalResults} entries
              </div>
            </div>
            :
            <div className="flex-grow-1">
              <div
                className="dataTables_info"
                id="leads-table_info"
                role="status"
                aria-live="polite"
              >
                Loading entries
              </div>
            </div>
          }
            <div>
              <div
                className="dataTables_paginate paging_simple_numbers"
                id="leads-table_paginate"
              >
                <ul className="pagination">
                  <li
                    className="page-item"
                    id="leads-table_previous"
                  >
                    <Link
                      to=""
                      aria-controls="leads-table"
                      data-dt-idx="0"
                      tabIndex="0"
                      className="page-link"
                      onClick={onClickPrev}
                    >
                      Previous
                    </Link>
                  </li>
                  {routes &&
                    routes.paginationArr &&
                    routes.paginationArr.length > 0 &&
                    routes.paginationArr.map((current, i) => {
                      return currentPageNumber === current ? (
                        <li className="page-item active" key={i}>
                          <Link className="page-link" value={current} to="">
                            {current}
                          </Link>
                        </li>
                      ) : (
                        <li className="page-item" key={i}>
                          <Link
                            className="page-link"
                            to=""
                            value={current}
                            onClick={() => handleChangeData(current)}
                          >
                            {current}
                          </Link>
                        </li>
                      );
                    })}
                  <li
                    // className="paginate_button page-item next"
                    className="page-item"
                    id="leads-table_next"
                  >
                    <Link
                      to=""
                      aria-controls="leads-table"
                      data-dt-idx="3"
                      tabIndex="0"
                      className="page-link"
                      onClick={onClickNext}
                    >
                      Next
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      <ArrangeOrderModalV2
        showModal={showModal}
        setShowModal={setShowModal}
        selectedRoute={selectedRoute}
        setSelectedRoute={setSelectedRoute}
      />
      <AdminRouteDeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        selectedRoute={selectedRoute}
        setSelectedRoute={setSelectedRoute}
      />
    </>
  );
};
export default RoutesTable;
