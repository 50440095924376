import axios from "axios";
import {
  CARDS_DATA_VIEW_REQUEST,
  CARDS_DATA_VIEW_SUCCESS,
  CARDS_DATA_VIEW_FAIL,
  CARDS_DATA2_VIEW_REQUEST,
  CARDS_DATA2_VIEW_SUCCESS,
  CARDS_DATA2_VIEW_FAIL,
  TODAY_RESERVATION_VIEW_REQUEST,
  TODAY_RESERVATION_VIEW_SUCCESS,
  TODAY_RESERVATION_VIEW_FAIL,
  WEEK_RESERVATION_COUNT_VIEW_REQUEST,
  WEEK_RESERVATION_COUNT_VIEW_SUCCESS,
  WEEK_RESERVATION_COUNT_VIEW_FAIL,
  CUSTOMER_SEARCH_VIEW_REQUEST,
  CUSTOMER_SEARCH_VIEW_SUCCESS,
  CUSTOMER_SEARCH_VIEW_FAIL,
  RESERVATION_REPORT_SEARCH_REQUEST,
  RESERVATION_REPORT_SEARCH_SUCCESS,
  RESERVATION_REPORT_SEARCH_FAIL,
  SERVERADDRESS,
} from "../constants";

// ALL CARDS DATA DISPLAY VIEW
export const getAllCardsData = (obj) => async (dispatch) => {
  dispatch({
    type: CARDS_DATA_VIEW_REQUEST,
  });
  try {
    const { data } = await axios.post(`${SERVERADDRESS}/v1/kpi/cardsData`, obj);
    localStorage.setItem("cardsData", JSON.stringify(data));
    dispatch({
      type: CARDS_DATA_VIEW_SUCCESS,
      payload: data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: CARDS_DATA_VIEW_FAIL,
      payload: err,
    });
  }
};

// ALL CARDS DATA2 DISPLAY VIEW
export const getAllCardsData2 = (obj) => async (dispatch) => {
  dispatch({
    type: CARDS_DATA2_VIEW_REQUEST,
  });
  try {
    const { data } = await axios.post(`${SERVERADDRESS}/v1/kpi/cardsData2`, obj);
    localStorage.setItem("cardsData2", JSON.stringify(data));
    dispatch({
      type: CARDS_DATA2_VIEW_SUCCESS,
      payload: data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: CARDS_DATA2_VIEW_FAIL,
      payload: err,
    });
  }
};

// ALL TODAY RESERVATION DISPLAY VIEW
export const getAllTodayReservation = (obj) => async (dispatch) => {
  dispatch({
    type: TODAY_RESERVATION_VIEW_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/kpi/todayReservation`,
      obj
    );
    localStorage.setItem("todayReservation", JSON.stringify(data));
    dispatch({
      type: TODAY_RESERVATION_VIEW_SUCCESS,
      payload: data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: TODAY_RESERVATION_VIEW_FAIL,
      payload: err,
    });
  }
};

// ALL WEEK RESERVATION DISPLAY VIEW
export const getAllWeekReservationCount = (obj) => async (dispatch) => {
  dispatch({
    type: WEEK_RESERVATION_COUNT_VIEW_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/kpi/weekReservationCount`,
      obj
    );
    localStorage.setItem("weekReservationCount", JSON.stringify(data));
    dispatch({
      type: WEEK_RESERVATION_COUNT_VIEW_SUCCESS,
      payload: data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: WEEK_RESERVATION_COUNT_VIEW_FAIL,
      payload: err,
    });
  }
};

// SEARCH CUSTOMER DATA
export const clientSearchFilter = (obj) => async (dispatch) => {
  // removing loadingHandler for time being
  // loadingHandler(true);   
  dispatch({
    type: CUSTOMER_SEARCH_VIEW_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/kpi/clientSearchFilter`,
      obj
    );
    localStorage.setItem("searchedCustomer", JSON.stringify(data));
    // loadingHandler(false);
    dispatch({
      type: CUSTOMER_SEARCH_VIEW_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // loadingHandler(false);
    dispatch({
      type: CUSTOMER_SEARCH_VIEW_FAIL,
      payload: err,
    });
  }
};


// ADMIN RESERVATION SEARCH REPORT
export const reservationReportSearch = (obj) => async (dispatch) => {
  dispatch({
    type: RESERVATION_REPORT_SEARCH_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/kpi/reservationReportSearch`,
      obj
    );
    // localStorage.setItem("reservationReportSearch", JSON.stringify(data));
    dispatch({
      type: RESERVATION_REPORT_SEARCH_SUCCESS,
      payload: data,
    });
    // arrReassign()
  } catch (err) {
    console.log(err);
    dispatch({
      type: RESERVATION_REPORT_SEARCH_FAIL,
      payload: err,
    });
  }
};
