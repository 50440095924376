import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import moment from 'moment'
import { addTrip } from '../../../actions/tripActions';
import { markedLocation, updateTrip } from '../../../actions/reservationAction';
import { NavLink } from 'react-router-dom';

const DriverDashboardTableRow = ({current, i, dropDownRow, setDropDownRow}) => {
  const trip = useSelector((state) => state.trip);
  const auth = useSelector((state) => state.auth);
  const [values, setValues] = useState({
    companyId: '',
    reservationId: '',
    tripStartTime: '',
  })
  const handleChange = (fieldName) => (event) => {
    setValues({
        ...values, [fieldName]:
        event.target.value
    })
  }
  console.log(values)
  useEffect(() => {
    setValues({
        ...values, 
        reservationId: current.reservation?.id
    })
  }, [current])
  const [values2, setValues2] = useState({
    _id: '',
    // arrivedTime: '',
    // pickUpTime: '',
    // pickUpLat: '',
    // pickUpLng: '',
    // dropOffTime: '',
    // dropOffLat: '',
    // dropOffLng: '',
    // waitingTimePickUp: '',
    // waitingTimePickUpInDecimal: '',
    // tripDuration: '',
    // tripDurationInDecimal: '',
    // tripStatus: '',
    // tripCancel: '',
    // isCompleted: '',
  })
  useEffect(() => {
    if (current.trip !== null){
      setValues2({
        ...values2, 
        _id: current.trip?.id,
        // arrivedTime: current.trip?.arrivedTime,
        // pickUpTime: current.trip?.pickUpTime,
        // pickUpLat: current.trip?.pickUpLat,
        // pickUpLng: current.trip?.pickUpLng,
        // dropOffTime: current.trip?.dropOffTime,
        // dropOffLat: current.trip?.dropOffLat,
        // dropOffLng: current.trip?.dropOffLng,
        // waitingTimePickUp: current.trip?.waitingTimePickUp,
        // waitingTimePickUpInDecimal: current.trip?.waitingTimePickUpInDecimal,
        // tripDuration: current.trip?.tripDuration,
        // tripDurationInDecimal: current.trip?.tripDurationInDecimal,
        // tripStatus: current.trip?.tripStatus,
        // tripCancel: current.trip?.tripCancel,
        // isCompleted: current.trip?.isCompleted,
      })
    }
  }, [current])
  const dispatch = useDispatch()
  const onClickNavigation = () => {
    dispatch(markedLocation(current));
  }
  const tripData = {
      companyId: auth.user.companyId.id,
      driverId: auth.user.id,
      reservationId: values.reservationId,
      // pickUpLat: 24.9261844, 
      // pickUpLng: 67.1356306,
      tripStartTime: moment().format().toString().substring(0,19)+".000Z",
  }
  // console.log(values2, "sssssssssssssssssssssssssssssssssssssss")
  const handleSubmit = async (event) => {
    event.preventDefault()
    console.log("first")
    dispatch(addTrip(tripData))
    setValues({
        assetNo: '',
        serviceType: '',
        vehicleOwnership: '',
    })
  }

  const handleArrived = async (event) => {
    dispatch(updateTrip({_id: values2._id, 
      arrivedTime: moment().format().toString().substring(0,19)+".000Z",
      tripStatus: "Arrived"
    }))
  }

  const handlePickUp = async (event) => {
    dispatch(updateTrip({_id: values2._id, 
      pickUpTime: moment().format().toString().substring(0,19)+".000Z",
      tripStatus: "Ongoing",
      pickUpLat: 24.9261844, 
      pickUpLng: 67.1356306,
    }))
  }

  const handleEndRide = async (event) => {
    dispatch(updateTrip({_id: values2._id, 
      dropOffTime: moment().format().toString().substring(0,19)+".000Z",
      tripStatus: "Completed",
      dropOffLat: 24.9261844, 
      dropOffLng: 67.1356306,
      isCompleted: true
    }))
  }

  return (
    <>
      <tr role="row" key={i} className="odd border">
        <td className="border-right">{current.reservation?.orderId}
          <i data-toggle="tooltip" className=""></i>
        </td>
        <td className="border-right">{current.reservation?.customerId.firstName + " " + current.reservation?.customerId.lastName}</td>
        <td className="border-right"> {current.reservation?.customerId.phone}</td>
        <td className="border-right">
          {current.reservation?.assetId.assetNo}
          <i data-toggle="tooltip" className=""></i>
          {/* <i data-toggle="tooltip" className="fa fa-info-circle fa-w-16 text-info"></i> */}
        </td>
        <td className="border-right w-30">
          <div className="d-flex justify-content-between">
            <div>
              <div>
                <p className="f-12 font-medium mb-0"><strong>{current.reservation?.pickUpAddress.address}</strong> </p>
                {/* <p className="f-12 mb-0">IL 60609, USA</p> */}
              </div>
              <div>
                <time dateTime="2022-11-03">{moment(current.reservation?.pickUpDate?.substring(0, 10)).format("MM/DD/YYYY")} - {current.reservation?.pickUpTime}{parseInt(current.reservation?.pickUpTime.substring(0, 2)) > 11 ? "pm" : 'am'}</time>
              </div>
            </div>
            <div className="mt-4 px-4">
              <div className="hidden sm:block">
                <svg xmlns="http://www.w3.org/2000/svg" width="80" height="12" viewBox="0 0 40 6" fill="none">
                  <path d="M40 3L35 0.113249V5.88675L40 3ZM0 3.5H35.5V2.5H0V3.5Z" fill="black"></path>
                </svg>
              </div>
              <p className="text-center font-medium">
                <span>{current.reservation?.totalMileage} mi</span>
              </p>
            </div>
            <div>
              <div>
                <p className="f-12 font-medium mb-0"><strong>{current.reservation?.dropOffAddress.address}</strong></p>
                {/* <p className="f-12 mb-0">IL 60623</p> */}
              </div>
              <div>
                <time dateTime="2022-11-03">{moment(current.reservation?.dropOffDate?.substring(0, 10)).format("MM/DD/YYYY")} - {current.reservation?.dropOffTime}{parseInt(current.reservation?.dropOffTime.substring(0, 2)) > 11 ? "pm" : 'am'}</time>
              </div>
            </div>
          </div>
        </td>
        <td className="border-right">{moment(current.reservation?.dateForReservation?.substring(0, 10)).format('MM/DD/YYYY')}<br /> {current.reservation?.pickUpTime}{parseInt(current.reservation?.pickUpTime.substring(0, 2)) > 11 ? "pm" : 'am'}</td>
        {/* <td className="border-right"> {current.reservation?.driverBookingStatus} </td> */}
        <td className="border-right text-center" >
          <NavLink to={"/navigate-reservation"} onClick={onClickNavigation} type="button" className="btn btn-info" style={{cursor:"default"}} >Navigate</NavLink>
        </td>
        {
          (auth?.user?.permissionId?.myOrder?.create === true || auth?.user?.permissionId?.myOrder?.update === true) &&
          (current.reservation?.status === "Completed" || current.trip?.tripStatus === "Completed") ?
          <td className="border-right text-center" >
            <button type="button" className="btn btn-success" style={{cursor:"default"}} >Completed</button>
          </td>
        // }
        // {
          // current.reservation?.status !== "Completed" &&
        :
          current.reservation?.status === "Cancelled" ?
          <td className="border-right text-center" >
            <button type="button" className="btn btn-info" style={{cursor:"default"}} >Cancelled</button>
          </td>
        :
          current &&
          current.trip === null ? 
          <td className="border-right text-center" >
            <button type="button" className="btn btn-primary" onClick={handleSubmit}>Start Ride</button>
          </td>
          :
          current.trip?.tripStatus === "Towards Customer" ?
          <td className="border-right text-center" >
            <button type="button" className="btn btn-dark" onClick={handleArrived}>Arrived</button>
          </td>
          :
          current.trip?.tripStatus === "Arrived" ?
          <>
            <td className="border-right text-center" >
              <button type="button" className="btn btn-secondary" onClick={handlePickUp}>Pick Customer</button>
            </td>
            {/* <td className="border-right text-center" >
              <button type="button" className="btn btn-danger" onClick={handleSubmit}>Cancel</button>
            </td> */}
          </>
          :
          current.trip?.tripStatus === "Ongoing" ?
          <td className="border-right text-center" >
            <button type="button" className="btn btn-warning" onClick={handleEndRide}>Ride End</button>
          </td>
          :
          <></>
        }
        </tr>
    </>
  )
}
export default DriverDashboardTableRow