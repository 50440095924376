import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import AdminHeader from '../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminHeader'
import AdminSidebar from '../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminSidebar'
import AdminReportTableView from '../../components/adminComponents/adminReportComponents/AdminReportTableView'
import AdminReportSearchandFilter from '../../components/adminComponents/adminReportComponents/AdminReportSearchandFilter'
import AdminReportSearchTable from '../../components/adminComponents/adminReportComponents/AdminReportSearchTable'
import { useNavigate } from 'react-router-dom'
import { navigateUserFunc } from '../../actions/generalActions'
import NotAllowed from '../../components/NotAllowed'

const AdminReportsPage = () => {
  const myState = useSelector((state => state.changeTheTab))
  let navigate = useNavigate();
  const auth = useSelector((state) => state?.auth);
  useEffect(() => {
    if (auth?.user?.permissionId?.reports?.isAllow === false){
      navigateUserFunc(auth?.user, navigate)
    }
  },[])
  return (
    <>
      <AdminHeader />
      <AdminSidebar />
      <div className="body-wrapper clearfix">
        <section className="main-container " id="fullscreen">
          <div className="px-4 py-2 border-top-0 emp-dashboard">
            <div className="d-lg-flex d-md-flex d-block py-4">
              <div>
                <h4 className=" mb-0 f-21 text-capitalize font-weight-bold">Reports</h4>
              </div>
            </div>
            {
              auth?.user?.permissionId?.reports?.create === true ?
              <AdminReportTableView />
              :
              <NotAllowed />
            }
          {/* 
            {
              myState.viewReport === "reportView" ?
              <>
                <AdminReportSearchandFilter />
                <AdminReportTableView />
              </>
              :
              myState.viewReport === "reportSearch" ?
              <>
                <AdminReportSearchandFilter />
                <AdminReportSearchTable />
              </> 
              :
              <div>Loading</div>
            }  
            */}
          </div>
        </section>
      </div>
    </>
  )
}
export default AdminReportsPage