import moment from 'moment'
import React from 'react'
import { useLocation } from 'react-router-dom'
// import img1 from '../../../assets/images/img1.jpg'

const AdminSettingVehicleFullDetailComponent = () => {
    const { state } = useLocation()

  return (
    <>
        <div className="row emp-dash-detail">
            <div className="col-xl-12 col-md-6 mb-4 mb-lg-0">
                <div className="card bg-white border-0 b-shadow-4 mb-4">
                    {/* <div className="card-header bg-white border-0 text-capitalize d-flex justify-content-between p-20">
                        <h4 className="f-18 f-w-500 mb-0">Vehicle Info</h4>
                    </div> */}
                    <div className="card-body ">
                        <div className="row p-3">
                            <div className="col-lg-12">
                                <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                                    <p className="mb-0  f-14 text-capitalize"><strong style={{fontSize: "18px"}}>Basic {" "}</strong></p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                                    <p className="mb-0  f-14 text-capitalize" ><strong>Asset#: {" "}</strong>{state.assetNo}</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                            {/* <div className="pro-edit"> */}
                                {/* <a className="edit-icon" onClick={openDropDown}>
                                        <i className="fa fa-pencil"></i>
                                    </a> */}
                                {/* </div> */}
                                <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                                    <p className="mb-0 f-14 text-capitalize"><strong>Type: {" "}</strong>{state.vehicleOwnership}</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                                    <p className="mb-0  f-14 text-capitalize"><strong>Service Type: {" "}</strong>{state.serviceType.name}</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                                    <p className="mb-0  f-14 text-capitalize"><strong>Seats: {" "}</strong>{state.seats}</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                                    <p className="mb-0  f-14 text-capitalize"><strong>Service Name: {" "}</strong>{state.serviceName ? state.serviceName : "-"}</p>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                                    <p className="mb-0  f-14 text-capitalize"><strong style={{fontSize: "18px"}}>Vehicle Information {" "}</strong></p>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                                    <p className="mb-0  f-14 text-capitalize"><strong>Make: {" "}</strong>{state.make}</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                                    <p className="mb-0  f-14 w-30 text-capitalize"><strong>Model: {" "}</strong>{state.model}</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                                    <p className="mb-0  f-14 text-capitalize"><strong>Year: {" "}</strong>{state.year}</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                                    <p className="mb-0  f-14 text-capitalize"><strong>VIN: {" "}</strong>{state.vin}</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                                    <p className="mb-0  f-14 text-capitalize"><strong>Plate: {" "}</strong>{state.numberPlate}</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                                    <p className="mb-0  f-14 text-capitalize"><strong>Color: {" "}</strong>{state.color}</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                                    <p className="mb-0  f-14 text-capitalize"><strong>Mileage: {" "}</strong>{state.mileage}</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                                    <p className="mb-0  f-14 text-capitalize"><strong>Vehicle Inspections Expiration: {" "}</strong>{moment(state.inspectionExpiration).format('MM/DD/YYYY')}</p>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                                    <p className="mb-0  f-14 text-capitalize"><strong style={{fontSize: "18px"}}>Insurance {" "}</strong></p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                                    <p className="mb-0  f-14 text-capitalize"><strong>Insurance Company Name: {" "}</strong>{state.insuranceCompanyName}</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                                    <p className="mb-0  f-14 text-capitalize"><strong>Insurance Policy Number: {" "}</strong>{state.insurancePolicyNumber}</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                                    <p className="mb-0  f-14 text-capitalize"><strong>Insurance Expiration Date: {" "}</strong>{moment(state.insuranceExpirationDate).format('MM/DD/YYYY')}</p>
                                </div>
                            </div>



                            <div className="col-lg-12">
                                <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                                    <p className="mb-0  f-14 text-capitalize"><strong style={{fontSize: "18px"}}>Brakes Expiration {" "}</strong></p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                                    <p className="mb-0  f-14 text-capitalize"><strong>Brakes Expiration Date: {" "}</strong>{moment(state.brakesExpiration).format('MM/DD/YYYY')}</p>
                                </div>
                            </div>


                        </div>  
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
export default AdminSettingVehicleFullDetailComponent


{/* <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
    <p className="mb-0  f-14 w-30 text-capitalize">Asset#:</p>
    <p className="mb-0 text-dark-grey f-14 w-70 text-wrap">{state.assetNo}</p>
</div>
<div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
    <p className="mb-0  f-14 w-30 text-capitalize">Type:</p>
    <p className="mb-0 text-dark-grey f-14 w-70 text-wrap">{state.vehicleOwnership}	</p>
</div>
<div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
    <p className="mb-0  f-14 w-30 text-capitalize">Mode:</p>
    <p className="mb-0 text-dark-grey f-14 w-70 text-wrap">{state.serviceType && state.serviceType.name}</p>
</div>
<div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
    <p className="mb-0 f-14 w-30 text-capitalize">Make:</p>
    <p className="mb-0 text-dark-grey f-14 w-70 text-wrap">{state.make}</p>
</div> <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
    <p className="mb-0  f-14 w-30 text-capitalize">Model:</p>
    <p className="mb-0 text-dark-grey f-14 w-70 text-wrap">{state.model}</p>
</div>
<div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
    <p className="mb-0  f-14 w-30 text-capitalize">Year:</p>
    <p className="mb-0 text-dark-grey f-14 w-70 text-wrap">{state.year}</p>
</div>
<div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
    <p className="mb-0  f-14 w-30 text-capitalize">VIN:</p>
    <p className="mb-0 text-dark-grey f-14 w-70 text-wrap">{state.vin}	</p>
</div>
<div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
    <p className="mb-0  f-14 w-30 text-capitalize">Plate:</p>
    <p className="mb-0 text-dark-grey f-14 w-70 text-wrap">{state.numberPlate}</p>
</div>
<div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
    <p className="mb-0  f-14 w-30 text-capitalize">Color:</p>
    <p className="mb-0 text-dark-grey f-14 w-70 text-wrap">{state.color}</p>
</div>
<div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
    <p className="mb-0  f-14 w-30 text-capitalize">Mileage:</p>
    <p className="mb-0 text-dark-grey f-14 w-70 text-wrap">{state.mileage}</p>
</div> */}