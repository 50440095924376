import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getUserByToken, userLogin } from '../../actions/authActions';
import signinImg from '../../assetsgeneral/images/sig-img.png'
// import signinImg from '../../assetsgeneral/images/Frame.png'
import signinLogo from '../../assetsgeneral/images/signin-logo.png'
// import signinLogo from '../../assetsgeneral/images/CONNECT CARE TRANS-04.png'
// import signinLogo from '../../assetsgeneral/images/CONNECTCARE_WhiteLogo.png'

const LoginComponent = () => {
    const auth = useSelector((state) => state.auth);
    let navigate = useNavigate()
    // const navigateUser = () => {
    //     navigate('/dashboard')
    // }
    const chkUser = async () => {
        dispatch(getUserByToken(auth?.tokens?.refresh?.token, navigate, auth))
    }
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        // if (localStorage.tokens === undefined){
        //     console.log("empty")
        // }
        // else {
        //     chkUser()
        // }
        if (auth?.tokens?.refresh?.token && auth?.user){
            chkUser()
        }
    },[])
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []); 
    // console.log(windowSize)
    const [values, setValues] = useState({
        email: '',
        password: '',
    })
    const handleChange = (fieldName) => (event) => {
        setValues({
            ...values, [fieldName]:
                event.target.value
        })
    }
    const userData = {
        email: values.email,
        password: values.password,
    }
    const dispatch = useDispatch()
    const handleSubmit = async (event) => {
        event.preventDefault()
        // dispatch(userLogin(userData, navigateUser))
       
        dispatch(userLogin(userData, navigate))
    }
    // const [isEmail, setIsEmail] = useState(true)
    // const changeEmailUsername = () => {
    //     setIsEmail((isEmail) => !isEmail)
    // }
    return (
        <>
            {/* Jumbotron  */}
            <div className="px-4 py-5 px-md-5 mt-4 mb-4 text-lg-start" >
                <div className="container">
                    <div className="row gx-lg-5 align-items-center">
                    <div className="col-lg-7 mb-5 mb-lg-0">
                        <div className="row">
                            <div className="sig-img">
                                <img src={signinImg} alt="" id="sigimg"/>
                            </div>
                        </div>
                    </div>
            
                    <div className="col-lg-5 mb-lg-0">
                        <div className="card-2">
                        <div className="card-body py-5 px-md-5">
                            <form onSubmit={handleSubmit}>
                            <div className="text-center p-4">
                                <Link to="">
                                <img src={signinLogo} alt="" id="logsedf" />
                                </Link>
                            </div>
                            <div className="text-white mb-3">
                                <h4>Sign In</h4>
                            </div>
                            {/* Email input */}
                                <div className="col-md-12 mb-4">
                                    <label className="form-label label-text" htmlFor="form3Example3">Email address or Username</label>
                                    <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                        <i className="fa fa-envelope icon"></i>
                                        </span>
                                    </div>
                                    <input className="form-control" placeholder="Your Email" value={values.email} onChange={handleChange('email')} aria-label="Your Email" id="form3Example3" aria-describedby="basic-addon1"/>
                                    </div>
                                </div>

                            {/* Username input */}
                            {/* {
                                isEmail === false &&
                                <div className="col-md-12 mb-4">
                                    <label className="form-label label-text" htmlFor="form3Example3">Username</label>
                                    <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                        <i className="fa fa-user icon"></i>
                                        </span>
                                    </div>
                                    <input className="form-control" placeholder="Your Username" value={values.username} onChange={handleChange('username')} aria-label="Your Email" id="form3Example3" aria-describedby="basic-addon1"/>
                                    </div>
                                    <div className='text-center'>
                                        <button style={{backgroundColor: "#F69B31"}} onClick={changeEmailUsername}>
                                            (Use Email Instead)
                                        </button>
                                    </div>
                                </div>
                            } */}
                            {/* <!-- Password input --> */}
                            <div className="col-md-12 mb-4">
                                <label className="form-label label-text" htmlFor="form3Example3">Password</label>
                                {/* <label className="form-label label-text" htmlFor="form3Example3" style={{color: "#fff"}}>Password</label> */}
                                <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">
                                    <i className="fa fa-key icon"></i>
                                    </span>
                                </div>
                                <input type="password" className="form-control" placeholder="Your Password" value={values.password} onChange={handleChange('password')} aria-label="Your Password" id="form3Example3" aria-describedby="basic-addon1"/>
                                </div>
                            </div>
            
                            {/* <!-- Submit button --> */}
                            {
                                auth.error &&
                                <span className="text-danger" > *Invalid Credentials*</span>
                            }
                            <div className="d-grid mb-4">
                                <button type="submit" className="btn btn-secondarynew" style={{padding: "5px 40px"}}>
                                Sign In
                                </button>
                            </div>

                            {/* <!-- signup --> */}
                            {/* <div className="row">
                                <div className="col-md-12">
                                <div className="text-center mb-4">
                                    <p>Signup as User <Link to="" style={{color: "#283E4B"}}>SIGNUP</Link> </p>
                                </div>
                                </div>
                            </div> */}
                            
            
                            {/* <!-- Register buttons --> */}
                            {/* <div className="text-center">
                                <p>or sign up with:</p>
                                <button type="button" className="btn btn-floating mx-1">
                                <i className="fa fa-facebook-f"></i> sign up with
                                </button>
                                <button type="button" className="btn btn-floating mx-1">
                                <i className="fa fa-google"></i> sign up with
                                </button>
                            </div> */}
                            </form>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoginComponent