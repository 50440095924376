import axios from "axios";
import { notifyFailure, notifySuccess } from "../components/toast";
import {
    PERMISSION_VIEW_REQUEST,
    PERMISSION_VIEW_SUCCESS,
    PERMISSION_VIEW_FAIL,
    PERMISSION_CREATE_REQUEST,
    PERMISSION_CREATE_SUCCESS,
    PERMISSION_CREATE_FAIL,
    PERMISSION_UPDATE_REQUEST,
    PERMISSION_UPDATE_SUCCESS,
    PERMISSION_UPDATE_FAIL,
    PERMISSION_DELETE_REQUEST,
    PERMISSION_DELETE_SUCCESS,
    PERMISSION_DELETE_FAIL,
    GET_PERMISSION_BY_ID_REQUEST,
    GET_PERMISSION_BY_ID_SUCCESS,
    GET_PERMISSION_BY_ID_FAIL,
    SERVERADDRESS,
} from "../constants"

// ALL PERMISSION DISPLAY VIEW 
export const getAllPermission = (obj) => async (dispatch) => {
    dispatch({
        type: PERMISSION_VIEW_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/permission/getPermissionsByCompanyId`, obj);
        localStorage.setItem("permission", JSON.stringify(data));
        dispatch({
            type: PERMISSION_VIEW_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: PERMISSION_VIEW_FAIL,
            payload: err,
        });
    }
};

// ADD NEW PERMISSION
export const addPermission = (obj, navigatePermission) => async ( dispatch ) => {
    dispatch({
        type: PERMISSION_CREATE_REQUEST,
    })
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/permission/createPermission`, obj)
    dispatch({
        type: PERMISSION_CREATE_SUCCESS,
        payload: data,
    })
    navigatePermission()
}
catch (err) {
    console.log(err)
    dispatch({
        type: PERMISSION_CREATE_FAIL,
        payload: err,
    })
}
}

// Edit PERMISSION
export const editPermissionAction = (obj, navigatePermission) => async (dispatch) => {
    dispatch({
      type: PERMISSION_UPDATE_REQUEST,
    });
    try {
      const { data } = await axios.put(
        `${SERVERADDRESS}/v1/permission/updatePermission`,
        obj
      );
      // handleSuccess();
      notifySuccess("Updated successfully !");
      dispatch({
        type: PERMISSION_UPDATE_SUCCESS,
        payload: data,
      });
      navigatePermission()
    } catch (err) {
      console.log(err);
      notifyFailure(err?.response?.data?.message);
      dispatch({
        type: PERMISSION_UPDATE_FAIL,
        payload: err,
      });
    }
  };
  
// Delete PERMISSION
export const deletePermissionAction = (obj) => async (dispatch) => {
    dispatch({
      type: PERMISSION_DELETE_REQUEST,
    });
    try {
      const { data } = await axios.put(
        `${SERVERADDRESS}/v1/permission/updatePermission`,
        obj
      );
      notifySuccess("Deleted successfully !");
      dispatch({
        type: PERMISSION_DELETE_SUCCESS,
        payload: data,
      });
    } catch (err) {
      console.log(err);
      notifyFailure(err?.response?.data?.message);
      dispatch({
        type: PERMISSION_DELETE_FAIL,
        payload: err,
      });
    }
  };
  
// ALL PERMISSION DISPLAY VIEW 
export const getPermissionById = (obj) => async (dispatch) => {
  dispatch({
      type: GET_PERMISSION_BY_ID_REQUEST,
  });
  try {
      const { data } = await axios.post(`${SERVERADDRESS}/v1/permission/getPermissionById`, obj);
      localStorage.setItem("permissionDetail", JSON.stringify(data));
      dispatch({
          type: GET_PERMISSION_BY_ID_SUCCESS,
          payload: data,
      });
  }
  catch (err) {
      console.log(err)
      dispatch({
          type: GET_PERMISSION_BY_ID_FAIL,
          payload: err,
      });
  }
};