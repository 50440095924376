import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { getAllUser } from "../../../actions/userActions";
import AdminUserTableComponentRow from "./AdminUserTableComponentRow";
import { Link, NavLink } from "react-router-dom";
import shift1 from "../../../assets/images/load.png"
// import AdminUserEditComponent from "./AdminUserEditComponent";
// import RightModal from "../../RightModal";

const AdminUserTableComponent = () => {
  const auth = useSelector((state) => state.auth);
  const users = useSelector((state) => state.users);
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };

  const [modal, setModal] = useState({ open: false, data: null });
  const handleModal = (data) => {
    setModal({ open: !modal.open, data: data });
  };

  const dispatch = useDispatch();
  const userData = async (e) => {
    dispatch(
      getAllUser(
        { companyId: auth?.user?.companyId?.id, isClient: false },
        limit,
        currentPageNumber
      )
    );
    // dispatch(getAllUser({companyId: auth.user.companyId.id, isClient: false, limit: limit, page: currentPageNumber }))
  };
  useEffect(() => {
    userData();
  }, []);

  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [limit, setLimit] = useState(10);
  const handleLimit = async (event) => {
    setLimit(parseInt(event.target.value));
    dispatch(
      getAllUser(
        { companyId: auth?.user?.companyId?.id, isClient: false },
        event.target.value,
        1
      )
    );
    setCurrentPageNumber(1);
  };
  const onClickNext = async () => {
    let i = currentPageNumber;
    i++;
    if (i <= users.users.totalPages) {
      setCurrentPageNumber(currentPageNumber + 1);
      dispatch(
        getAllUser(
          { companyId: auth?.user?.companyId?.id, isClient: false },
          limit,
          currentPageNumber + 1
        )
      );
    }
  };
  const onClickPrev = async () => {
    let i = currentPageNumber;
    i--;
    if (i >= 1) {
      setCurrentPageNumber(currentPageNumber - 1);
      dispatch(
        getAllUser(
          { companyId: auth?.user?.companyId?.id, isClient: false },
          limit,
          currentPageNumber - 1
        )
      );
    }
  };
  const handleChangeData = async (pageNo) => {
    setCurrentPageNumber(pageNo);
    dispatch(
      getAllUser({ companyId: auth?.user?.companyId?.id, isClient: false }, limit, pageNo)
    );
  };

  return (
    <>
      <div className="d-flex flex-column w-tables rounded mt-3 bg-white">
        <div className="d-flex border">
          <div className="flex-grow-1">
            <div className="dataTables_length d-flex" id="leads-table_length">
              <div className="p-2">
                <label>Show entries</label>
              </div>
              <label>
                <select
                  name="leads-table_length"
                  aria-controls="leads-table"
                  className="custom-select custom-select-sm form-control form-control-sm"
                  autoComplete="false"
                  onChange={handleLimit}
                >
                  {[10, 25, 50, 100].map((current, i) => {
                    return (
                      <option value={current} key={i}>
                        {current}
                      </option>
                    );
                  })}
                </select>
              </label>
            </div>
          </div>
          {/* <!-- SEARCH BY TASK START --> */}
          {/* <div className="task-search d-flex  py-1 px-lg-3 px-0 border-right-grey align-items-center">
                    <form className="w-100 mr-1 mr-lg-0 mr-md-1 ml-md-1 ml-0 ml-lg-0">
                        <div className="input-group bg-grey rounded">
                            <div className="input-group-prepend">
                                <span className="input-group-text border-0 bg-additional-grey">
                                    <i className="fa fa-search f-13 text-dark-grey"></i>
                                </span>
                            </div>
                            <input type="text" className="form-control f-14 p-1 border-additional-grey" id="search-text-field" placeholder="Start typing to search"/>
                        </div>
                    </form>
                </div> */}
          {/* <!-- SEARCH BY TASK END --> */}
        </div>
        <div
          id="leads-table_wrapper"
          className="dataTables_wrapper dt-bootstrap4 no-footer"
        >
          <div className="row">
            <div className="col-sm-12">
              <table
                className="table border w-100 dataTable no-footer"
                id="leads-table"
                role="grid"
                aria-describedby="leads-table_info"
                style={{ width: "1065px" }}
              >
                <thead className="card-header">
                  <tr role="row">
                    {/* <th
                      title="Image"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Image
                    </th> */}
                    <th
                      title="First Name"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Mobile: activate to sort column ascending"
                    >
                      Name
                    </th>
                    {/* <th title="Middle Name" className="sorting_disabled border-right" rowSpan="1" colSpan="1" aria-label="Next Follow Up">
                                        Middle Name
                                    </th> */}
                    {/* <th
                      title="Last Name"
                      className="sorting_disabled border-right"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Next Follow Up"
                    >
                      Last Name
                    </th> */}
                    <th
                      title="Permission"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Permission
                    </th>
                    {/* <th
                      title="Address"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Address
                    </th> */}
                    <th
                      title="City"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      City
                    </th>
                    <th
                      title="State"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      State
                    </th>
                    <th
                      title="Phone Number"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Phone Number
                    </th>
                    <th
                      title="Email"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Email
                    </th>
                    <th
                      title="Username"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Username
                    </th>
                    <th
                      title="User Status"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      User Status
                    </th>
                    <th
                      title="Action"
                      className="text-right pr-20 sorting_disabled border-right"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Action"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {users &&
                    users.users &&
                    users.users.results &&
                    users.users.results.length > 0 &&
                    users.users.results.map((current, i) => {
                      // if (current.delete === false) {
                      return (
                        <AdminUserTableComponentRow
                          current={current}
                          i={i}
                          key={i}
                          handleModal={handleModal}
                        />
                      );
                      // }
                    })}
                </tbody>
              </table>
              {
                users &&
                users.users &&
                users.users.results &&
                users.users.results.length === 0 &&
                <div className="dash-stats-list" style={{ textAlign: "center" }}>
                  <br />
                  <br />
                  <img
                    alt=""
                    src={shift1}
                    style={{ width: "145px", opacity: "0.5" }} />
                  <br />
                  <p style={{ opacity: "0.5" }}>
                    No Data Found
                  </p>
                </div>
              }
              <div
                id="leads-table_processing"
                className="dataTables_processing card"
                style={{ display: "none" }}
              >
                Processing...
              </div>
            </div>
          </div>
          <div className="d-flex border">
            {
              // users.users && (
              users.loading === false ?
              <div className="flex-grow-1">
                <div
                  className="dataTables_info"
                  id="leads-table_info"
                  role="status"
                  aria-live="polite"
                >
                  Showing {1 + limit * (currentPageNumber - 1)} to{" "}
                  {users?.users?.displayingResults + limit * (currentPageNumber - 1)}{" "}
                  of {users?.users?.totalResults} entries
                </div>
              </div>
              :
              <div className="flex-grow-1">
                <div
                  className="dataTables_info"
                  id="leads-table_info"
                  role="status"
                  aria-live="polite"
                >
                  Loading entries
                </div>
              </div>
            }
            <div>
              <div
                className="dataTables_paginate paging_simple_numbers"
                id="leads-table_paginate"
              >
                <ul className="pagination">
                  <li className="page-item">
                    <NavLink
                      className="page-link"
                      to=""
                      aria-label="Previous"
                      onClick={onClickPrev}
                    >
                      <span aria-hidden="true">Previous</span>
                      <span className="sr-only">Previous</span>
                    </NavLink>
                  </li>
                  {users.users &&
                    users.users.paginationArr &&
                    users.users.paginationArr.length > 0 &&
                    users.users.paginationArr.map((current, i) => {
                      return currentPageNumber === current ? (
                        <li className="page-item active" key={i}>
                          <Link className="page-link" value={current} to="">
                            {current}
                          </Link>
                        </li>
                      ) : (
                        <li className="page-item" key={i}>
                          <Link
                            className="page-link"
                            to=""
                            value={current}
                            onClick={() => handleChangeData(current)}
                          >
                            {current}
                          </Link>
                        </li>
                      );
                    })}
                  <li className="page-item">
                    <NavLink
                      className="page-link"
                      to=""
                      aria-label="Next"
                      onClick={onClickNext}
                    >
                      <span aria-hidden="true">Next</span>
                      <span className="sr-only">Next</span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <RightModal open={modal.open} handleModal={handleModal}>
        {modal.open && (
          <AdminUserEditComponent
            state={modal.data}
            handleModal={handleModal}
          />
        )}
      </RightModal> */}
    </>
  );
};
export default AdminUserTableComponent;
