import React from "react";
import { useLocation } from "react-router-dom";
import InvoiceFile from "./InvoiceFile";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useSelector } from "react-redux";
import moment from "moment";

function InvoiceUI() {
  const { state } = useLocation();
  const auth = useSelector((state) => state?.auth);

  console.log("===>", state);
  return (
    <div className="card p-4">
      <div className="container-fluid">
        <h4 className="text-center">
          Invoice for the order # {state?.reservationId?.orderId}
        </h4>
        <br />
        <h3 className="text-center">{auth.user.companyId.companyName}</h3>
        <br />
        <div className="row">
          <div className="col-6 mb-3">
            <p className="m-0 font-weight-bold mt3">
              Customer:{" "}
              {state?.reservationId?.customerId.firstName +
                " " +
                state?.reservationId?.customerId.lastName}
            </p>
            <p className="m-0">
              Phone: {state?.reservationId?.customerId.phone}
            </p>
            {state?.reservationId?.customerId?.externalCompanyId?.name && (
              <p className="m-0 font-weight-bold mt3">
                Company Details: {state?.reservationId?.customerId?.externalCompanyId?.name}
              </p>
            )}
            {state?.reservationId?.loadNumber && (
              <p className="m-0 font-weight-bold mt3">
                Load Number: {state?.reservationId?.loadNumber}
              </p>
            )}
            {/* {state?.reservationId?.clientIdExternal && (
              <p className="m-0 font-weight-bold mt3">
                External Client: {state?.reservationId?.clientIdExternal}
              </p>
            )} */}
            {/* <p className="m-0">shayan@iconictek.com</p> */}
          </div>
          <div className="col-6 mb-3">
            <p className="m-0 font-weight-bold mt3">
              Type: {state?.reservationId?.type.name}
            </p>
            <p className="m-0 font-weight-bold mt3">
              Driver: {state?.reservationId?.driverId?.firstName}{" "}
              {state?.reservationId?.driverId?.lastName}
            </p>
            <p className="m-0 font-weight-bold mt3">
              Vehicle Asset No: {state?.reservationId?.assetId?.assetNo}
            </p>
            <p className="m-0 font-weight-bold mt3">
              Invoice Date: {moment(state?.invoiceDate).format('MM/DD/YYYY')}
            </p>
            <br />
          </div>
          <div className="col-6 mb-3">
            <p className="m-0 font-weight-bold mt3">
              Distance: {state?.reservationId?.totalMileage} mi
            </p>
            <p className="m-0 font-weight-bold mt3">
              Amount: ${state?.reservationId?.totalAmount}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <p className="m-0 mt-3 font-weight-bold">Trip Details:</p>
          </div>
        </div>
        <div className="row">
          <div className="col-4 mb-3">
            <div className="card p-2" style={{minHeight:'185.6px'}}>
              {" "}
              <div className="d-flex justify-content-between">
                <p className="m-0 font-weight-bold mt3">PickUp Lng:</p>
                <p className="m-0  mt3">
                  {state?.reservationId.pickUpAddress.longitude}
                </p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="m-0 font-weight-bold mt3">PickUp Lat: </p>
                <p className="m-0  mt3">
                  {state?.reservationId.pickUpAddress.latitude}
                </p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="m-0 font-weight-bold mt3">Actual PickUp Lng:</p>
                <p className="m-0  mt3">{state?.tripId?.pickUpLng}</p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="m-0 font-weight-bold mt3">Actual PickUp Lat: </p>
                <p className="m-0  mt3">{state?.tripId?.pickUpLat}</p>
              </div>{" "}
              <div className="d-flex justify-content-between">
                <p className="m-0 font-weight-bold mt3">Pickup Time: </p>
                <p className="m-0  mt3">
                  {moment(state?.reservationId?.pickUpDate).format(
                    "MM/DD/YYYY hh:mm a"
                  )}
                </p>
              </div>
              <br />
              <br />
            </div>
          </div>{" "}
          <div className="col-4 mb-3">
            <div className="card p-2" style={{minHeight:'185.6px'}}>
              {" "}
              <div className="d-flex justify-content-between">
                <p className="m-0 font-weight-bold mt3">DropOff Lng:</p>
                <p className="m-0  mt3">
                  {state?.reservationId.dropOffAddress.longitude}
                </p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="m-0 font-weight-bold mt3">DropOff Lat: </p>
                <p className="m-0  mt3">
                  {state?.reservationId.dropOffAddress.latitude}
                </p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="m-0 font-weight-bold mt3">Actual DropOff Lng:</p>
                <p className="m-0  mt3">{state?.tripId?.dropOffLng}</p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="m-0 font-weight-bold mt3">Actual DropOff Lat: </p>
                <p className="m-0  mt3">{state?.tripId?.dropOffLat}</p>
              </div>{" "}
              <div className="d-flex justify-content-between">
                <p className="m-0 font-weight-bold mt3">DropOff Time: </p>
                <p className="m-0  mt3">
                  {moment(state?.reservationId?.dropOffDate).format(
                    "MM/DD/YYYY hh:mm a"
                  )}
                </p>
              </div>
            </div>
          </div>{" "}
          <div className="col-4 mb-3">
            <div className="card p-2">
              <div className="d-flex justify-content-between">
                <p className="m-0 font-weight-bold mt3">
                  HQ to PickUp Point:{" "}
                </p>
                <p className="m-0  mt3">
                  {moment(state?.tripId?.tripStartTime).format("hh:mm a")}
                </p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="m-0 font-weight-bold mt3">
                  Arrived At Pickup:{" "}
                </p>
                <p className="m-0 mt3">
                  {moment(state?.tripId?.arrivedTime).format("hh:mm a")}
                </p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="m-0 font-weight-bold mt3">Driver Waiting Time:</p>
                <p className="m-0 mt3">{state?.tripId?.waitingTimePickUp}</p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="m-0 font-weight-bold mt3">Actual Pickup Time: </p>
                <p className="m-0  mt3">
                  {moment(state?.tripId?.pickUpTime).format(
                    "hh:mm a"
                  )}
                </p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="m-0 font-weight-bold mt3">
                  Ride Started With Customer:
                </p>
                <p className="m-0  mt3">
                  {moment(state?.tripId?.pickUpTime).format("hh:mm a")}
                </p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="m-0 font-weight-bold mt3">Actual DropOff Time: </p>
                <p className="m-0  mt3">
                  {moment(state?.tripId?.dropOffTime).format(
                    "hh:mm a"
                  )}
                </p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="m-0 font-weight-bold mt3">Trip Duration: </p>
                <p className="m-0  mt3">{state?.tripId?.tripDuration}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <p className="m-0 mt-3 font-weight-bold">Ride Details:</p>
            <table className="table border w-100 dataTable no-footer">
              <thead>
                <tr role="row">
                  <th className="border p-1 px-2">No.</th>
                  <th className="border p-1 px-2">Stop</th>
                  <th className="border p-1 px-2">Address</th>
                  <th className="border p-1 px-2">Date</th>
                  <th className="border p-1 px-2">Time</th>
                </tr>
              </thead>
              {state?.reservationId?.rideHistory &&
                state?.reservationId?.rideHistory.length > 0 &&
                state?.reservationId?.rideHistory.map((current, i) => {
                  return (
                    <tr key={i}>
                      <td className="border p-1 px-2">{i + 1}</td>
                      <td className="border p-1 px-2">
                        {i === 0 ? "Pickup" : "Dropoff"}
                      </td>
                      <td className="border p-1 px-2">
                        {current.address.address}
                      </td>
                      <td className="border p-1 px-2">
                        {moment(
                          current.date.toString().substring(0, 10)
                        ).format("MM/DD/YYYY")}
                      </td>
                      <td className="border p-1 px-2">{current.time}</td>
                    </tr>
                  );
                })}
            </table>

            {/* <div className="d-flex">
              <p className="m-0">Distance from Base Location</p>
              <p className="m-0 ml-2 font-weight-bold">
                {state?.distanceFromBaseLocation} mi
              </p>
            </div>{" "}
            <div className="d-flex justify-content-start">
              <p className="m-0 p-1 border">Distance from Pickup to Dropoff</p>
              <p className="m-0 p-1 font-weight-bold border">
                {state?.distanceFromPickToDropOff} mi
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvoiceUI;
