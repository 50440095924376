import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import mapboxgl from "mapbox-gl";
import { useNavigate } from "react-router-dom";
import PassengerAccordionComponent from "./PassengerRouteAccordionComponent";
import { createRoute } from "../../../actions/routeActions";
import ArrangeOrderModalV2 from "./ArrangeOrderModalV2";
import { notifyFailure } from "../../../components/toast";

// import AdminBookingReturnTrip from './AdminBookingReturnTrip'

const pickObj = {
  pickUp: "",
  pickUpTime: "",
  dropOff: "",
  dropOffTime: "",
};

const dropObj = {
  pickUp: "",
  pickUpTime: "",
  dropOff: "",
  dropOffTime: "",
};

const customerDetailsObj = {
  customerId: "",
  type: "",
  capacity: "1",
  // pick: {
  //   pickUp: "",
  //   pickUpTime: "",
  //   dropOff: "",
  //   dropOffTime: "",
  // },
  daysOfWeek: [
    { day: 1, dayName: "Monday", active: true },
    { day: 2, dayName: "Tuesday", active: true },
    { day: 3, dayName: "Wednesday", active: true },
    { day: 4, dayName: "Thursday", active: true },
    { day: 5, dayName: "Friday", active: true },
    { day: 6, dayName: "Saturday", active: false },
    { day: 7, dayName: "Sunday", active: false },
  ],
  returnRoute: false,
};

const AdminAddRouteForm = ({ current, i }) => {
  const directionsService = new window.google.maps.DirectionsService();

  mapboxgl.accessToken =
    "pk.eyJ1IjoiaWNvbmljdGVrIiwiYSI6ImNsZW9oZXlsOTAyZmQzcGw4ejA2Zzk2bmYifQ.StmuWRfUB_3kU8yI5fISxQ";
  const auth = useSelector((state) => state.auth);
  // const reservation = useSelector((state) => state.reservation);
  // const kpi = useSelector((state) => state.kpi);
  const location = useSelector((state) => state.location);
  const fare = useSelector((state) => state.fare);
  const students = useSelector((state) => state.students);

  let navigate = useNavigate();

  // const [showDropDown, setshowDropDown] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [isRidePatternArranged, setIsRidePatternArranged] = useState(false);
  const [orignalRidePattern, setOrignalRidePattern] = useState([]);
  const [orignalRidePatternWithDistance, setOrignalRidePatternWithDistance] =
    useState([]);

  const [returnRidePattern, setReturnRidePattern] = useState([]);
  const [returnRidePatternWithDistance, setReturnRidePatternWithDistance] =
    useState([]);

  const [customerDetailsArr, setCustomerDetailsArr] = useState([
    customerDetailsObj,
  ]);

  // const [showPickLoc, setShowPicLoc] = useState(false);
  // const openPickLoc = () => {
  //   setShowPicLoc((showPickLoc) => !showPickLoc);
  // };

  const [values, setValues] = useState({});

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const dispatch = useDispatch();

  console.log();
  const handleSubmit = async (event) => {
    event.preventDefault();
    // beforeSubmit();

    if (!isRidePatternArranged) {
      notifyFailure("Please arrange ride pattern first !");
      setShowModal(true);
      return;
    }
    // tempReturnRouteArr.reverse();

    const customerDetails = customerDetailsArr.map((x) => ({
      // ...x,
      customerId: x.customerId,
      pickUpAddress: x.pickUpAddress,
      pickUpTime: x.pickUpTime,
      dropOffAddress: x.dropOffAddress,
      dropOffTime: x.dropOffTime,
      type: x.type,
      capacity: x.capacity,
      daysOfWeek: x.daysOfWeek,
      fareAndDistance: x.fareAndDistance,
      dispatchNotes: x.dispatchNotes,
      schoolName: x.schoolName,
      returnRoute: x.returnRoute,
      fareAndDistance: x.fareAndDistance,
    }));

    let tempOrignalRouteArr = [];

    // if (orignalRidePattern.length === 0) {
    //   for (let i = 0; i < customerDetails.length; i++) {
    //     tempOrignalRouteArr.push({
    //       customerId: customerDetailsArr[i].customerId,
    //       address: customerDetailsArr[i].pickUpAddress,
    //       time: customerDetailsArr[i].pickUpTime,
    //       routeType: "Pick",
    //       customerDetails: customerDetailsArr[i],
    //     });
    //     tempOrignalRouteArr.push({
    //       customerId: customerDetailsArr[i].customerId,
    //       address: customerDetailsArr[i].dropOffAddress,
    //       time: customerDetailsArr[i].dropOffTime,
    //       routeType: "Drop",
    //       customerDetails: customerDetailsArr[i],
    //     });
    //   }

    //   calculateOrignalMileageAndTime(tempOrignalRouteArr);
    // }

    dispatch(
      createRoute(
        {
          companyId: auth.user.companyId.id,
          routeNumber: values.routeNumber,
          // routeNumberReturn: `return-${values.routeNumber}`,
          // customerDetails: passengerRouteWithDistanceArr,
          customerDetails: customerDetails,

          // ridePattern:
          //   orignalRidePattern.length > 0
          //     ? orignalRidePatternWithDistance
          //     : tempOrignalRouteArr,
          ridePattern: orignalRidePatternWithDistance,
          bookingDate: new Date().toISOString(),
          // totalAmount: customerDetailsArr.reduce(function (
          //   previousValue,
          //   currentValue
          // ) {
          //   return previousValue + currentValue?.fareAndDistance?.rideFare;
          // },
          // 0),
          isReturnRoute: customerDetailsArr.find((z) => z.returnRoute)
            ? true
            : false,
          totalMileage: customerDetailsArr.reduce(function (
            previousValue,
            currentValue
          ) {
            return previousValue + currentValue?.fareAndDistance?.totalMileage;
          },
          0),
          totalDrivenMileage: orignalRidePatternWithDistance.reduce(function (
            previousValue,
            currentValue
          ) {
            return previousValue + currentValue?.distanceFromLastPoint;
          },
          0),
        },
        orignalRouteSuccessHandler
      )
    );
  };

  const orignalRouteSuccessHandler = () => {
    const returnRouteCustomerDetails = customerDetailsArr
      .filter((x) => x.returnRoute)
      .map((x) => ({
        // ...x,
        customerId: x.customerId,
        pickUpAddress: x.returnPickUpAddress,
        pickUpTime: x.returnPickUpTime,
        dropOffAddress: x.returnDropOffAddress,
        dropOffTime: x.returnDropOffTime,
        type: x.type,
        capacity: x.capacity,
        daysOfWeek: x.daysOfWeek,
        fareAndDistance: x.returnFareAndDistance,
        // fareAndDistance: x.returnFareAndDistance,
        dispatchNotes: x.dispatchNotes,
        schoolName: x.schoolName,
        // fareAndDistance: x.fareAndDistance,
      }));

    // let tempReturnRouteArr = [];
    // if (returnRidePattern.length === 0) {
    //   for (let i = 0; i < returnRouteCustomerDetails.length; i++) {
    //     tempReturnRouteArr.push({
    //       customerId: customerDetailsArr[i].customerId,
    //       address: customerDetailsArr[i].dropOffAddress,
    //       time: customerDetailsArr[i].dropOffTime,
    //       customerDetails: customerDetailsArr[i],

    //       routeType: "Drop",
    //     });
    //     tempReturnRouteArr.push({
    //       customerId: customerDetailsArr[i].customerId,
    //       address: customerDetailsArr[i].pickUpAddress,
    //       time: customerDetailsArr[i].pickUpTime,
    //       customerDetails: customerDetailsArr[i],

    //       routeType: "Pick",
    //     });
    //   }
    //   tempReturnRouteArr.reverse();
    //   calculateReturnMileageAndTime(tempReturnRouteArr);
    // }

    if (
      customerDetailsArr.filter((x) => x.returnRoute).length > 0 &&
      returnRidePattern.length > 0
    ) {
      dispatch(
        createRoute(
          {
            companyId: auth.user.companyId.id,
            routeNumber: values.routeNumber,
            routeNumberReturn: `return-${values.routeNumber}`,
            // customerDetails: passengerRouteWithDistanceArr,
            customerDetails: returnRouteCustomerDetails,

            // ridePattern:
            // returnRidePattern.length > 0
            //   ? returnRidePattern
            //   : tempReturnRouteArr,

            ridePattern: returnRidePatternWithDistance,

            bookingDate: new Date().toISOString(),
            // totalAmount: returnRouteCustomerDetails.reduce(function (
            //   previousValue,
            //   currentValue
            // ) {
            //   return previousValue + currentValue?.fareAndDistance?.rideFare;
            // },
            // 0),

            totalMileage: returnRouteCustomerDetails.reduce(function (
              previousValue,
              currentValue
            ) {
              return (
                previousValue + currentValue?.fareAndDistance?.totalMileage
              );
            },
            0),
            totalDrivenMileage: returnRidePatternWithDistance.reduce(function (
              previousValue,
              currentValue
            ) {
              return previousValue + currentValue?.distanceFromLastPoint;
            },
            0),
          },
          successHandler
        )
      );
    } else {
      successHandler();
    }
  };

  const successHandler = () => {
    navigate("/routes");
  };

  const calculateOrignalMileageAndTime = async (data) => {
    let drivenMileage = 0;

    console.log("data in orignal route==>", data);
    let arr = [
      {
        customerDetails: data[0].customerDetails,
        customerId: data[0].customerId,

        distanceFromLastPoint: 0,
        routeType: data[0].routeType,
        address: data[0].address,
        time: data[0].time,
        timeFromLastPoint: `0 mins`,
      },
    ];

    let eta = { hour: 0, mins: 0 };

    let estimatedTime;

    for (let i = 0; i < data.length - 1; i++) {
      if (data[i].address.address == data[i + 1].address.address) {
        arr.push({
          customerDetails: data[i].customerDetails,
          customerId: data[i].customerId,

          distanceFromLastPoint: 0,
          routeType: data[i + 1].routeType,
          address: data[i + 1].address,
          time: data[i].time,
          timeFromLastPoint: data[i].timeFromLastPoint,
        });
      } else {
        await directionsService
          .route({
            origin: {
              query: data[i]?.address?.address,
            },
            destination: {
              query: data[i + 1]?.address?.address,
            },
            travelMode: window.google.maps.TravelMode.DRIVING,
          })
          .then((response) => {
            console.log("response2", response);
            drivenMileage = parseFloat(
              response.routes[0].legs[0].distance.text.split(" mi")[0]
            );

            let oldDate = new Date("2023-04-20T" + data[i].time + ":00.000Z");

            // yahan se new logic
            if (response.routes[0].legs[0].duration.text.length > 7) {
              const timeString = response.routes[0].legs[0].duration.text;
              const hourRegex = /(\d+)\s*hour/;
              const minuteRegex = /(\d+)\s*min/;

              const hourMatch = hourRegex.exec(timeString);
              const minuteMatch = minuteRegex.exec(timeString);

              const hours = hourMatch ? parseInt(hourMatch[1]) : 0;
              const minutes = minuteMatch ? parseInt(minuteMatch[1]) : 0;

              eta.hour = hours;
              eta.mins = minutes;

              let aaa = (hours * 60 + minutes) * 60 * 1000;

              estimatedTime = new Date(aaa + new Date(oldDate).getTime())
                .toISOString()
                .slice(11, 16);

              arr.push({
                customerDetails: data[i].customerDetails,
                customerId: data[i].customerId,

                distanceFromLastPoint: drivenMileage,
                timeFromLastPoint: `${aaa / 60000} mins`,
                routeType: data[i + 1].routeType,
                address: data[i + 1].address,
                time: estimatedTime,
              });
              // setCustomerDetailsArr(
              //   customerDetailsArr.map((x, i) =>
              //     i === index
              //       ? {
              //           ...x,
              //           dropOffTime,
              //         }
              //       : x
              //   )
              // );
            } else {
              eta.mins = parseInt(
                response.routes[0].legs[0].duration.text.split(" mins")[0]
              );

              let aaa =
                parseInt(
                  response.routes[0].legs[0].duration.text.split(" mins")[0]
                ) *
                60 *
                1000;

              estimatedTime = new Date(aaa + new Date(oldDate).getTime())
                .toISOString()
                .slice(11, 16);

              arr.push({
                customerDetails: data[i].customerDetails,
                customerId: data[i].customerId,

                distanceFromLastPoint: drivenMileage,
                routeType: data[i + 1].routeType,
                address: data[i + 1].address,
                time: estimatedTime,
                timeFromLastPoint: `${aaa / 60000} mins`,
              });

              // setCustomerDetailsArr(
              //   customerDetailsArr.map((x, i) =>
              //     i === index
              //       ? {
              //           ...x,
              //           dropOffTime: new Date(aaa + new Date(oldDate).getTime())
              //             .toISOString()
              //             .slice(11, 16),
              //         }
              //       : x
              //   )
              // );
            }
          });
      }
    }
    // console.log("mileage arr orignal==>", arr, orignalRidePattern);
    setOrignalRidePatternWithDistance(arr);
  };

  const calculateReturnMileageAndTime = async (data) => {
    let drivenMileage = 0;

    let arr = [
      {
        customerDetails: data[0].customerDetails,
        customerId: data[0].customerId,
        distanceFromLastPoint: 0,
        routeType: data[0].routeType,
        address: data[0].address,
        time: data[0].time,
        timeFromLastPoint: `0 mins`,
      },
    ];

    let eta = { hour: 0, mins: 0 };

    let estimatedTime;

    for (let i = 0; i < data.length - 1; i++) {
      if (data[i].address.address == data[i + 1].address.address) {
        arr.push({
          distanceFromLastPoint: 0,
          routeType: data[i + 1].routeType,
          address: data[i + 1].address,
          time: data[i].time,
          timeFromLastPoint: data[i].timeFromLastPoint,
          customerDetails: data[i].customerDetails,
          customerId: data[i].customerId,
        });
      } else {
        await directionsService
          .route({
            origin: {
              query: data[i]?.address?.address,
            },
            destination: {
              query: data[i + 1]?.address?.address,
            },
            travelMode: window.google.maps.TravelMode.DRIVING,
          })
          .then((response) => {
            console.log("response2", response);
            drivenMileage = parseFloat(
              response.routes[0].legs[0].distance.text.split(" mi")[0]
            );

            let oldDate = new Date("2023-04-20T" + data[i].time + ":00.000Z");

            // yahan se new logic
            if (response.routes[0].legs[0].duration.text.length > 7) {
              const timeString = response.routes[0].legs[0].duration.text;
              const hourRegex = /(\d+)\s*hour/;
              const minuteRegex = /(\d+)\s*min/;

              const hourMatch = hourRegex.exec(timeString);
              const minuteMatch = minuteRegex.exec(timeString);

              const hours = hourMatch ? parseInt(hourMatch[1]) : 0;
              const minutes = minuteMatch ? parseInt(minuteMatch[1]) : 0;

              eta.hour = hours;
              eta.mins = minutes;

              let aaa = (hours * 60 + minutes) * 60 * 1000;

              estimatedTime = new Date(aaa + new Date(oldDate).getTime())
                .toISOString()
                .slice(11, 16);

              arr.push({
                customerDetails: data[i].customerDetails,
                customerId: data[i].customerId,

                distanceFromLastPoint: drivenMileage,
                routeType: data[i + 1].routeType,
                address: data[i + 1].address,
                time: estimatedTime,
                timeFromLastPoint: `${aaa / 60000} mins`,
              });
              // setCustomerDetailsArr(
              //   customerDetailsArr.map((x, i) =>
              //     i === index
              //       ? {
              //           ...x,
              //           dropOffTime,
              //         }
              //       : x
              //   )
              // );
            } else {
              eta.mins = parseInt(
                response.routes[0].legs[0].duration.text.split(" mins")[0]
              );

              let aaa =
                parseInt(
                  response.routes[0].legs[0].duration.text.split(" mins")[0]
                ) *
                60 *
                1000;

              estimatedTime = new Date(aaa + new Date(oldDate).getTime())
                .toISOString()
                .slice(11, 16);

              arr.push({
                customerDetails: data[i].customerDetails,
                customerId: data[i].customerId,

                distanceFromLastPoint: drivenMileage,
                routeType: data[i + 1].routeType,
                address: data[i + 1].address,
                time: estimatedTime,
                timeFromLastPoint: `${aaa / 60000} mins`,
              });

              // setCustomerDetailsArr(
              //   customerDetailsArr.map((x, i) =>
              //     i === index
              //       ? {
              //           ...x,
              //           dropOffTime: new Date(aaa + new Date(oldDate).getTime())
              //             .toISOString()
              //             .slice(11, 16),
              //         }
              //       : x
              //   )
              // );
            }
          });
      }
    }
    console.log("mileage arr return==>", arr);
    setReturnRidePatternWithDistance(arr);
  };

  useEffect(() => {
    if (orignalRidePattern.length > 0)
      calculateOrignalMileageAndTime(orignalRidePattern);
  }, [orignalRidePattern]);

  useEffect(() => {
    if (returnRidePattern.length > 0)
      calculateReturnMileageAndTime(returnRidePattern);
  }, [returnRidePattern]);

  console.log("calc orignal ride pattern ===>", orignalRidePatternWithDistance);

  console.log(
    "return ===>",
    returnRidePattern,
    customerDetailsArr.filter((x) => x.returnRoute)
  );

  return (
    <>
      <div className="content-wrapper">
        <div className="d-flex flex-column w-tables rounded mt-3 bg-white">
          <div
            id="leads-table_wrapper"
            className="dataTables_wrapper dt-bootstrap4 no-footer p-3"
          >
            <form onSubmit={handleSubmit}>
              <div className="bg-white  text-capitalize d-flex justify-content-between  border-bottom-grey pb-3 px-2">
                <h4 className="f-18 f-w-500 mb-0">Add Route</h4>
              </div>
              <div className=" bg-white border-0 text-capitalize d-flex justify-content-between  pb-3 pt-3 px-2">
                <h4 className="f-18 f-w-500 mb-0">Basic info</h4>
              </div>
              <div className="row">
                <div className="col-xl-8 col-lg-12 col-md-12 e-d-info-notices">
                  <div className="row">
                    <div className="col-sm-3 pl-4">
                      <div className="form-group my-3">
                        <div className="d-flex">
                          <div>
                            <label
                              className="f-14 text-dark mb-12"
                              data-label=""
                              htmlFor="default_task_status"
                            >
                              Route Number
                            </label>
                          </div>
                        </div>
                        <div className="dropdown bootstrap-select form-control select-picker">
                          <input
                            type="number"
                            className="form-control height-35 f-14"
                            placeholder="Route Number"
                            value={values.routeNumber}
                            name="routeNumber"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <PassengerAccordionComponent
                values={values}
                setValues={setValues}
                handleChange={handleChange}
                customerDetailsArr={customerDetailsArr}
                setCustomerDetailsArr={setCustomerDetailsArr}
                customerDetailsObj={customerDetailsObj}
                dropObj={dropObj}
              />
              <div className="row">
                <div className="col-12 col-md-6 pl-4">
                  <div className="form-group my-5">
                    {true ? (
                      <button
                        type="button"
                        className="btn-primary rounded f-12 p-2 mr-3 "
                        onClick={() => setShowModal(true)}
                      >
                        Arrange Order
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>{" "}
                <div className="col-12 col-md-6 pl-4">
                  <div className="form-group my-5 text-right">
                    {true ? (
                      <button
                        type="submit"
                        className="btn-primary rounded f-12 p-2 mr-3 "
                        // onClick={submitRouteHandler}
                      >
                        Submit Route
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>{" "}
            </form>
          </div>
        </div>
      </div>
      {/* <AdminBookingAddClientModal
        showDropDown={showDropDown}
        openDropDown={openDropDown}
        values={values}
        setValues={setValues}
      /> */}
      {/* <AdminBookingAddDropPick
        showDropLoc={showDropLoc}
        openDropLoc={openDropLoc}
        values={values}
        // stopArr={stopArr}
        // setStopArr={setStopArr}
      /> */}
      {/* <ArrangeOrderModal
        showModal={showModal}
        setShowModal={setShowModal}
        customerDetailsArr={customerDetailsArr}
        setCustomerDetailsArr={setcCustomerDetailsArr}
      /> */}{" "}
      {showModal && (
        <ArrangeOrderModalV2
          showModal={showModal}
          setShowModal={setShowModal}
          customerDetailsArr={customerDetailsArr}
          setCustomerDetailsArr={setCustomerDetailsArr}
          orignalRidePattern={orignalRidePattern}
          setOrignalRidePattern={setOrignalRidePattern}
          returnRidePattern={returnRidePattern}
          setReturnRidePattern={setReturnRidePattern}
          isRidePatternArranged={isRidePatternArranged}
          setIsRidePatternArranged={setIsRidePatternArranged}
        />
      )}
    </>
  );
};
export default AdminAddRouteForm;
