import { GET_STUDENTS_FAIL, GET_STUDENTS_REQUEST, GET_STUDENTS_SUCCESS, STUDENT_CREATE_FAIL, STUDENT_CREATE_REQUEST, STUDENT_CREATE_SUCCESS } from "../constants";

export const studentsReducer = (
  state = {
    list: [],
  },
  action
) => {
  switch (action.type) {

    // ALL STUDENTS
    case GET_STUDENTS_REQUEST:
      return {
        ...state,
      };
    case GET_STUDENTS_SUCCESS:
      return {
        ...state,
        list: action.payload,
      };
    case GET_STUDENTS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    // STUDENT CREATE
    case STUDENT_CREATE_REQUEST:
      return {
        ...state,
      };
    case STUDENT_CREATE_SUCCESS:
      return {
        ...state,
        list: [...state.list, action.payload],
      };
    case STUDENT_CREATE_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
