import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { getAllReservation } from "../../../actions/reservationAction";
import moment from "moment";
import InvoicingTableRow from "./InvoicingTableRow";
import { getAllInvoicesAction } from "../../../actions/invoiceActions";
import { Link, NavLink } from "react-router-dom";
import shift1 from "../../../assets/images/load.png"

const InvoicingTable = () => {
  const invoices = useSelector((state) => state.invoices);
  const auth = useSelector((state) => state.auth);
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };
  const dispatch = useDispatch();

  const getInvoices = async () => {
    dispatch(
      getAllInvoicesAction({
        companyId: auth.user.companyId.id,
        weekFirst: moment().format('dddd') !== 'Sunday' ? moment().startOf('week').add(1, 'days').format('YYYY-MM-DD') : moment().startOf('week').add(-6, 'days').format('YYYY-MM-DD'),
        weekLast: moment().format('dddd') !== 'Sunday' ? moment().endOf('week').add(1, 'days').format('YYYY-MM-DD') : moment().endOf('week').add(-6, 'days').format('YYYY-MM-DD'),
        monthFirst: moment().startOf('month').format('YYYY-MM-DD'),
        monthLast: moment().endOf('month').format('YYYY-MM-DD'),
        limit: limit,
        page: currentPageNumber,
      })
    );
  };
  // date.startOf('week').add(-6, 'days').format('DD MMMM YYYY')
  // console.log("weekStart", moment().startOf('week').add(-6, 'days').format('YYYY-MM-DD'), moment().endOf('week').add(-6, 'days').format('YYYY-MM-DD'))

  useEffect(() => {
    getInvoices();
  }, []);

  
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [limit, setLimit] = useState(10);
  const handleLimit = async (event) => {
    setLimit(parseInt(event.target.value));
    dispatch(
      getAllInvoicesAction(
        { companyId: auth?.user?.companyId?.id, 
          weekFirst: moment().format('dddd') !== 'Sunday' ? moment().startOf('week').add(1, 'days').format('YYYY-MM-DD') : moment().startOf('week').add(-6, 'days').format('YYYY-MM-DD'),
          weekLast: moment().format('dddd') !== 'Sunday' ? moment().endOf('week').add(1, 'days').format('YYYY-MM-DD') : moment().endOf('week').add(-6, 'days').format('YYYY-MM-DD'),
          monthFirst: moment().startOf('month').format('YYYY-MM-DD'),
          monthLast: moment().endOf('month').format('YYYY-MM-DD'),
          limit: event.target.value,
          page: 1,
        },
      )
    );
    setCurrentPageNumber(1);
  };
  const onClickNext = async () => {
    let i = currentPageNumber;
    i++;
    if (i <= invoices?.list.totalPages) {
      setCurrentPageNumber(currentPageNumber + 1);
      dispatch(
        getAllInvoicesAction(
          { companyId: auth?.user?.companyId?.id, 
            weekFirst: moment().format('dddd') !== 'Sunday' ? moment().startOf('week').add(1, 'days').format('YYYY-MM-DD') : moment().startOf('week').add(-6, 'days').format('YYYY-MM-DD'),
            weekLast: moment().format('dddd') !== 'Sunday' ? moment().endOf('week').add(1, 'days').format('YYYY-MM-DD') : moment().endOf('week').add(-6, 'days').format('YYYY-MM-DD'),
            monthFirst: moment().startOf('month').format('YYYY-MM-DD'),
            monthLast: moment().endOf('month').format('YYYY-MM-DD'),
            limit: limit,
            page: currentPageNumber + 1,
          },
        )
      );
    }
  };
  const onClickPrev = async () => {
    let i = currentPageNumber;
    i--;
    if (i >= 1) {
      setCurrentPageNumber(currentPageNumber - 1);
      dispatch(
        getAllInvoicesAction(
          { companyId: auth?.user?.companyId?.id, 
            weekFirst: moment().format('dddd') !== 'Sunday' ? moment().startOf('week').add(1, 'days').format('YYYY-MM-DD') : moment().startOf('week').add(-6, 'days').format('YYYY-MM-DD'),
            weekLast: moment().format('dddd') !== 'Sunday' ? moment().endOf('week').add(1, 'days').format('YYYY-MM-DD') : moment().endOf('week').add(-6, 'days').format('YYYY-MM-DD'),
            monthFirst: moment().startOf('month').format('YYYY-MM-DD'),
            monthLast: moment().endOf('month').format('YYYY-MM-DD'),
            limit: limit,
            page: currentPageNumber - 1,
          },
        )
      );
    }
  };
  const handleChangeData = async (pageNo) => {
    setCurrentPageNumber(pageNo);
    dispatch(
      getAllInvoicesAction({ companyId: auth?.user?.companyId?.id, 
        weekFirst: moment().format('dddd') !== 'Sunday' ? moment().startOf('week').add(1, 'days').format('YYYY-MM-DD') : moment().startOf('week').add(-6, 'days').format('YYYY-MM-DD'),
        weekLast: moment().format('dddd') !== 'Sunday' ? moment().endOf('week').add(1, 'days').format('YYYY-MM-DD') : moment().endOf('week').add(-6, 'days').format('YYYY-MM-DD'),
        monthFirst: moment().startOf('month').format('YYYY-MM-DD'),
        monthLast: moment().endOf('month').format('YYYY-MM-DD'),  
        limit: limit, page: pageNo
      })
    );
  };

  return (
    <>
      <div className="d-flex flex-column w-tables rounded mt-3 bg-white">
        <div className="d-flex border">
          <div className="flex-grow-1">
            <div className="dataTables_length d-flex" id="leads-table_length">
              <div className="p-2">
                <label>Show entries</label>
              </div>
              <label>
                <select
                  name="leads-table_length"
                  aria-controls="leads-table"
                  className="custom-select custom-select-sm form-control form-control-sm"
                  autoComplete="false"
                  onChange={handleLimit}
                >
                  {[10, 25, 50, 100].map((current, i) => {
                    return (
                      <option value={current} key={i}>
                        {current}
                      </option>
                    );
                  })}
                </select>
              </label>
            </div>
          </div>
        </div>
        <div
          id="leads-table_wrapper"
          className="dataTables_wrapper dt-bootstrap4 no-footer"
        >
          <div className="row">
            <div className="col-sm-12">
              <table
                className="table border w-100 dataTable no-footer"
                id="leads-table"
                role="grid"
                aria-describedby="leads-table_info"
                style={{ overflowX: "auto" }}
              >
                <thead className="card-header">
                  <tr role="row">
                    <th
                      title="(Res#)"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Mobile: activate to sort column ascending"
                    >
                      Order ID
                    </th>
                    <th
                      title="Vehicle"
                      className="sorting_disabled border-right"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Next Follow Up"
                    >
                      Asset#
                    </th>

                    <th
                      title="Passenger"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Passenger
                    </th>

                    <th
                      title="Price"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Amount
                    </th>
                    <th
                      title="Status"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Status
                    </th>
                    <th
                      title="Action"
                      className="sorting border-right"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Action"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    invoices &&
                    invoices?.list &&
                    invoices?.list?.results &&
                    invoices?.list?.results.length > 0 &&
                    invoices?.list?.results.map((current, i) => {
                      return <InvoicingTableRow current={current} i={i} key={i}/>;
                    })
                  }
                </tbody>
              </table>
              {
                invoices &&
                invoices?.list &&
                invoices?.list?.results &&
                invoices?.list?.results.length === 0 &&
                <div className="dash-stats-list" style={{ textAlign: "center" }}>
                  <br />
                  <br />
                  <img
                    alt=""
                    src={shift1}
                    style={{ width: "145px", opacity: "0.5" }} />
                  <br />
                  <p style={{ opacity: "0.5" }}>
                    No Data Found
                  </p>
                </div>
              }
              <div
                id="leads-table_processing"
                className="dataTables_processing card"
                style={{ display: "none" }}
              >
                Processing...
              </div>
            </div>
          </div>
          <div className="d-flex border">
            {
              invoices.loading === false ?
              <div className="flex-grow-1">
                <div
                  className="dataTables_info"
                  id="leads-table_info"
                  role="status"
                  aria-live="polite"
                >
                  Showing {1 + limit * (currentPageNumber - 1)} to{" "}
                    {invoices?.list?.displayingResults + limit * (currentPageNumber - 1)}{" "}
                    of {invoices?.list?.totalResults} entries
                </div>
              </div>
                :
                <div className="flex-grow-1">
                <div
                  className="dataTables_info"
                  id="leads-table_info"
                  role="status"
                  aria-live="polite"
                >
                  Loading entries
                </div>
              </div>
            }
            <div>
              <div
                className="dataTables_paginate paging_simple_numbers"
                id="leads-table_paginate"
              >
                <ul className="pagination">
                  <li className="page-item">
                    <NavLink
                      className="page-link"
                      to=""
                      aria-label="Previous"
                      onClick={onClickPrev}
                    >
                      <span aria-hidden="true">Previous</span>
                      <span className="sr-only">Previous</span>
                    </NavLink>
                  </li>
                  {invoices?.list &&
                    invoices?.list?.paginationArr &&
                    invoices?.list?.paginationArr.length > 0 &&
                    invoices?.list?.paginationArr.map((current, i) => {
                      return currentPageNumber === current ? (
                        <li className="page-item active" key={i}>
                          <Link className="page-link" value={current} to="">
                            {current}
                          </Link>
                        </li>
                      ) : (
                        <li className="page-item" key={i}>
                          <Link
                            className="page-link"
                            to=""
                            value={current}
                            onClick={() => handleChangeData(current)}
                          >
                            {current}
                          </Link>
                        </li>
                      );
                    })}
                  <li className="page-item">
                    <NavLink
                      className="page-link"
                      to=""
                      aria-label="Next"
                      onClick={onClickNext}
                    >
                      <span aria-hidden="true">Next</span>
                      <span className="sr-only">Next</span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default InvoicingTable;
