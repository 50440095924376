import {
    TRIP_VIEW_REQUEST,
    TRIP_VIEW_SUCCESS,
    TRIP_VIEW_FAIL,
    TRIP_CREATE_REQUEST,
    TRIP_CREATE_SUCCESS,
    TRIP_CREATE_FAIL,
    // TRIP_UPDATE_REQUEST,
    // TRIP_UPDATE_SUCCESS,
    // TRIP_UPDATE_FAIL,
} from "../constants"

export const tripReducer = (
    state = {
        trip: {
            readTrip: null,
            tripCreate: null,
        }
    },
    action
) => {
        switch (action.type) {

        // TRIP VIEW TABLE
        case TRIP_VIEW_REQUEST : 
            return {
                ...state,
            };
        case TRIP_VIEW_SUCCESS :
            return {
                ...state,
                readTrip: action.payload,
            };
        case TRIP_VIEW_FAIL :
            return {
                ...state,
                error: action.payload,
            };

        // TRIP CREATE 
        case TRIP_CREATE_REQUEST :
            return {
                ...state,
            }
        case TRIP_CREATE_SUCCESS :
            return {
                ...state,
                tripCreate: action.payload,
            }
        case TRIP_CREATE_FAIL :
            return {
                ...state,
                error: action.payload,
            }

        // // TRIP UPDATE
        // case TRIP_UPDATE_REQUEST :
        //     return {
        //         ...state,
        //     }
        // case TRIP_UPDATE_SUCCESS :
        //     return {
        //         ...state,
        //         // readTrip: action.payload,
        //     }
        // case TRIP_UPDATE_FAIL :
        //     return {
        //         ...state,
        //         error: action.payload,
        //     }

        default:
            return state;
    }
}