import React, { useEffect } from 'react'
import NavBar from '../../components/landingWebsiteComponents/NavBar'
import LoginComponent from '../../components/loginComponents/LoginComponent'
import { tabSignIn } from '../../actions/tabActions'
import { useDispatch } from 'react-redux'
import Footer from '../../components/landingWebsiteComponents/Footer'

const LoginPage = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(tabSignIn())
  }, [])
  return (
    <>
      {/* <div className="body-wrapper clearfix">
        <section className="main-container " id="fullscreen">
          <div className="px-4 py-2 border-top-0 emp-dashboard">
            <div className="d-lg-flex d-md-flex d-block py-4">
            </div> */}
            {/* <NavBar /> */}
            <NavBar />
            <LoginComponent />
            <Footer />
          {/* </div>
        </section>
      </div> */}
    </>
  )
}
export default LoginPage