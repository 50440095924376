import React, { useState } from 'react'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getAllPermission } from '../../../actions/permissionActions'
import { Link, useNavigate } from 'react-router-dom'
import AdminPermissionDeleteModal from './AdminPermissionDeleteModal'

const AdminPermissionListComponent = () => {  
  const auth = useSelector((state) => state.auth);
  const permission = useSelector((state) => state.permission);
  const [showDeleteDropDown, setshowDeleteDropDown] = useState(false)
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false)
  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown)
  }
  const openDeleteDropDown = () => {
    setshowDeleteDropDown((showDeleteDropDown) => !showDeleteDropDown)
  }

  const dispatch = useDispatch()
  const permissionData = async (e) => {
      dispatch(getAllPermission({companyId: auth.user.companyId.id }))
  }
  useEffect(() => {
      permissionData()
  },[])
  // console.log(permission, "djdjdjjdjjdjdjdjdjdjdjdjdjdjdjdjdjdjd")
  let navigate = useNavigate();
  const navigatePermission = (id) => {
    navigate("/edit-permission/"+id);
  };
  return (
    <>
      <div className="row emp-dash-detail">
      {
        permission &&
        permission.permission &&
        permission.permission.length > 0 &&
        permission.permission.map((current, i) => {
          // if (current.delete === false) {
            let count = 0;
            let remainingCount = 0;
            const firstThreeNames = [];
            for (let key in current.permission) {
              if (current.permission.hasOwnProperty(key)) {
                if (current.permission[key].hasOwnProperty("isAllow")) {
                  if (count < 3) {
                    firstThreeNames.push(current.permission[key]);
                    count++;
                  } else {
                    remainingCount++;
                  }
                }
              }
            }
            // console.log(firstThreeNames, remainingCount,"firstThreeNames")
          return(
          // <...... current={current} i={i}/>
          // }
          <div className="col-xl-6 col-lg-12 col-md-12 e-d-info-notices" key={i}>
            <div className="row mb-3 mt-xl-0 mt-lg-4 mt-md-4 mt-4">
              <div className="col-md-12 mb-3 mt-md-4">
              <div className="card h-100">
                <div className="card-header">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h2 className="f-18 f-w-500 mb-0"> {current.permission.name}</h2>
                    </div>
                    <div>
                      <h2 className="f-16 f-w-500 mb-0">Total users with this role: {current.users}</h2>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4">
                      <h2 className="f-18 f-w-500 mb-0"> Module</h2>
                    </div>
                    <div className="col-md-2">
                      <h2 className="f-18 f-w-500 mb-0"> C</h2>
                    </div>
                    <div className="col-md-2">
                      <h2 className="f-18 f-w-500 mb-0"> R</h2>
                    </div>
                    <div className="col-md-2">
                      <h2 className="f-18 f-w-500 mb-0"> U</h2>
                    </div>
                    <div className="col-md-2">
                      <h2 className="f-18 f-w-500 mb-0"> D</h2>
                    </div>
                  </div>
                  { 
                    firstThreeNames.map((item, index) => {
                      return(
                        <>
                          <div className="row mt-3">
                            <div className="col-md-4">
                              <h2 className="f-14 f-w-500 mb-0"> {item.name}</h2>
                            </div>
                            <div className="col-md-2">
                              <h2 className="f-18 f-w-500 mb-0">
                                { item.create === true ? <i className="fa fa-check text-success"></i> : <i className="fa fa-close text-danger"></i> }
                              </h2>
                            </div>
                            <div className="col-md-2">
                              <h2 className="f-18 f-w-500 mb-0">
                                { item.read === true ? <i className="fa fa-check text-success"></i> : <i className="fa fa-close text-danger"></i> }
                              </h2>
                            </div>
                            <div className="col-md-2">
                              <h2 className="f-18 f-w-500 mb-0">
                                { item.update === true ? <i className="fa fa-check text-success"></i> : <i className="fa fa-close text-danger"></i> }
                              </h2>
                            </div>
                            <div className="col-md-2">
                              <h2 className="f-18 f-w-500 mb-0">
                                { item.delete === true ? <i className="fa fa-check text-success"></i> : <i className="fa fa-close text-danger"></i> }
                              </h2>
                            </div>
                          </div>
                        </>
                      )
                    })                  
                  }   
                  {/* to={`/edit-permission/${current.permission.id}`}       */}
                  <div className="row mt-3">
                    <div class="col-md-4">
                      <h2 class="f-14 f-w-500 mb-0" onClick={()=> navigatePermission(current.permission.id)} style={{cursor:"pointer"}}>and 24 more...</h2>
                    </div>      
                  </div>
                  <div className="row mt-3">
                    <div className="col-md">
                      {/* <Link to="" type="button" className="btn-primary rounded f-14 p-2 mr-3 float-left mb-2 mb-lg-0 mb-md-0" id="add-lead">
                        View Role
                      </Link> */}
                    </div>
                      <Link to={`/edit-permission/${current.permission.id}`} type="button" className="btn-primary rounded f-14 p-2 mr-3 float-left mb-2 mb-lg-0 mb-md-0" id="add-lead">
                        {" "}Edit{" "}
                      </Link>
                      <Link to="" onClick={openDeleteDropDown} type="button" className="btn-primary rounded f-14 p-2 mr-3 float-left mb-2 mb-lg-0 mb-md-0" id="add-lead">
                        Delete
                      </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AdminPermissionDeleteModal current={current} 
          showDeleteDropDown={showDeleteDropDown} 
          openDeleteDropDown={openDeleteDropDown}
          index={i}/>
        </div>
        )})
      } 
      </div>
    </>
  )
}
export default AdminPermissionListComponent


{/* <!-- EMP DASHBOARD TASKS PROJECTS START --> */}
{/* <div className="row mb-3 mt-xl-0 mt-lg-4 mt-md-4 mt-4">
<div className="col-md-12 mb-3 mt-md-4">
  <div className="card h-100">
    <div className="card-header">
      <div className="d-flex justify-content-between">
        <div>
          <h2 className="f-18 f-w-500 mb-0"> Driver</h2>
        </div>
        <div>
          <h2 className="f-16 f-w-500 mb-0">Total users with this role: 14</h2>
        </div>
      </div>
    </div>
    <div className="card-body">
      <div className="row">
        <div className="col-md-4">
          <h2 className="f-18 f-w-500 mb-0"> Module</h2>
        </div>
        <div className="col-md-2">
          <h2 className="f-18 f-w-500 mb-0"> C</h2>
        </div>
        <div className="col-md-2">
          <h2 className="f-18 f-w-500 mb-0"> R</h2>
        </div>
        <div className="col-md-2">
          <h2 className="f-18 f-w-500 mb-0"> U</h2>
        </div>
        <div className="col-md-2">
          <h2 className="f-18 f-w-500 mb-0"> D</h2>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-4">
          <h2 className="f-14 f-w-500 mb-0"> Dashboard</h2>
        </div>
        <div className="col-md-2">
          <h2 className="f-18 f-w-500 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16" style={{color: "#F1426C"}}>
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </h2>
        </div>
        <div className="col-md-2">
          <h2 className="f-18 f-w-500 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check2 f-21 f-w-500" viewBox="0 0 16 16" style={{color: "#51CC88"}}>
              <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
            </svg>
          </h2>
        </div>
        <div className="col-md-2">
          <h2 className="f-18 f-w-500 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check2 f-21 f-w-500" viewBox="0 0 16 16" style={{color: "#51CC88"}}>
              <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
            </svg>
          </h2>
        </div>
        <div className="col-md-2">
          <h2 className="f-18 f-w-500 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check2 f-21 f-w-500" viewBox="0 0 16 16" style={{color: "#51CC88"}}>
              <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
            </svg>
          </h2>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-4">
          <h2 className="f-14 f-w-500 mb-0"> Permission</h2>
        </div>
        <div className="col-md-2">
          <h2 className="f-18 f-w-500 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16" style={{color: "#F1426C"}}>
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </h2>
        </div>
        <div className="col-md-2">
          <h2 className="f-18 f-w-500 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16" style={{color: "#F1426C"}}>
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </h2>
        </div>
        <div className="col-md-2">
          <h2 className="f-18 f-w-500 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16" style={{color: "#F1426C"}}>
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </h2>
        </div>
        <div className="col-md-2">
          <h2 className="f-18 f-w-500 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16" style={{color: "#F1426C"}}>
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </h2>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-4">
          <h2 className="f-14 f-w-500 mb-0"> Clients</h2>
        </div>
        <div className="col-md-2">
          <h2 className="f-18 f-w-500 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16" style={{color: "#F1426C"}}>
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </h2>
        </div>
        <div className="col-md-2">
          <h2 className="f-18 f-w-500 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check2 f-21 f-w-500" viewBox="0 0 16 16" style={{color: "#51CC88"}}>
              <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
            </svg>
          </h2>
        </div>
        <div className="col-md-2">
          <h2 className="f-18 f-w-500 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check2 f-21 f-w-500" viewBox="0 0 16 16" style={{color: "#51CC88"}}>
              <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
            </svg>
          </h2>
        </div>
        <div className="col-md-2">
          <h2 className="f-18 f-w-500 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16" style={{color: "#F1426C"}}>
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </h2>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-4">
          <h2 className="f-14 f-w-500 mb-0"> and 24 more...</h2>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-3">
          <Link to="" type="button" className="btn-primary rounded f-14 p-2 mr-3 float-left mb-2 mb-lg-0 mb-md-0" id="add-lead">
            View Role
          </Link>
        </div>
        <div className="col-md-3">
          <Link to="" type="button" className="btn-primary rounded f-14 p-2 mr-3 float-left mb-2 mb-lg-0 mb-md-0" id="add-lead">
            Edit Role
          </Link>
        </div>
      </div>
    </div>
  </div>
</div> */}
{/* <div className="col-md-12 mt-md-4">
  <div className="card h-100">
    <div className="card-header">
      <div className="d-flex justify-content-between">
        <div>
          <h2 className="f-18 f-w-500 mb-0"> NEWONE</h2>
        </div>
        <div>
          <h2 className="f-16 f-w-500 mb-0">Total users with this role: 0</h2>
        </div>
      </div>
    </div>
    <div className="card-body">
      <div className="row">
        <div className="col-md-4">
          <h2 className="f-18 f-w-500 mb-0"> Module</h2>
        </div>
        <div className="col-md-2">
          <h2 className="f-18 f-w-500 mb-0"> C</h2>
        </div>
        <div className="col-md-2">
          <h2 className="f-18 f-w-500 mb-0"> R</h2>
        </div>
        <div className="col-md-2">
          <h2 className="f-18 f-w-500 mb-0"> U</h2>
        </div>
        <div className="col-md-2">
          <h2 className="f-18 f-w-500 mb-0"> D</h2>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-4">
          <h2 className="f-14 f-w-500 mb-0"> Dashboard</h2>
        </div>
        <div className="col-md-2">
          <h2 className="f-18 f-w-500 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check2 f-21 f-w-500" viewBox="0 0 16 16" style={{color: "#51CC88"}}>
              <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
            </svg>
          </h2>
        </div>
        <div className="col-md-2">
          <h2 className="f-18 f-w-500 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check2 f-21 f-w-500" viewBox="0 0 16 16" style={{color: "#51CC88"}}>
              <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
            </svg>
          </h2>
        </div>
        <div className="col-md-2">
          <h2 className="f-18 f-w-500 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check2 f-21 f-w-500" viewBox="0 0 16 16" style={{color: "#51CC88"}}>
              <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
            </svg>
          </h2>
        </div>
        <div className="col-md-2">
          <h2 className="f-18 f-w-500 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check2 f-21 f-w-500" viewBox="0 0 16 16" style={{color: "#51CC88"}}>
              <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
            </svg>
          </h2>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-4">
          <h2 className="f-14 f-w-500 mb-0"> Permission</h2>
        </div>
        <div className="col-md-2">
          <h2 className="f-18 f-w-500 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16" style={{color: "#F1426C"}}>
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </h2>
        </div>
        <div className="col-md-2">
          <h2 className="f-18 f-w-500 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16" style={{color: "#F1426C"}}>
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </h2>
        </div>
        <div className="col-md-2">
          <h2 className="f-18 f-w-500 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16" style={{color: "#F1426C"}}>
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </h2>
        </div>
        <div className="col-md-2">
          <h2 className="f-18 f-w-500 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16" style={{color: "#F1426C"}}>
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </h2>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-4">
          <h2 className="f-14 f-w-500 mb-0"> Clients</h2>
        </div>
        <div className="col-md-2">
          <h2 className="f-18 f-w-500 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16" style={{color: "#F1426C"}}>
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </h2>
        </div>
        <div className="col-md-2">
          <h2 className="f-18 f-w-500 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16" style={{color: "#F1426C"}}>
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </h2>
        </div>
        <div className="col-md-2">
          <h2 className="f-18 f-w-500 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16" style={{color: "#F1426C"}}>
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </h2>
        </div>
        <div className="col-md-2">
          <h2 className="f-18 f-w-500 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16" style={{color: "#F1426C"}}>
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </h2>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-4">
          <h2 className="f-14 f-w-500 mb-0"> and 24 more...</h2>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-3">
          <Link to="" type="button" className="btn-primary rounded f-14 p-2 mr-3 float-left mb-2 mb-lg-0 mb-md-0" id="add-lead">
            View Role
          </Link>
        </div>
        <div className="col-md-3">
          <Link to="" type="button" className="btn-primary rounded f-14 p-2 mr-3 float-left mb-2 mb-lg-0 mb-md-0" id="add-lead">
            Edit Role
          </Link>
        </div>
      </div>
    </div>
  </div>
</div>
</div> */}




    
{/* <div className="col-md-12 mb-3 mt-md-4">
  <div className="card h-100">
    <div className="card-header">
      <div className="d-flex justify-content-between">
        <div>
          <h2 className="f-18 f-w-500 mb-0"> Dispatch</h2>
        </div>
        <div>
          <h2 className="f-16 f-w-500 mb-0">Total users with this role: 0</h2>
        </div>
      </div>
    </div>
    <div className="card-body">
      <div className="row">
        <div className="col-md-4">
          <h2 className="f-18 f-w-500 mb-0"> Module</h2>
        </div>
        <div className="col-md-2">
          <h2 className="f-18 f-w-500 mb-0"> C</h2>
        </div>
        <div className="col-md-2">
          <h2 className="f-18 f-w-500 mb-0"> R</h2>
        </div>
        <div className="col-md-2">
          <h2 className="f-18 f-w-500 mb-0"> U</h2>
        </div>
        <div className="col-md-2">
          <h2 className="f-18 f-w-500 mb-0"> D</h2>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-4">
          <h2 className="f-14 f-w-500 mb-0"> Dashboard</h2>
        </div>
        <div className="col-md-2">
          <h2 className="f-18 f-w-500 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check2 f-21 f-w-500" viewBox="0 0 16 16" style={{color: "#51CC88"}}>
              <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
            </svg>
          </h2>
        </div>
        <div className="col-md-2">
          <h2 className="f-18 f-w-500 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check2 f-21 f-w-500" viewBox="0 0 16 16" style={{color: "#51CC88"}}>
              <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
            </svg>
          </h2>
        </div>
        <div className="col-md-2">
          <h2 className="f-18 f-w-500 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check2 f-21 f-w-500" viewBox="0 0 16 16" style={{color: "#51CC88"}}>
              <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
            </svg>
          </h2>
        </div>
        <div className="col-md-2">
          <h2 className="f-18 f-w-500 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check2 f-21 f-w-500" viewBox="0 0 16 16" style={{color: "#51CC88"}}>
              <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
            </svg>
          </h2>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-4">
          <h2 className="f-14 f-w-500 mb-0"> Permission</h2>
        </div>
        <div className="col-md-2">
          <h2 className="f-18 f-w-500 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16" style={{color: "#F1426C"}}>
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </h2>
        </div>
        <div className="col-md-2">
          <h2 className="f-18 f-w-500 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16" style={{color: "#F1426C"}}>
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </h2>
        </div>
        <div className="col-md-2">
          <h2 className="f-18 f-w-500 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16" style={{color: "#F1426C"}}>
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </h2>
        </div>
        <div className="col-md-2">
          <h2 className="f-18 f-w-500 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16" style={{color: "#F1426C"}}>
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </h2>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-4">
          <h2 className="f-14 f-w-500 mb-0"> Clients</h2>
        </div>
        <div className="col-md-2">
          <h2 className="f-18 f-w-500 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check2 f-21 f-w-500" viewBox="0 0 16 16" style={{color: "#51CC88"}}>
              <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
            </svg>
          </h2>
        </div>
        <div className="col-md-2">
          <h2 className="f-18 f-w-500 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check2 f-21 f-w-500" viewBox="0 0 16 16" style={{color: "#51CC88"}}>
              <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
            </svg>
          </h2>
        </div>
        <div className="col-md-2">
          <h2 className="f-18 f-w-500 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check2 f-21 f-w-500" viewBox="0 0 16 16" style={{color: "#51CC88"}}>
              <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
            </svg>
          </h2>
        </div>
        <div className="col-md-2">
          <h2 className="f-18 f-w-500 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check2 f-21 f-w-500" viewBox="0 0 16 16" style={{color: "#51CC88"}}>
              <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
            </svg>
          </h2>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-4">
          <h2 className="f-14 f-w-500 mb-0"> and 24 more...</h2>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-3">
          <Link to="#" type="button" className="btn-primary rounded f-14 p-2 mr-3 float-left mb-2 mb-lg-0 mb-md-0" id="add-lead">
            View Role
          </Link>
        </div>
        <div className="col-md-3">
          <Link to="#" type="button" className="btn-primary rounded f-14 p-2 mr-3 float-left mb-2 mb-lg-0 mb-md-0" id="add-lead">
            Edit Role
          </Link>
        </div>
      </div>
    </div>
  </div>
</div> */}