import { applyMiddleware, createStore, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import { authReducer } from "./reducers/authReducers";
import {
  adminCompanyInfoReducer,
  externalCompaniesReducer,
} from "./reducers/companyReducers";
import { fareReducer } from "./reducers/fareReducers";
import { invoicesReducer } from "./reducers/invoiceReducers";
import { kpiReducer } from "./reducers/kpiReducers";
import { locationReducer } from "./reducers/locationReducers";
import { permissionReducer } from "./reducers/permissionReducers";
import { reservationReducer } from "./reducers/reservationReducers";
import { serviceReducer } from "./reducers/serviceReducers";
import { shiftReducer } from "./reducers/shiftReducers";
import { sidebarReducer } from "./reducers/sidebarReducers";
import { changeTheTab } from "./reducers/tabReducers";
import { tripReducer } from "./reducers/tripReducers";
import { userReducer } from "./reducers/userReducers";
import { vehicleReducer } from "./reducers/vehicleReducers";
import { routesReducer } from "./reducers/routeReducers";
import { studentsReducer } from "./reducers/studentReducers";
import { mailReducer } from "./reducers/mailReducers";
// import { USER_LOGOUT_SUCCESS } from "./constants";

const initialState = {
  auth: {
    tokens: localStorage.getItem("tokens")
      ? JSON.parse(localStorage.getItem("tokens"))
      : null,
    user: localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null,
    company: localStorage.getItem("company")
      ? JSON.parse(localStorage.getItem("company"))
      : null,
    activeUserCountry: localStorage.getItem("activeUserCountry")
      ? JSON.parse(localStorage.getItem("activeUserCountry"))
      : null,
    allUserLoginInActivity: localStorage.getItem("allUserLoginInActivity")
      ? JSON.parse(localStorage.getItem("allUserLoginInActivity"))
      : null,
    loading: false,
  },
  users: {
    users: localStorage.getItem("users")
      ? JSON.parse(localStorage.getItem("users"))
      : null,
    driver: localStorage.getItem("driver")
      ? JSON.parse(localStorage.getItem("driver"))
      : null,
    client: localStorage.getItem("client")
      ? JSON.parse(localStorage.getItem("client"))
      : null,
    allDriversWithoutPagination: localStorage.getItem("allDriversWithoutPagination")
      ? JSON.parse(localStorage.getItem("allDriversWithoutPagination"))
      : null,
    allClients: localStorage.getItem("allClients")
      ? JSON.parse(localStorage.getItem("allClients"))
      : null,
    allEmployees: localStorage.getItem("allEmployees")
      ? JSON.parse(localStorage.getItem("allEmployees"))
      : null,
    externalCompanyUsers: localStorage.getItem("externalCompanyUsers")
      ? JSON.parse(localStorage.getItem("externalCompanyUsers"))
      : null,
  },
  vehicle: {
    vehicle: localStorage.getItem("vehicle")
      ? JSON.parse(localStorage.getItem("vehicle"))
      : null,
    allVehicle: localStorage.getItem("allVehicle")
      ? JSON.parse(localStorage.getItem("allVehicle"))
      : null,
  },
  permission: {
    permission: localStorage.getItem("permission")
      ? JSON.parse(localStorage.getItem("permission"))
      : null,
  },
  location: {
    location: localStorage.getItem("location")
      ? JSON.parse(localStorage.getItem("location"))
      : null,
    baseLocation: localStorage.getItem("baseLocation")
      ? JSON.parse(localStorage.getItem("baseLocation"))
      : null,
  },
  fare: {
    fare: localStorage.getItem("fare")
      ? JSON.parse(localStorage.getItem("fare"))
      : null,
  },
  reservation: {
    reservation: localStorage.getItem("reservation")
      ? JSON.parse(localStorage.getItem("reservation"))
      : null,
    reservationsSchedule: localStorage.getItem("reservationsSchedule")
      ? JSON.parse(localStorage.getItem("reservationsSchedule"))
      : null,
    todayReservationView: localStorage.getItem("todayReservationView")
      ? JSON.parse(localStorage.getItem("todayReservationView"))
      : null,
    pastHistory: localStorage.getItem("pastHistory")
      ? JSON.parse(localStorage.getItem("pastHistory"))
      : null,
    reservationRequest: localStorage.getItem("reservationRequest")
      ? JSON.parse(localStorage.getItem("reservationRequest"))
      : null,
    userNotificationList: localStorage.getItem("userNotificationList")
      ? JSON.parse(localStorage.getItem("userNotificationList"))
      : null,
    unassigned: localStorage.getItem("unassigned")
      ? JSON.parse(localStorage.getItem("unassigned"))
      : null,
    assigned: localStorage.getItem("assigned")
      ? JSON.parse(localStorage.getItem("assigned"))
      : null,
    pending: localStorage.getItem("pending")
      ? JSON.parse(localStorage.getItem("pending"))
      : null,
    driverEarning: localStorage.getItem("driverEarning")
      ? JSON.parse(localStorage.getItem("driverEarning"))
      : null,
  },
  shift: {
    shift: localStorage.getItem("shift")
      ? JSON.parse(localStorage.getItem("shift"))
      : null,
  },
  adminCompanyInfo: {
    adminCompanyInfo: localStorage.getItem("adminCompanyInfo")
      ? JSON.parse(localStorage.getItem("adminCompanyInfo"))
      : null,
  },
  trip: {
    readTrip: localStorage.getItem("readTrip")
      ? JSON.parse(localStorage.getItem("readTrip"))
      : null,
    tripCreate: localStorage.getItem("tripCreate")
      ? JSON.parse(localStorage.getItem("tripCreate"))
      : null,
  },
  kpi: {
    cardsData: localStorage.getItem("cardsData")
      ? JSON.parse(localStorage.getItem("cardsData"))
      : null,
    cardsData2: localStorage.getItem("cardsData2")
      ? JSON.parse(localStorage.getItem("cardsData2"))
      : null,
    todayReservation: localStorage.getItem("todayReservation")
      ? JSON.parse(localStorage.getItem("todayReservation"))
      : null,
    weekReservationCount: localStorage.getItem("weekReservationCount")
      ? JSON.parse(localStorage.getItem("weekReservationCount"))
      : null,
    // reservationReportSearch: localStorage.getItem("reservationReportSearch")
    //   ? JSON.parse(localStorage.getItem("reservationReportSearch"))
    //   : null,
    // searchedCustomer: localStorage.getItem("searchedCustomer")
    //     ? JSON.parse(localStorage.getItem("searchedCustomer"))
    //     : null,
  },
  service: {
    service: localStorage.getItem("service")
      ? JSON.parse(localStorage.getItem("service"))
      : null,
  },
  invoices: {
    list: localStorage.getItem("list")
      ? JSON.parse(localStorage.getItem("list"))
      : [],
    cardDetails: localStorage.getItem("cardDetails")
      ? JSON.parse(localStorage.getItem("cardDetails"))
      : [],
  },
  mail:{
    mailData: localStorage.getItem("mailData")
      ? JSON.parse(localStorage.getItem("mailData"))
      : null,
  },
  routes: { list: [],
    todayRoute: localStorage.getItem("todayRoute")
      ? JSON.parse(localStorage.getItem("todayRoute"))
      : [],
    routeRequest: localStorage.getItem("routeRequest")
      ? JSON.parse(localStorage.getItem("routeRequest"))
      : [],
    routeLog: localStorage.getItem("routeLog")
      ? JSON.parse(localStorage.getItem("routeLog"))
      : [],
    externalCompanyRoutes: localStorage.getItem("externalCompanyRoutes")
      ? JSON.parse(localStorage.getItem("externalCompanyRoutes"))
      : [],
  },
};

// console.log("initialState", initialState)
const reducer = combineReducers({
  auth: authReducer,
  users: userReducer,
  vehicle: vehicleReducer,
  permission: permissionReducer,
  location: locationReducer,
  fare: fareReducer,
  reservation: reservationReducer,
  invoices: invoicesReducer,
  shift: shiftReducer,
  kpi: kpiReducer,
  service: serviceReducer,
  adminCompanyInfo: adminCompanyInfoReducer,
  externalCompanies: externalCompaniesReducer,
  trip: tripReducer,
  changeTheTab: changeTheTab,
  sidebar: sidebarReducer,
  routes: routesReducer,
  students: studentsReducer,
  mail: mailReducer,
});

// const rootReducer = (state, action) => {
//     if (action.type === 'USER_LOGOUT_SUCCESS') {
//         state = undefined;
//     }
//     return reducer(state, action);
// }
// const middleware = [thunk]

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducer,
  initialState,
  composeEnhancer(applyMiddleware(thunk))
);
export default store;
