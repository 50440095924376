import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { getAllUserLoginInforByCompanyId } from '../../../actions/authActions';
import Accordion from "react-bootstrap/Accordion";
import AdminUserLogInActivitiesRowComponent from "./AdminUserLogInActivitiesRowComponent";
import defaultimg from '../../../assets/images/profile.jpg'

const AdminUserLogInActivitiesComponent = () => {
    const [selectedImage, setSelectedImage] = useState(defaultimg);
    const auth = useSelector((state) => state.auth);
    const users = useSelector((state) => state.users);
    const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
    const openMoreVertDropDown = () => {
      setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
    };
  
    const [modal, setModal] = useState({ open: false, data: null });
    const handleModal = (data) => {
        setModal({ open: !modal.open, data: data });
    };
  
    const dispatch = useDispatch();
    const userActivityData = async (e) => {
        dispatch(
            getAllUserLoginInforByCompanyId({
                companyId: auth?.user?.companyId?.id
            })
        );
    };
    useEffect(() => {
        userActivityData();
    }, []);
  
    return (
        <>
            <div className="d-flex flex-column w-tables rounded mt-3 bg-white">
                <div className="col-lg-12 col-md-12 ntfcn-tab-content-left w-100 p-4 ">
                    <div className="row">
                        <div className="col-sm-12">
                        <Accordion defaultActiveKey="0">
                            {
                                auth &&
                                auth.allUserLoginInActivity &&
                                auth.allUserLoginInActivity.length > 0 &&
                                auth.allUserLoginInActivity.map((user, index) => {
                                return (
                                    <Accordion.Item eventKey={index} key={index}>
                                        <Accordion.Header className="w-100">
                                            {
                                                user.user &&
                                                user.user.profilePic &&
                                                user.user.profilePic.fileUrl ?
                                                <img className="inline-block" src={user.user.profilePic.fileUrl} alt="Profile Pic" style={{borderRadius: "50%", height: "40px", width: "40px"}}/>
                                                :
                                                <img className="inline-block" src={selectedImage} alt="Profile Pic" style={{borderRadius: "50%", height: "40px", width: "40px"}}/>
                                            }
                                            {` ${user?.user?.firstName} ${user?.user?.lastName}`}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <table
                                                className="table border w-100 dataTable no-footer"
                                                id="leads-table"
                                                role="grid"
                                                aria-describedby="leads-table_info"
                                                style={{ overflowX: "auto" }}
                                            >
                                                <thead className="card-header">
                                                    <tr role="row">
                                                        {/* <th
                                                            title="Name"
                                                            className="sorting border-right "
                                                            tabIndex="0"
                                                            aria-controls="leads-table"
                                                            rowSpan="1"
                                                            colSpan="1"
                                                            aria-label="Mobile: activate to sort column ascending"
                                                        >
                                                            Name
                                                        </th>
                                                        <th
                                                            title="User Type"
                                                            className="sorting_disabled border-right"
                                                            rowSpan="1"
                                                            colSpan="1"
                                                            aria-label="Next Follow Up"
                                                        >
                                                            User Type
                                                        </th> */}
                                                        <th
                                                            title="Country"
                                                            className="sorting_disabled border-right"
                                                            rowSpan="1"
                                                            colSpan="1"
                                                            aria-label="Next Follow Up"
                                                        >
                                                            Country
                                                        </th>
                                                        <th
                                                            title="State"
                                                            className="sorting_disabled border-right"
                                                            rowSpan="1"
                                                            colSpan="1"
                                                            aria-label="Next Follow Up"
                                                        >
                                                            State
                                                        </th>
                                                        <th
                                                            title="IP Address"
                                                            className="sorting_disabled border-right"
                                                            rowSpan="1"
                                                            colSpan="1"
                                                            aria-label="Next Follow Up"
                                                        >
                                                            IP Address
                                                        </th>
                                                        <th
                                                            title="Device Type"
                                                            className="sorting_disabled border-right"
                                                            rowSpan="1"
                                                            colSpan="1"
                                                            aria-label="Next Follow Up"
                                                        >
                                                            Device Type
                                                        </th>
                                                        <th
                                                            title="Log In Activities"
                                                            className="sorting_disabled border-right"
                                                            rowSpan="1"
                                                            colSpan="1"
                                                            aria-label="Next Follow Up"
                                                        >
                                                            Log In Activities
                                                        </th>
                                                        <th
                                                            title="Log Out Activities"
                                                            className="sorting_disabled border-right"
                                                            rowSpan="1"
                                                            colSpan="1"
                                                            aria-label="Next Follow Up"
                                                        >
                                                            Log Out Activities
                                                        </th>
                                                        <th
                                                            title="Duration"
                                                            className="sorting_disabled border-right"
                                                            rowSpan="1"
                                                            colSpan="1"
                                                            aria-label="Next Follow Up"
                                                        >
                                                            Duration
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        user?.loggedInInfo?.map((current, i) => (
                                                            <AdminUserLogInActivitiesRowComponent
                                                                current={current}
                                                                i={i}
                                                                setModal={setModal}
                                                                key={i} 
                                                            />
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                );
                            })}
                        </Accordion>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AdminUserLogInActivitiesComponent