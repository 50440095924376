import {
  // RESERVATION_VIEW_REQUEST,
  // RESERVATION_VIEW_SUCCESS,
  // RESERVATION_VIEW_FAIL,
  // RESERVATION_CREATE_REQUEST,
  // RESERVATION_CREATE_SUCCESS,
  // RESERVATION_CREATE_FAIL,
  // RESERVATION_UPDATE_REQUEST,
  // RESERVATION_UPDATE_SUCCESS,
  // RESERVATION_UPDATE_FAIL,
  // DRIVER_RESERVATION_REQUEST,
  // DRIVER_RESERVATION_SUCCESS,
  // DRIVER_RESERVATION_FAIL,
  // RESERVATION_VIEW_SCHEDULE_REQUEST,
  // RESERVATION_VIEW_SCHEDULE_SUCCESS,
  // RESERVATION_VIEW_SCHEDULE_FAIL,
  // RESERVATION_DRIVER_STATUS_UPDATE_REQUEST,
  // RESERVATION_DRIVER_STATUS_UPDATE_SUCCESS,
  // RESERVATION_DRIVER_STATUS_UPDATE_FAIL,
  // DRIVER_TODAY_RESERVATION_VIEW_REQUEST,
  // DRIVER_TODAY_RESERVATION_VIEW_SUCCESS,
  // DRIVER_TODAY_RESERVATION_VIEW_FAIL,
  // DRIVER_RIDE_UPDATE_REQUEST,
  // DRIVER_RIDE_UPDATE_SUCCESS,
  // DRIVER_RIDE_UPDATE_FAIL,
  GET_INVOICES_REQUEST,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_FAIL,
} from "../constants";

export const invoicesReducer = (
  state = {
    loading: false,
    list: [],
    cardDetails: [],
  },
  action
) => {
  switch (action.type) {
    // RESERVATION VIEW TABLE
    case GET_INVOICES_REQUEST:
      return {
        ...state,
        loading : true,
      };
    case GET_INVOICES_SUCCESS:
      return {
        ...state,
        loading : false,
        list: action.payload.invoices,
        cardDetails: action.payload.cardDetails,
      };
    case GET_INVOICES_FAIL:
      return {
        ...state,
        loading : false,
        error: action.payload,
      };

    default:
      return state;
  }
};
