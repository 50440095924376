import {
    USER_AUTH_REQUEST,
    USER_AUTH_SUCCESS,
    USER_AUTH_FAIL,
    COMPANY_AUTH_REQUEST,
    COMPANY_AUTH_SUCCESS,
    COMPANY_AUTH_FAIL,
    USER_LOGOUT_SUCCESS,
    USER_LOGOUT_FAIL,
    GET_USER_FAIL,
    GET_USER_REQUEST,
    GET_USER_SUCCESS,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAIL,
    FORGOT_PASSWORD_CREATE_REQUEST,
    FORGOT_PASSWORD_CREATE_SUCCESS,
    FORGOT_PASSWORD_CREATE_FAIL,
    RESET_PASSWORD_CREATE_REQUEST,
    RESET_PASSWORD_CREATE_SUCCESS,
    RESET_PASSWORD_CREATE_FAIL,
    GET_TOKEN_CREATE_REQUEST,
    GET_TOKEN_CREATE_SUCCESS,
    GET_TOKEN_CREATE_FAIL,
    USER_LOGIN_INFO_CREATE_REQUEST,
    USER_LOGIN_INFO_CREATE_SUCCESS,
    USER_LOGIN_INFO_CREATE_FAIL,
    USER_LOGIN_ACTIVITY_VIEW_REQUEST,
    USER_LOGIN_ACTIVITY_VIEW_SUCCESS,
    USER_LOGIN_ACTIVITY_VIEW_FAIL,
    // UPDATE_USER_LOGIN_INFO_REQUEST,
    // UPDATE_USER_LOGIN_INFO_SUCCESS,
    // UPDATE_USER_LOGIN_INFO_FAIL,
    ADMIN_COMPANY_INFO_VIEW_REQUEST,
    ADMIN_COMPANY_INFO_VIEW_SUCCESS,
    ADMIN_COMPANY_INFO_VIEW_FAIL,
    COMPANY_UPDATE_REQUEST,
    COMPANY_UPDATE_SUCCESS,
    COMPANY_UPDATE_FAIL,
    USER_UPDATE_SUCCESS,
} from "../constants"

export const authReducer = (
    state = {
        auth: {
            user: null,
            tokens: null,
            company: null,
            activeUserCountry: null,
            allUserLoginInActivity: null,
            loading: false,
        },
    },
    action
) => {
        switch (action.type) {
        // USER AUTH
        case USER_AUTH_REQUEST : 
            return {
                ...state,
                loading: true,
            };
        case USER_AUTH_SUCCESS :
            return {
                ...state,
                loading: false,
                user: action.payload.user,
                tokens: action.payload.tokens,
                // newRegisteration: action.payload.register ? true : false,
            };
        case USER_AUTH_FAIL :
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case USER_LOGOUT_SUCCESS:
            return {
                user: null,
                tokens: null,
                company: null,
                activeUserCountry: null,
                loading: false,
            };
        case USER_LOGOUT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_USER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                tokens: action.payload,
            };
        case GET_USER_FAIL:
            return {
                ...state,
                user: null,
                tokens: null,
                loading: false,
            };
        case UPDATE_USER_REQUEST : 
            return {
                ...state,
            };
        case UPDATE_USER_SUCCESS :
            return {
                ...state,
                user: action.payload,
            };
        case UPDATE_USER_FAIL :
            return {
                ...state,
                error: action.payload,
            };


        // COMPANY INFO
        case COMPANY_AUTH_REQUEST : 
            return {
                ...state,
            };
        case COMPANY_AUTH_SUCCESS :
            return {
                ...state,
                company: action.payload,
            };
        case COMPANY_AUTH_FAIL :
            return {
                ...state,
                error: action.payload,
            };


        // FORGOT PASSWORD
        case FORGOT_PASSWORD_CREATE_REQUEST :
            return {
                ...state,
            };
        case FORGOT_PASSWORD_CREATE_SUCCESS :
            return {
                ...state,
                // : [...state., action.payload],
            };
        case FORGOT_PASSWORD_CREATE_FAIL :
            return {
                ...state,
                error: action.payload,
            };


        // RESET PASSWORD
        case RESET_PASSWORD_CREATE_REQUEST :
            return {
                ...state,
            };
        case RESET_PASSWORD_CREATE_SUCCESS :
            return {
                ...state,
                // : [...state., action.payload],
            };
        case RESET_PASSWORD_CREATE_FAIL :
            return {
                ...state,
                error: action.payload,
            };
        
        case USER_LOGIN_ACTIVITY_VIEW_REQUEST : 
            return {
                ...state,
            };
        case USER_LOGIN_ACTIVITY_VIEW_SUCCESS :
            return {
                ...state,
                allUserLoginInActivity: action.payload,
            };
        case USER_LOGIN_ACTIVITY_VIEW_FAIL :
            return {
                ...state,
                error: action.payload,
            };


        // GET TOKEN
        case GET_TOKEN_CREATE_REQUEST :
            return {
                ...state,
            };
        case GET_TOKEN_CREATE_SUCCESS :
            return {
                ...state,
                // : [...state., action.payload],
            };
        case GET_TOKEN_CREATE_FAIL :
            return {
                ...state,
                error: action.payload,
            };

        // EDIT USER PROFILE
        case USER_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.payload,
            };


        // // USER LOGIN INFO CREATE
        // case USER_LOGIN_INFO_CREATE_REQUEST :
        //     return {
        //         ...state,
        //     };
        // case USER_LOGIN_INFO_CREATE_SUCCESS :
        //     return {
        //         ...state,
        //         activeUserCountry: action.payload,
        //         // : [...state., action.payload],
        //     };
        // case USER_LOGIN_INFO_CREATE_FAIL :
        //     return {
        //         ...state,
        //         error: action.payload,
        //     };
        
        
        // // UPDATE USER LOGIN INFO 
        // case UPDATE_USER_LOGIN_INFO_REQUEST :
        //     return {
        //         ...state,
        //     };
        // case UPDATE_USER_LOGIN_INFO_SUCCESS :
        //     return {
        //         ...state,
        //     //     payrollOvertimeTable: state.payrollOvertimeTable.map((x) =>
        //     //     x.id === action.payload.id ? action.payload : x
        //     //   ),
        //     };
        // case UPDATE_USER_LOGIN_INFO_FAIL :
        //     return {
        //         ...state,
        //         error: action.payload,
        //     };
        case ADMIN_COMPANY_INFO_VIEW_REQUEST:
            return {
                ...state,
            };
        case ADMIN_COMPANY_INFO_VIEW_SUCCESS:
            return {
                ...state,
                user: action.payload,
            };
        case ADMIN_COMPANY_INFO_VIEW_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case COMPANY_UPDATE_REQUEST :
            return {
                ...state,
            }
        case COMPANY_UPDATE_SUCCESS :
            return {
                ...state,
                user: {...state.user, companyId: action.payload}
            }
        case COMPANY_UPDATE_FAIL :
            return {
                ...state,
                error: action.payload,
            }

        // USER LOGIN INFO CREATE
        case USER_LOGIN_INFO_CREATE_REQUEST :
            return {
                ...state,
            };
        case USER_LOGIN_INFO_CREATE_SUCCESS :
            return {
                ...state,
                activeUserCountry: action.payload,
                // : [...state., action.payload],
            };
        case USER_LOGIN_INFO_CREATE_FAIL :
            return {
                ...state,
                error: action.payload,
            };

            
        default:
            return state;
    }
}