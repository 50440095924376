import axios from "axios";
import {
  RESERVATION_VIEW_REQUEST,
  RESERVATION_VIEW_SUCCESS,
  RESERVATION_VIEW_FAIL,
  RESERVATION_CREATE_REQUEST,
  RESERVATION_CREATE_SUCCESS,
  RESERVATION_CREATE_FAIL,
  RESERVATION_UPDATE_REQUEST,
  RESERVATION_UPDATE_SUCCESS,
  RESERVATION_UPDATE_FAIL,
  DRIVER_RESERVATION_REQUEST,
  DRIVER_RESERVATION_SUCCESS,
  DRIVER_RESERVATION_FAIL,
  RESERVATION_VIEW_SCHEDULE_REQUEST,
  RESERVATION_VIEW_SCHEDULE_SUCCESS,
  RESERVATION_VIEW_SCHEDULE_FAIL,
  RESERVATION_DRIVER_STATUS_UPDATE_REQUEST,
  RESERVATION_DRIVER_STATUS_UPDATE_SUCCESS,
  RESERVATION_DRIVER_STATUS_UPDATE_FAIL,
  DRIVER_TODAY_RESERVATION_VIEW_REQUEST,
  DRIVER_TODAY_RESERVATION_VIEW_SUCCESS,
  DRIVER_TODAY_RESERVATION_VIEW_FAIL,
  DRIVER_RIDE_UPDATE_REQUEST,
  DRIVER_RIDE_UPDATE_SUCCESS,
  DRIVER_RIDE_UPDATE_FAIL,
  SERVERADDRESS,
  GET_ROUTES_REQUEST,
  GET_ROUTES_SUCCESS,
  GET_ROUTES_FAIL,
  ROUTE_CREATE_REQUEST,
  ROUTE_CREATE_SUCCESS,
  ROUTE_CREATE_FAIL,
  ROUTE_DETAILS_REQUEST,
  ROUTE_DETAILS_SUCCESS,
  ROUTE_DETAILS_FAIL,
  ROUTE_TODAY_ORDER_VIEW_REQUEST,
  ROUTE_TODAY_ORDER_VIEW_SUCCESS,
  ROUTE_TODAY_ORDER_VIEW_FAIL,
  ROUTE_ACTIVE_ORDER_VIEW_REQUEST,
  ROUTE_ACTIVE_ORDER_VIEW_SUCCESS,
  ROUTE_ACTIVE_ORDER_VIEW_FAIL,
  ROUTE_DRIVER_STATUS_UPDATE_REQUEST,
  ROUTE_DRIVER_STATUS_UPDATE_SUCCESS,
  ROUTE_DRIVER_STATUS_UPDATE_FAIL,
  ROUTE_UPDATE_REQUEST,
  ROUTE_UPDATE_SUCCESS,
  ROUTE_UPDATE_FAIL,
  ROUTETRIP_UPDATE_REQUEST,
  ROUTETRIP_UPDATE_SUCCESS,
  ROUTETRIP_UPDATE_FAIL,
  ROUTE_AND_TRIP_REQUEST,
  ROUTE_AND_TRIP_SUCCESS,
  ROUTE_AND_TRIP_FAIL,
  ALL_ROUTE_LOG_VIEW_REQUEST,
  ALL_ROUTE_LOG_VIEW_SUCCESS,
  ALL_ROUTE_LOG_VIEW_FAIL,
  EXTERNAL_COMPANY_ROUTES_VIEW_REQUEST,
  EXTERNAL_COMPANY_ROUTES_VIEW_SUCCESS,
  EXTERNAL_COMPANY_ROUTES_VIEW_FAIL,
} from "../constants";
import { notifyFailure, notifySuccess } from "../components/toast";

// ALL ROUTES DISPLAY VIEW
export const getAllRoutesOfCompany = (obj) => async (dispatch) => {
  dispatch({
    type: GET_ROUTES_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/route/getRoutesByCompanyId`,
      obj
    );
    localStorage.setItem("routes", JSON.stringify(data));

    dispatch({
      type: GET_ROUTES_SUCCESS,
      payload: data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: GET_ROUTES_FAIL,
      payload: err,
    });
  }
};

// ADD NEW ROUTE
export const createRoute = (obj, successHandler) => async (dispatch) => {
  dispatch({
    type: ROUTE_CREATE_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/route/createRoute`,
      obj
    );
    dispatch({
      type: ROUTE_CREATE_SUCCESS,
      payload: data,
    });

    notifySuccess(
      obj.routeNumberReturn
        ? "Return Route Created Successfully !"
        : "Route Created Successfully !"
    );
    successHandler();
  } catch (err) {
    console.log(err);
    notifyFailure(err.response.data.message);
    dispatch({
      type: ROUTE_CREATE_FAIL,
      payload: err,
    });
  }
};

// ROUTE DETAILS
export const getRouteById = (id) => async (dispatch) => {
  dispatch({
    type: ROUTE_DETAILS_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/route/getRouteById`,
      { _id: id }
    );
    dispatch({
      type: ROUTE_DETAILS_SUCCESS,
      payload: data,
    });

    // successHandler();
  } catch (err) {
    console.log(err);
    notifyFailure(err.response.data.message);
    dispatch({
      type: ROUTE_DETAILS_FAIL,
      payload: err,
    });
  }
};

// UPDATE ROUTE
export const updateRouteById = (obj, successHandler) => async (dispatch) => {
  dispatch({
    type: ROUTE_UPDATE_REQUEST,
  });
  try {
    const { data } = await axios.put(
      `${SERVERADDRESS}/v1/route/updateRoute`,
      obj
    );
    dispatch({
      type: ROUTE_UPDATE_SUCCESS,
      payload: data,
    });
    
    notifySuccess("Route updated successfully");
    successHandler();
  } catch (err) {
    console.log(err);
    notifyFailure(err.response.data.message);
    dispatch({
      type: ROUTE_UPDATE_FAIL,
      payload: err,
    });
  }
};

// for driver route k liya
export const setActiveRoute = (obj) => (dispatch) => {
  dispatch({
      type: "SET_ACTIVE_ROUTE",
      payload: obj
  });
}


export const getTodayRoutesByUserId = (obj) => async (dispatch) => {
  dispatch({
      type: ROUTE_TODAY_ORDER_VIEW_REQUEST,
  });
  try {
      const { data } = await axios.post(`${SERVERADDRESS}/v1/route/getTodayRoutesByUserId`, obj);
      localStorage.setItem("todayRoute", JSON.stringify(data));
      dispatch({
          type: ROUTE_TODAY_ORDER_VIEW_SUCCESS,
          payload: data,
      });
  }
  catch (err) {
      console.log(err)
      dispatch({
          type: ROUTE_TODAY_ORDER_VIEW_FAIL,
          payload: err,
      });
  }
};


export const getRoutesByUserId = (obj) => async (dispatch) => {
  dispatch({
      type: ROUTE_ACTIVE_ORDER_VIEW_REQUEST,
  });
  try {
      const { data } = await axios.post(`${SERVERADDRESS}/v1/route/getRoutesByUserId`, obj);
      localStorage.setItem("routeRequest", JSON.stringify(data));
      dispatch({
          type: ROUTE_ACTIVE_ORDER_VIEW_SUCCESS,
          payload: data,
      });
  }
  catch (err) {
      console.log(err)
      dispatch({
          type: ROUTE_ACTIVE_ORDER_VIEW_FAIL,
          payload: err,
      });
  }
};

export const markedLocationRoute = (obj) => (dispatch) => {
  // console.log("redux actions", obj )
  dispatch({
      type: "MARKED_LOCATIONROUTE",
      payload: obj,
  });
}

// ROUTE STATUS UPDATE BY ROUTE ID
export const updateRouteDriverStatus = (obj) => async (dispatch) => {
  dispatch({
      type: ROUTE_DRIVER_STATUS_UPDATE_REQUEST,
  });
  try {
      const { data } = await axios.put(`${SERVERADDRESS}/v1/reservationStatus/updateRouteDriverStatus`, obj);
      dispatch({
          type: ROUTE_DRIVER_STATUS_UPDATE_SUCCESS,
          payload: data,
      });
  }
  catch (err) {
      console.log(err)
      dispatch({
          type: ROUTE_DRIVER_STATUS_UPDATE_FAIL,
          payload: err,
      });
  }
};


// UPDATE ROUTETRIP
export const updateRouteTrip = (obj) => async ( dispatch ) => {
    dispatch({
        type: ROUTETRIP_UPDATE_REQUEST,
    })
    try {
        const { data } = await axios.put(`${SERVERADDRESS}/v1/routeTrip/updateRouteTrip`, obj)
        dispatch({
            type: ROUTETRIP_UPDATE_SUCCESS,
            payload: data,
    })
}
catch (err) {
    console.log(err)
    dispatch({
        type: ROUTETRIP_UPDATE_FAIL,
        payload: err,
    })
}
}


// UPDATE ROUTE
export const reAssignRoteAction = (obj, successHandler) => async (dispatch) => {
  dispatch({
    type: ROUTE_UPDATE_REQUEST,
  });
  try {
    const { data } = await axios.put(
      `${SERVERADDRESS}/v1/reservationStatus/updateRouteDriverStatus`,
      obj
    );
    dispatch({
      type: ROUTE_UPDATE_SUCCESS,
      payload: data,
    });
    
    notifySuccess("Driver reassigned successfully");
    successHandler();
  } catch (err) {
    console.log(err);
    notifyFailure(err.response.data.message);
    dispatch({
      type: ROUTE_UPDATE_FAIL,
      payload: err,
    });
  }
};


// GET ROUTE AND ROUTETRIP BY ID
export const getRouteandTripById = (obj) => async ( dispatch ) => {
  dispatch({
      type: ROUTE_AND_TRIP_REQUEST,
  })
  try {
      const { data } = await axios.post(`${SERVERADDRESS}/v1/route/getRouteandTripById`, obj)
      dispatch({
          type: ROUTE_AND_TRIP_SUCCESS,
          payload: data,
  })
}
catch (err) {
  console.log(err)
  dispatch({
      type: ROUTE_AND_TRIP_FAIL,
      payload: err,
  })
}
}



export const getRouteandAllTripById = (obj) => async (dispatch) => {
  dispatch({
      type: ALL_ROUTE_LOG_VIEW_REQUEST,
  });
  try {
      const { data } = await axios.post(`${SERVERADDRESS}/v1/route/getRouteandAllTripById`, obj);
      localStorage.setItem("routeLog", JSON.stringify(data));
      dispatch({
          type: ALL_ROUTE_LOG_VIEW_SUCCESS,
          payload: data,
      });
  }
  catch (err) {
      console.log(err)
      dispatch({
          type: ALL_ROUTE_LOG_VIEW_FAIL,
          payload: err,
      });
  }
};


// EXTERNAL COMPANY ACTION
export const getRoutesByExternalCompanyId = (obj) => async (dispatch) => {
  dispatch({
    type: EXTERNAL_COMPANY_ROUTES_VIEW_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/route/getRoutesByExternalCompanyId`,
      obj
    );
    localStorage.setItem("externalCompanyRoutes", JSON.stringify(data));
    dispatch({
      type: EXTERNAL_COMPANY_ROUTES_VIEW_SUCCESS,
      payload: data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: EXTERNAL_COMPANY_ROUTES_VIEW_FAIL,
      payload: err,
    });
  }
};

