import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import {
  tabBasicReportRow,
  tabCalculatorReportRow,
} from "../../../actions/tabActions";
import AdminBasicReportTable from "./AdminBasicReportTable";
import AdminCalculatorReport from "./AdminCalculatorReport";
import AdminReportSearchandFilter from "./AdminReportSearchandFilter";
import AdminReportSearchTable from "./AdminReportSearchTable";

const AdminReportTableView = () => {
  const myState = useSelector((state) => state.changeTheTab);
  const dispatch = useDispatch();


  // min width 992px hai toh

  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []); 



  return (
    <>
      {/* <div className="w-100 row "> */}
        <div
          className="mobile-close-overlay w-100 h-100"
          id="close-settings-overlay"
        ></div>
        {/* <div className="col-12 col-lg-3"> */}
          {/* <div
            className="settings-sidebar border py-3"
            id="mob-settings-sidebar"> */}

            <Link to="" className="d-block d-lg-none close-it" id="close-settings">
              <svg
                className="svg-inline--fa fa-times fa-w-11"
                aria-hidden="true"
                focusable="false"
                data-prefix="fa"
                data-icon="times"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 352 512"
                data-fa-i2svg=""
              >
                <path
                  fill="currentColor"
                  d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                ></path>
              </svg>
            </Link>
            {/* <ul className="settings-menu" id="settingsMenu"> */}
              {
              myState.viewReport === "basicReportRow" ||
              myState.viewReport === "basicReportSearch" ? (
                <>
                {
                windowSize.width >= 992 ?
                <>
                  <div className="row align-items-center">
                    <div className="col">
                      <div className="col-7 mitbsnav">
                        <ul className="nav nav-pills nav-justified">
                          <li className="nav-item active">
                            <NavLink to="/report" onClick={() => dispatch(tabBasicReportRow())} className="">Report</NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink to="/report" onClick={() => dispatch(tabCalculatorReportRow())} className="">Route Calculator Report</NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  </>
                  :
                <></>
              }
                  <div className="col-2 float-right ml-auto">
                  </div>
                 

                  {/* <li className="active">
                    <NavLink
                      className="d-block f-15 text-dark-grey text-capitalize border-bottom-grey"
                      to="/report"
                      onClick={() => dispatch(tabBasicReportRow())}
                    >
                      Basic Report
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="d-block f-15 text-dark-grey text-capitalize border-bottom-grey"
                      to="/report"
                      onClick={() => dispatch(tabCalculatorReportRow())}
                    >
                      Route Calculator Report
                    </NavLink>
                  </li> */}
                </>
              ) : myState.viewReport === "calculatorReportRow" ? (
                <>

              {
                windowSize.width >= 992 ?
                <>
                  <div className="row align-items-center">
                    <div className="col">
                      <div className="col-7 mitbsnav">
                        <ul className="nav nav-pills nav-justified">
                          <li className="nav-item ">
                            <NavLink to="/report" onClick={() => dispatch(tabBasicReportRow())} className="">Report</NavLink>
                          </li>
                          <li className="nav-item active">
                            <NavLink to="/report" onClick={() => dispatch(tabCalculatorReportRow())} className="">Route Calculator Report</NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  </>
                  :
                <></>
              }
                  <div className="col-2 float-right ml-auto">
                  </div>







                  {/* <li>
                    <NavLink
                      className="d-block f-15 text-dark-grey text-capitalize border-bottom-grey"
                      to="/report"
                      onClick={() => dispatch(tabBasicReportRow())}
                    >
                      Basic Report
                    </NavLink>
                  </li>
                  <li className="active">
                    <NavLink
                      className="d-block f-15 text-dark-grey text-capitalize border-bottom-grey"
                      to="/report"
                      onClick={() => dispatch(tabCalculatorReportRow())}
                    >
                      Route Calculator Report
                    </NavLink>
                  </li> */}
                </>
              ) : (
                <></>
              )}
            {/* </ul> */}
          {/* </div> */}
          <br />
          <div className="d-lg-none">
            <div className="settings-box rounded">
              <select className="custom-select custom-select mb-3" onChange={(e)=>dispatch({type: e.target.value})}>
                <option selected value="TAB_BASIC_REPORT_ROW">Basic Report</option>
                <option value="TAB_CALCULATOR_REPORT_ROW">Route Calculator Report</option>
              </select>
            </div>
          </div>
        {/* </div> */}
        <div className="col-12 col-lg-12">
          <div className="settings-box rounded">
            <form
              method="POST"
              id="editSettings"
              className="ajax-form"
              autoComplete="off"
            >
              <div className="s-b-inner s-b-notifications bg-white b-shadow-4 rounded">
                <div className="s-b-n-header d-lg-flex justify-content-between card-header border-bottom-grey">
                  <div className="s-b-n-header" id="tabs">
                    <h2 className="mb-0 p-20 f-21 font-weight-normal text-capitalize">
                      <span>REPORTS</span>
                    </h2>
                  </div>
                </div>

                {myState.viewReport === "basicReportRow" ? (
                  <>
                    <AdminReportSearchandFilter />
                    <AdminBasicReportTable />
                  </>
                ) : myState.viewReport === "basicReportSearch" ? (
                  <>
                    <AdminReportSearchandFilter />
                    <AdminReportSearchTable />
                  </>
                ) : myState.viewReport === "calculatorReportRow" ? (
                  <>
                    {/* <AdminReportSearchandFilter /> */}
                    <AdminCalculatorReport />
                  </>
                ) : (
                  <div>Loading</div>
                )}
              </div>
            </form>
          </div>
        </div>
      {/* </div> */}
    </>
  );
};
export default AdminReportTableView;
