import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { editUserAction } from "../../../actions/userActions";
import { notifyPasswordNotMatch } from "../../toast";

const ChangePasswordComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useSelector((state) => state.auth);
  const { user } = auth;

  const [profileData, setProfileData] = useState({});

  const changeHandler = (e) => {
    setProfileData({ ...profileData, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    if (user) {
      setProfileData(user);
    }
  }, [user]);
  
  // console.log("===>", profileData);

  const submitHandler = (e) => {
    e.preventDefault();
    let tempData;
    if (values.password !== ''){
      tempData = {
        userId: profileData.id,
        // firstName: profileData.firstName,
        // middleName: profileData.middleName,
        // lastName: profileData.lastName,
        // email: data.email,
        // email: profileData.email,

        // phone: profileData.phone,
        // workPhone: values2.workPhone,
        // otherPhone: values2.otherPhone,
        // clientWorkInformation: values2.clientWorkInformation,
        // address: profileData.address,
        // state: values2.state,
        // city: profileData.city,
        // zip: profileData.zip,
        // summary: profileData.summary,
        password: values.password,
      };
    }
    else {
      tempData = {
        userId: profileData.id,
        // firstName: profileData.firstName,
        // middleName: profileData.middleName,
        // lastName: profileData.lastName,
        // email: data.email,
        // email: profileData.email,
  
        // phone: profileData.phone,
        // workPhone: values2.workPhone,
        // otherPhone: values2.otherPhone,
        // clientWorkInformation: values2.clientWorkInformation,
        // address: profileData.address,
        // state: values2.state,
        // city: profileData.city,
        // zip: profileData.zip,
        // summary: profileData.summary,
      };
    }
    if (values.password === values.passwordConfirm){
      dispatch(editUserAction(tempData, handleSuccess));
    }
    else {
      notifyPasswordNotMatch()
    }
  };
  const [values, setValues] = useState({
    password : "",
    passwordConfirm : "",
  })
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values, [fieldName]:
      event.target.value
    })
  }

  const handleSuccess = () => {
    let from = location.state?.from?.pathname || "/dashboard-driver";
    navigate(from, { replace: true });
  };

    return (
        <>
            <div className="row emp-dash-detail">
                <div className="col-xl-12 col-lg-12 col-md-12 e-d-info-notices">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card p-3 mb-3">
                                <form 
                                    onSubmit={submitHandler}
                                    >
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-6">
                                                <label
                                                    className="f-16 text-dark-grey mb-12"
                                                    data-label=""
                                                    htmlFor="after_days">
                                                        Your password must be at least eight characters and must be alphanumeric.
                                                </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group my-3">
                                                <label
                                                    className="f-14 text-dark-grey mb-12"
                                                    data-label=""
                                                    htmlFor="after_days"
                                                >Change Password</label>
                                                <input className="form-control height-35 f-14" 
                                                type="password" 
                                                value={values.password || ''} 
                                                onChange={handleChange('password')} 
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group my-3">
                                                <label
                                                    className="f-14 text-dark-grey mb-12"
                                                    data-label=""
                                                    htmlFor="after_days"
                                                >Confirm Password</label>
                                                <input className="form-control height-35 f-14" 
                                                type="password" 
                                                value={values.passwordConfirm || ''} 
                                                onChange={handleChange('passwordConfirm')}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div
                                                className="form-group "
                                                style={{ marginTop: "1.5em !important" }}
                                            >
                                                <button
                                                    type="sbmit"
                                                    className="btn-primary rounded f-14 p-2 mr-3"
                                                    id="save-form"
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ChangePasswordComponent