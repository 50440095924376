import React, { useState } from 'react'

const AdminRouteLogTableRow = ({  current,i,dropDownRow,setDropDownRow,}) => {
    const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
    const openMoreVertDropDown = () => {
      setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
    };
    return (
        <>
            <tr
                id="row-20"
                role="row"
                className="odd border"
                key={i}
                onMouseLeave={() => setshowMoreVertDropDown(false)}
            >
                <td className="border-right">{current.customerId.firstName + " " +current.customerId.lastName}</td>
                <td className="border-right">
                    {current.isDone === true ? "Completed" : "In progress"}
                </td>
                <td className="border-right">{parseInt(current?.pickUpTime.toString().substring(11,13)) > 11 ? current?.pickUpTime.toString().substring(11,16)+" pm" : current?.pickUpTime.toString().substring(11,16)+' am'}</td>
                <td className="border-right">{parseInt(current?.dropOffTime.toString().substring(11,13)) > 11 ? current?.dropOffTime.toString().substring(11,16)+" pm" : current?.dropOffTime.toString().substring(11,16)+' am'}</td>
                <td className="border-right">{current.pickUpLat}</td>
                <td className="border-right">{current.pickUpLng}</td>
                <td className="border-right">{current.dropOffLat}</td>
                <td className="border-right">{current.dropOffLng}</td>
                {/* <td className=" text-right pr-20">
                    <div className="task_view">
                        <div className="dropdown">
                            <Link
                                className="task_view_more d-flex align-items-center justify-content-center dropdown-toggle"
                                type="link"
                                id="dropdownMenuLink-20"
                                data-toggle="dropdown"
                                onClick={openMoreVertDropDown}
                                aria-haspopup="true"
                                aria-expanded="false"
                                to=""
                            >
                                <i className="icon-options-vertical icons"></i>
                            </Link>
                            <div
                                className={`dropdown-menu dropdown-menu-right ${showMoreVertDropDown
                                    ? "dropdown-menu dropdown-menu-right show"
                                    : "dropdown-menu dropdown-menu-right"}`}
                                aria-labelledby="dropdownMenuLink-20"
                                tabIndex="0"
                            >
                                <Link
                                    className="dropdown-item"
                                    to=""
                                    onClick={() => setModal({ open: true, data: current })}
                                >
                                    <i className="fa fa-edit"> Edit</i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </td> */}
            </tr>
        </>
    )
}
export default AdminRouteLogTableRow