import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { navigateUserFunc } from '../../actions/generalActions'
import AdminHeader from '../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminHeader'
import AdminSidebar from '../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminSidebar'
import AdminUserLogInActivitiesComponent from '../../components/adminComponents/adminUserComponents/AdminUserLogInActivitiesComponent'
import NotAllowed from '../../components/NotAllowed'

const AdminUserLogInActivitiesPage = () => {
    let navigate = useNavigate();
    const auth = useSelector((state) => state?.auth);
    useEffect(() => {
        if (auth?.user?.permissionId?.user?.isAllow === false){
            navigateUserFunc(auth?.user, navigate)
        }
    },[])

  return (
    <>
        <AdminHeader />
        <AdminSidebar />
        <div className="body-wrapper clearfix">
            <section className="main-container " id="fullscreen">
                <div className="px-4 py-2 border-top-0 emp-dashboard">
                    <div className="d-block d-lg-flex d-md-flex justify-content-between action-bar mb-20 mt-3">
                        <div id="table-actions" className="flex-grow-1 align-items-center">
                            <h4 className=" mb-0 f-21 text-capitalize font-weight-bold"> Users </h4>
                        </div>
                        <div className="btn-group mt-2 mt-lg-0 mt-md-0 ml-0 ml-lg-3 ml-md-3" role="group">
                            <NavLink to="/add-user" type="button" className="btn-secondary rounded f-14 p-2 mr-3 float-left mb-2 mb-lg-0 mb-md-0" id="add-lead">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                </svg> Add User
                            </NavLink>
                        </div>
                        <div className="btn-group mt-2 mt-lg-0 mt-md-0 ml-0 ml-lg-3 ml-md-3" role="group">
                            <NavLink to="/users" type="button" className="btn-secondary rounded f-14 p-2 mr-3 float-left mb-2 mb-lg-0 mb-md-0" id="add-lead">
                                {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                </svg>  */}
                                View All User
                            </NavLink>
                        </div>
                    </div>
                    <AdminUserLogInActivitiesComponent />

                        {/* {
                            auth?.user?.permissionId?.user?.read === true ?
                            <AdminUserTableComponent />
                            :
                            <NotAllowed />
                        } */}
                </div>
            </section>
        </div>
    </>
  )
}
export default AdminUserLogInActivitiesPage