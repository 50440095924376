import React, { useState } from 'react'
import logo2 from "../../assetsgeneral/images/header-logo.png"
// import logo2 from "../../assetsgeneral/images/CONNECT CARE TRANS-04.png"
// import logo2 from "../../assetsgeneral/images/CONNECT_CARE_TRANS-04small.jpg"
// import logo2 from "../../assetsgeneral/images/CONNECT CARE TRANS-04 (1).png"
import { useDispatch, useSelector } from 'react-redux'
import { tabContactUs, tabFaq, tabHome, tabServices, tabSignIn, tabWhyUs } from '../../actions/tabActions'
import { NavLink } from 'react-router-dom'

const NavBar = () => {
    const myState = useSelector((state => state.changeTheTab))
    const dispatch = useDispatch()
    const [showDropDown, setshowDropDown] = useState(false)
    const openDropDown = () => {
        setshowDropDown((showDropDown) => !showDropDown)
    } 
    return (
        <>
            <div className="paraxdo-header-section">
                {/* <div className="container"> */}
                    {/* <div className="row"> */}
                        <div className="paraxdo-header-img">
                            <nav className="navbar sticky-top navbar-expand-lg bg-dark">
                                <div className="container">
                                    <NavLink className="navbar-brand" href="index">
                                        <img src={logo2} id="logsedf" alt=""/>
                                    </NavLink>
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                        <i className="fa fa-bars" aria-hidden="true"></i>
                                    </button>
                                    {/* <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                    {
                                        myState.viewNavBar === "home" ?
                                        <>
                                            <ul className="navbar-nav">
                                                <li className="nav-item">
                                                    <NavLink to="/" className="nav-link active-3" onClick={() => dispatch(tabHome())}>Home</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/why-us" className="nav-link" onClick={() => dispatch(tabWhyUs())}>Why Us?</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/services" className="nav-link" onClick={() => dispatch(tabServices())}>Services</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/faq" className="nav-link" onClick={() => dispatch(tabFaq())}>Faq</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/contact-us" className="nav-link" onClick={() => dispatch(tabContactUs())}> Contact Us</NavLink>
                                                </li>
                                                <li className="nav-item sign-in">
                                                    <NavLink to="/login" className="nav-link" onClick={() => dispatch(tabSignIn())}> Sign In</NavLink>
                                                </li>
                                            </ul>
                                        </>      
                                        : 
                                            myState.viewNavBar === "whyus" ?
                                        <>
                                            <ul className="navbar-nav">
                                                <li className="nav-item">
                                                    <NavLink to="/" className="nav-link" onClick={() => dispatch(tabHome())}>Home</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/why-us" className="nav-link active-3" onClick={() => dispatch(tabWhyUs())}>Why Us?</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/services" className="nav-link" onClick={() => dispatch(tabServices())}>Services</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/faq" className="nav-link" onClick={() => dispatch(tabFaq())}>Faq</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/contact-us" className="nav-link" onClick={() => dispatch(tabContactUs())}> Contact Us</NavLink>
                                                </li>
                                                <li className="nav-item sign-in">
                                                    <NavLink to="/login" className="nav-link" onClick={() => dispatch(tabSignIn())}> Sign In</NavLink>
                                                </li>
                                            </ul>
                                        </>
                                        : 
                                            myState.viewNavBar === "services" ?
                                        <>
                                            <ul className="navbar-nav">
                                                <li className="nav-item">
                                                    <NavLink to="/" className="nav-link" onClick={() => dispatch(tabHome())}>Home</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/why-us" className="nav-link " onClick={() => dispatch(tabWhyUs())}>Why Us?</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/services" className="nav-link active-3" onClick={() => dispatch(tabServices())}>Services</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/faq" className="nav-link" onClick={() => dispatch(tabFaq())}>Faq</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/contact-us" className="nav-link" onClick={() => dispatch(tabContactUs())}> Contact Us</NavLink>
                                                </li>
                                                <li className="nav-item sign-in">
                                                    <NavLink to="/login" className="nav-link" onClick={() => dispatch(tabSignIn())}> Sign In</NavLink>
                                                </li>
                                            </ul>
                                        </>
                                        : 
                                            myState.viewNavBar === "faq" ?
                                        <>
                                            <ul className="navbar-nav">
                                                <li className="nav-item">
                                                    <NavLink to="/" className="nav-link" onClick={() => dispatch(tabHome())}>Home</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/why-us" className="nav-link " onClick={() => dispatch(tabWhyUs())}>Why Us?</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/services" className="nav-link" onClick={() => dispatch(tabServices())}>Services</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/faq" className="nav-link active-3" onClick={() => dispatch(tabFaq())}>Faq</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/contact-us" className="nav-link" onClick={() => dispatch(tabContactUs())}> Contact Us</NavLink>
                                                </li>
                                                <li className="nav-item sign-in">
                                                    <NavLink to="/login" className="nav-link" onClick={() => dispatch(tabSignIn())}> Sign In</NavLink>
                                                </li>
                                            </ul>
                                        </>
                                        : 
                                            myState.viewNavBar === "contactus" ?
                                        <>
                                            <ul className="navbar-nav">
                                                <li className="nav-item">
                                                    <NavLink to="/" className="nav-link" onClick={() => dispatch(tabHome())}>Home</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/why-us" className="nav-link " onClick={() => dispatch(tabWhyUs())}>Why Us?</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/services" className="nav-link" onClick={() => dispatch(tabServices())}>Services</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/faq" className="nav-link " onClick={() => dispatch(tabFaq())}>Faq</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/contact-us" className="nav-link active-3" onClick={() => dispatch(tabContactUs())}> Contact Us</NavLink>
                                                </li>
                                                <li className="nav-item sign-in">
                                                    <NavLink to="/login" className="nav-link" onClick={() => dispatch(tabSignIn())}> Sign In</NavLink>
                                                </li>
                                            </ul>
                                        </>
                                        : 
                                            myState.viewNavBar === "signin" ?
                                        <>
                                            <ul className="navbar-nav">
                                                <li className="nav-item">
                                                    <NavLink to="/" className="nav-link" onClick={() => dispatch(tabHome())}>Home</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/why-us" className="nav-link " onClick={() => dispatch(tabWhyUs())}>Why Us?</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/services" className="nav-link" onClick={() => dispatch(tabServices())}>Services</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/faq" className="nav-link " onClick={() => dispatch(tabFaq())}>Faq</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/contact-us" className="nav-link" onClick={() => dispatch(tabContactUs())}> Contact Us</NavLink>
                                                </li>
                                                <li className="nav-item sign-in">
                                                    <NavLink to="/login" className="nav-link" onClick={() => dispatch(tabSignIn())}> Sign In</NavLink>
                                                </li>
                                            </ul>
                                        </>
                                        : 
                                            <div>Loading</div>
                                         }
                                    </div>
                                    <div className="nav-right-content">
                                        <NavLink to="/login" className="info-bar-item-two">
                                            <div className="author-nav-content">
                                                <span> Sign In</span>
                                            </div>
                                        </NavLink>
                                    </div> */}
                                </div>
                            </nav>
                        </div>
                    {/* </div> */}
                {/* </div> */}
            </div>
        </>
    )
}
export default NavBar