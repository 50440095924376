import moment from 'moment'
import React, { useState } from 'react'

const AdminReportSearchTableRow = ({current, i, dropDownRow, setDropDownRow}) => {
    const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false)
    const [showDropDown, setshowDropDown] = useState(false)
    const openMoreVertDropDown = () => {
        setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown)
    }
    
    const openDropDown = () => {
        setshowDropDown((showDropDown) => !showDropDown)
    }
  return (
    <>
        <tr role="row" className="odd border" key={i} onMouseLeave={() => setshowMoreVertDropDown(false)}>
            <td className="border-right">{current.reservation.orderId}</td>
            <td className="border-right">{current?.reservation?.assetId?.assetNo}
                <i data-toggle="tooltip"></i>
            </td>
            <td className="border-right"><strong>Pickup</strong> {current.reservation.pickUpTime}{parseInt(current.reservation.pickUpTime.substring(0, 2)) > 11 ? "pm" : 'am'}<br /><strong>Dropoff</strong> {current.reservation.dropOffTime}{parseInt(current.reservation.dropOffTime.substring(0, 2)) > 11 ? "pm" : 'am'}<br /> 
            {/* 1 hrs 5 mint */}
            {current?.reservation?.fareAndDistance?.estimatedTime}
            </td>
            <td className="border-right"><strong>{current.reservation.customerId.firstName} {current.reservation.customerId.lastName}</strong><br />{current.reservation.customerId.phone}<br />
            </td>
            <td className="border-right w-30">
                <div className="d-flex justify-content-between">
                    <div>
                        <div>
                            <p className="f-12 font-medium mb-0"><strong>{current.reservation.pickUpAddress.address}</strong> </p>
                            {/* <p className="f-12 mb-0">IL 60609, USA</p> */}
                        </div>
                        <div>
                            <time> {moment(current.reservation.pickUpDate).format('MM/DD/YYYY')} - {current.reservation.pickUpTime}{parseInt(current.reservation.pickUpTime.substring(0, 2)) > 11 ? "pm" : 'am'}</time>
                        </div>
                    </div>
                    <div className="mt-4 px-4">
                        <div className="hidden sm:block">
                            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="12" viewBox="0 0 40 6" fill="none">
                                <path d="M40 3L35 0.113249V5.88675L40 3ZM0 3.5H35.5V2.5H0V3.5Z" fill="black"></path>
                            </svg>
                        </div>
                        <p className="text-center font-medium">
                            {/* <span>{current.reservation.distanceFromBaseLocation} {current.reservation.distanceFromPickToDropOff} {current.reservation.totalMileage} mi</span> */}
                            <span>{current.reservation.totalMileage} mi</span>
                        </p>
                    </div>
                    <div>
                        <div>
                            <p className="f-12 font-medium mb-0"><strong>{current.reservation.dropOffAddress.address}</strong></p>
                            {/* <p className="f-12 mb-0">IL 60623</p> */}
                        </div>
                        <div>
                            <time> {moment(current.reservation.dropOffDate).format('MM/DD/YYYY')} - {current.reservation.dropOffTime}{parseInt(current.reservation.dropOffTime.substring(0, 2)) > 11 ? "pm" : 'am'}</time>
                        </div>
                    </div>
                </div>
            </td>
            <td className="border-right">{current.reservation.type.name}</td>
            {/* <td className="border-right"><strong>$787.45</strong> */}
            <td className="border-right"><strong>${current.reservation.totalAmount.toFixed(2)}</strong>
                {/* <i data-toggle="tooltip" className="fa fa-info-circle fa-w-16 text-info"></i> */}
                <i data-toggle="tooltip"></i>
            </td>
            <td className="border-right">
                {current.reservation.status}
                {/* <div className="select-picker"> */}
                    {/* <select className="form-control select-picker assign_role" style={{ height: '35px' }} value={values.status} onChange={handleChange('status')}>
                        <option value="Unassigned">Unassigned</option>
                        <option value="Assigned">Assigned</option>
                        <option value="Pending">Pending</option>
                    </select> */}
                    {/* {
                        values &&
                        values.status &&
                        values.status !== "Pending" ?
                        <select className="form-control select-picker assign_role" style={{ height: '35px' }} value={values.status} onChange={handleChange('status')}>
                            <option value="Unassigned">Unassigned</option>
                            <option value="Assigned">Assigned</option>
                            <option value="Pending">Pending</option>
                        </select>
                        :
                        <select className="form-control select-picker assign_role" style={{ height: '35px' }} value={values.status} onChange={handleChange('status')} disabled>
                            <option value="Unassigned">Unassigned</option>
                            <option value="Assigned">Assigned</option>
                            <option value="Pending">Pending</option>
                        </select>
                    } */}
                {/* </div> */}
            </td>
            
        </tr>
    </>
  )
}
export default AdminReportSearchTableRow