import React, { useEffect, useState } from 'react'
import { Accordion } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getRouteandAllTripById } from '../../../actions/routeActions';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import AdminRouteLogTableRow from './AdminRouteLogTableRow';

const AdminRouteLogTableComponent = () => {
  const auth = useSelector((state) => state.auth);
  const routes = useSelector((state) => state.routes);
  const params = useParams();

  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };
  const dispatch = useDispatch();
  const routeLogData = async (e) => {
    dispatch(getRouteandAllTripById({_id: params.id }))
  }
  useEffect(() => {
    routeLogData();
  }, []);

  return (
    <>
        <div className="settings-box  rounded">
        <form
          method="POST"
          id="editSettings"
          className="ajax-form"
          autoComplete="off"
        >
          
          <div className="s-b-inner s-b-notifications bg-white b-shadow-4 rounded">
            <div className="s-b-n-header d-lg-flex justify-content-between card-header border-bottom-grey">
              <div className="s-b-n-header" id="tabs">
                <h2 className="mb-0 p-20 f-21 font-weight-normal text-capitalize">
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="21"
                    fill="currentColor"
                    className="bi bi-credit-card-2-back"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11 5.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1z" />
                    <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2zm13 2v5H1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zm-1 9H2a1 1 0 0 1-1-1v-1h14v1a1 1 0 0 1-1 1z" />
                  </svg> */}
                  <span>ROUTES LOG DETAILS</span>
                </h2>
              </div>
            </div>
            <div className="s-b-n-content">
            <div className="tab-content" id="nav-tabContent">
              <div
                className="tab-pane fade show active"
                id="nav-email"
                role="tabpanel"
                aria-labelledby="nav-email-tab"
              >
                <div className="d-flex flex-wrap justify-content-between">
                  <div className="col-lg-12 col-md-12 ntfcn-tab-content-left w-100 p-4 ">
                    <div className="row">
                      <div className="col-md-5">
                          <div className="form-group ">
                              <p className="f-18 text-dark mb-12" htmlFor="Name">
                                Assigning Details:
                              </p>
                          </div>
                      </div>
                      <table
                          className="table border dataTable no-footer"
                          id="leads-table"
                          role="grid"
                          aria-describedby="leads-table_info"
                          style={{ overflowX: "auto", width: "95%", margin: "auto" }}
                      >
                          <thead className="card-header">
                              <tr role="row">
                                  <th
                                      title="AName"
                                      className="sorting border-right"
                                      tabIndex="0"
                                      aria-controls="leads-table"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Mobile: activate to sort column ascending"
                                  >
                                      Assignee's Name
                                  </th>
                                  <th
                                      title="Assignedon"
                                      className="sorting_disabled border-right"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Next Follow Up"
                                  >
                                      Assigned On
                                  </th>
                                  <th
                                      title="Assignedto"
                                      className="sorting_disabled border-right"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Next Follow Up"
                                  >
                                      Assigned to
                                  </th>
                                  <th
                                      title="Response"
                                      className="sorting_disabled border-right"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Next Follow Up"
                                  >
                                      Response Date
                                  </th>
                                  <th
                                      title="status"
                                      className="sorting_disabled border-right"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Next Follow Up"
                                  >
                                      Status
                                  </th>
                                  <th
                                      title="Reason"
                                      className="sorting_disabled border-right"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Next Follow Up"
                                  >
                                      Reason to Reject
                                  </th>
                              </tr>
                          </thead>
                          <tbody>
                              {
                                  routes &&
                                  routes.routeLog &&
                                  routes.routeLog.resStatus &&
                                  routes.routeLog.resStatus.length > 0 &&
                                  routes.routeLog.resStatus.map((e, i) => {
                                      return (
                                          <tr
                                              role="row"
                                              className="odd border" key={i}
                                          >
                                              <td className="border-right">
                                                  {e.assigneeId.firstName + " " + e.assigneeId.lastName}
                                              </td>
                                              <td className="border-right">
                                                  {moment(e.assignedDate).format('MM/DD/YYYY')}
                                              </td>
                                              <td className="border-right">
                                                  {e.driverId.firstName + " " + e.driverId.lastName}
                                              </td>
                                              <td className="border-right">
                                                  {moment(e.responseDate).format('MM/DD/YYYY')}
                                              </td>
                                              <td className="border-right">
                                                  {e.driverBookingStatus === 'Assigned' ? "Accepeted" : "Rejected"}
                                              </td>
                                              <td className="border-right">
                                                  {e.declineReason}
                                              </td>
                                          </tr>
                                      )
                                  })}
                          </tbody>
                      </table>
                  </div>
                  <br />
                    <div className="row">
                      <div className="col-sm-12">
                      <div className="form-group ">
                        <p className="f-18 text-dark mb-12" htmlFor="Name">
                          {" "}
                          Trips Details:
                        </p>
                      </div>
                        <Accordion defaultActiveKey="0">
                          {routes &&
                            routes.routeLog &&
                            routes.routeLog.trip &&
                            routes.routeLog.trip.length > 0 &&
                            routes.routeLog.trip.map((routeType, index) => (
                          <Accordion.Item eventKey={index} key={index}>
                            {/* <Accordion.Item> */}
                              <Accordion.Header className="w-100">
                                {moment(routeType?.tripStartTime.toString().substring(0, 10)).format("MM/DD/YYYY")}
                              </Accordion.Header>
                              
                              <Accordion.Body>
                                <table
                                  className="table border w-100 dataTable no-footer"
                                  id="leads-table"
                                  role="grid"
                                  aria-describedby="leads-table_info"
                                  style={{ overflowX: "auto" }}
                                  >
                                    <thead className="card-header">
                                      <tr role="row">
                                        <th
                                          title="Name"
                                          className="sorting border-right "
                                          tabIndex="0"
                                          aria-controls="leads-table"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Mobile: activate to sort column ascending"
                                        >
                                          Client's Name
                                        </th>
                                        <th
                                          title="Status"
                                          className="sorting border-right"
                                          tabIndex="0"
                                          aria-controls="leads-table"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Lead Agent: activate to sort column ascending"
                                        >
                                          Status
                                        </th>
                                        <th
                                          title="TimeandDate"
                                          className="sorting border-right"
                                          tabIndex="0"
                                          aria-controls="leads-table"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Lead Agent: activate to sort column ascending"
                                        >
                                          Pickup Time
                                        </th>
                                        <th
                                          title="DropoffTimeandDate"
                                          className="sorting border-right"
                                          tabIndex="0"
                                          aria-controls="leads-table"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Lead Agent: activate to sort column ascending"
                                        >
                                          Dropoff Time
                                        </th>
                                        <th
                                          title="latitude"
                                          className="sorting border-right"
                                          tabIndex="0"
                                          aria-controls="leads-table"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Lead Agent: activate to sort column ascending"
                                        >
                                          Pickup Latitude
                                        </th>
                                        <th
                                          title="longitude"
                                          className="sorting border-right"
                                          tabIndex="0"
                                          aria-controls="leads-table"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Lead Agent: activate to sort column ascending"
                                        >
                                          Pickup Longitude
                                        </th>
                                        <th
                                          title="latitude"
                                          className="sorting border-right"
                                          tabIndex="0"
                                          aria-controls="leads-table"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Lead Agent: activate to sort column ascending"
                                        >
                                          Dropoff Latitude
                                        </th>
                                        <th
                                          title="longitude"
                                          className="sorting border-right"
                                          tabIndex="0"
                                          aria-controls="leads-table"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Lead Agent: activate to sort column ascending"
                                        >
                                          Dropoff Longitude
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {routeType?.customersDetails?.map((current, i) => {
                                        return (
                                          <AdminRouteLogTableRow
                                            current={current}
                                            i={i}
                                            // setModal={setModal}
                                            key={i}
                                          />
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </Accordion.Body>
                              </Accordion.Item>
                             )
                            )}
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </form>
      </div>
    </>
  )
}
export default AdminRouteLogTableComponent