import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import ExternalCompanyUserTableRow from "./ExternalCompanyUserTableRow";
import { getPassengerByExternalCompanyId } from "../../actions/userActions";
import { getBaseLocation } from "../../actions/locationActions";
import shift1 from "../../assets/images/load.png"

const ExternalCompanyUserTable = () => {
    const auth = useSelector((state) => state.auth);
    const users = useSelector((state) => state.users);
    // const externalCompanies = useSelector((state) => state.externalCompanies);
    const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
    const openMoreVertDropDown = () => {
      setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
    };
    const dispatch = useDispatch();
    const externalClientData = async (e) => {
        dispatch(
            getPassengerByExternalCompanyId(
                { 
                    externalCompanyId: auth?.user?.externalCompanyId ,
                    limit: limit,
                    page: currentPageNumber
                }
            )
        );
        dispatch(getBaseLocation({ companyId: auth?.user?.companyId?.id }));
    };
   

    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [limit, setLimit] = useState(10);
    
    const handleLimit = async (event) => {
        setLimit(parseInt(event.target.value));
        dispatch(
        getPassengerByExternalCompanyId(
            { 
                externalCompanyId: auth?.user?.externalCompanyId,
                limit: event.target.value,
                page: 1,
            })
        );
        setCurrentPageNumber(1);
    };

  const onClickNext = async () => {
    let i = currentPageNumber;
    i++;
    if (i <= users.externalCompanyUsers.totalPages) {
      setCurrentPageNumber(currentPageNumber + 1);
      dispatch(
        getPassengerByExternalCompanyId(
          { 
            externalCompanyId: auth?.user?.externalCompanyId,
            limit: limit,
            page: currentPageNumber + 1
          })
      );
    }
  };

  const onClickPrev = async () => {
    let i = currentPageNumber;
    i--;
    if (i >= 1) {
      setCurrentPageNumber(currentPageNumber - 1);
      dispatch(
        getPassengerByExternalCompanyId(
          { 
            externalCompanyId: auth?.user?.externalCompanyId,
            limit: limit,
            page: currentPageNumber - 1
          })
      );
    }
  };

  const handleChangeData = async (pageNo) => {
    setCurrentPageNumber(pageNo);
    dispatch(
      getPassengerByExternalCompanyId({ 
        externalCompanyId: auth?.user?.externalCompanyId, 
        limit: limit, 
        page: pageNo
    })
    );
  };

useEffect(() => {
    externalClientData();
  }, []);

    return (
        <>
            <div className="d-flex flex-column w-tables rounded mt-3 bg-white">
                <div className="d-flex border">
                    <div className="flex-grow-1">
                        <div className="dataTables_length d-flex" id="leads-table_length">
                            <div className="p-2">
                                <label>Show entries</label>
                            </div>
                            <label>
                                <select
                                    name="leads-table_length"
                                    aria-controls="leads-table"
                                    className="custom-select custom-select-sm form-control form-control-sm"
                                    autoComplete="false"
                                    onChange={handleLimit}
                                >
                                    {[10, 25, 50, 100].map((current, i) => {
                                        return (
                                            <option value={current} key={i}>
                                                {current}
                                            </option>
                                        );
                                    })}
                                </select>
                            </label>
                        </div>
                    </div>
                </div>
                <div
                    id="leads-table_wrapper"
                    className="dataTables_wrapper dt-bootstrap4 no-footer"
                >
                    <div className="row">
                        <div className="col-sm-12">
                            <table
                                className="table border w-100 dataTable no-footer"
                                id="leads-table"
                                role="grid"
                                aria-describedby="leads-table_info"
                                style={{ overflowX: "auto" }}
                            >
                                <thead className="card-header">
                                    <tr role="row">
                                        <th
                                            title="Client Name"
                                            className="sorting border-right"
                                            tabIndex="0"
                                            aria-controls="leads-table"
                                            rowSpan="1"
                                            colSpan="1"
                                            aria-label="Mobile: activate to sort column ascending"
                                        >
                                            Client's Name
                                        </th>
                                        <th
                                            title="Email"
                                            className="sorting border-right"
                                            tabIndex="0"
                                            aria-controls="leads-table"
                                            rowSpan="1"
                                            colSpan="1"
                                            aria-label="Lead Agent: activate to sort column ascending"
                                        >
                                            Email
                                        </th>
                                        <th
                                            title="Phone"
                                            className="sorting border-right"
                                            tabIndex="0"
                                            aria-controls="leads-table"
                                            rowSpan="1"
                                            colSpan="1"
                                            aria-label="Lead Agent: activate to sort column ascending"
                                        >
                                            Phone
                                        </th>
                                        {/* <th
                                            title="Address"
                                            className="sorting border-right"
                                            tabIndex="0"
                                            aria-controls="leads-table"
                                            rowSpan="1"
                                            colSpan="1"
                                            aria-label="Lead Agent: activate to sort column ascending"
                                        >
                                            Address
                                        </th> */}
                                        <th
                                            title="Summary"
                                            className="sorting border-right"
                                            tabIndex="0"
                                            aria-controls="leads-table"
                                            rowSpan="1"
                                            colSpan="1"
                                            aria-label="Lead Agent: activate to sort column ascending"
                                        >
                                            Notes
                                        </th>
                                        <th
                                            title="Action"
                                            className="text-right pr-20 sorting_disabled border-right"
                                            rowSpan="1"
                                            colSpan="1"
                                            aria-label="Action"
                                        >
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        users &&
                                        users.externalCompanyUsers &&
                                        users.externalCompanyUsers.results &&
                                        users.externalCompanyUsers.results.length > 0 &&
                                        users.externalCompanyUsers.results.map((current, i) => {
                                            // if (current.delete === false) {
                                            return (
                                                <ExternalCompanyUserTableRow 
                                                    current={current}
                                                    i={i}
                                                    key={i}
                                                    // handleModal={handleModal} 
                                                />
                                            );
                                            // }
                                        })
                                    }
                                </tbody>
                            </table>
                            {
                                users &&
                                users.externalCompanyUsers &&
                                users.externalCompanyUsers.results &&
                                users.externalCompanyUsers.results.length === 0 &&
                                <div className="dash-stats-list" style={{ textAlign: "center" }}>
                                <br />
                                <br />
                                <img
                                    alt=""
                                    src={shift1}
                                    style={{ width: "145px", opacity: "0.5" }} />
                                <br />
                                <p style={{ opacity: "0.5" }}>
                                    No Data Found
                                </p>
                                </div>
                            }
                            <div
                                id="leads-table_processing"
                                className="dataTables_processing card"
                                style={{ display: "none" }}
                            >
                                Processing...
                            </div>
                        </div>
                    </div>
                    <div className="d-flex border">
                        {
                            users.loading === false ?
                            <div className="flex-grow-1">
                                <div
                                    className="dataTables_info"
                                    id="leads-table_info"
                                    role="status"
                                    aria-live="polite"
                                >
                                    Showing {1 + limit * (currentPageNumber - 1)} to{" "}
                                    {users?.externalCompanyUsers?.displayingResults + limit * (currentPageNumber - 1)}{" "}
                                    of {users?.externalCompanyUsers?.totalResults} entries
                                </div>
                            </div>
                            :
                            <div className="flex-grow-1">
                            <div
                                className="dataTables_info"
                                id="leads-table_info"
                                role="status"
                                aria-live="polite"
                            >
                                Loading entries
                            </div>
                            </div>
                        }
                        <div>
                            <div
                                className="dataTables_paginate paging_simple_numbers"
                                id="leads-table_paginate"
                            >
                                <ul className="pagination">
                                    <li className="page-item">
                                        <NavLink
                                            className="page-link"
                                            to="#"
                                            aria-label="Previous"
                                            onClick={onClickPrev}
                                        >
                                            <span aria-hidden="true">Previous</span>
                                            <span className="sr-only">Previous</span>
                                        </NavLink>
                                    </li>
                                    {users.externalCompanyUsers &&
                                        users.externalCompanyUsers.paginationArr &&
                                        users.externalCompanyUsers.paginationArr.length > 0 &&
                                        users.externalCompanyUsers.paginationArr.map((current, i) => {
                                            return currentPageNumber === current ? (
                                                <li className="page-item active" key={i}>
                                                    <Link to="" className="page-link" value={current}>
                                                        {current}
                                                    </Link>
                                                </li>
                                            ) : (
                                                <li className="page-item" key={i}>
                                                    <Link
                                                        className="page-link"
                                                        value={current}
                                                        onClick={() => handleChangeData(current)}
                                                        to=""
                                                    >
                                                        {current}
                                                    </Link>
                                                </li>
                                            );
                                        })}
                                    <li className="page-item">
                                        <NavLink
                                            className="page-link"
                                            to="#"
                                            aria-label="Next"
                                            onClick={onClickNext}
                                        >
                                            <span aria-hidden="true">Next</span>
                                            <span className="sr-only">Next</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default ExternalCompanyUserTable