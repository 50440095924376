import axios from "axios";
import {
    RESERVATION_VIEW_REQUEST,
    RESERVATION_VIEW_SUCCESS,
    RESERVATION_VIEW_FAIL,
    RESERVATION_CREATE_REQUEST,
    RESERVATION_CREATE_SUCCESS,
    RESERVATION_CREATE_FAIL,
    RESERVATION_UPDATE_REQUEST,
    RESERVATION_UPDATE_SUCCESS,
    RESERVATION_UPDATE_FAIL,
    RESERVATION_DELETE_REQUEST,
    RESERVATION_DELETE_SUCCESS,
    RESERVATION_DELETE_FAIL,
    DRIVER_RESERVATION_REQUEST,
    DRIVER_RESERVATION_SUCCESS,
    DRIVER_RESERVATION_FAIL,
    RESERVATION_VIEW_SCHEDULE_REQUEST,
    RESERVATION_VIEW_SCHEDULE_SUCCESS,
    RESERVATION_VIEW_SCHEDULE_FAIL,
    RESERVATION_DRIVER_STATUS_BY_USERID_REQUEST,
    RESERVATION_DRIVER_STATUS_BY_USERID_SUCCESS,
    RESERVATION_DRIVER_STATUS_BY_USERID_FAIL,
    DRIVER_EARNING_REQUEST,
    DRIVER_EARNING_SUCCESS,
    DRIVER_EARNING_FAIL,
    RESERVATION_DRIVER_STATUS_UPDATE_REQUEST,
    RESERVATION_DRIVER_STATUS_UPDATE_SUCCESS,
    RESERVATION_DRIVER_STATUS_UPDATE_FAIL,
    DRIVER_TODAY_RESERVATION_VIEW_REQUEST,
    DRIVER_TODAY_RESERVATION_VIEW_SUCCESS,
    DRIVER_TODAY_RESERVATION_VIEW_FAIL,
    DRIVER_RIDE_UPDATE_REQUEST,
    DRIVER_RIDE_UPDATE_SUCCESS,
    DRIVER_RIDE_UPDATE_FAIL,
    DRIVER_PAST_RESERVATION_VIEW_REQUEST,
    DRIVER_PAST_RESERVATION_VIEW_SUCCESS,
    DRIVER_PAST_RESERVATION_VIEW_FAIL,
    SERVERADDRESS,
} from "../constants"
import { notifyFailure, notifySuccess } from "../components/toast";

// ALL RESERVATION BOOKING DISPLAY VIEW 
export const getAllReservation = (obj) => async (dispatch) => {
    dispatch({
        type: RESERVATION_VIEW_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/reservation/getReservationsByCompanyId`, obj);
        localStorage.setItem("reservation", JSON.stringify(data));
        localStorage.setItem("today", JSON.stringify(data.today));
        localStorage.setItem("previous", JSON.stringify(data.previous));
        dispatch({
            type: RESERVATION_VIEW_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: RESERVATION_VIEW_FAIL,
            payload: err,
        });
    }
};



// ADD NEW RESERVATION
export const addReservation = (obj, navigateDispatching, setReservationId) => async ( dispatch ) => {
    dispatch({
        type: RESERVATION_CREATE_REQUEST,
    })
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/reservation/createReservation`, obj)
        dispatch({
            type: RESERVATION_CREATE_SUCCESS,
            payload: data,
        })
        // console.log(data.id, "returnReservationData")
        // const resId = data.id
        // setReservationId({id:resId})
        // navigate('/requested-orders')
        navigateDispatching()
        // console.log(navigateDispatching(),"chal toh raha hn 2")
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: RESERVATION_CREATE_FAIL,
            payload: err,
        })
    }
}



// RESERVATION UPDATE BY ID
export const updateReservation = (obj, navigateDispatching) => async (dispatch) => {
    dispatch({
        type: RESERVATION_UPDATE_REQUEST,
    });
    try {
        const { data } = await axios.put(`${SERVERADDRESS}/v1/reservation/updateReservation`, obj);
        notifySuccess("Reservation Updated Successfully !");
        dispatch({
            type: RESERVATION_UPDATE_SUCCESS,
            payload: data,
        });
        if (navigateDispatching){
            navigateDispatching()
        }
    }
    catch (err) {
        console.log(err)
        notifyFailure(err.response.data.message);
        dispatch({
            type: RESERVATION_UPDATE_FAIL,
            payload: err,
        });
    }
};

// RESERVATION DELETE BY ID
export const deleteReservation = (obj) => async (dispatch) => {
    dispatch({
        type: RESERVATION_DELETE_REQUEST,
    });
    try {
        const { data } = await axios.put(`${SERVERADDRESS}/v1/reservation/updateReservation`, obj);
        dispatch({
            type: RESERVATION_DELETE_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: RESERVATION_DELETE_FAIL,
            payload: err,
        });
    }
};


// ALL RESERVATION OF A DRIVER 
// export const getReservationsByUserId = (obj) => async (dispatch) => {
//     dispatch({
//         type: DRIVER_RESERVATION_REQUEST,
//     });
//     try {
//         const { data } = await axios.post(`${SERVERADDRESS}/v1/reservation/getReservationsByUserId`, obj);
//         localStorage.setItem("newRequest", JSON.stringify(data.newRequest));
//         localStorage.setItem("history", JSON.stringify(data.history));
//         dispatch({
//             type: DRIVER_RESERVATION_SUCCESS,
//             payload: data,
//         });
//     }
//     catch (err) {
//         console.log(err)
//         dispatch({
//             type: DRIVER_RESERVATION_FAIL,
//             payload: err,
//         });
//     }
// };
export const getReservationsByUserId = (obj) => async (dispatch) => {
    dispatch({
        type: DRIVER_RESERVATION_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/reservation/getReservationsByUserId`, obj);
        localStorage.setItem("reservationRequest", JSON.stringify(data));
        dispatch({
            type: DRIVER_RESERVATION_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: DRIVER_RESERVATION_FAIL,
            payload: err,
        });
    }
};


export const getPastReservationsByUserId = (obj) => async (dispatch) => {
    dispatch({
        type: DRIVER_PAST_RESERVATION_VIEW_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/reservation/getPastReservationsByUserId`, obj);
        localStorage.setItem("pastHistory", JSON.stringify(data));
        dispatch({
            type: DRIVER_PAST_RESERVATION_VIEW_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: DRIVER_PAST_RESERVATION_VIEW_FAIL,
            payload: err,
        });
    }
};




// ALL RESERVATION SCHEDULE 
export const getReservationsSchedule = (obj) => async (dispatch) => {
    dispatch({
        type: RESERVATION_VIEW_SCHEDULE_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/reservation/getReservationsSchedule`, obj);
        localStorage.setItem("reservationsSchedule", JSON.stringify(data));
        localStorage.setItem("assigned", JSON.stringify(data.assigned));
        localStorage.setItem("unassigned", JSON.stringify(data.unassigned));
        localStorage.setItem("pending", JSON.stringify(data.pending));
        dispatch({
            type: RESERVATION_VIEW_SCHEDULE_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: RESERVATION_VIEW_SCHEDULE_FAIL,
            payload: err,
        });
    }
};

export const setNewRequest = (obj) => (dispatch) => {
    dispatch({
        type: "SET_NEWREQUEST",
        payload: obj
    });
}

// RESERVATION STATUS UPDATE BY RESERVATION ID
export const updateReservationDriverStatus = (obj) => async (dispatch) => {
    dispatch({
        type: RESERVATION_DRIVER_STATUS_UPDATE_REQUEST,
    });
    try {
        const { data } = await axios.put(`${SERVERADDRESS}/v1/reservationStatus/updateReservationDriverStatus`, obj);
        dispatch({
            type: RESERVATION_DRIVER_STATUS_UPDATE_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: RESERVATION_DRIVER_STATUS_UPDATE_FAIL,
            payload: err,
        });
    }
};


export const setReservations = (obj) => (dispatch) => {
    console.log("redux actions", obj )
    dispatch({
        type: "SET_RESERVATIONS",
        payload: obj,
    });
}
// GET DRIVER TODAY BOOKING VIEW 
export const getTodayReservationsByUserId = (obj) => async (dispatch) => {
    dispatch({
        type: DRIVER_TODAY_RESERVATION_VIEW_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/reservation/getTodayReservationsByUserId`, obj);
        localStorage.setItem("todayReservationView", JSON.stringify(data));
        dispatch({
            type: DRIVER_TODAY_RESERVATION_VIEW_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: DRIVER_TODAY_RESERVATION_VIEW_FAIL,
            payload: err,
        });
    }
};


// UPDATE TRIP
export const updateTrip = (obj) => async ( dispatch ) => {
    dispatch({
        type: DRIVER_RIDE_UPDATE_REQUEST,
    })
    try {
        const { data } = await axios.put(`${SERVERADDRESS}/v1/trip/updateTrip`, obj)
        dispatch({
            type: DRIVER_RIDE_UPDATE_SUCCESS,
            payload: data,
    })
}
catch (err) {
    console.log(err)
    dispatch({
        type: DRIVER_RIDE_UPDATE_FAIL,
        payload: err,
    })
}
}

export const markedLocation = (obj) => (dispatch) => {
    // console.log("redux actions", obj )
    dispatch({
        type: "MARKED_LOCATION",
        payload: obj,
    });
}


// ALL RESERVATION BOOKING DISPLAY VIEW 
export const getAllReservationDriverStatusByUserId = (obj) => async (dispatch) => {
    dispatch({
        type: RESERVATION_DRIVER_STATUS_BY_USERID_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/reservationStatus/getAllReservationDriverStatusByUserId`, obj);
        localStorage.setItem("userNotificationList", JSON.stringify(data));
        // localStorage.setItem("reservationRequest", JSON.stringify(data));
        dispatch({
            type: RESERVATION_DRIVER_STATUS_BY_USERID_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: RESERVATION_DRIVER_STATUS_BY_USERID_FAIL,
            payload: err,
        });
    }
};

// DRIVER EARNING
export const driverEarning = (obj) => async (dispatch) => {
    dispatch({
        type: DRIVER_EARNING_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/reservation/driverEarning`, obj);
        localStorage.setItem("driverEarning", JSON.stringify(data));
        dispatch({
            type: DRIVER_EARNING_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        // console.log(err)
        dispatch({
            type: DRIVER_EARNING_FAIL,
            payload: err,
        });
    }
};
