import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import DriverRouteComponentRow from './DriverRouteComponentRow';
import { getRoutesByUserId } from '../../../actions/routeActions';
import shift1 from "../../../assets/images/load.png"

// import { setActiveRoute } from '../../../actions/routeActions';
// import io from "socket.io-client";
// const socket = io("https://ridetify-cca5568f0479.herokuapp.com");

const DriverRouteComponent = () => {
  const routes = useSelector((state) => state.routes);
  const { loading, list, error } = routes;
  const auth = useSelector((state) => state.auth);
//   const activeRoute = useSelector((state) => state.activeRoute);
//   const reservation = useSelector((state) => state.reservation);
//   const [cc, setCC] = useState(0);
//   const [data, setData] = useState([]);
//   useEffect(() => {
//     socket.emit(
//       "driver-reservations",
//       { driverId: auth.user.id },
//       (response) => {
//         // console.log(response)
//         setData(response.route);
//         dispatch(setActiveRoute(response.route));
//         return () => {
//           socket.off("driver-reservations");
//         };
//       }
//     );
//   }, [cc]);
//   let bb = 0;
//   useEffect(() => {
//     setInterval(
//       () => setCC(bb++),
//       // , 2000);
//       2000
//     );
//   }, []);
//   console.log(data, "data hn mein");
// const activeOrderData = async (e) => {
    //     dispatch(getTodayReservationsByUserId({driverId: "63cf2534057dae4374e3eb1b", today: "2023-04-18" }))
    // }
    // useEffect(() => {
        //     activeOrderData()
        // },[])
    const dispatch = useDispatch();
    const routeData = async (e) => {
        dispatch(getRoutesByUserId({driverId: auth.user.id, 
            limit: limit,
            page: currentPageNumber }))
        }
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [limit, setLimit] = useState(10);
    const handleLimit = async (event) => {
        setLimit(parseInt(event.target.value));
        dispatch(
            getRoutesByUserId({ 
            driverId: auth.user.id,
            limit: event.target.value,
            page: 1,
            })
        );
        setCurrentPageNumber(1);
    };
    const onClickNext = async () => {
        let i = currentPageNumber;
            i++;
            if (i <= routes.routeRequest.totalPages) {
            setCurrentPageNumber(currentPageNumber + 1);
            dispatch(
                getRoutesByUserId({
                driverId: auth.user.id,
                limit: limit,
                page: currentPageNumber + 1,
                })
            );
        }
    };
    const onClickPrev = async () => {
        let i = currentPageNumber;
            i--;
            if (i >= 1) {
            setCurrentPageNumber(currentPageNumber - 1);
            dispatch(
                getRoutesByUserId({
                driverId: auth.user.id,
                limit: limit,
                page: currentPageNumber - 1,
                })
            );
        }
    };
    const handleChangeData = async (pageNo) => {
        setCurrentPageNumber(pageNo);
        dispatch(
            getRoutesByUserId({
                driverId: auth.user.id,
                limit: limit,
                page: pageNo,
            })
        );
    };
        

    useEffect(() => {
        routeData()
    },[])

    return (
        <>
            {/* {loading && list.length == 0 && <TableDataLoader />} */}
            <div className="d-flex flex-column w-tables rounded mt-3 bg-white">
                <div className="d-flex border">
                    <div className="flex-grow-1">
                        <div className="dataTables_length d-flex" id="leads-table_length">
                            <div className="p-2">
                                <label>Show entries</label>
                            </div>
                            <label>
                                <select
                                    name="leads-table_length"
                                    aria-controls="leads-table"
                                    className="custom-select custom-select-sm form-control form-control-sm"
                                    // className="select"
                                    autoComplete="false"
                                    onChange={handleLimit}
                                    >
                                    {[10, 25, 50, 100].map((current, i) => {
                                        return (
                                        <option value={current} key={i}>
                                            {current}
                                        </option>
                                        );
                                    })}
                                </select>
                            </label>
                        </div>
                    </div>
                </div>
                
                <div
                    id="leads-table_wrapper"
                    className="dataTables_wrapper dt-bootstrap4 no-footer"
                >
                    <div className="row">
                        <div className="col-sm-12">
                            <table
                                className="table border w-100 dataTable no-footer"
                                id="leads-table"
                                role="grid"
                                aria-describedby="leads-table_info"
                                style={{ overflowX: "auto" }}
                            >
                                <thead className="card-header">
                                    <tr role="row">
                                        <th
                                            title="(Res#)"
                                            className="sorting border-right"
                                            tabIndex="0"
                                            aria-controls="leads-table"
                                            rowSpan="1"
                                            colSpan="1"
                                            aria-label="Mobile: activate to sort column ascending"
                                        >
                                            Route #
                                        </th>

                                        <th
                                            title="Time"
                                            className="sorting border-right"
                                            tabIndex="0"
                                            aria-controls="leads-table"
                                            rowSpan="1"
                                            colSpan="1"
                                            aria-label="Lead Agent: activate to sort column ascending"
                                        >
                                            No. of Passengers
                                        </th>
                                        {/* <th
                                            title="Passenger"
                                            className="sorting border-right"
                                            tabIndex="0"
                                            aria-controls="leads-table"
                                            rowSpan="1"
                                            colSpan="1"
                                            aria-label="Lead Agent: activate to sort column ascending"
                                        >
                                            School
                                        </th> */}
{/* 
                                        <th
                                            title="Status"
                                            className="sorting border-right"
                                            tabIndex="0"
                                            aria-controls="leads-table"
                                            rowSpan="1"
                                            colSpan="1"
                                            aria-label="Lead Agent: activate to sort column ascending"
                                        >
                                            Status
                                        </th> */}
                                        <th
                                            title="Details"
                                            className="sorting border-right"
                                            tabIndex="0"
                                            aria-controls="leads-table"
                                            rowSpan="1"
                                            colSpan="1"
                                            aria-label="Lead Agent: activate to sort column ascending"
                                        >
                                            Details
                                        </th>
                                        <th
                                            title="Status"
                                            className="sorting border-right"
                                            tabIndex="0"
                                            aria-controls="leads-table"
                                            rowSpan="1"
                                            colSpan="1"
                                            aria-label="Lead Agent: activate to sort column ascending"
                                        >
                                            Status
                                        </th>
                                        <th
                                            title="Actions"
                                            className="sorting border-right"
                                            tabIndex="0"
                                            aria-controls="leads-table"
                                            rowSpan="1"
                                            colSpan="1"
                                            aria-label="Lead Agent: activate to sort column ascending"
                                            style={{width: "17%"}}
                                        >
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* {list.map((current, i) => {
                                        return (
                                            <DriverRouteComponentRow
                                                current={current}
                                                i={i}
                                                key={i}
                                                // editOrderHandler={editOrderHandler} 
                                            />
                                        );
                                    })} */}
                                    {
                                        routes &&
                                        routes.routeRequest &&
                                        routes.routeRequest.results &&
                                        routes.routeRequest.results.length > 0 &&
                                        routes.routeRequest.results.map((current, i) => {
                                        // if (current.delete === false) {
                                        return (
                                            <DriverRouteComponentRow
                                            current={current}
                                            i={i}
                                            key={i}
                                            />
                                        );
                                        // }
                                        })}
                                </tbody>
                            </table>
                            {
                                routes &&
                                routes.routeRequest &&
                                routes.routeRequest.results &&
                                routes.routeRequest.results.length === 0 &&
                                <div className="dash-stats-list" style={{ textAlign: "center" }}>
                                <br />
                                <br />
                                <img
                                    alt=""
                                    src={shift1}
                                    style={{ width: "145px", opacity: "0.5" }} />
                                <br />
                                <p style={{ opacity: "0.5" }}>
                                    No Data Found
                                </p>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="d-flex border">
                    {
                        routes.loading === false ?
                        <div className="flex-grow-1">
                            <div
                                className="dataTables_info"
                                id="leads-table_info"
                                role="status"
                                aria-live="polite"
                            >
                                Showing {1 + limit * (currentPageNumber - 1)} to{" "}
                                {routes.routeRequest.displayingResults + limit * (currentPageNumber - 1)}{" "}
                                of {routes.routeRequest.totalResults} entries
                            </div>
                        </div>
                        :
                        <div className="flex-grow-1">
                          <div
                            className="dataTables_info"
                            id="leads-table_info"
                            role="status"
                            aria-live="polite"
                          >
                            Loading entries
                          </div>
                        </div>
                    }
                        <div>
                            <div
                                className="dataTables_paginate paging_simple_numbers"
                                id="leads-table_paginate"
                            >
                                <ul className="pagination">
                                    <li className="page-item">
                                        <NavLink
                                        className="page-link"
                                        to="#"
                                        aria-label="Previous"
                                        onClick={onClickPrev}
                                        >
                                        <span aria-hidden="true">Previous</span>
                                        <span className="sr-only">Previous</span>
                                        </NavLink>
                                    </li>
                                    { 
                                    routes &&
                                    routes.routeRequest &&
                                    routes.routeRequest.paginationArr &&
                                    routes.routeRequest.paginationArr.length > 0 &&
                                    routes.routeRequest.paginationArr.map((current, i) => {
                                        return currentPageNumber === current ? (
                                        <li className="page-item active" key={i}>
                                            <Link className="page-link" value={current} to="">
                                            {current}
                                            </Link>
                                        </li>
                                        ) : (
                                        <li className="page-item" key={i}>
                                            <Link
                                            className="page-link"
                                            to=""
                                            value={current}
                                            onClick={() => handleChangeData(current)}
                                            >
                                            {current}
                                            </Link>
                                        </li>
                                        );
                                    })}
                                    <li
                                        className="paginate_button page-item next"
                                        id="leads-table_next"
                                    >
                                        <Link
                                        to=""
                                        aria-controls="leads-table"
                                        data-dt-idx="3"
                                        tabIndex="0"
                                        className="page-link"
                                        onClick={onClickNext}
                                        >
                                        Next
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>{" "}
        </>
    );
}
export default DriverRouteComponent