import axios from "axios";
import {
  SERVERADDRESS,
  GET_INVOICES_REQUEST,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_FAIL,
} from "../constants";

// ALL INVOICES
export const getAllInvoicesAction = (obj) => async (dispatch) => {
  dispatch({
    type: GET_INVOICES_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/invoice/getInvoicesByCompanyId`,
      obj
    );
    localStorage.setItem("list", JSON.stringify(data));
    localStorage.setItem("cardDetails", JSON.stringify(data.cardDetails));
    dispatch({
      type: GET_INVOICES_SUCCESS,
      payload: data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: GET_INVOICES_FAIL,
      payload: err,
    });
  }
};
