import moment from 'moment'
import React from 'react'

const AdminBookingViewComponent = ({data}) => {
    return (
        <div className="row">
            {/* <div className="col-md-5">
                <div className="form-group ">
                    <p className="f-18 text-dark mb-12" htmlFor="Name">
                        Assigning Details:
                    </p>
                </div>
            </div> */}
            <table
                className="table border dataTable no-footer"
                id="leads-table"
                role="grid"
                aria-describedby="leads-table_info"
                style={{ overflowX: "auto", width: "95%", margin: "auto" }}
            >
                <thead className="card-header">
                    <tr role="row">
                        <th
                            title="(Res#)"
                            className="sorting border-right"
                            tabIndex="0"
                            aria-controls="leads-table"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Mobile: activate to sort column ascending"
                        >
                            Order ID
                        </th>
                        <th
                            title="Time"
                            className="sorting border-right"
                            tabIndex="0"
                            aria-controls="leads-table"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Lead Agent: activate to sort column ascending"
                        >
                            Time
                        </th>
                        <th
                            title="Passenger"
                            className="sorting border-right"
                            tabIndex="0"
                            aria-controls="leads-table"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Lead Agent: activate to sort column ascending"
                        >
                            Passenger
                        </th>
                        <th
                            title="Pickup Drop Off"
                            className="sorting border-right w-30"
                            tabIndex="0"
                            aria-controls="leads-table"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Lead Agent: activate to sort column ascending"
                        >
                            Pickup - Drop Off
                        </th>
                        <th
                            title="Vehicle/Driver"
                            className="sorting border-right"
                            tabIndex="0"
                            aria-controls="leads-table"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Lead Agent: activate to sort column ascending"
                        >
                            Vehicle Type
                        </th>
                        <th
                            title="Price"
                            className="sorting border-right"
                            tabIndex="0"
                            aria-controls="leads-table"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Lead Agent: activate to sort column ascending"
                        >
                            Price
                        </th>
                        <th
                            title="Status"
                            className="sorting border-right"
                            tabIndex="0"
                            aria-controls="leads-table"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Lead Agent: activate to sort column ascending"
                        >
                            Status
                        </th>
                        <th
                            title="Status"
                            className="sorting border-right"
                            tabIndex="0"
                            aria-controls="leads-table"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Lead Agent: activate to sort column ascending"
                        >
                            Driver Name
                        </th>
                        <th
                            title="Action"
                            className="sorting border-right"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Action"
                        >
                            Paid Status
                        </th>
                    </tr>
                </thead>
                <tbody>
                <tr
                    role="row"
                    className="odd border"
                >
                    <td className="border-right">{data?.orderId}</td>
                    <td className="border-right">
                    <strong>Pickup</strong> {data?.pickUpTime}
                    {parseInt(data?.pickUpTime.substring(0, 2)) > 11 ? "pm" : "am"}
                    <br />
                    <strong>Dropoff</strong> {data?.dropOffTime}
                    {parseInt(data?.dropOffTime.substring(0, 2)) > 11 ? "pm" : "am"}
                    <br /> 
                    {data?.fareAndDistance?.estimatedTime}
                    </td>
                    <td className="border-right">
                    <strong>
                        {data?.customerId?.firstName} {data?.customerId?.lastName}
                    </strong>
                    <br />
                    {data?.customerId?.phone}
                    <br />
                    </td>
                    <td className="border-right w-30">
                    <div className="d-flex justify-content-between">
                        <div>
                        <div>
                            <p className="f-12 font-medium mb-0">
                            <strong>{data?.pickUpAddress?.address}</strong>{" "}
                            </p>
                        </div>
                        <div>
                            <time>
                            {" "}
                            {moment(data?.pickUpDate).format("MM/DD/YYYY")} -{" "}
                            {data?.pickUpTime}
                            {parseInt(data?.pickUpTime.substring(0, 2)) > 11
                                ? "pm"
                                : "am"}
                            </time>
                        </div>
                        </div>
                        <div className="mt-4 px-4">
                        <div className="hidden sm:block">
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="80"
                            height="12"
                            viewBox="0 0 40 6"
                            fill="none"
                            >
                            <path
                                d="M40 3L35 0.113249V5.88675L40 3ZM0 3.5H35.5V2.5H0V3.5Z"
                                fill="black"
                            ></path>
                            </svg>
                        </div>
                        <p className="text-center font-medium">
                            <span>{data?.totalMileage} mi</span>
                        </p>
                        </div>
                        <div>
                        <div>
                            <p className="f-12 font-medium mb-0">
                            <strong>{data?.dropOffAddress?.address}</strong>
                            </p>
                        </div>
                        <div>
                            <time>
                            {" "}
                            {moment(data?.dropOffDate).format("MM/DD/YYYY")} -{" "}
                            {data?.dropOffTime}
                            {parseInt(data?.dropOffTime.substring(0, 2)) > 11
                                ? "pm"
                                : "am"}
                            </time>
                        </div>
                        </div>
                    </div>
                    </td>
                    <td className="border-right">{data?.type?.name}</td>
                    <td className="border-right">
                    <strong>${data?.totalAmount.toFixed(2)}</strong>
                    <i data-toggle="tooltip"></i>
                    </td>
                    <td className="border-right">
                        {data?.status}
                    </td>
                    <td className="border-right">
                        {
                            data?.isAssigned === true ?
                            data?.driverId?.firstName + " " + data?.driverId?.lastName
                            : "-"
                        }
                    </td>
                    <td className="">
                        {
                            data?.isPaid === true ?
                            "Paid"
                            : "Unpaid"
                        }
                    </td>
                </tr>
                </tbody>
            </table>

            <br />
             {/* <div id="table-actions" className="flex-grow-1 align-items-center"> */}
             <h4 className=" " style={{overflowX: "auto", width: "95%", margin: "auto"}}> All Adresses & Stops </h4>
            {/* </div> */}
            <br />
            <table
                className="table border dataTable no-footer"
                id="leads-table"
                role="grid"
                aria-describedby="leads-table_info"
                style={{ overflowX: "auto", width: "95%", margin: "auto" }}
            >
                <thead className="card-header">
                    <tr role="row">
                        <th
                            title="Address"
                            className="sorting border-right"
                            tabIndex="0"
                            aria-controls="leads-table"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Mobile: activate to sort column ascending"
                        >
                            Address
                        </th>
                        <th
                            title="Date"
                            className="sorting border-right"
                            tabIndex="0"
                            aria-controls="leads-table"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Lead Agent: activate to sort column ascending"
                        >
                            Date
                        </th>
                        <th
                            title="Time"
                            className="sorting border-right"
                            tabIndex="0"
                            aria-controls="leads-table"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Lead Agent: activate to sort column ascending"
                        >
                            Time
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data &&
                        data.rideHistory &&
                        data.rideHistory.length > 0 &&
                        data.rideHistory.map((current, i) => {
                        return(
                            <tr
                                role="row"
                                className="odd border"
                                >
                                <td className="border-right">{current?.address?.address}</td>
                                <td className="border-right">{moment(current?.date).format("MM/DD/YYYY")}</td>
                                <td className="border-right">{current?.time}</td>
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>
        </div>
    )
}

export default AdminBookingViewComponent
