import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAllReservationDriverStatusByUserId } from '../../../actions/reservationAction';
import ViewAllNotificationsRow from './ViewAllNotificationsRow';

const ViewAllNotifications = () => {
  const auth = useSelector((state) => state.auth);
  const reservation = useSelector((state) => state.reservation);
  const dispatch = useDispatch();
//   const [cb, setCb] = useState();
//   const [driverFunct, setDriverFunct] = useState();
  useEffect(() => {
        if (auth?.user?.isDriver === true) {
            dispatch(getAllReservationDriverStatusByUserId({ driverId: auth.user.id }))
        }
        else {
            dispatch(getAllReservationDriverStatusByUserId({assigneeId: auth.user.id}))
        }
    }, [])
    // }, [auth.user])
    // console.log(reservation.userNotificationList, "reservation.userNotificationList reservation.userNotificationList hoooooon");

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="card mb-0">
                        <div className="card-body">
                            <div className="email-header">
                                <div className="row">
                                    <div className="col top-action-left">
                                        <div className="float-left">
                                        </div>
                                    </div>
                                    <div className="col-auto top-action-right">
                                    </div>
                                </div>
                            </div>
                            <div className="email-content">
                                <div className="table-responsive">
                                    <table className="table table-inbox table-hover">
                                        <thead>
                                            <tr>
                                                <th colSpan="3" style={{ borderBottom: "2px solid" }}>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                
                                                reservation.userNotificationList &&
                                                reservation.userNotificationList.length > 0 &&
                                                reservation.userNotificationList.map((item, i) => {
                                                return (
                                                    <ViewAllNotificationsRow item={item} i={i} />
                                                    )
                                                })
                                            }
                                            
                                            
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ViewAllNotifications