import React from 'react'
import { useSelector } from 'react-redux';

const InvoicingCards = () => {
    const invoices = useSelector((state) => state.invoices);
    return (
        <>
            <div className="card-body">
                <div className="row">
                    {invoices &&
                    invoices?.cardDetails.length > 0 &&
                    invoices.cardDetails.map((current, i) => {
                        return (
                            <div className="col-4" key={i}>
                                <div className="b-shadow-4 rounded p-2 ">
                                    <div className="card-body p-2 clearfix d-flex">
                                        <div className="bg-success rounded p-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-currency-dollar font-2xl" viewBox="0 0 16 16" style={{color: "#fff"}}>
                                            <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z"/>
                                            </svg>
                                        </div>
                                        <div className="px-2">
                                            <div className="h5 f-21 font-weight-bold text-success mb-0 pt-1">${current.sum}</div> 
                                            <div className="text-muted text-uppercase font-weight-bold font-xs">Total Ride {current.count} {current.value}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

export default InvoicingCards
