import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "react-bootstrap/Accordion";
import { useLocation, useParams } from "react-router-dom";
import Select from "react-select";
import { getAllClients } from "../../../actions/userActions";
import AddressSearchInput from "../../../components/AddressSearchInput";
import AdminBookingAddClientModal from "../../../components/adminComponents/adminBookingComponents/AdminBookingAddClientModal";
import { getRouteById } from "../../../actions/routeActions";
import AddStudentModal from "../AdminAddRoutePage/AddStudentModal";
import PassengerRouteAccordionItem from "./PassengerRouteAccordionItem";

function PassengerRouteAccordionComponent({
  values,
  setValues,
  // handleChange,
  customerDetailsArr,
  setCustomerDetailsArr,
  passengerRouteObj,
  dropObj,
  setIsRidePatternArranged,
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();

  const auth = useSelector((state) => state.auth);
  const fare = useSelector((state) => state.fare);
  const kpi = useSelector((state) => state.kpi);
  const students = useSelector((state) => state.students);
  const service = useSelector((state) => state.service);

  const routes = useSelector((state) => state.routes);

  const { routeDetails } = routes;

  const [searchingClients, setSearchingClients] = useState(false);
  const [oldName, setOldName] = useState("");

  const [showDropDown, setshowDropDown] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState({});

  const openDropDown = () => {
    setshowDropDown((showDropDown) => !showDropDown);
  };
  // const [customerDetailsArr, setCustomerDetailsArr] = useState([passengerRouteObj]);

  const addPassengerRoute = () => {
    setCustomerDetailsArr([...customerDetailsArr, passengerRouteObj]);
    setIsRidePatternArranged(false);
  };

  const removePassengerRoute = (index) => {
    setCustomerDetailsArr(customerDetailsArr.filter((x, i) => i !== index));
    setIsRidePatternArranged(false);
  };

  const addReturnRoute = (index) => {
    setCustomerDetailsArr(
      customerDetailsArr.map((x, i) =>
        i == index ? { ...x, drop: dropObj } : x
      )
    );
  };
  const removeReturnRoute = (index) => {
    setCustomerDetailsArr(
      customerDetailsArr.map((x, i) =>
        i == index ? { ...x, drop: undefined } : x
      )
    );
  };

  const handleAddress = (addressObj, index, field) => {
    setCustomerDetailsArr(
      customerDetailsArr.map((x, i) =>
        i == index ? { ...x, [field]: addressObj } : x
      )
    );
  };

  const handleChange = (e, index) => {
    setCustomerDetailsArr(
      customerDetailsArr.map((arrItem, itemIndex) =>
        index == itemIndex
          ? { ...arrItem, [e.target.name]: e.target.value }
          : arrItem
      )
    );
  };

  useEffect(() => {
    dispatch(
      getAllClients({
        companyId: auth.user.companyId.id,
      })
    );
  }, []);

  console.log("cust det ==>", customerDetailsArr);
  
  return (
    <>
      <Accordion defaultActiveKey="0">
        {customerDetailsArr.map((customerRoute, index) => (
          <Accordion.Item eventKey={index} key={index}>
            <PassengerRouteAccordionItem
              {...{
                customerRoute,
                index,
                removePassengerRoute,
                addReturnRoute,
                removeReturnRoute,
                setSelectedStudent,
                openDropDown,
                customerDetailsArr,
                setCustomerDetailsArr,
              }}
            />
            {/* <Accordion.Header className="w-100">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  Student # {index + 1} {x.id && "( New )"}
                </div>
                {index > 0 && (
                  <div
                    type="button"
                    className="btn-lightest-grey rounded f-14 p-2 mr-3 height-35"
                    id="save-form"
                    onClick={() => removePassengerRoute(index)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-trash3-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                    </svg>
                  </div>
                )}
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="row">
                <div className="col-sm-12 col-md-3 pl-4">
                  <div className="form-group my-3">
                    <div className="d-flex justify-content-between">
                      <div>
                        <div className="d-flex">
                          <div>
                            <label
                              className="f-14 text-dark mb-12"
                              data-label=""
                              htmlFor="clnumber"
                            >
                              Student Name
                            </label>
                          </div>
                          <div className="p-2">
                            <sup className="text-danger f-14">*</sup>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      value={students?.list
                        ?.map((x) => ({
                          label: `${x.firstName} ${x.lastName} `,
                          value: x.id,
                        }))
                        .filter((l) =>
                          typeof x?.customerId == "object"
                            ? l.value == x?.customerId?.id
                            : l.value == x?.customerId
                        )}
                      isDisabled={false}
                      isLoading={searchingClients}
                      isClearable={false}
                      isRtl={false}
                      isSearchable={true}
                      required={true}
                      onInputChange={(e) =>
                        setValues((prev) => ({ ...prev, name: e }))
                      }
                      onChange={(e) =>
                        setCustomerDetailsArr((prev) =>
                          prev.map((arrItem, itemIndex) =>
                            itemIndex == index
                              ? { ...arrItem, customerId: e.value }
                              : arrItem
                          )
                        )
                      }
                      options={students?.list?.map((x) => ({
                        label: `${x.firstName} ${x.lastName} `,
                        value: x.id,
                      }))}
                    />
                  </div>
                </div>
                <div className="col-md-3 col-sm-12 pl-4">
                  <div className="form-group my-3">
                    <div className="d-flex">
                      <div>
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="driver"
                        >
                          School Name
                        </label>
                      </div>
                      <div className="p-2">
                        <sup className="text-danger f-14">*</sup>
                      </div>
                    </div>
                    <input
                      type="text"
                      className="form-control height-35 f-14"
                      placeholder="Enter school name"
                      value={customerDetailsArr[index]?.schoolName}
                      name="schoolName"
                      // onChange={handleStopChange(i, "time")}
                      onChange={(e) => handleChange(e, index)}
                      required
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-3 pl-4">
                  <div className="form-group my-3">
                    <div className="d-flex">
                      <div>
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="clnumber"
                        >
                          Type
                        </label>
                      </div>
                      <div className="p-2">
                        <sup className="text-danger f-14">*</sup>
                      </div>
                    </div>
                    <div className="select-picker">
                      <select
                        className="form-control select-picker"
                        style={{ height: "39px", fontSize: "14px" }}
                        value={customerDetailsArr[index].type}
                        name="type"
                        onChange={(e) => handleChange(e, index)}
                        required
                        disabled={x.customerId == ""}
                      >
                        <option value="" key={"st"}>
                          {" "}
                          Select Type
                        </option>
                        {service.service &&
                          service.service.length > 0 &&
                          service.service.map((current, i) => {
                            const selectedStudent = students?.list?.find(
                              (student) =>
                                typeof x?.customerId == "object"
                                  ? x?.customerId?.id == student?.id
                                  : x?.customerId == student?.id
                            );

                            if (selectedStudent) {
                              if (selectedStudent?.isCompany) {
                                const fareFound = fare?.fare?.find(
                                  (z) =>
                                    z?.business?.id ==
                                    selectedStudent?.externalCompanyId
                                );
                                if (
                                  fareFound?.details.find(
                                    (k) => k?.serviceId?.id == current?.id
                                  )
                                )
                                  return (
                                    <option
                                      value={JSON.stringify({
                                        id: current.id,
                                        name: current.name,
                                      })}
                                      key={i}
                                    >
                                      {current.name}
                                    </option>
                                  );
                              } else {
                                if (!selectedStudent?.isCompany) {
                                  const fareFound = fare?.fare?.find(
                                    (z) => z?.business?.name == "Individual"
                                  );
                                  if (
                                    fareFound?.details.find(
                                      (k) => k?.serviceId?.id == current?.id
                                    )
                                  )
                                    console.log(
                                      "sel client main chala =>",

                                      fareFound,
                                      x
                                    );
                                  return (
                                    <option
                                      value={current.id}
                                      // value={JSON.stringify({
                                      //   id: current.id,
                                      //   name: current.name,
                                      // })}
                                      key={i}
                                    >
                                      {current.name}
                                    </option>
                                  );
                                }
                              }
                            }
                          })}
                      </select>
                    </div>
                  </div>
                </div>
                {customerDetailsArr[index] &&
                  customerDetailsArr[index].type &&
                  customerDetailsArr[index].type !== "" && (
                    <div className="col-sm-12 col-md-3 details ambulatory show">
                      <div className="form-group my-3">
                        <div className="d-flex">
                          <div>
                            <label
                              className="f-14 text-dark mb-12"
                              data-label=""
                              htmlFor="clnumber"
                            >
                              Capacity
                            </label>
                          </div>
                          <div className="p-2">
                            <sup className="text-danger f-14">*</sup>
                          </div>
                        </div>
                        <div className="select-picker">
                          <input
                            className="form-control"
                            type="number"
                            style={{ height: "39px" }}
                            value={x?.capacity}
                            name="capacity"
                            onChange={(e) => handleChange(e, index)}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  

                <div className="p-2">
                  <div>
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="clnumber"
                    >
                      Active days
                    </label>
                  </div>
                  <div>
                    <div className="d-flex flex-wrap">
                      {x.daysOfWeek.map((day, dayIndex) => (
                        <div
                          className={`d-flex align-items-center p-1 px-3 m-1 rounded text-white cursor-pointer ${
                            day.active ? "bg-primary" : "bg-secondary"
                          }`}
                          onClick={() =>
                            setCustomerDetailsArr((prev) =>
                              prev.map((arrItem, itemIndex) =>
                                itemIndex == index
                                  ? {
                                      ...arrItem,
                                      daysOfWeek: arrItem.daysOfWeek.map(
                                        (arrDayItem, arrDayIndex) =>
                                          arrDayIndex == dayIndex
                                            ? {
                                                ...arrDayItem,
                                                active: !arrDayItem.active,
                                              }
                                            : arrDayItem
                                      ),
                                    }
                                  : arrItem
                              )
                            )
                          }
                        >
                          {day.active ? (
                            <i
                              className="fa fa-check-circle mr-1"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            <i
                              className="fa fa-plus-circle mr-1"
                              aria-hidden="true"
                            ></i>
                          )}

                          <p className="m-1">{day.dayName}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 pl-4">
                  <div className="form-group my-3">
                    <div className="d-flex justify-content-between">
                      <div>
                        <div className="d-flex">
                          <div>
                            <label
                              className="f-14 text-dark mb-12"
                              data-label=""
                              htmlFor="clnumber"
                            >
                              Pickup Address
                            </label>
                          </div>
                          <div className="p-2">
                            <sup className="text-danger f-14">*</sup>
                          </div>
                        </div>
                      </div>
                      <div>
                        <button
                          className="bg-button"
                          type="button"
                          data-toggle="modal"
                          data-target="#exampleModalCenter2"
                          // onClick={openDropLoc}
                        ></button>
                      </div>
                    </div>

                    <AddressSearchInput
                      chooseAddressHandler={handleAddress}
                      index={index}
                      field="pickUpAddress"
                      searchAddress={x?.pickUpAddress?.address}
                    />
                  </div>
                </div>

                <div className="col-md-3 col-sm-12 pl-4">
                  <div className="form-group my-3">
                    <div className="d-flex">
                      <div>
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="driver"
                        >
                          Pickup Time
                        </label>
                      </div>
                      <div className="p-2">
                        <sup className="text-danger f-14">*</sup>
                      </div>
                    </div>
                    <input
                      type="time"
                      className="form-control height-35 f-14"
                      placeholder=""
                      value={customerDetailsArr[index]?.pickUpTime}
                      name="pickUpTime"
                      // onChange={handleStopChange(i, "time")}
                      onChange={(e) => handleChange(e, index)}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-3 col-sm-12  pl-4">
                  <div className="form-group my-3">
                    <div className="d-flex justify-content-between">
                      <div>
                        <div className="d-flex">
                          <div>
                            <label
                              className="f-14 text-dark mb-12"
                              data-label=""
                              htmlFor="clnumber"
                            >
                              Dropoff Address
                            </label>
                          </div>
                          <div className="p-2">
                            <sup className="text-danger f-14">*</sup>
                          </div>
                        </div>
                      </div>
                      <div>
                        <button
                          className="bg-button"
                          type="button"
                          data-toggle="modal"
                          data-target="#exampleModalCenter2"
                          // onClick={openDropLoc}
                        ></button>
                      </div>
                    </div>
                    <AddressSearchInput
                      chooseAddressHandler={handleAddress}
                      index={index}
                      field="dropOffAddress"
                      searchAddress={x?.dropOffAddress?.address}
                    />
                  </div>
                </div>

                <div className="col-md-3 col-sm-12 pl-4">
                  <div className="form-group my-3">
                    <div className="d-flex">
                      <div>
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="driver"
                        >
                          Dropoff Time
                        </label>
                      </div>
                      <div className="p-2">
                        <sup className="text-danger f-14">*</sup>
                      </div>
                    </div>
                    <input
                      type="time"
                      className="form-control height-35 f-14"
                      placeholder=""
                      id="driver"
                      min="0"
                      autoComplete="off"
                      value={customerDetailsArr[index]?.dropOffTime}
                      name="dropOffTime"
                      // onChange={handleStopChange(i, "time")}
                      onChange={(e) => handleChange(e, index)}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12 pl-4">
                  <div className="form-group my-3">
                    <div className="d-flex">
                      <div>
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="driver"
                        >
                          Notes
                        </label>
                      </div>
                      <div className="p-2">
                        <sup className="text-danger f-14">*</sup>
                      </div>
                    </div>
                    <textarea
                      className="form-control f-14"
                      placeholder=""
                      id="driver"
                      rows="3"
                      name="dispatchNotes"
                      value={x.dispatchNotes}
                      // onChange={handleStopChange(i, "time")}
                      onChange={(e) => handleChange(e, index)}
                      required
                    />
                  </div>
                </div>
              </div>
            </Accordion.Body> */}
          </Accordion.Item>
        ))}

        <div className="row text-right">
          <div className="col-12 col-md-3 ml-auto pl-4">
            <div className="form-group my-5">
              {true ? (
                <button
                  type="button"
                  className="btn-lightest-grey rounded f-12 p-2 mr-3 "
                  onClick={addPassengerRoute}
                >
                  Add Another Leg
                </button>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </Accordion>
      {showDropDown && (
        <AddStudentModal
          showDropDown={showDropDown}
          openDropDown={openDropDown}
          // values={values}
          // setValues={setValues}
        />
      )}
    </>
  );
}

export default PassengerRouteAccordionComponent;
