import {
  USER_VIEW_REQUEST,
  USER_VIEW_SUCCESS,
  USER_VIEW_FAIL,
  USER_CREATE_REQUEST,
  USER_CREATE_SUCCESS,
  USER_CREATE_FAIL,
  DRIVER_VIEW_REQUEST,
  DRIVER_VIEW_SUCCESS,
  DRIVER_VIEW_FAIL,
  ALL_DRIVER_VIEW_REQUEST,
  ALL_DRIVER_VIEW_SUCCESS,
  ALL_DRIVER_VIEW_FAIL,
  DRIVER_CREATE_REQUEST,
  DRIVER_CREATE_SUCCESS,
  DRIVER_CREATE_FAIL,
  CLIENT_VIEW_REQUEST,
  CLIENT_VIEW_SUCCESS,
  CLIENT_VIEW_FAIL,
  CLIENT_CREATE_REQUEST,
  CLIENT_CREATE_SUCCESS,
  CLIENT_CREATE_FAIL,
  CLIENT_OR_USER_DELETE_REQUEST,
  CLIENT_OR_USER_DELETE_SUCCESS,
  CLIENT_OR_USER_DELETE_FAIL,
  ALL_EMPLOYEES_DATA_REQUEST,
  ALL_EMPLOYEES_DATA_SUCCESS,
  ALL_EMPLOYEES_DATA_FAIL,
  USER_LOGOUT_SUCCESS,
  CLIENT_UPDATE_REQUEST,
  CLIENT_UPDATE_SUCCESS,
  CLIENT_UPDATE_FAIL,
  GET_CLIENTS_REQUEST,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_FAIL,
  GET_STUDENTS_REQUEST,
  GET_STUDENTS_SUCCESS,
  GET_STUDENTS_FAIL,
  STUDENT_CREATE_REQUEST,
  STUDENT_CREATE_SUCCESS,
  STUDENT_CREATE_FAIL,
  EXTERNAL_COMPANY_USER_VIEW_REQUEST,
  EXTERNAL_COMPANY_USER_VIEW_SUCCESS,
  EXTERNAL_COMPANY_USER_VIEW_FAIL,
  EXTERNAL_COMPANY_USER_UPDATE_REQUEST,
  EXTERNAL_COMPANY_USER_UPDATE_SUCCESS,
  EXTERNAL_COMPANY_USER_UPDATE_FAIL,
  EXTERNAL_COMPANY_USER_DELETE_REQUEST,
  EXTERNAL_COMPANY_USER_DELETE_SUCCESS,
  EXTERNAL_COMPANY_USER_DELETE_FAIL,
} from "../constants";

export const userReducer = (
  state = {
    users: {
      users: null,
      driver: null,
      client: null,
      allDriversWithoutPagination: null,
      allEmployees: null,
      externalCompanyUsers: null,
    },
  },
  action
) => {
  switch (action.type) {
    // USER LOGOUT
    // case USER_LOGOUT_SUCCESS:
    //     return {
    //         user: null,
    //     };

    // USER VIEW TABLE
    case USER_VIEW_REQUEST:
      return {
        ...state,
        loading : true,
      };
    case USER_VIEW_SUCCESS:
      return {
        ...state,
        loading : false,
        users: action.payload,
      };
    case USER_VIEW_FAIL:
      return {
        ...state,
        loading : false,
        error: action.payload,
      };

    // USER CREATE
    case USER_CREATE_REQUEST:
      return {
        ...state,
      };
    case USER_CREATE_SUCCESS:
      return {
        ...state,
        // users: [...state.users, action.payload],
      };
    case USER_CREATE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    // DRIVER VIEW TABLE
    case DRIVER_VIEW_REQUEST:
      return {
        ...state,
      };
    case DRIVER_VIEW_SUCCESS:
      return {
        ...state,
        driver: action.payload,
      };
    case DRIVER_VIEW_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    // ALL DRIVER WITHOUT PAGINATION
    case ALL_DRIVER_VIEW_REQUEST:
      return {
        ...state,
      };
    case ALL_DRIVER_VIEW_SUCCESS:
      return {
        ...state,
        allDriversWithoutPagination: action.payload,
      };
    case ALL_DRIVER_VIEW_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ALL_EMPLOYEES_DATA_REQUEST:
      return {
        ...state,
      };
    case ALL_EMPLOYEES_DATA_SUCCESS:
      return {
        ...state,
        allEmployees: action.payload,
      };
    case ALL_EMPLOYEES_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    // DRIVER CREATE
    case DRIVER_CREATE_REQUEST:
      return {
        ...state,
      };
    case DRIVER_CREATE_SUCCESS:
      return {
        ...state,
        driver: [...state.driver, action.payload],
      };
    case DRIVER_CREATE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    // CLIENT VIEW TABLE
    case CLIENT_VIEW_REQUEST:
      return {
        ...state,
        loading : true,
      };
    case CLIENT_VIEW_SUCCESS:
      return {
        ...state,
        loading : false,
        client: action.payload,
      };
    case CLIENT_VIEW_FAIL:
      return {
        ...state,
        loading : false,
        error: action.payload,
      };

    // CLIENT CREATE
    case CLIENT_CREATE_REQUEST:
      return {
        ...state,
      };
    case CLIENT_CREATE_SUCCESS:
      return {
        ...state,
        allClients: [...state.allClients, action.payload],
      };
    case CLIENT_CREATE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    // ALL CLIENTS
    case GET_CLIENTS_REQUEST:
      return {
        ...state,
      };
    case GET_CLIENTS_SUCCESS:
      return {
        ...state,
        allClients: action.payload,
      };
    case GET_CLIENTS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    // CLIENT UPDATE
    case CLIENT_UPDATE_REQUEST:
      return {
        ...state,
      };
    case CLIENT_UPDATE_SUCCESS:
      return {
        ...state,
        client: {
          ...state.client,
          results: state.client.results.map((x) =>
            x.id == action.payload.id ? action.payload : x
          ),
        },
        users: {
          ...state.users,
          results: state.users.results.map((x) =>
            x.id == action.payload.id ? action.payload : x
          ),
        },
      };
    case CLIENT_UPDATE_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    // CLIENT OR USER UPDATE (ADMIN)
    case CLIENT_OR_USER_DELETE_REQUEST:
      return {
        ...state,
      };
    case CLIENT_OR_USER_DELETE_SUCCESS:
      return {
        ...state,
        client: {
          ...state.client,
          results: state?.client?.results?.filter((x) =>
            x.id !== action.payload.id),
        },
        users: {
          ...state.users,
          results: state?.users?.results?.filter((x) =>
            x.id !== action.payload.id),
        },
      };
    case CLIENT_OR_USER_DELETE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

          
    case EXTERNAL_COMPANY_USER_VIEW_REQUEST:
      return {
        ...state,
        loading : true,
      };
    case EXTERNAL_COMPANY_USER_VIEW_SUCCESS:
      return {
        ...state,
        loading : false,
        externalCompanyUsers: action.payload,
      };
    case EXTERNAL_COMPANY_USER_VIEW_FAIL:
      return {
        ...state,
        loading : false,
        error: action.payload,
      };



    case EXTERNAL_COMPANY_USER_UPDATE_REQUEST:
      return {
        ...state,
      };
    case EXTERNAL_COMPANY_USER_UPDATE_SUCCESS:
      return {
        ...state,
        externalCompanyUsers:{ ...state.externalCompanyUsers, results: state.externalCompanyUsers.results.map((x) =>
            x.id == action.payload.id ? action.payload : x
          ),

        },
      };
    case EXTERNAL_COMPANY_USER_UPDATE_FAIL:
      return {
        ...state,
        error: action.payload,
      };


    case EXTERNAL_COMPANY_USER_DELETE_REQUEST:
      return {
        ...state,
      };
    case EXTERNAL_COMPANY_USER_DELETE_SUCCESS:
      return {
        // ...state,
        // externalCompanyUsers: {...state.externalCompanyUsers, results:state.externalCompanyUsers.results.map((x) =>
        //   x.id === action.payload.id ? action.payload : x
        //   )},

          ...state,
          externalCompanyUsers: state.externalCompanyUsers.filter((x) => x.id !== action.payload.id),
      };
    case EXTERNAL_COMPANY_USER_DELETE_FAIL:
      return {
        ...state,
        error: action.payload,
      };
  
      

    default:
      return state;
  }
};
