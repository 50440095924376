import React from "react";

function LoadingComponent() {
  return (
    <div
      className="preloader-container d-flex justify-content-center align-items-center"
      // style={{ display: "none" }}
    >
      <div className="spinner-border" role="status" aria-hidden="true"></div>
    </div>
  );
}

export default LoadingComponent;
