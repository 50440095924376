import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import RideHistoryTable from "./RideHistoryTable";
import moment from "moment";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
    margin: 20,
  },
  section: {
    padding: "10 0",
    width: "90%",
    // borderBottom: 1,
    // borderBottomColor: "#686868",
    textAlign: "center",
    marginBottom: 24,
  },
  heading: {
    fontWeight: "bold",
    fontSize: 16,
  },
  view100: {
    width: "100%",
    flexDirection: "row",
    borderBottomColor: "#000",
    borderRightWidth: 1,
  },
  view50: {
    width: "50%",
    // flexDirection: "row",
    // borderBottomColor: "#000",
    // borderRightWidth: 1,
  },
  view40: {
    width: "29%",
    // flexDirection: "row",
    // borderBottomColor: "#000",
    // borderRightWidth: 1,
    marginRight: 15,
    marginTop: 15,
  },
  border: {
    border: 1,
    borderColor: "black",
    padding: 5,
    minHeight: "150px"
  },
  justifyBetween: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  textBold: {
    fontWeight: "ultrabold",
    fontSize: 10,
    marginTop: 8,
    color: "#000",
  },
  text: {
    fontWeight: "light",
    fontSize: 10,
    marginTop: 8,
    color: "#000",
  },
});

export default function InvoiceFile({ data, auth }) {
  return (
    <Document>
      <Page size="A4" style={styles.page} wrap={true}>
        <View style={styles.section}>
          <Text style={styles.heading}>
            Invoice for the order # {data?.reservationId?.orderId}
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.heading}>{auth.user.companyId.companyName}</Text>
        </View>

        <View styles={styles.section}>
          <View style={styles.view100}>
            <View style={styles.view50}>
              <Text style={styles.textBold}>
                Customer:{" "}
                {data?.reservationId?.customerId.firstName +
                  " " +
                  data?.reservationId?.customerId.lastName}
              </Text>
              <Text style={styles.text}>
                Phone: {data?.reservationId?.customerId.phone}
              </Text>
              {data?.reservationId?.customerId?.externalCompanyId?.name && (
                <Text style={styles.textBold}>
                  Company Details: {data?.reservationId?.customerId?.externalCompanyId?.name}
                </Text>
              )}
              {data?.reservationId?.loadNumber && (
                <Text style={styles.textBold}>
                  Load Number: {data?.reservationId?.loadNumber}
                </Text>
              )}
              {/* {data?.reservationId?.clientIdExternal && (
                <Text style={styles.textBold}>
                  External Client: {data?.reservationId?.clientIdExternal}
                </Text>
              )} */}
            </View>
            <View style={styles.view50}>
              <Text style={styles.textBold}>
                Type: {data?.reservationId?.type.name}
              </Text>
              <Text style={styles.text}>
                Driver: {data?.reservationId?.driverId?.firstName}{" "}
                {data?.reservationId?.driverId?.lastName}
              </Text>
              <Text style={styles.text}>
                Vehicle Asset No: {data?.reservationId?.assetId?.assetNo}
              </Text>
              <Text style={styles.text}>
                Invoice Date: {moment(data?.invoiceDate).format('MM/DD/YYYY')}
              </Text>
            </View>
          </View>
          <View styles={styles.section}>
            <View style={styles.view100}>
              <View style={styles.view50}>
                <Text style={styles.text}>
                  Distance: {data?.reservationId?.totalMileage} mi
                </Text>
                <Text style={styles.text}>
                  Amount: ${data?.reservationId?.totalAmount}
                </Text>
              </View>
            </View>
          </View>
          <View >
            <Text style={styles.text}>
              Trip Details:
            </Text>
          </View>
          <View styles={styles.section}>
            <View style={styles.view100}>
              <View style={styles.view40}>
                <View style={styles.border}>
                  <View style={styles.justifyBetween}>
                    <Text style={styles.textBold}>PickUp Lng:</Text>
                    <Text style={styles.textBold}>
                      {data?.reservationId.pickUpAddress.longitude}
                    </Text>
                  </View>
                  <View style={styles.justifyBetween}>
                    <Text style={styles.textBold}>PickUp Lat: </Text>
                    <Text style={styles.textBold}>
                      {data?.reservationId.pickUpAddress.latitude}
                    </Text>
                  </View>
                  <View style={styles.justifyBetween}>
                    <Text style={styles.textBold}>Actual PickUp Lng:</Text>
                    <Text style={styles.textBold}>
                      {data?.tripId?.pickUpLng}
                    </Text>
                  </View>
                  <View style={styles.justifyBetween}>
                    <Text style={styles.textBold}>Actual PickUp Lat: </Text>
                    <Text style={styles.textBold}>
                      {data?.tripId?.pickUpLat}
                    </Text>
                  </View>{" "}
                  <View style={styles.justifyBetween}>
                    <Text style={styles.textBold}>Pickup Time: </Text>
                    <Text style={styles.textBold}>
                      {moment(data?.reservationId?.pickUpDate).format(
                        "MM/DD/YYYY hh:mm a"
                      )}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={styles.view40}>
                <View style={styles.border}>
                  {" "}
                  <View style={styles.justifyBetween}>
                    <Text style={styles.textBold}>DropOff Lng:</Text>
                    <Text style={styles.text}>
                      {data?.reservationId.dropOffAddress.longitude}
                    </Text>
                  </View>
                  <View style={styles.justifyBetween}>
                    <Text style={styles.textBold}>DropOff Lat: </Text>
                    <Text style={styles.textBold}>
                      {data?.reservationId.dropOffAddress.latitude}
                    </Text>
                  </View>
                  <View style={styles.justifyBetween}>
                    <Text style={styles.textBold}>Actual DropOff Lng:</Text>
                    <Text style={styles.textBold}>
                      {data?.tripId?.dropOffLng}
                    </Text>
                  </View>
                  <View style={styles.justifyBetween}>
                    <Text style={styles.textBold}>Actual DropOff Lat: </Text>
                    <Text style={styles.textBold}>
                      {data?.tripId?.dropOffLat}
                    </Text>
                  </View>{" "}
                  <View style={styles.justifyBetween}>
                    <Text style={styles.textBold}>DropOff Time: </Text>
                    <Text style={styles.textBold}>
                      {moment(data?.reservationId?.dropOffDate).format(
                        "MM/DD/YYYY hh:mm a"
                      )}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={styles.view40}>
                <View style={styles.border}>
                  <View style={styles.justifyBetween}>
                    <Text style={styles.textBold}>
                      HQ to PickUp Point:{" "}
                    </Text>
                    <Text style={styles.textBold}>
                      {moment(data?.tripId?.tripStartTime).format("hh:mm a")}
                    </Text>
                  </View>
                  <View style={styles.justifyBetween}>
                    <Text style={styles.textBold}>
                      Arrived At Pickup:{" "}
                    </Text>
                    <Text style={styles.textBold}>
                      {moment(data?.tripId?.arrivedTime).format("hh:mm a")}
                    </Text>
                  </View>
                  <View style={styles.justifyBetween}>
                    <Text style={styles.textBold}>Driver Waiting Time:</Text>
                    <Text style={styles.textBold}>
                      {data?.tripId?.waitingTimePickUp}
                    </Text>
                  </View>
                  <View style={styles.justifyBetween}>
                    <Text style={styles.textBold}>Actual Pickup Time:</Text>
                    <Text style={styles.textBold}>
                      {moment(data?.tripId?.pickUpTime).format(
                       "hh:mm a"
                      )}
                    </Text>
                  </View>
                  <View style={styles.justifyBetween}>
                    <Text style={styles.textBold}>Ride Started :</Text>
                    <Text style={styles.textBold}>
                      {moment(data?.tripId?.pickUpTime).format("hh:mm a")}
                    </Text>
                  </View>
                  <View style={styles.justifyBetween}>
                    <Text style={styles.textBold}>Actual DropOff Time:</Text>
                    <Text style={styles.textBold}>
                      {moment(data?.tripId?.dropOffTime).format(
                       "hh:mm a"
                      )}
                    </Text>
                  </View>
                  <View style={styles.justifyBetween}>
                    <Text style={styles.textBold}>Trip Duration: </Text>
                    <Text style={styles.textBold}>
                      {data?.tripId?.tripDuration}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View >
            <Text style={styles.text}>
              Ride Details:
            </Text>
          </View>
          <View styles={styles.section}>
            <RideHistoryTable data={data} />
          </View>
        </View>
      </Page>
    </Document>
  );
}
