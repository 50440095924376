import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getExternalCompanies } from "../../../actions/companyAction";
import { addClient } from "../../../actions/userActions";
import AdminSettingPaymentAddCompanyModal from "../adminSettingComponents/AdminSettingPaymentAddCompanyModal";
import MyComponent from "../Map/Map";

const AdminBookingAddClientModal = ({
  showDropDown,
  openDropDown,
  values,
  setValues,
}) => {
  let [showModal, setShowModal] = useState();
  // let [selectedCompany, setSelectedCompany] = useState();
  const externalCompanies = useSelector((state) => state.externalCompanies);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getExternalCompanies({ companyId: auth.user.companyId.id }));
  }, [auth]);

  let [selectedCompanyId, setSelectedCompanyId] = useState("");
  const [values2, setValues2] = useState({
    // companyId: "63cb14f53f55723694dbd185",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phone: "",
    // workPhone: '',
    // otherPhone: '',
    role: "",
    // clientWorkInformation: '',
    address: "",
    // state: '',
    city: "",
    zip: "",
    summary: "",
    type: "",
    isClient: true,
    isCompany: "",
    externalCompanyId: "",
  });

  const dispatch = useDispatch();
  const clientData = {
    companyId: auth.user.companyId.id,
    password: "abAl145dd",
    firstName: values2.firstName,
    lastName: values2.lastName,
    email: values2.email,
    role: "user",
    phone: values2.phone,
    // workPhone: values2.workPhone,
    // otherPhone: values2.otherPhone,
    // clientWorkInformation: values2.clientWorkInformation,
    address: values2.address,
    // state: values2.state,
    city: values2.city,
    zip: values2.zip,
    summary: values2.summary,
    isClient: true,
    isCompany: values2.type === "Company" ? true : false,
    externalCompanyId: values2.type === "Company" ? selectedCompanyId : "",
  };
  // console.log("clientData=>", clientData);
  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log("first")
    for (let key of Object.keys(clientData)) {
      if (clientData[key] === "") {
        delete clientData[key];
      }
    }
    // console.log(clientData)
    dispatch(addClient(clientData, values, setValues, successHandler));
    // setValues2({
    //   companyId: "",
    //   firstName: "",
    //   lastName: "",
    //   email: "",
    //   password: "",
    //   phone: "",
    //   isCompany: "",
    //   externalCompanyId: "",
    //   // workPhone: '',
    //   // otherPhone: '',
    //   role: "",
    //   // clientWorkInformation: '',
    //   address: "",
    //   // state: '',
    //   city: "",
    //   zip: "",
    //   summary: "",
    //   isClient: true,
    // });
  };

  const successHandler = () => {
    openDropDown();
  };
  const handleChange = (fieldName) => (event) => {
    setValues2({
      ...values2,
      [fieldName]: event.target.value,
    });
  };

  const handlePhone = (fieldName) => (event) => {
    if (event.target.value.length === 1) {
      if (event.target.value !== "(") {
        setValues2({
          ...values2,
          [fieldName]: "(" + event.target.value,
        });
      }
    } else if (event.target.value.length === 4) {
      if (event.target.value !== ")") {
        setValues2({
          ...values2,
          [fieldName]: event.target.value + ")-",
        });
      }
    } else if (event.target.value.length === 9) {
      if (event.target.value !== "-") {
        setValues2({
          ...values2,
          [fieldName]: event.target.value + "-",
        });
      }
    } else {
      setValues2({
        ...values2,
        [fieldName]: event.target.value,
      });
    }
  };
  const alphabets = [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "+",
    "-",
    "/",
    "*",
    "!",
    "@",
    "#",
    "$",
    "%",
    "^",
    "&",
    "_",
    "(",
    ")",
    ",",
    ".",
  ];

  return (
    <>
      <div
        className={`modal fade ${showDropDown ? "show modal-y-axis" : "hide"}`}
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                {" "}
                Customer Details
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => openDropDown()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark-grey mb-12"
                      data-label=""
                      htmlFor="after_days"
                    >
                      First Name
                    </label>
                    <sup className="text-danger">*</sup>
                    <input
                      type="text"
                      className="form-control height-35 f-14"
                      placeholder="Enter Your First Name"
                      autoComplete="false"
                      value={values2.firstName}
                      onChange={handleChange("firstName")}
                      required
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark-grey mb-12"
                      data-label=""
                      htmlFor="after_days"
                    >
                      Last Name
                    </label>
                    <sup className="text-danger">*</sup>
                    <input
                      type="text"
                      className="form-control height-35 f-14"
                      placeholder="Enter Your Last Name"
                      autoComplete="false"
                      value={values2.lastName}
                      onChange={handleChange("lastName")}
                      required
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark-grey mb-12"
                      data-label=""
                      htmlFor="after_days"
                    >
                      Email
                    </label>
                    <sup className="text-danger">*</sup>
                    <input
                      type="Email"
                      className="form-control height-35 f-14"
                      placeholder="Enter Your Email"
                      autoComplete="false"
                      value={values2.email}
                      onChange={handleChange("email")}
                      required
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark-grey mb-12"
                      data-label=""
                      htmlFor="after_days"
                    >
                      Primary Phone
                    </label>
                    {/* <sup className="text-danger">*</sup> */}
                    <input
                      type="tel"
                      className="form-control height-35 f-14"
                      minLength="14"
                      maxLength="14"
                      required
                      onKeyDown={(e) => {
                        if (alphabets.includes(e.key)) {
                          e.preventDefault();
                        }
                        if (
                          e.code === "Backspace" &&
                          values2.phone.length === 6
                        ) {
                          setValues2({
                            ...values2,
                            phone: values2.phone.substring(0, 4),
                          });
                        }
                        if (
                          e.code === "Backspace" &&
                          values2.phone.length === 2
                        ) {
                          setValues2({
                            ...values2,
                            phone: "",
                          });
                        }
                        if (
                          e.code === "Backspace" &&
                          values2.phone.length === 10
                        ) {
                          setValues2({
                            ...values2,
                            phone: values2.phone.substring(0, 9),
                          });
                        }
                      }}
                      name="phone"
                      value={values2.phone}
                      onChange={handlePhone("phone")}
                      placeholder="(123)-456-7890"
                    />
                  </div>
                </div>
                {/* <div className="col-md-6">
                                    <div className="form-group my-3">
                                        <label className="f-14 text-dark-grey mb-12" data-label="" htmlFor="after_days">Work Phone</label>
                                        <sup className="text-danger">*</sup>
                                        <input type="tel" className="form-control height-35 f-14" placeholder="Enter Work Phone Number"
                                        autoComplete="false" value={values2.workPhone} onChange={handleChange('workPhone')}/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group my-3">
                                        <label className="f-14 text-dark-grey mb-12" data-label="" htmlFor="after_days">Other phone</label>
                                        <sup className="text-danger">*</sup>
                                        <input type="tel" className="form-control height-35 f-14" placeholder="Enter Other phone Number"
                                        autoComplete="false" value={values2.otherPhone} onChange={handleChange('otherPhone')}/>
                                    </div>
                                </div> */}
                {/* <div className="col-md-6">
                                    <label className="f-14 text-dark-grey mb-12 mt-3" data-label="" htmlFor="default_task_status">Work Information</label>
                                    <sup className="text-danger">*</sup>
                                    <div className="form-group mb-0">
                                        <div className="select-picker">
                                            <select name="default_task_status" className="form-control select-picker" style={{height: '39px'}}>
                                                <option selected="" value="1"> Company Type:</option>
                                                <option value="2"> Individual Contacts</option>
                                                <option value="3"> Corporate Contact</option>
                                            </select>
                                        </div>
                                    </div>
                                </div> */}
                {/* <div className="col-md-6">
                                    <div className="form-group my-3">
                                        <label className="f-14 text-dark-grey mb-12" data-label="" htmlFor="after_days">Work Information</label>
                                        <sup className="text-danger">*</sup>
                                        <input type="text" className="form-control height-35 f-14" placeholder="Enter Your Work Details"
                                        autoComplete="false" value={values2.clientWorkInformation} onChange={handleChange('clientWorkInformation')}/>
                                    </div>
                                </div> */}
                <div className="col-md-6">
                  <div className="form-group my-3 ">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex">
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="Name"
                        >
                          {" "}
                          Service Type
                        </label>
                        <sup className="text-danger">*</sup>
                      </div>
                    </div>
                    <select
                      className="form-control height-35 f-14"
                      onChange={handleChange("type")}
                      required
                    >
                      <option value="">Select Type</option>
                      <option value="Individual">Individual</option>
                      <option value="Company">Payer Name/Company</option>
                    </select>
                    {/* )} */}
                  </div>
                </div>

                {values2.type == "Company" && (
                  <div className="col-md-6">
                    <div className="form-group my-3 ">
                      <div className="d-flex justify-content-between">
                        <div className="d-flex">
                          <label
                            className="f-14 text-dark mb-12"
                            data-label=""
                            htmlFor="Name"
                          >
                            {" "}
                            Select Payer Name/Company
                          </label>
                          <sup className="text-danger">*</sup>
                        </div>
                        <div>
                          <button
                            className="bg-button"
                            type="button"
                            data-toggle="modal"
                            data-target="#exampleModalCenter"
                            onClick={() => setShowModal(true)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="currentColor"
                              className="bi bi-plus-circle-fill fa-w-20 f-20 text-dark"
                              viewBox="0 0 16 16"
                            >
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                            </svg>
                          </button>
                        </div>
                      </div>
                      {/* {serviceValue && serviceValue.name && ( */}
                      <select
                        //   type="text"
                        className="form-control height-35 f-14"
                        id="Name"
                        // onChange={handleChange("externalCompanyId")}
                        onChange={(e) => setSelectedCompanyId(e.target.value)}
                        value={selectedCompanyId}
                        required
                        //   value={serviceValue.name}
                        //   disabled
                      >
                        <option value="">Select Client</option>
                        {externalCompanies.list.map((x) => (
                          <option value={x.id}>{x.name}</option>
                        ))}
                      </select>
                      {/* )} */}
                    </div>
                  </div>
                )}

                {/* <div className="col-md-12">
                                    <div className="form-group my-3">
                                        <label className="f-14 text-dark mb-12" data-label="" htmlFor="Name">Street Address</label>
                                        <sup className="text-danger">*</sup>
                                        <MyComponent values2={values2} setValues2={setValues2}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group my-3">
                                        <label className="f-14 text-dark mb-12" data-label="" htmlFor="City">City</label>
                                        <sup className="text-danger">*</sup>
                                        <input type="text" className="form-control height-35 f-14" placeholder="" id="City" value={values2.city} required/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group my-3">
                                        <label className="f-14 text-dark mb-12" data-label="" htmlFor="cname">State</label>
                                        <sup className="text-danger">*</sup>
                                        <input type="text" className="form-control height-35 f-14" placeholder="" id="State" value={values2.state} required/>
                                    </div>
                                </div> */}
                {/* <div className="col-md-4">
                                    <div className="form-group my-3">
                                        <label className="f-14 text-dark mb-12" data-label="" htmlFor="Zipcode">Zip Code</label>
                                        <sup className="text-danger">*</sup>
                                        <input type="text" className="form-control height-35 f-14" placeholder="" id="Zipcode" value={values2.zip} required/>
                                    </div>
                                </div> */}
                <div className="col-md-12">
                  <label htmlFor="allowed_file_types">Note: </label>
                  <textarea
                    type="text"
                    name="allowed_file_types"
                    id="allowed_file_types"
                    placeholder="Enter The Note"
                    className="form-control f-14"
                    autoComplete="false"
                    value={values2.summary}
                    onChange={handleChange("summary")}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn-secondary rounded f-14 p-2 mr-3"
                data-dismiss="modal"
                onClick={() => openDropDown()}
              >
                Close
              </button>
              <button
                type="button"
                className="btn-primary rounded f-14 p-2 mr-3"
                onClick={handleSubmit}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
      <AdminSettingPaymentAddCompanyModal
        {...{ showModal, setShowModal, setSelectedCompanyId }}
      />
    </>
  );
};
export default AdminBookingAddClientModal;
