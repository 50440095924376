/** @format */

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addPermission } from "../../../actions/permissionActions";
import { useNavigate } from "react-router-dom";

const AdminPermissionCreatePermissionComponent = () => {
  const auth = useSelector((state) => state.auth);
  const permission = useSelector((state) => state.permission);
  const [values, setValues] = useState({
    // "companyId": "63cb14f53f55723694dbd185",
    name: "",
    dashboard: {
      isAllow: false,
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    booking: {
      isAllow: false,
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    clients: {
      isAllow: false,
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    dispatching: {
      isAllow: false,
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    track: {
      isAllow: false,
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    reports: {
      isAllow: false,
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    settings: {
      isAllow: false,
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    permission: {
      isAllow: false,
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    user: {
      isAllow: false,
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    dashboardDriver: {
      isAllow: false,
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    dashboardDispatcher: {
      isAllow: false,
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    myOrder: {
      isAllow: false,
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    myRoutes: {
      isAllow: false,
      create: false,
      read: false,
      update: false,
      delete: false,
    },
  });
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  // console.log(values);
  const dispatch = useDispatch();
  const permissionData = {
    companyId: auth.user.companyId.id,
    name: values.name,
    dashboard: {
      isAllow: values.dashboard.isAllow,
      create: values.dashboard.create,
      read: values.dashboard.read,
      update: values.dashboard.update,
      delete: values.dashboard.delete,
    },
    booking: {
      isAllow: values.booking.isAllow,
      create: values.booking.create,
      read: values.booking.read,
      update: values.booking.update,
      delete: values.booking.delete,
    },
    clients: {
      isAllow: values.clients.isAllow,
      create: values.clients.create,
      read: values.clients.read,
      update: values.clients.update,
      delete: values.clients.delete,
    },
    dispatching: {
      isAllow: values.dispatching.isAllow,
      create: values.dispatching.create,
      read: values.dispatching.read,
      update: values.dispatching.update,
      delete: values.dispatching.delete,
    },
    track: {
      isAllow: values.track.isAllow,
      create: values.track.create,
      read: values.track.read,
      update: values.track.update,
      delete: values.track.delete,
    },
    reports: {
      isAllow: values.reports.isAllow,
      create: values.reports.create,
      read: values.reports.read,
      update: values.reports.update,
      delete: values.reports.delete,
    },
    settings: {
      isAllow: values.settings.isAllow,
      create: values.settings.create,
      read: values.settings.read,
      update: values.settings.update,
      delete: values.settings.delete,
    },
    permission: {
      isAllow: values.permission.isAllow,
      create: values.permission.create,
      read: values.permission.read,
      update: values.permission.update,
      delete: values.permission.delete,
    },
    user: {
      isAllow: values.user.isAllow,
      create: values.user.create,
      read: values.user.read,
      update: values.user.update,
      delete: values.user.delete,
    },
    dashboardDriver: {
      isAllow: values.dashboardDriver.isAllow,
      create: values.dashboardDriver.create,
      read: values.dashboardDriver.read,
      update: values.dashboardDriver.update,
      delete: values.dashboardDriver.delete,
    },
    dashboardDispatcher: {
      isAllow: values.dashboardDispatcher.isAllow,
      create: values.dashboardDispatcher.create,
      read: values.dashboardDispatcher.read,
      update: values.dashboardDispatcher.update,
      delete: values.dashboardDispatcher.delete,
    },
    myOrder: {
      isAllow: values.myOrder.isAllow,
      create: values.myOrder.create,
      read: values.myOrder.read,
      update: values.myOrder.update,
      delete: values.myOrder.delete,
    },
    myRoutes: {
      isAllow: values.myRoutes.isAllow,
      create: values.myRoutes.create,
      read: values.myRoutes.read,
      update: values.myRoutes.update,
      delete: values.myRoutes.delete,
    },
  };
  let navigate = useNavigate();
  const navigatePermission = () => {
    navigate("/permission");
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log("first");
    dispatch(addPermission(permissionData, navigatePermission));
    // openDropDown()
  };

  return (
    <>
      <div className="d-flex flex-column w-tables rounded mt-3 bg-white ">
        <div className="d-flex border">
          <div className="flex-grow-1">
            <div className="dataTables_length d-flex" id="leads-table_length">
              <div className="p-2">
                {/* <label className="f-18">Role Title: </label> */}
                {/* <sup className="text-danger"> *</sup> */}
                <input
                  type="text"
                  className="form-control height-35 f-14"
                  required
                  placeholder="Role Title..."
                  autoComplete="false"
                  value={values.name}
                  onChange={handleChange("name")}
                />
              </div>
            </div>
          </div>
          {/* <!-- SEARCH BY TASK START --> */}
          {/* <div className="task-search d-flex  py-1 px-lg-3 px-0 border-right-grey align-items-center">
                        <form className="w-100 mr-1 mr-lg-0 mr-md-1 ml-md-1 ml-0 ml-lg-0">
                            <div className="input-group bg-grey rounded">
                                <div className="input-group-prepend">
                                    <span className="input-group-text border-0 bg-additional-grey">
                                        <i className="fa fa-search f-13 text-dark-grey"></i>
                                    </span>
                                </div>
                                <input type="text" className="form-control f-14 p-1 border-additional-grey" id="search-text-field" placeholder="Start typing to search"/>
                            </div>
                        </form>
                    </div> */}
          {/* <!-- SEARCH BY TASK END --> */}
        </div>
        <div
          id="leads-table_wrapper"
          className="dataTables_wrapper dt-bootstrap4 no-footer"
        >
          <div className="row">
            <div className="col-sm-12">
              <div className="row">
                <div className="col-md-4 my-3 pl-5">
                  <h4 className=" mb-0 f-18 text-capitalize font-weight-bold">
                    {" "}
                    Module Name{" "}
                  </h4>
                </div>
                <div className="col-md-2 my-3">
                  <h4 className=" mb-0 f-18 text-capitalize font-weight-bold">
                    {" "}
                    Create{" "}
                  </h4>
                </div>
                <div className="col-md-2 my-3">
                  <h4 className=" mb-0 f-18 text-capitalize font-weight-bold">
                    {" "}
                    Read{" "}
                  </h4>
                </div>
                <div className="col-md-2 my-3">
                  <h4 className=" mb-0 f-18 text-capitalize font-weight-bold">
                    {" "}
                    Update{" "}
                  </h4>
                </div>
                <div className="col-md-2 my-3">
                  <h4 className=" mb-0 f-18 text-capitalize font-weight-bold">
                    {" "}
                    Delete{" "}
                  </h4>
                </div>
              </div>
              <div className="row ">
                <div className="col-md-4 pl-5">
                  <div className="form-group my-3">
                    <div className="d-flex">
                      <div className="btn-choice">
                        <label className="switch">
                          <input
                            type="checkbox"
                            onClick={() =>
                              setValues({
                                ...values,
                                dashboard: {
                                  ...values.dashboard,
                                  isAllow: !values.dashboard.isAllow,
                                },
                              })
                            }
                            className="switch-input"
                            id="toggle"
                          />
                          <span
                            className="switch-label"
                            data-on=""
                            data-off=""
                          ></span>
                          <span className="switch-handle"></span>
                        </label>
                      </div>
                      <div className="pl-3 my-2">
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="clnumber"
                        >
                          Dashboard (Admin)
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 my-3">
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={() =>
                          setValues({
                            ...values,
                            dashboard: {
                              ...values.dashboard,
                              create: !values.dashboard.create,
                            },
                          })
                        }
                        className="switch-input"
                        id="toggle"
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
                <div className="col-md-2 my-3">
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={() =>
                          setValues({
                            ...values,
                            dashboard: {
                              ...values.dashboard,
                              read: !values.dashboard.read,
                            },
                          })
                        }
                        className="switch-input"
                        id="toggle"
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
                <div className="col-md-2 my-3">
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={() =>
                          setValues({
                            ...values,
                            dashboard: {
                              ...values.dashboard,
                              update: !values.dashboard.update,
                            },
                          })
                        }
                        className="switch-input"
                        id="toggle"
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
                <div className="col-md-2 my-3">
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={() =>
                          setValues({
                            ...values,
                            dashboard: {
                              ...values.dashboard,
                              delete: !values.dashboard.delete,
                            },
                          })
                        }
                        className="switch-input"
                        id="toggle"
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 pl-5">
                  <div className="form-group my-3">
                    <div className="d-flex">
                      <div className="btn-choice">
                        <label className="switch">
                          <input
                            type="checkbox"
                            onClick={() =>
                              setValues({
                                ...values,
                                booking: {
                                  ...values.booking,
                                  isAllow: !values.booking.isAllow,
                                },
                              })
                            }
                            className="switch-input"
                            id="toggle"
                          />
                          <span
                            className="switch-label"
                            data-on=""
                            data-off=""
                          ></span>
                          <span className="switch-handle"></span>
                        </label>
                      </div>
                      <div className="pl-3 my-2">
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="clnumber"
                        >
                          Booking
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 my-3">
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={() =>
                          setValues({
                            ...values,
                            booking: {
                              ...values.booking,
                              create: !values.booking.create,
                            },
                          })
                        }
                        className="switch-input"
                        id="toggle"
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
                <div className="col-md-2 my-3">
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={() =>
                          setValues({
                            ...values,
                            booking: {
                              ...values.booking,
                              read: !values.booking.read,
                            },
                          })
                        }
                        className="switch-input"
                        id="toggle"
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
                <div className="col-md-2 my-3">
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={() =>
                          setValues({
                            ...values,
                            booking: {
                              ...values.booking,
                              update: !values.booking.update,
                            },
                          })
                        }
                        className="switch-input"
                        id="toggle"
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
                <div className="col-md-2 my-3">
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={() =>
                          setValues({
                            ...values,
                            booking: {
                              ...values.booking,
                              delete: !values.booking.delete,
                            },
                          })
                        }
                        className="switch-input"
                        id="toggle"
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 pl-5">
                  <div className="form-group my-3">
                    <div className="d-flex">
                      <div className="btn-choice">
                        <label className="switch">
                          <input
                            type="checkbox"
                            onClick={() =>
                              setValues({
                                ...values,
                                dispatching: {
                                  ...values.dispatching,
                                  isAllow: !values.dispatching.isAllow,
                                },
                              })
                            }
                            className="switch-input"
                            id="toggle"
                          />
                          <span
                            className="switch-label"
                            data-on=""
                            data-off=""
                          ></span>
                          <span className="switch-handle"></span>
                        </label>
                      </div>
                      <div className="pl-3 my-2">
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="clnumber"
                        >
                          Dispatching
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 my-3">
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={() =>
                          setValues({
                            ...values,
                            dispatching: {
                              ...values.dispatching,
                              create: !values.dispatching.create,
                            },
                          })
                        }
                        className="switch-input"
                        id="toggle"
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
                <div className="col-md-2 my-3">
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={() =>
                          setValues({
                            ...values,
                            dispatching: {
                              ...values.dispatching,
                              read: !values.dispatching.read,
                            },
                          })
                        }
                        className="switch-input"
                        id="toggle"
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
                <div className="col-md-2 my-3">
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={() =>
                          setValues({
                            ...values,
                            dispatching: {
                              ...values.dispatching,
                              update: !values.dispatching.update,
                            },
                          })
                        }
                        className="switch-input"
                        id="toggle"
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
                <div className="col-md-2 my-3">
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={() =>
                          setValues({
                            ...values,
                            dispatching: {
                              ...values.dispatching,
                              delete: !values.dispatching.delete,
                            },
                          })
                        }
                        className="switch-input"
                        id="toggle"
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 pl-5">
                  <div className="form-group my-3">
                    <div className="d-flex">
                      <div className="btn-choice">
                        <label className="switch">
                          <input
                            type="checkbox"
                            onClick={() =>
                              setValues({
                                ...values,
                                clients: {
                                  ...values.clients,
                                  isAllow: !values.clients.isAllow,
                                },
                              })
                            }
                            className="switch-input"
                            id="toggle"
                          />
                          <span
                            className="switch-label"
                            data-on=""
                            data-off=""
                          ></span>
                          <span className="switch-handle"></span>
                        </label>
                      </div>
                      <div className="pl-3 my-2">
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="clnumber"
                        >
                          Clients
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 my-3">
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={() =>
                          setValues({
                            ...values,
                            clients: {
                              ...values.clients,
                              create: !values.clients.create,
                            },
                          })
                        }
                        className="switch-input"
                        id="toggle"
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
                <div className="col-md-2 my-3">
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={() =>
                          setValues({
                            ...values,
                            clients: {
                              ...values.clients,
                              read: !values.clients.read,
                            },
                          })
                        }
                        className="switch-input"
                        id="toggle"
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
                <div className="col-md-2 my-3">
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={() =>
                          setValues({
                            ...values,
                            clients: {
                              ...values.clients,
                              update: !values.clients.update,
                            },
                          })
                        }
                        className="switch-input"
                        id="toggle"
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
                <div className="col-md-2 my-3">
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={() =>
                          setValues({
                            ...values,
                            clients: {
                              ...values.clients,
                              delete: !values.clients.delete,
                            },
                          })
                        }
                        className="switch-input"
                        id="toggle"
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row ">
                <div className="col-md-4 pl-5">
                  <div className="form-group my-3">
                    <div className="d-flex">
                      <div className="btn-choice">
                        <label className="switch">
                          <input
                            type="checkbox"
                            onClick={() =>
                              setValues({
                                ...values,
                                track: {
                                  ...values.track,
                                  isAllow: !values.track.isAllow,
                                },
                              })
                            }
                            className="switch-input"
                            id="toggle"
                          />
                          <span
                            className="switch-label"
                            data-on=""
                            data-off=""
                          ></span>
                          <span className="switch-handle"></span>
                        </label>
                      </div>
                      <div className="pl-3 my-2">
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="clnumber"
                        >
                          Track
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 my-3">
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={() =>
                          setValues({
                            ...values,
                            track: {
                              ...values.track,
                              create: !values.track.create,
                            },
                          })
                        }
                        className="switch-input"
                        id="toggle"
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
                <div className="col-md-2 my-3">
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={() =>
                          setValues({
                            ...values,
                            track: {
                              ...values.track,
                              read: !values.track.read,
                            },
                          })
                        }
                        className="switch-input"
                        id="toggle"
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
                <div className="col-md-2 my-3">
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={() =>
                          setValues({
                            ...values,
                            track: {
                              ...values.track,
                              update: !values.track.update,
                            },
                          })
                        }
                        className="switch-input"
                        id="toggle"
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
                <div className="col-md-2 my-3">
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={() =>
                          setValues({
                            ...values,
                            track: {
                              ...values.track,
                              delete: !values.track.delete,
                            },
                          })
                        }
                        className="switch-input"
                        id="toggle"
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 pl-5">
                  <div className="form-group my-3">
                    <div className="d-flex">
                      <div className="btn-choice">
                        <label className="switch">
                          <input
                            type="checkbox"
                            onClick={() =>
                              setValues({
                                ...values,
                                reports: {
                                  ...values.reports,
                                  isAllow: !values.reports.isAllow,
                                },
                              })
                            }
                            className="switch-input"
                            id="toggle"
                          />
                          <span
                            className="switch-label"
                            data-on=""
                            data-off=""
                          ></span>
                          <span className="switch-handle"></span>
                        </label>
                      </div>
                      <div className="pl-3 my-2">
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="clnumber"
                        >
                          Reports
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 my-3">
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={() =>
                          setValues({
                            ...values,
                            reports: {
                              ...values.reports,
                              create: !values.reports.create,
                            },
                          })
                        }
                        className="switch-input"
                        id="toggle"
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
                <div className="col-md-2 my-3">
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={() =>
                          setValues({
                            ...values,
                            reports: {
                              ...values.reports,
                              read: !values.reports.read,
                            },
                          })
                        }
                        className="switch-input"
                        id="toggle"
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
                <div className="col-md-2 my-3">
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={() =>
                          setValues({
                            ...values,
                            reports: {
                              ...values.reports,
                              update: !values.reports.update,
                            },
                          })
                        }
                        className="switch-input"
                        id="toggle"
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
                <div className="col-md-2 my-3">
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={() =>
                          setValues({
                            ...values,
                            reports: {
                              ...values.reports,
                              delete: !values.reports.delete,
                            },
                          })
                        }
                        className="switch-input"
                        id="toggle"
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 pl-5">
                  <div className="form-group my-3">
                    <div className="d-flex">
                      <div className="btn-choice">
                        <label className="switch">
                          <input
                            type="checkbox"
                            onClick={() =>
                              setValues({
                                ...values,
                                settings: {
                                  ...values.settings,
                                  isAllow: !values.settings.isAllow,
                                },
                              })
                            }
                            className="switch-input"
                            id="toggle"
                          />
                          <span
                            className="switch-label"
                            data-on=""
                            data-off=""
                          ></span>
                          <span className="switch-handle"></span>
                        </label>
                      </div>
                      <div className="pl-3 my-2">
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="clnumber"
                        >
                          Settings
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 my-3">
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={() =>
                          setValues({
                            ...values,
                            settings: {
                              ...values.settings,
                              create: !values.settings.create,
                            },
                          })
                        }
                        className="switch-input"
                        id="toggle"
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
                <div className="col-md-2 my-3">
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={() =>
                          setValues({
                            ...values,
                            settings: {
                              ...values.settings,
                              read: !values.settings.read,
                            },
                          })
                        }
                        className="switch-input"
                        id="toggle"
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
                <div className="col-md-2 my-3">
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={() =>
                          setValues({
                            ...values,
                            settings: {
                              ...values.settings,
                              update: !values.settings.update,
                            },
                          })
                        }
                        className="switch-input"
                        id="toggle"
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
                <div className="col-md-2 my-3">
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={() =>
                          setValues({
                            ...values,
                            settings: {
                              ...values.settings,
                              delete: !values.settings.delete,
                            },
                          })
                        }
                        className="switch-input"
                        id="toggle"
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 pl-5">
                  <div className="form-group my-3">
                    <div className="d-flex">
                      <div className="btn-choice">
                        <label className="switch">
                          <input
                            type="checkbox"
                            onClick={() =>
                              setValues({
                                ...values,
                                permission: {
                                  ...values.permission,
                                  isAllow: !values.permission.isAllow,
                                },
                              })
                            }
                            className="switch-input"
                            id="toggle"
                          />
                          <span
                            className="switch-label"
                            data-on=""
                            data-off=""
                          ></span>
                          <span className="switch-handle"></span>
                        </label>
                      </div>
                      <div className="pl-3 my-2">
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="clnumber"
                        >
                          Permission
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 my-3">
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={() =>
                          setValues({
                            ...values,
                            permission: {
                              ...values.permission,
                              create: !values.permission.create,
                            },
                          })
                        }
                        className="switch-input"
                        id="toggle"
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
                <div className="col-md-2 my-3">
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={() =>
                          setValues({
                            ...values,
                            permission: {
                              ...values.permission,
                              read: !values.permission.read,
                            },
                          })
                        }
                        className="switch-input"
                        id="toggle"
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
                <div className="col-md-2 my-3">
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={() =>
                          setValues({
                            ...values,
                            permission: {
                              ...values.permission,
                              update: !values.permission.update,
                            },
                          })
                        }
                        className="switch-input"
                        id="toggle"
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
                <div className="col-md-2 my-3">
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={() =>
                          setValues({
                            ...values,
                            permission: {
                              ...values.permission,
                              delete: !values.permission.delete,
                            },
                          })
                        }
                        className="switch-input"
                        id="toggle"
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 pl-5">
                  <div className="form-group my-3">
                    <div className="d-flex">
                      <div className="btn-choice">
                        <label className="switch">
                          <input
                            type="checkbox"
                            onClick={() =>
                              setValues({
                                ...values,
                                user: {
                                  ...values.user,
                                  isAllow: !values.user.isAllow,
                                },
                              })
                            }
                            className="switch-input"
                            id="toggle"
                          />
                          <span
                            className="switch-label"
                            data-on=""
                            data-off=""
                          ></span>
                          <span className="switch-handle"></span>
                        </label>
                      </div>
                      <div className="pl-3 my-2">
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="clnumber"
                        >
                          User
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 my-3">
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={() =>
                          setValues({
                            ...values,
                            user: {
                              ...values.user,
                              create: !values.user.create,
                            },
                          })
                        }
                        className="switch-input"
                        id="toggle"
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
                <div className="col-md-2 my-3">
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={() =>
                          setValues({
                            ...values,
                            user: { ...values.user, read: !values.user.read },
                          })
                        }
                        className="switch-input"
                        id="toggle"
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
                <div className="col-md-2 my-3">
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={() =>
                          setValues({
                            ...values,
                            user: {
                              ...values.user,
                              update: !values.user.update,
                            },
                          })
                        }
                        className="switch-input"
                        id="toggle"
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
                <div className="col-md-2 my-3">
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={() =>
                          setValues({
                            ...values,
                            user: {
                              ...values.user,
                              delete: !values.user.delete,
                            },
                          })
                        }
                        className="switch-input"
                        id="toggle"
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-md-4 pl-5">
              <div className="form-group my-3">
                <div className="d-flex">
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={() =>
                          setValues({
                            ...values,
                            dashboardDriver: {
                              ...values.dashboardDriver,
                              isAllow: !values.dashboardDriver.isAllow,
                            },
                          })
                        }
                        className="switch-input"
                        id="toggle"
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                  <div className="pl-3 my-2">
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="clnumber"
                    >
                      Dashboard (Driver)
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2 my-3">
              <div className="btn-choice">
                <label className="switch">
                  <input
                    type="checkbox"
                    onClick={() =>
                      setValues({
                        ...values,
                        dashboardDriver: {
                          ...values.dashboardDriver,
                          create: !values.dashboardDriver.create,
                        },
                      })
                    }
                    className="switch-input"
                    id="toggle"
                  />
                  <span
                    className="switch-label"
                    data-on=""
                    data-off=""
                  ></span>
                  <span className="switch-handle"></span>
                </label>
              </div>
            </div>
            <div className="col-md-2 my-3">
              <div className="btn-choice">
                <label className="switch">
                  <input
                    type="checkbox"
                    onClick={() =>
                      setValues({
                        ...values,
                        dashboardDriver: {
                          ...values.dashboardDriver,
                          read: !values.dashboardDriver.read,
                        },
                      })
                    }
                    className="switch-input"
                    id="toggle"
                  />
                  <span
                    className="switch-label"
                    data-on=""
                    data-off=""
                  ></span>
                  <span className="switch-handle"></span>
                </label>
              </div>
            </div>
            <div className="col-md-2 my-3">
              <div className="btn-choice">
                <label className="switch">
                  <input
                    type="checkbox"
                    onClick={() =>
                      setValues({
                        ...values,
                        dashboardDriver: {
                          ...values.dashboardDriver,
                          update: !values.dashboardDriver.update,
                        },
                      })
                    }
                    className="switch-input"
                    id="toggle"
                  />
                  <span
                    className="switch-label"
                    data-on=""
                    data-off=""
                  ></span>
                  <span className="switch-handle"></span>
                </label>
              </div>
            </div>
            <div className="col-md-2 my-3">
              <div className="btn-choice">
                <label className="switch">
                  <input
                    type="checkbox"
                    onClick={() =>
                      setValues({
                        ...values,
                        dashboardDriver: {
                          ...values.dashboardDriver,
                          delete: !values.dashboardDriver.delete,
                        },
                      })
                    }
                    className="switch-input"
                    id="toggle"
                  />
                  <span
                    className="switch-label"
                    data-on=""
                    data-off=""
                  ></span>
                  <span className="switch-handle"></span>
                </label>
              </div>
            </div>
          </div>

          <div className="row ">
            <div className="col-md-4 pl-5">
              <div className="form-group my-3">
                <div className="d-flex">
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={() =>
                          setValues({
                            ...values,
                            dashboardDispatcher: {
                              ...values.dashboardDispatcher,
                              isAllow: !values.dashboardDispatcher.isAllow,
                            },
                          })
                        }
                        className="switch-input"
                        id="toggle"
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                  <div className="pl-3 my-2">
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="clnumber"
                    >
                      Dashboard (Dispatcher)
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2 my-3">
              <div className="btn-choice">
                <label className="switch">
                  <input
                    type="checkbox"
                    onClick={() =>
                      setValues({
                        ...values,
                        dashboardDispatcher: {
                          ...values.dashboardDispatcher,
                          create: !values.dashboardDispatcher.create,
                        },
                      })
                    }
                    className="switch-input"
                    id="toggle"
                  />
                  <span
                    className="switch-label"
                    data-on=""
                    data-off=""
                  ></span>
                  <span className="switch-handle"></span>
                </label>
              </div>
            </div>
            <div className="col-md-2 my-3">
              <div className="btn-choice">
                <label className="switch">
                  <input
                    type="checkbox"
                    onClick={() =>
                      setValues({
                        ...values,
                        dashboardDispatcher: {
                          ...values.dashboardDispatcher,
                          read: !values.dashboardDispatcher.read,
                        },
                      })
                    }
                    className="switch-input"
                    id="toggle"
                  />
                  <span
                    className="switch-label"
                    data-on=""
                    data-off=""
                  ></span>
                  <span className="switch-handle"></span>
                </label>
              </div>
            </div>
            <div className="col-md-2 my-3">
              <div className="btn-choice">
                <label className="switch">
                  <input
                    type="checkbox"
                    onClick={() =>
                      setValues({
                        ...values,
                        dashboardDispatcher: {
                          ...values.dashboardDispatcher,
                          update: !values.dashboardDispatcher.update,
                        },
                      })
                    }
                    className="switch-input"
                    id="toggle"
                  />
                  <span
                    className="switch-label"
                    data-on=""
                    data-off=""
                  ></span>
                  <span className="switch-handle"></span>
                </label>
              </div>
            </div>
            <div className="col-md-2 my-3">
              <div className="btn-choice">
                <label className="switch">
                  <input
                    type="checkbox"
                    onClick={() =>
                      setValues({
                        ...values,
                        dashboardDispatcher: {
                          ...values.dashboardDispatcher,
                          delete: !values.dashboardDispatcher.delete,
                        },
                      })
                    }
                    className="switch-input"
                    id="toggle"
                  />
                  <span
                    className="switch-label"
                    data-on=""
                    data-off=""
                  ></span>
                  <span className="switch-handle"></span>
                </label>
              </div>
            </div>
          </div>
          
          <div className="row ">
            <div className="col-md-4 pl-5">
              <div className="form-group my-3">
                <div className="d-flex">
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={() =>
                          setValues({
                            ...values,
                            myOrder: {
                              ...values.myOrder,
                              isAllow: !values.myOrder.isAllow,
                            },
                          })
                        }
                        className="switch-input"
                        id="toggle"
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                  <div className="pl-3 my-2">
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="clnumber"
                    >
                      My Booking (Driver)
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2 my-3">
              <div className="btn-choice">
                <label className="switch">
                  <input
                    type="checkbox"
                    onClick={() =>
                      setValues({
                        ...values,
                        myOrder: {
                          ...values.myOrder,
                          create: !values.myOrder.create,
                        },
                      })
                    }
                    className="switch-input"
                    id="toggle"
                  />
                  <span
                    className="switch-label"
                    data-on=""
                    data-off=""
                  ></span>
                  <span className="switch-handle"></span>
                </label>
              </div>
            </div>
            <div className="col-md-2 my-3">
              <div className="btn-choice">
                <label className="switch">
                  <input
                    type="checkbox"
                    onClick={() =>
                      setValues({
                        ...values,
                        myOrder: {
                          ...values.myOrder,
                          read: !values.myOrder.read,
                        },
                      })
                    }
                    className="switch-input"
                    id="toggle"
                  />
                  <span
                    className="switch-label"
                    data-on=""
                    data-off=""
                  ></span>
                  <span className="switch-handle"></span>
                </label>
              </div>
            </div>
            <div className="col-md-2 my-3">
              <div className="btn-choice">
                <label className="switch">
                  <input
                    type="checkbox"
                    onClick={() =>
                      setValues({
                        ...values,
                        myOrder: {
                          ...values.myOrder,
                          update: !values.myOrder.update,
                        },
                      })
                    }
                    className="switch-input"
                    id="toggle"
                  />
                  <span
                    className="switch-label"
                    data-on=""
                    data-off=""
                  ></span>
                  <span className="switch-handle"></span>
                </label>
              </div>
            </div>
            <div className="col-md-2 my-3">
              <div className="btn-choice">
                <label className="switch">
                  <input
                    type="checkbox"
                    onClick={() =>
                      setValues({
                        ...values,
                        myOrder: {
                          ...values.myOrder,
                          delete: !values.myOrder.delete,
                        },
                      })
                    }
                    className="switch-input"
                    id="toggle"
                  />
                  <span
                    className="switch-label"
                    data-on=""
                    data-off=""
                  ></span>
                  <span className="switch-handle"></span>
                </label>
              </div>
            </div>
          </div>

          <div className="row ">
            <div className="col-md-4 pl-5">
              <div className="form-group my-3">
                <div className="d-flex">
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={() =>
                          setValues({
                            ...values,
                            myRoutes: {
                              ...values.myRoutes,
                              isAllow: !values.myRoutes.isAllow,
                            },
                          })
                        }
                        className="switch-input"
                        id="toggle"
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                  <div className="pl-3 my-2">
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="clnumber"
                    >
                      My Routes (Driver)
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2 my-3">
              <div className="btn-choice">
                <label className="switch">
                  <input
                    type="checkbox"
                    onClick={() =>
                      setValues({
                        ...values,
                        myRoutes: {
                          ...values.myRoutes,
                          create: !values.myRoutes.create,
                        },
                      })
                    }
                    className="switch-input"
                    id="toggle"
                  />
                  <span
                    className="switch-label"
                    data-on=""
                    data-off=""
                  ></span>
                  <span className="switch-handle"></span>
                </label>
              </div>
            </div>
            <div className="col-md-2 my-3">
              <div className="btn-choice">
                <label className="switch">
                  <input
                    type="checkbox"
                    onClick={() =>
                      setValues({
                        ...values,
                        myRoutes: {
                          ...values.myRoutes,
                          read: !values.myRoutes.read,
                        },
                      })
                    }
                    className="switch-input"
                    id="toggle"
                  />
                  <span
                    className="switch-label"
                    data-on=""
                    data-off=""
                  ></span>
                  <span className="switch-handle"></span>
                </label>
              </div>
            </div>
            <div className="col-md-2 my-3">
              <div className="btn-choice">
                <label className="switch">
                  <input
                    type="checkbox"
                    onClick={() =>
                      setValues({
                        ...values,
                        myRoutes: {
                          ...values.myRoutes,
                          update: !values.myRoutes.update,
                        },
                      })
                    }
                    className="switch-input"
                    id="toggle"
                  />
                  <span
                    className="switch-label"
                    data-on=""
                    data-off=""
                  ></span>
                  <span className="switch-handle"></span>
                </label>
              </div>
            </div>
            <div className="col-md-2 my-3">
              <div className="btn-choice">
                <label className="switch">
                  <input
                    type="checkbox"
                    onClick={() =>
                      setValues({
                        ...values,
                        myRoutes: {
                          ...values.myRoutes,
                          delete: !values.myRoutes.delete,
                        },
                      })
                    }
                    className="switch-input"
                    id="toggle"
                  />
                  <span
                    className="switch-label"
                    data-on=""
                    data-off=""
                  ></span>
                  <span className="switch-handle"></span>
                </label>
              </div>
            </div>
          </div>
          <div className="d-flex border">
            <div className="flex-grow-1">
              <div
                className="dataTables_info"
                id="leads-table_info"
                role="status"
                aria-live="polite"
              ></div>
            </div>
            <div>
              <div
                className="dataTables_paginate paging_simple_numbers"
                id="leads-table_paginate"
              >
                <button
                  type="button"
                  className="btn-primary rounded f-14 p-2 mr-3"
                  onClick={handleSubmit}
                >
                      {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                            <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                          </svg> */}
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminPermissionCreatePermissionComponent;
