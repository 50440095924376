export function navigateUserFunc (user, navigate) {
    let navigateUrl = ''
    if (user?.isExternalCompany === true) {
        navigate("/clients-list")
    } else {
        navigateUrl = Object.values(user?.permissionId).find((innerObj) => {
            return Object.entries(innerObj).some(([key, value]) => {
                return key === 'isAllow' && value === true;
            });
        });
    
    // console.log(navigateUrl, "aaaaaaaaaaaaaaaaaaaaaaa")
    // if (user?.permissionId?.dashboard?.isAllow === true) {
    //     navigate('/dashboard-admin')
    // }
    // else if (user?.permissionId?.dashboardDriver?.isAllow === true) {
    //     navigate('/dashboard-driver')
    // }
    // else if (user?.permissionId?.dashboardDispatcher?.isAllow === true) {
    //     navigate('/dashboard-dispatcher')
    // }
        navigate(navigateUrl.urlRoute)
    }
};